const helper = require('../../../utils/helper');
const ux = require('../../ux-tracking-center');

export const initFiscalMonth = () => {
  return fetch(
    helper.getAPIHost() + '/api/getFiscalMonths',
    helper.apiHeaders(),
  )
    .then((response) => response.json())
    .then((result) => {
      console.log(result);
      console.log('In initFiscalMonth');
      helper.catchServer(result);
      localStorage.setItem(
        'FiscalCurrentMonth',
        JSON.stringify(result.result.currentMonth),
      );
      localStorage.setItem(
        'FiscalMonths',
        JSON.stringify(result.result.monthLists),
      );

      let totalMonths = result.result.monthLists.length;
      let coachingMonth = result.result.monthLists.slice(
        totalMonths - 4,
        totalMonths,
      );
      if (result.result.futureMonths !== undefined) {
        localStorage.setItem(
          'FiscalFutureMonths',
          JSON.stringify(result.result.futureMonths),
        );
        coachingMonth.push(...result.result.futureMonths.slice(0, 2));
      }
      localStorage.setItem(
        'FiscalMonthsCoaching',
        JSON.stringify(coachingMonth),
      );

      if (window.innerWidth < 1285) {
        helper.setTouch(true);
      }
      return true;
    });
};

export const initDashboardKPI = () => {
  let view = localStorage.getItem('BobSquidManagerView');
  // return fetch(helper.getAPIHost()+"/api/Dashboard/getDashboardKPI?empID="+helper.getEmplId(), helper.apiHeaders())
  try {
    return fetch(
      helper.getAPIHost() +
        '/api/Dashboard/getDashboardKPI?isBobSquadMgr=' +
        view,
      helper.apiHeaders(),
    )
      .then(async (response) => {
        if (response.ok) {
          let result = await response.json();
          helper.catchServer(result);
          console.log('In initDashboardKPI');
          localStorage.setItem('DashboardKPIs', JSON.stringify(result.result));
          localStorage.setItem('GaugeUpdated', false);
          return true;
        } else {
          console.log('In initDashboardKPI Error');
          ux.logError(
            'DataInit',
            'initDashboardKPI else',
            JSON.stringify(response),
          );
          localStorage.setItem('DashboardKPIs', JSON.stringify({}));
          localStorage.setItem('GaugeUpdated', false);
          return true;
        }
      })
      .catch((e) => {
        console.log('In initDashboardKPI Error catch');
        ux.logError('DataInit', 'initDashboardKPI catch 1', JSON.stringify(e));
        localStorage.setItem('DashboardKPIs', JSON.stringify({}));
        localStorage.setItem('GaugeUpdated', false);
        return true;
      });
  } catch (err) {
    console.log('In initDashboardKPI Error catch2 ' + err);
    console.log(err);
    ux.logError('DataInit', 'initDashboardKPI catch 2', JSON.stringify(err));
    localStorage.setItem('DashboardKPIs', JSON.stringify({}));
    localStorage.setItem('GaugeUpdated', false);
    return true;
  }
  /*.then(response => response.json())
    .then(result => {
        console.log("In initDashboardKPI")
        localStorage.setItem('DashboardKPIs', JSON.stringify(result.result));
        localStorage.setItem('GaugeUpdated', false);
        return true;
    });*/
};

export const initFeatureReleaseNotes = () => {
  try {
    let url = helper.getAPIHost() + '/api/FeatureReleases/getUserFeatureNote';
    fetch(url, { method: 'GET', ...helper.apiHeaders() })
      .then(async (res) => {
        let data = await res.json();
        if (data.status === 'OK') {
          if (Object.keys(data.result).length > 0) {
            localStorage.setItem('ReleaseNotes', JSON.stringify(data.result));
          }
        } else {
          ux.logError(
            'Init:  Feature Release',
            'getAllRegionsName',
            JSON.stringify(res),
          );
        }
      })
      .catch((err) => {
        ux.logError('init:  Feature Release', 'getAllRegionsName', err);
      });
  } catch (err) {
    ux.logError('Setup:  Feature Release', 'getAllRegionsName', err);
  }
};
