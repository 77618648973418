export const Chat = require('./360_chat.png');
export const Cordial = require('./360_cordial.png');
export const Podium = require('./360_podium.png');
export const SAP = require('./360_SAP.png');
export const Survey = require('./360_survey.png');
export const Contact = require('./360_contact.png');
export const Calendly = require('./360_calendly.png');
export const GoogleReviewStar = require('./googleReview_star.png');
export const DispatchTrack = require('./360_dispatchTrack.png');
export const Pencil = require('./about_pencil.png');
export const Pencil2 = require('./about_pencil2.png');
export const Upset = require('./highlight_upset.png');
export const GoBack = require('./GoBack.png');
export const Scroll = require('./scroll.png');
