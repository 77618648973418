import React, { useEffect, useState, useContext, useRef } from 'react';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  ListItem,
  Modal,
  Stack,
  TextareaAutosize,
} from '@mui/material';
import { Input } from 'reactstrap';
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

//Custom Component
import {
  Dropdown,
  ModalBtnClose,
  RedButton,
} from '../../components/CommonComponent';
import PotentialCustomer from '../../components/potential-customer';
import SpinnerLoading from '../../components/SpinnerLoading';
import { ModalCustomerProfile } from '../../components/customer-profile';
import OnlineCartsWishlists from './online-cart-wishlist';
import { GetCartlist, GetAllWishlist } from './data';
import OpportunityStackDialog from './opportunity-stack-dialog';

//Context
import { OpportunityContext } from './opportunity-context';

const helper = require('../../utils/helper');
const enums = require('../../enums');
const ux = require('../../application-center/ux-tracking-center');

export const beginOpportunityActions = {
  walkIn: 'Walk In',
  returnGuest: 'Return Guest',
  incomingCall: 'Incoming Call',
  unattendedGuest: 'Unattended Guest',
};

export const closeOpportunityActions = {
  sale: 'Sale',
  noSale: 'No Sale',
  anowCart: 'ACIMA Cart',
  merchandisePickup: 'Merchandise Pickup',
  customerToOffice: 'Customer to Office',
  noFurnitureNeeded: 'No Furniture Needed',
  wentToOutlet: 'Went to Outlet',
  returnQuestOtherAsscoiate: 'Return Guest - Other Associate',
};

const transferOpportunityAction = 'Transfer';

export const pendingOpportunityAction = 'Pending Opportunity';

const newGuestMapping = [
  {
    action: closeOpportunityActions.sale,
    shouldTimeLimit: false,
  },
  {
    action: closeOpportunityActions.noSale,
    shouldTimeLimit: false,
  },
  {
    action: closeOpportunityActions.anowCart,
    shouldTimeLimit: false,
  },
  {
    action: closeOpportunityActions.merchandisePickup,
    shouldTimeLimit: true,
  },
  {
    action: closeOpportunityActions.customerToOffice,
    shouldTimeLimit: true,
  },
  {
    action: closeOpportunityActions.noFurnitureNeeded,
    shouldTimeLimit: true,
  },
  {
    action: closeOpportunityActions.returnQuestOtherAsscoiate,
    shouldTimeLimit: true,
  },
  {
    action: closeOpportunityActions.wentToOutlet,
    shouldTimeLimit: true,
  },
];

const returnGuestMapping = [
  {
    action: closeOpportunityActions.sale,
    shouldTimeLimit: false,
  },
  {
    action: closeOpportunityActions.noSale,
    shouldTimeLimit: false,
  },
  {
    action: closeOpportunityActions.anowCart,
    shouldTimeLimit: false,
  },
  {
    action: closeOpportunityActions.merchandisePickup,
    shouldTimeLimit: true,
  },
  {
    action: closeOpportunityActions.customerToOffice,
    shouldTimeLimit: true,
  },
  {
    action: closeOpportunityActions.noFurnitureNeeded,
    shouldTimeLimit: true,
  },
  {
    action: closeOpportunityActions.wentToOutlet,
    shouldTimeLimit: true,
  },
];

const incomingCallMapping = [
  {
    action: closeOpportunityActions.sale,
    shouldTimeLimit: false,
  },
  {
    action: closeOpportunityActions.noSale,
    shouldTimeLimit: false,
  },
];

const unattendedGuestMapping = [
  {
    action: closeOpportunityActions.sale,
    shouldTimeLimit: false,
  },
  {
    action: closeOpportunityActions.noSale,
    shouldTimeLimit: false,
  },
  {
    action: closeOpportunityActions.anowCart,
    shouldTimeLimit: false,
  },
  {
    action: closeOpportunityActions.merchandisePickup,
    shouldTimeLimit: true,
  },
  {
    action: closeOpportunityActions.customerToOffice,
    shouldTimeLimit: true,
  },
  {
    action: closeOpportunityActions.noFurnitureNeeded,
    shouldTimeLimit: true,
  },
  {
    action: closeOpportunityActions.returnQuestOtherAsscoiate,
    shouldTimeLimit: true,
  },
  {
    action: closeOpportunityActions.wentToOutlet,
    shouldTimeLimit: true,
  },
];

const mainActionsMapping = {
  [beginOpportunityActions.walkIn]: newGuestMapping,
  // 'Next Queue Guest': newGuestMapping,
  // 'Appointment Guest': newGuestMapping,
  // 'Appointment Return Guest': returnGuestMapping,
  [beginOpportunityActions.returnGuest]: returnGuestMapping,
  [beginOpportunityActions.incomingCall]: incomingCallMapping,
  [beginOpportunityActions.unattendedGuest]: unattendedGuestMapping,
};

const calculateElapsedMinutes = (startTime) => {
  let start = new Date(startTime);
  let now = new Date();

  const elapsedTimeInMilliseconds = now - start;
  const elapsedTimeInMinutes = Math.floor(elapsedTimeInMilliseconds / 60000);

  return elapsedTimeInMinutes;
};

export function BeginOpportunityModal({
  open,
  onClose,
  engageOpportunity,
  disengageOpportunity,
  setToggleInfoModal,
  associateData,
  isStack,
}) {
  const [elapsedMinutes, setElapsedMinutes] = useState(0);
  const [isStackDialogOpen, setStackDialogOpen] = useState(false);
  const [engagementAction, setEngagementAction] = useState(null);

  const handleEngagementAction = (e) => {
    let selectedAction =
      engagementAction || e.currentTarget.getAttribute('type');

    engageOpportunity(
      associateData.employeeID,
      selectedAction,
      associateData.uid,
    );
    setToggleInfoModal(true);
    setEngagementAction(null);
    onClose();
  };

  const handleDisengagementAction = (e) => {
    let selectedAction = e.currentTarget.getAttribute('type');

    disengageOpportunity(
      associateData.uid,
      associateData.employeeID,
      selectedAction,
    );
    onClose();
  };

  useEffect(() => {
    //Get startAction time properly. Need to convert to locale string due to Stacking behavior
    let startAction =
      associateData.startAction && associateData.startAction.includes('T')
        ? new Date().toLocaleString()
        : associateData.startAction;

    let elapsed = calculateElapsedMinutes(startAction ?? new Date());

    if (elapsed >= 1) {
      //If already pass 1 min, then disabled
      setElapsedMinutes(elapsed);
    } else if (elapsedMinutes < 1) {
      //Set interval each minutes up to 1 min
      let beginDate = new Date();

      const interval = setInterval(() => {
        setElapsedMinutes(calculateElapsedMinutes(startAction ?? beginDate));
      }, 60000); // Update every minute (60000 milliseconds)

      return () => clearInterval(interval);
    }
  }, [elapsedMinutes, associateData]);

  return (
    <Modal
      open={open}
      onClose={() => onClose()}
      aria-labelledby="begin-opportunity-modal"
      className="begin-opportunity-modal"
      disablePortal
    >
      <Box>
        <div className="modal-header">
          <h5>Begin Opportunity</h5>
          <ModalBtnClose onClick={() => onClose()} />
        </div>

        <div className="modal-body">
          <span>What type of opportunity is it?</span>

          <div className="opportunity-options">
            {Object.keys(mainActionsMapping).map((action, index) => {
              //Do not show Walk-in option for associate not from #1 or for stacked operation
              return (!associateData.walkInEnable || isStack) &&
                action === beginOpportunityActions.walkIn ? null : (
                <React.Fragment key={index}>
                  <RedButton
                    key={action}
                    label={action}
                    type={action}
                    customStyle={`option-${action
                      .toLowerCase()
                      .replace(' ', '-')}`}
                    onClick={(e) => {
                      setEngagementAction(action);

                      if (isStack) {
                        setStackDialogOpen(!isStackDialogOpen);
                      } else {
                        handleEngagementAction(e);
                      }
                    }}
                  />

                  {isStack && isStackDialogOpen && (
                    <OpportunityStackDialog
                      key={`stack-${action}`}
                      isOpen={isStackDialogOpen}
                      setStackDialogOpen={() =>
                        setStackDialogOpen(!isStackDialogOpen)
                      }
                      callbackConfirmFunc={() => handleEngagementAction()}
                      selectedAction={engagementAction}
                    />
                  )}
                </React.Fragment>
              );
            })}

            {/* Cancel Opportunity Button */}
            <RedButton
              key={'cancel'}
              outline={true}
              label={
                <>
                  Cancel/Undo Opportunity
                  <br />
                  <i>*Note: This will return to the bottom of the queue</i>
                </>
              }
              type={'Cancel Opportunity'}
              disabled={elapsedMinutes >= 1 && !isStack}
              customStyle={`option-cancel`}
              onClick={(e) => handleDisengagementAction(e)}
            />
          </div>
        </div>
      </Box>
    </Modal>
  );
}

export function CloseOpportunityModal({
  open,
  onClose,
  disengageOpportunity,
  associateData,
  isLastRecord,
  opportunityTicket,
}) {
  const [elapsedMinutes, setElapsedMinutes] = useState(0);

  const currentUserInformation =
    useContext(OpportunityContext).currentUserInformation;

  const handleDisengagementAction = (e) => {
    let selectedAction = e.currentTarget.getAttribute('type');

    const checkIfShouldTotalRemoveManager = () => {
      return (
        isLastRecord && //To avoid exit when there is stacked opportunity
        associateData.employeeID === currentUserInformation.empId && //Only for self record
        currentUserInformation.isManager &&
        !currentUserInformation.isSnrGXS &&
        !currentUserInformation.isAssociate
      );
    };

    disengageOpportunity(
      associateData.uid,
      associateData.employeeID,
      selectedAction,
      checkIfShouldTotalRemoveManager(),
      opportunityTicket,
    );
    console.log('Disengage opportunity data:', disengageOpportunity);
    console.log('Disengage opportunity  ticket', opportunityTicket);

    onClose();
  };

  useEffect(() => {
    let elapsed = calculateElapsedMinutes(associateData.startAction);

    if (elapsed >= 5) {
      //If already pass 5 min, then disabled
      setElapsedMinutes(elapsed);
    } else if (elapsedMinutes < 5) {
      //Set interval each minutes up to 5 min
      const interval = setInterval(() => {
        setElapsedMinutes(calculateElapsedMinutes(associateData.startAction));
      }, 60000); // Update every minute (60000 milliseconds)

      return () => clearInterval(interval);
    }
  }, [elapsedMinutes, associateData]);

  return (
    <Modal
      open={open}
      onClose={() => onClose()}
      aria-labelledby="close-opportunity-modal"
      className="close-opportunity-modal"
      disablePortal
    >
      <Box>
        <div className="modal-header">
          <h5>Close Opportunity</h5>
          <ModalBtnClose onClick={() => onClose()} />
        </div>

        <div className="modal-body">
          <span>How did the opportunity turn out?</span>

          <div className="opportunity-options">
            {mainActionsMapping[associateData.userAction].map(
              (mappingAction, index) => {
                // Should disable rule:-
                //  1. When elapsed time from engaging time more than 5 minutes, some options are disabled
                //  2. For location with no outlet, option 'Went to Outlet' is removed
                //  3. For location with no ANOW, option 'ANOW Cart' is removed
                if (
                  (mappingAction.action ===
                    closeOpportunityActions.wentToOutlet &&
                    !localStorage.getItem('OutletId')) ||
                  (mappingAction.action === closeOpportunityActions.anowCart &&
                    localStorage.getItem('IsANOWStore') === 'false')
                ) {
                  //Instead of disabled, Bob's wanted to remove this option from being displayed
                  return null;
                } else {
                  return (
                    <RedButton
                      key={mappingAction.action}
                      label={mappingAction.action}
                      type={mappingAction.action}
                      disabled={
                        elapsedMinutes >= 5 && mappingAction.shouldTimeLimit
                      }
                      onClick={(e) => handleDisengagementAction(e)}
                    />
                  );
                }
              },
            )}
          </div>
        </div>
      </Box>
    </Modal>
  );
}

export const unavailableActions = {
  lunchBreak: 'Lunch/Break',
  crmActivity: 'CRM Activity',
  notAvailable: 'Not Available',
  assistanceRequested: 'Assistance Requested',
  associateUnavailable: 'Associate Unavailable', //Only for associate mark other associate
};

export function UnavailableOpportunityModal({
  open,
  onClose,
  changeOpportunityStatus,
  associateData,
}) {
  const currentUserInformation =
    useContext(OpportunityContext).currentUserInformation;

  const handleUnavailableAction = (e) => {
    let selectedAction = e.currentTarget.getAttribute('type');

    changeOpportunityStatus(associateData.employeeID, selectedAction);

    console.log(selectedAction);
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={() => onClose()}
      aria-labelledby="unavailable-opportunity-modal"
      className="unavailable-opportunity-modal"
      disablePortal
    >
      <Box>
        <div className="modal-header">
          <h5>Unavailable</h5>
          <ModalBtnClose onClick={() => onClose()} />
        </div>

        <div className="modal-body">
          <span>Why are you currently not available for opportunity?</span>

          <div className="opportunity-options">
            {Object.keys(unavailableActions).map((action, index) => {
              if (
                currentUserInformation.empId === associateData.employeeID ||
                currentUserInformation.isManager
              ) {
                //If manager or self, show all four options
                if (
                  unavailableActions[action] ===
                  unavailableActions.associateUnavailable
                ) {
                  return null;
                } else {
                  return (
                    <RedButton
                      key={unavailableActions[action]}
                      label={unavailableActions[action]}
                      type={unavailableActions[action]}
                      onClick={(e) => handleUnavailableAction(e)}
                    />
                  );
                }
              } else {
                //If associate mark other associate, show only associate unavailable
                if (
                  unavailableActions[action] ===
                  unavailableActions.associateUnavailable
                ) {
                  return (
                    <RedButton
                      key={unavailableActions[action]}
                      label={unavailableActions[action]}
                      type={unavailableActions[action]}
                      onClick={(e) => handleUnavailableAction(e)}
                    />
                  );
                } else {
                  return null;
                }
              }
            })}
          </div>
        </div>
      </Box>
    </Modal>
  );
}

export function OpportunityInformationModal({ open, onClose, associateData }) {
  const [title, setTitle] = useState('');
  const [pax, setPax] = useState(0);
  const paxDummyArray = Array(6).fill(0);
  const [guestCM, setGuestCM] = useState('');
  const [guestName, setGuestName] = useState('');
  const [guestPhone, setGuestPhone] = useState('');
  const [guestEmail, setGuestEmail] = useState('');
  const [disableLookupButton, setDisableLookupButton] = useState(true);
  const [linkedSuccessfully, setLinkedSuccessfully] = useState(false);
  const [guestConsent, setGuestConsent] = useState(false);
  const [toggleCustomerProfileModal, setToggleCustomerProfileModal] =
    useState(false);
  const [selectedShoppingFor, setShoppingFor] = useState(new Set());
  const [selectedTabs, setTabs] = useState(0);
  const [note, setNote] = useState('');
  const [openCartWishlist, setOpenCartWishlist] = useState(false);

  //cart and wishlist data
  const [cartData, setCartData] = useState([]);
  const [wishData, setWishData] = useState([]);

  const parentModalRef = useRef(null);

  const shoppingForOptions = [
    'Living Room',
    'Motion',
    'Mattresses',
    'Kids',
    'Bedrooms',
    'Dining',
    'Other',
  ];

  const handleSubmitInfo = (e) => {
    const validateField = () => {
      // if (title.length === 0) {
      //   window.message.notification(
      //     `Please enter your opportunity title`,
      //     enums.notificationType.E,
      //   );
      //   return false;
      // }

      // if (pax === 0) {
      //   window.message.notification(
      //     `Please select your party size`,
      //     enums.notificationType.E,
      //   );
      //   return false;
      // }

      if (guestEmail) {
        let regEmail =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (!regEmail.test(guestEmail)) {
          window.message.notification(
            `Guest email is not valid`,
            enums.notificationType.E,
          );
          return false;
        }
      }

      return true;
    };

    if (validateField()) {
      let payload = {
        uid: associateData.uid,
        title: title,
        pax: pax,
        customerNumber: guestCM,
        customerName: guestName,
        customerPhone: guestPhone,
        customerEmail: guestEmail,
        isCustomerLinked: linkedSuccessfully,
        isCustomerConsent: guestConsent,
        shoppingFor:
          selectedShoppingFor.size > 0
            ? Array.from(selectedShoppingFor).join(',')
            : null,
      };

      postOpportunityInfo(payload);
    }
  };

  const postOpportunityInfo = async (payload) => {
    await fetch(
      helper.getSignalRHost() + `/api/OpportunityList/PostOpportunityInfo`,
      {
        method: 'POST',
        body: JSON.stringify(payload),
        ...helper.apiHeaders(),
      },
    )
      .then(async (res) => {
        if (res.status === 200) {
          window.message.notification(
            `Opportunity information saved`,
            enums.notificationType.S,
          );

          if (!checkUnsavedNotesExist()) {
            onClose();
          }
        } else {
          window.message.notification(
            'Unable to save opportunity information',
            enums.notificationType.E,
          );
          ux.logError(
            'GuestConnect',
            'Opportunity Information',
            JSON.stringify(res),
            JSON.stringify(payload),
          );
        }
      })
      .catch((err) => {
        window.message.notification(
          'Unable to save opportunity information',
          enums.notificationType.E,
        );
        ux.logError(
          'GuestConnect',
          'Opportunity Information',
          JSON.stringify(err),
          JSON.stringify(payload),
        );
      });
  };

  const checkUnsavedNotesExist = () => {
    if (note && note.length > 0) {
      window.message.notification(
        `You have unsaved note`,
        enums.notificationType.W,
      );
      return true;
    }
  };

  const handleAddComment = async () => {
    let payload = {
      source: 'Order Notes',
      type: 'GuestConnect',
      customerNbr: guestCM,
      customerNm: guestName,
      phoneNbr: guestPhone,
      email: guestEmail,
      profitCenterNbr: helper.getStorelId(),
      profitCenterNm: localStorage.getItem('StoreName'),
      salesAssociate: helper.getEmplId(),
      salesAssociateNm: localStorage.getItem('EmplName'),
      salesDocNbr: null,
      details: note,
      messageName: null,
    };

    await fetch(helper.getAPIHost() + '/api/Customer360/postCustomerTimeline', {
      method: 'POST',
      body: JSON.stringify(payload),
      ...helper.apiHeaders(),
    })
      .then(async (res) => {
        if (res.status === 200) {
          window.message.notification(
            `Note Added Successfully`,
            enums.notificationType.S,
          );

          setNote(''); //Reset note
          ux.logAction('GuestConnect', 'Add Note', note);
        } else {
          window.message.notification(
            `Unable to add note at the moment. Please try again later.`,
            enums.notificationType.E,
          );
          ux.logError(
            'GuestConnect',
            'postCustomerTimeline',
            res.statusText,
            JSON.stringify(payload),
          );
        }
      })
      .catch((err) => {
        window.message.notification(
          `Unable to add note at the moment. Please try again later.`,
          enums.notificationType.E,
        );
        ux.logError(
          'GuestConnect',
          'postCustomerTimeline',
          err,
          JSON.stringify(payload),
        );
      });
  };

  const onBlurTriggerPotentialCustomer = () => {
    setTimeout(() => {
      document.getElementById('potential-cust-button')?.click();
    }, 1000);
  };

  const handleShoppingFor = (option) => {
    setShoppingFor((prevSelected) => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(option)) {
        newSelected.delete(option);
      } else {
        newSelected.add(option);
      }
      return newSelected;
    });
  };

  useEffect(() => {
    if (associateData) {
      if (associateData.opportunityTitle) {
        setTitle(associateData.opportunityTitle);
      }

      if (associateData.numberOfParty) {
        setPax(associateData.numberOfParty);
      }

      if (associateData.customerNbr) {
        setGuestCM(associateData.customerNbr);
      }

      if (associateData.customerNm) {
        setGuestName(associateData.customerNm);
      }

      if (associateData.email) {
        setGuestEmail(associateData.email);
      }

      if (associateData.phoneNbr) {
        setGuestPhone(associateData.phoneNbr);
      }

      if (associateData.isCustomerLinked) {
        setLinkedSuccessfully(associateData.isCustomerLinked);
      }

      if (associateData.isCustomerConsent) {
        setGuestConsent(associateData.isCustomerConsent);
      }

      if (associateData.shoppingFor) {
        setShoppingFor(
          new Set(associateData.shoppingFor.toString().split(',')),
        );
      }
    }
  }, [associateData]);

  useEffect(() => {
    if ((guestCM || guestPhone || guestEmail) && !linkedSuccessfully) {
      setDisableLookupButton(false);
    }

    if ((!guestEmail || guestEmail?.length === 0) && guestConsent) {
      setGuestConsent(false); //Reset guest consent if email blank
    }
  }, [guestCM, guestPhone, guestEmail, linkedSuccessfully, guestConsent]);

  useEffect(() => {
    const checkCartWishlistData = async () => {
      console.log('Calling Cart & Wish API');

      try {
        const [resCart, resWish] = await Promise.all([
          GetCartlist(guestEmail),
          GetAllWishlist(guestEmail),
        ]);

        console.log(resCart);
        console.log(resWish);

        const dataExist = resCart.length > 0 || resWish.length > 0;

        if (resCart.length > 0) {
          // const multipliedData = resCart.flatMap((item) =>
          //   Array(10).fill(item),
          // );
          setCartData(resCart);
        }

        if (resWish.length > 0) {
          setWishData(resWish);
        }

        // To check data if exist or not, if exist then only open the Online Carts & Wishlist
        setOpenCartWishlist(dataExist);
      } catch (error) {
        console.error('Error fetching cart or wishlist data:', error);
        setOpenCartWishlist(false);
      }
    };

    if (linkedSuccessfully) {
      checkCartWishlistData();
    } else {
      setOpenCartWishlist(false); // Close
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linkedSuccessfully]);

  useEffect(() => {
    if (!openCartWishlist && parentModalRef) {
      parentModalRef.current.style.width = '50%';
      parentModalRef.current.style.left = '25%';
    } else {
      parentModalRef.current.style.width = '80%';
      parentModalRef.current.style.left = '10%';
    }
  }, [openCartWishlist]);

  return (
    <Modal
      open={open}
      onClose={() => onClose()}
      ref={parentModalRef}
      aria-labelledby="information-opportunity-modal"
      className="information-opportunity-modal"
      disablePortal
    >
      <>
        <ModalBtnClose onClick={() => onClose()} />

        <Box>
          <Tabs
            className={'information-tabs'}
            onSelect={(index) => setTabs(index)}
          >
            <TabList>
              <Tab>Guest Information</Tab>
              <Tab>Opportunity Information</Tab>
              <Tab disabled={!linkedSuccessfully}>Notes</Tab>
            </TabList>

            {/* Guest Information */}
            <TabPanel>
              <div className="guest-info-tab-body">
                {/* Title */}
                <div>
                  <span>Would you like to title your opportunity?</span>

                  <Input
                    onChange={(e) => {
                      let value = e.currentTarget.value;
                      setTitle(value);
                    }}
                    value={title}
                    maxLength={30}
                    placeholder="Title"
                  />
                </div>

                <Accordion
                  allowZeroExpanded={true}
                  allowMultipleExpanded={true}
                  preExpanded={['opp-party', 'opp-guest-info']}
                  className="opp-modal-accordion"
                >
                  {/* Party Size */}
                  <AccordionItem uuid="opp-party">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        <div className="header">How big is your party?</div>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <Stack direction="row" spacing={2}>
                        {paxDummyArray.map((x, index) => {
                          return (
                            <ListItem
                              key={index}
                              onClick={() => setPax(index + 1)}
                              className={`${pax === index + 1 && 'selected'}`}
                            >
                              {index + 1}
                              {index + 1 === paxDummyArray.length && '+'}
                            </ListItem>
                          );
                        })}
                      </Stack>
                    </AccordionItemPanel>
                  </AccordionItem>

                  {/* Guest Info */}
                  <AccordionItem uuid="opp-guest-info">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        <div className="header">Guest Information</div>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="guest-info">
                        <Grid container spacing={2}>
                          {/* CM # */}
                          <Grid item xs={4} style={{ display: 'none' }}>
                            <Input
                              onChange={(e) => {
                                let value = e.currentTarget.value;
                                setGuestCM(value);
                                setLinkedSuccessfully(false);
                              }}
                              value={guestCM}
                              maxLength={50}
                              placeholder="CM #"
                              onBlur={() => onBlurTriggerPotentialCustomer()}
                              readOnly={true}
                            />
                          </Grid>
                          {/* Name */}
                          <Grid item xs={4} style={{ display: 'none' }}>
                            <Input
                              value={guestName}
                              placeholder="Customer Name"
                              onChange={(e) =>
                                setGuestName(e.currentTarget.value)
                              }
                              readOnly={true}
                            />
                          </Grid>
                          {/* Email */}
                          <Grid item xs={4}>
                            <Input
                              onChange={(e) => {
                                let value = e.currentTarget.value;
                                setGuestEmail(value);
                                setLinkedSuccessfully(false);
                              }}
                              value={guestEmail}
                              maxLength={50}
                              placeholder="Email address"
                              onBlur={() => onBlurTriggerPotentialCustomer()}
                              // readOnly={true}
                            />
                          </Grid>
                          {/* Phone */}
                          <Grid item xs={4}>
                            <Input
                              onChange={(e) => {
                                let value = e.currentTarget.value;
                                setGuestPhone(value);
                                setLinkedSuccessfully(false);
                              }}
                              value={guestPhone}
                              maxLength={50}
                              placeholder="Phone Number"
                              onBlur={() => onBlurTriggerPotentialCustomer()}
                              // readOnly={true}
                            />
                          </Grid>

                          {/* Lookup */}
                          <Grid item xs={4}>
                            <PotentialCustomer
                              placement={'left-start'}
                              customerID={guestCM}
                              customerEmail={guestEmail}
                              customerPhone={guestPhone}
                              origin={'opportunity'}
                              updateProfileData={(data) => {
                                setLinkedSuccessfully(
                                  data.openOnlineCartInGuestConnect,
                                );
                                setGuestCM(data.customerID);
                                setGuestName(data.customerName);
                                setGuestEmail(data.customerEmail);
                                setGuestPhone(data.customerPhone);
                              }}
                              disabledProps={disableLookupButton}
                              setDisableFunc={setDisableLookupButton}
                              disablePortal={true}
                              popoverCustomClass={'opportunity-list'}
                            />
                          </Grid>
                        </Grid>

                        {/* Customer Profile */}
                        {linkedSuccessfully && (
                          <>
                            <div
                              onClick={() =>
                                setToggleCustomerProfileModal(
                                  !toggleCustomerProfileModal,
                                )
                              }
                              className="guest-profile-link"
                            >
                              View Customer Profile
                            </div>

                            {/* Customer Profile / Timeline */}
                            {toggleCustomerProfileModal && (
                              <ModalCustomerProfile
                                callerToggleToReset={() =>
                                  setToggleCustomerProfileModal(
                                    !toggleCustomerProfileModal,
                                  )
                                }
                                orderNumber={''}
                                customerID={guestCM}
                                customerName={guestName}
                                customerEmail={guestEmail}
                                customerPhone={guestPhone}
                              />
                            )}
                          </>
                        )}

                        {/* Customer Consent */}
                        <FormControlLabel
                          className="guest-consent"
                          control={
                            <Checkbox
                              checked={guestConsent}
                              onChange={() => setGuestConsent(!guestConsent)}
                              disabled={
                                guestEmail
                                  ? guestEmail.length > 0
                                    ? false
                                    : true
                                  : true
                              }
                            />
                          }
                          label="By checking the box, the guest consented to receiving our marketing newsletter via email."
                        />
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
              </div>
            </TabPanel>

            {/* Opportunity Information */}
            <TabPanel>
              <div className="opportunity-info-tab-body">
                <Grid container spacing={2} justifyContent={'center'}>
                  {shoppingForOptions.map((option, index) => {
                    return (
                      <Grid item xs={3} key={option}>
                        <RedButton
                          outline={!selectedShoppingFor.has(option)}
                          label={option}
                          onClick={() => handleShoppingFor(option)}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </div>
            </TabPanel>

            {/* Notes */}
            <TabPanel>
              <div className="notes-tab-body">
                <TextareaAutosize
                  minRows={10}
                  placeholder="Please start typing..."
                  maxLength={500}
                  value={note}
                  onChange={(val) => {
                    let text = val.currentTarget.value;
                    setNote(text);
                  }}
                />
                <i>Note: All notes will be published to Customer Timeline</i>
              </div>
            </TabPanel>
          </Tabs>

          {/* Hide Dismiss/Confirm button on Notes tab */}
          <div className="info-modal-footer">
            {selectedTabs === 2 ? (
              // For Notes tab
              <RedButton
                key={'saveNote'}
                label={'Save Note'}
                disabled={note?.length === 0 || !linkedSuccessfully}
                onClick={(e) => handleAddComment()}
              />
            ) : (
              // For other tabs
              <>
                <RedButton
                  key={'dismiss'}
                  label={'Dismiss'}
                  onClick={(e) => {
                    if (!checkUnsavedNotesExist()) {
                      onClose();
                    }
                  }}
                  outline={true}
                />

                <RedButton
                  key={'confirm'}
                  label={'Confirm'}
                  // disabled={
                  //   linkedSuccessfully === false &&
                  //   (guestCM || guestEmail || guestName || guestPhone)
                  // }
                  onClick={(e) => handleSubmitInfo(e)}
                />
              </>
            )}
          </div>
        </Box>

        {/* Online Carts & Wishlists  */}
        {openCartWishlist && (
          <OnlineCartsWishlists
            parentModalRef={parentModalRef}
            cartData={cartData}
            wishData={wishData}
          />
        )}
      </>
    </Modal>
  );
}

export function TransferOpportunityModal({ open, onClose, associateData }) {
  const [activeUsers, setActiveUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedUser, setSelectedUser] = useState(null);

  const postTransferOpportunity = async () => {
    await fetch(
      helper.getSignalRHost() +
        `/api/OpportunityList/PostTransferOpportunity?transferUser=${selectedUser.value}`,
      {
        method: 'POST',
        body: JSON.stringify({
          Uid: associateData.uid,
          EmployeeId: associateData.employeeID,
          action: transferOpportunityAction,
        }),
        ...helper.apiHeaders(),
      },
    )
      .then(async (res) => {
        if (res.status === 200) {
          window.message.notification(
            `Opportunity transfered to ${selectedUser.label}`,
            enums.notificationType.S,
          );
          onClose();
        } else {
          window.message.notification(
            `Unable to transfer opportunity to ${selectedUser.label}`,
            enums.notificationType.E,
          );
          ux.logError(
            'GuestConnect',
            'PostTransferOpportunity',
            JSON.stringify(res),
            selectedUser.value,
          );
        }
      })
      .catch((err) => {
        window.message.notification(
          `Unable to transfer opportunity to ${selectedUser.label}`,
          enums.notificationType.E,
        );
        ux.logError(
          'GuestConnect',
          'PostTransferOpportunity',
          JSON.stringify(err),
          selectedUser.value,
        );
      });
  };

  const getTransferActiveUser = async () => {
    let url =
      helper.getSignalRHost() +
      `/api/OpportunityList/GetTransferActiveUser?EmployeeId=${associateData.employeeID}`;

    await fetch(url, { method: 'GET', ...helper.apiHeaders() })
      .then(async (response) => {
        let data = await response.json();

        if (data.status === 'OK') {
          let activeUsers = data.result.map((employee) => ({
            label: employee.employeeName,
            value: employee.employeeID,
          }));

          setActiveUsers(activeUsers);
        }
      })
      .catch((error) => {
        window.message.notification(
          'Unable to get Opportunity active users for transfer',
          enums.notificationType.E,
        );
        ux.logError(
          'GuestConnect',
          'GetTransferActiveUser',
          'Unable to get data from API',
          JSON.stringify(error),
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getTransferActiveUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (activeUsers?.length > 0) {
      console.log('Transfer active users', activeUsers);
    }
  }, [activeUsers]);

  return (
    <Modal
      open={open}
      onClose={() => onClose()}
      aria-labelledby="transfer-opportunity-modal"
      className="transfer-opportunity-modal"
      disablePortal
    >
      <Box>
        <div className="modal-header">
          <h5>Transfer Opportunity</h5>
          <ModalBtnClose onClick={() => onClose()} />
        </div>

        <div className="modal-body">
          {isLoading ? (
            <SpinnerLoading />
          ) : (
            <>
              <div>
                Please choose an associate to transfer this opportunity.
              </div>
              <Dropdown
                id={'transfer-active-user'}
                value={selectedUser}
                options={activeUsers}
                onChange={(obj) => setSelectedUser(obj)}
              />
            </>
          )}
        </div>

        <div className="transfer-modal-footer">
          <RedButton
            key={'cancel'}
            label={'Cancel'}
            onClick={(e) => onClose()}
            outline={true}
          />

          <RedButton
            key={'confirm'}
            label={'Confirm'}
            onClick={() => postTransferOpportunity()}
            disabled={!selectedUser}
          />
        </div>
      </Box>
    </Modal>
  );
}

export function CheckInTracking({
  checkedInData,
  setCheckedInData,
  inSession,
}) {
  const { currentUserInformation } = useContext(OpportunityContext);

  const [isCheckInCalled, setIsCheckInCalled] = useState(false);

  const fetchManagerCheckIn = async () => {
    try {
      await fetch(
        `${helper.getSignalRHost()}/api/OpportunityList/ManagerCheckIn?checkInUser=${
          currentUserInformation.empId
        }`,
        {
          method: 'POST',
          body: JSON.stringify({
            uid: checkedInData.uid,
            employeeId: checkedInData.employeeID,
            action: 'checkin',
          }),
          ...helper.apiHeaders(),
        },
      );
    } catch (error) {
      console.error('Check-In Error', error);
    }
  };

  const fetchManagerCheckOut = async (empId) => {
    try {
      await fetch(
        `${helper.getSignalRHost()}/api/OpportunityList/ManagerCheckOut?ManagerId=${empId}`,
        {
          method: 'POST',
          ...helper.apiHeaders(),
        },
      );
    } catch (error) {
      console.error('Check-In Error', error);
    }
  };

  const handleClose = async () => {
    //Reset state & context
    setCheckedInData(null);
    setIsCheckInCalled(false);

    //Call Checkout API
    await fetchManagerCheckOut(currentUserInformation.empId);
  };

  useEffect(() => {
    if (checkedInData && checkedInData.uid && !isCheckInCalled && !inSession) {
      setIsCheckInCalled(true);
      fetchManagerCheckIn();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedInData]);

  return (
    <Modal
      open={isCheckInCalled || inSession}
      aria-labelledby="manager-check-in-modal"
      className="checkin-tracking-modal"
      disablePortal
    >
      <Box>
        <div className="modal-body">
          <p>
            Manager Engagement with <b>{checkedInData.employeeName}</b>
          </p>
          <RedButton label="Complete" onClick={() => handleClose()} />
        </div>
      </Box>
    </Modal>
  );
}
