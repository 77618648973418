//React
import React, { useEffect, useState } from 'react';
//SCSS
import './StoreGuideIcon.scss';
import { FaRegQuestionCircle } from 'react-icons/fa';

const helper = require('../../utils/helper');
const enums = require('../../enums');
const ux = require('../../application-center/ux-tracking-center');

export const DIVStoreGuideIcon = ({ callerUID, callerMenuCode }) => {
  const [URL, setURL] = useState('');
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    //console.clear();
    //console.log('Store Guide URL ID', { callerUID, callerMenuCode});
    if (typeof callerUID !== 'undefined' && callerUID !== '') {
      console.log('getByUID', { callerUID });
      console.log('Store Guide URL ID', { callerUID, callerMenuCode });
      getByUID(callerUID);
    } else if (typeof callerMenuCode !== 'undefined' && callerMenuCode !== '') {
      console.log('getByMenuCode', { callerMenuCode });
      console.log('Store Guide URL ID', { callerUID, callerMenuCode });
      getByMenuCode(callerMenuCode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callerUID, callerMenuCode]);

  const getByMenuCode = async (paramMenucode) => {
    try {
      let url = helper.getAPIHost() + '/api/MenuSetups';

      await fetch(url, { method: 'GET', ...helper.apiHeaders() })
        .then(async (response) => {
          let data = await response.json();
          if (data.status === 'OK') {
            let tmpData = data.result.filter(
              (e) => e.menuCode === paramMenucode,
            );
            console.log('paramMenucode', paramMenucode);
            helper.catchServer(data);
            //this.setState({ uid: tmpData[0].uid });
            //console.log("tmpData", tmpData)
            if (tmpData.length > 0) getByUID(tmpData[0].uid);
          } else {
            this.notification(
              'Unable to get menu data from server! -',
              enums.notificationType.E,
            );
            ux.logError(
              'getByMenuCode',
              'Get Data from API',
              'Unable to get coaching data from server!',
            );
          }
        })
        .catch((error) => {
          this.notification(
            'Init Data API: get2MinuteDrill 1 - ' + error,
            enums.notificationType.E,
          );
          ux.logError(
            'getByMenuCode',
            'Get Data from API',
            'Init Data API: getByMenuCode 1 - ' + error,
          );
        });
    } catch (error) {
      this.notification(
        'Init Data API: get2MinuteDrill 2 - ' + error,
        enums.notificationType.E,
      );
      ux.logError(
        'getByMenuCode',
        'Get Data from API',
        'Init Data API: getByMenuCode 2 - ' + error,
      );
    }
  };

  const getByUID = async (paramUID) => {
    console.log('getData');
    await fetch(
      helper.getAPIHost() + '/api/MenuSetups/' + paramUID,
      helper.apiHeaders(),
    )
      .then(async (response) => {
        let data = await response.json();
        console.log('getByUID data here,', data);
        if (data.status === 'OK') {
          if (data.result.length > 0) {
            setURL(data.result[0].supportCenterURL);
            setEnabled(data.result[0].supportCenterLinkEnabled);
          } else {
            setURL('');
            setEnabled(false);
          }
        } else {
          ux.logError(
            'getByUID',
            'Get Data from API',
            'Unable to get data from API',
            JSON.stringify(data),
          );
        }
      })
      .catch((error) => {
        ux.logError(
          'getByUID',
          'Get Data from API',
          'Unable to get data from API',
          error,
        );
      });
  };

  const handleClick = () => {
    var goUrl = URL;

    console.log('New Store Guide URL ID', { goUrl });
    window.open(goUrl, '_blank', 'rel=noopener noreferrer');
  };

  return (
    <>
      {enabled === true && (
        <span className="storeguideIcon" onClick={handleClick}>
          <FaRegQuestionCircle />
        </span>
      )}
    </>
  );
};
