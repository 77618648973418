import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import React from 'react';
import { RedButton } from '../../components/CommonComponent';

export default function OpportunityStackDialog({
  isOpen,
  setStackDialogOpen,
  callbackConfirmFunc,
  selectedAction,
}) {
  return (
    <>
      {/* Confirmation Dialog Before Stacking */}
      <Dialog
        open={isOpen}
        onClose={() => setStackDialogOpen(!isOpen)}
        aria-labelledby="stack-dialog-confirmation"
        aria-describedby="stack-dialog-confirmation-description"
      >
        <DialogTitle id="stack-dialog-confirmation">
          Stack Opportunity
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="stack-dialog-confirmation-description">
            Are you sure you want to stack a new {selectedAction} Opportunity?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <RedButton
            label="Cancel"
            outline={true}
            onClick={(e) => {
              setStackDialogOpen(false);
            }}
          />
          <RedButton
            label="Confirm"
            onClick={(e) => {
              setStackDialogOpen(false);
              callbackConfirmFunc();
            }}
          />
        </DialogActions>
      </Dialog>
    </>
  );
}
