import { receivedFolderLogic } from '.';

const helper = require('../../utils/helper');
const ux = require('../../application-center/ux-tracking-center');
const CommunicationType = Object.freeze({
  MedalliaSurvey: 1,
  Shared: 2,
  Notes: 3,
  FollowUp: 4,
  // UnitPriceChanged: 5,
  // SalesAssociateChanged: 6,
  GX2Complete: 7,
});

export let headerUnread = 0;
export let headerNotAck = 0;

export const CommunicationCollaborationScheduler = async () => {
  // check path
  let currentPage = window.location.pathname.split('/');
  let menu = currentPage[1]?.toLowerCase();

  let timerInMS = parseInt(
    JSON.parse(localStorage.getItem('Parameters'))?.UI_CNC_SCHEDULER || 60000,
  );
  let lastTrigger = localStorage.getItem('LastTrigger');
  let payload = {
    triggerdate:
      lastTrigger === 'undefined' ||
      lastTrigger === 'null' ||
      lastTrigger === undefined ||
      lastTrigger === null
        ? new Date().toISOString()
        : lastTrigger,
  };

  if (!menu || menu === 'home') {
    //Do not use this scheduler in dashboard homepage. Will be using from C&C center "RefreshNotesFollowUp"
    //console.log('not refreshing via header scheduler');
    window.cncscheduler = setTimeout(
      () => CommunicationCollaborationScheduler(),
      timerInMS,
    ); //Set first time render
    return;
  }

  if (!JSON.parse(localStorage.getItem('Parameters'))?.UI_CNC_SCHEDULER) {
    return;
  }

  //console.log('refreshing via header scheduler');

  //Call API to update all notes
  await fetch(helper.getAPIHost() + '/api/Customer360/getNotesUpdate', {
    method: 'POST',
    body: JSON.stringify(payload),
    ...helper.apiHeaders(),
  })
    .then(async (response) => {
      let data = await response.json();
      if (data.status === 'OK') {
        if (data.result.currentNote?.length > 0) {
          let newData = [...data.result.currentNote];

          //Collaboration stored as UTC. Revert to user timezone
          newData
            .filter(
              (rec) =>
                rec.commType === CommunicationType.Shared ||
                rec.commType === CommunicationType.Notes ||
                rec.commType === CommunicationType.FollowUp ||
                rec.commType === CommunicationType.GX2Complete,
            )
            .forEach((rec) => {
              rec.lastMessageTimeStamp = rec.lastMessageTimeStamp + 'Z';
              rec.messageList.forEach((indRec) => {
                indRec.dateTime = new Date(indRec.dateTime + 'Z').toString();
              });
            });

          let headerUnread =
            receivedFolderLogic(newData)?.filter((x) => !x.isRead)?.length || 0;
          let headerNotAck =
            receivedFolderLogic(newData).filter(
              (x) =>
                x.commType === CommunicationType.Notes &&
                x.messageList.some(
                  (rec) => !rec.salesAssociateNm && !rec.acknowledgeBy,
                ),
            )?.length || 0;

          //Update to home badge and document.title
          let homeBadge = document.getElementById('comm-collab-badge');
          let homeBadgeUnAck = document.getElementById(
            'comm-collab-badge-unack',
          );

          if (homeBadge && homeBadgeUnAck) {
            // Unread
            if (headerUnread > 0) {
              homeBadge.innerHTML = headerUnread;
              homeBadge.style.display = 'block';
            } else {
              homeBadge.style.display = 'none';
            }

            //Un-acknowledged
            if (headerNotAck > 0) {
              homeBadgeUnAck.innerHTML = headerNotAck;
              homeBadgeUnAck.style.display = 'block';
            } else {
              homeBadgeUnAck.style.display = 'none';
            }

            //Unread Tab title
            document.title = `BobBoost ${
              headerUnread > 0 ? `(${headerUnread})` : ''
            }`;
          }
        }
      }
      localStorage.setItem(
        'LastTrigger',
        data.result.currentNote[0]?.triggerdate === undefined
          ? new Date().toISOString()
          : data.result.currentNote[0].triggerdate,
      );

      //Call next scheduler
      window.cncscheduler = setTimeout(
        () => CommunicationCollaborationScheduler(),
        timerInMS,
      );
    })
    .catch((error) => {
      console.error(error);
      ux.logError(
        'Communication and Collaboration',
        'getNotesUpdate',
        error.message,
        'Header Refresh',
      );
    });
};
