import React, { useEffect, useContext } from 'react';
import { Navbar } from 'reactstrap';
import { Grid } from '@mui/material';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.css'; // Manual import due to issue tabs default classes not loading from pages other than homepage

// Custom Components
import OpportunityFloor from './opportunity-floor';
import OpportunityDashboard from './opportunity-dashboard';

//Icon
import { ImEnter, ImExit } from 'react-icons/im';
import { GoDotFill } from 'react-icons/go';

// SCSS
import './opportunity-list.scss';

// Context
import { OpportunityContext } from './opportunity-context';
import OpportunityNotepad from './opportunity-notepad';

// Helpers
const helper = require('../../utils/helper');
const enums = require('../../enums');

export default function OpportunityList({
  connection,
  connectionConnected,
  associateJoinedQueue,
  associateCurrentlyEngaged,
  joinOpportunity,
  exitOpportunity,
  engageOpportunity,
  disengageOpportunity,
  changeOpportunitySequence,
  changeOpportunityStatus,
  queueAssociate,
  engagingAssociate,
  associateInfoStack,
  getCurrentUserPosition,
  checkInEmpIdRef,
  questions,
}) {
  const currentUserInformation =
    useContext(OpportunityContext).currentUserInformation;

  useEffect(() => {
    const setupBeforeUnloadListener = () => {
      window.addEventListener('beforeunload', handleBeforeUnload);
    };

    const removeBeforeUnloadListener = () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };

    setupBeforeUnloadListener();

    return () => {
      removeBeforeUnloadListener();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBeforeUnload = async (e) => {
    e.preventDefault();
    e.returnValue = '';
    const confirmationMessage = 'Are you sure you want to leave?';
    e.returnValue = confirmationMessage; // For older browsers

    const unloadListener = async (event) => {
      if (e.returnValue === confirmationMessage) {
        event.preventDefault();
        try {
          // await exitOpportunity();
          delete event['returnValue'];
          localStorage.removeItem('opportunityTabOpened');
        } catch (error) {
          // Handle any error that occurred during the API call
        }
      }
    };

    // Create a promise to wait for the exitOpportunity function to complete
    const unloadPromise = new Promise((resolve) => {
      unloadListener({ preventDefault() {} }).then(() => {
        resolve();
      });
    });

    window.addEventListener('unload', unloadListener);
    // Wait for the exitOpportunity function to complete before unloading
    await unloadPromise;

    window.removeEventListener('unload', unloadListener);
  };

  const checkDashboardPermission = () => {
    const userGroup = helper.getUserGroup();

    if (
      userGroup === enums.userGroups.StoreManager ||
      userGroup === enums.userGroups.SeniorGxs
    ) {
      return 'manager';
    } else {
      return 'associate';
    }
  };

  const dashboardPermission = checkDashboardPermission();

  return (
    <div className="opportunity-list">
      <Navbar light expand className={`iss-header`}>
        <Tabs className={'parent-tabs'}>
          <TabList>
            <Tab>
              <>
                <span className="page-title">GuestConnect</span>
                <span
                  className={`connection ${connectionConnected && 'connected'}`}
                >
                  <GoDotFill
                    title={connectionConnected ? 'Connected' : 'Disconnected'}
                  />
                </span>
              </>
            </Tab>
            {(dashboardPermission === 'manager' ||
              dashboardPermission === 'associate') && (
              <Tab>
                <span className="page-title">Dashboard</span>
              </Tab>
            )}
            <Tab>
              <span className="page-title">Notepad</span>
            </Tab>
          </TabList>

          {/* GuestConnect */}
          <TabPanel>
            <Grid
              container
              spacing={2}
              direction="column"
              justifyContent="center"
              alignItems="center"
              className="grid"
            >
              {/* Queue Floor */}
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <div className="grid-header">
                  Queue{' '}
                  {queueAssociate.find(
                    (x) =>
                      x.employeeID === currentUserInformation.empId &&
                      x.isActive,
                  ) &&
                    ` (You're on ${getCurrentUserPosition(
                      currentUserInformation.empId,
                      true,
                    )})`}
                </div>
                <div className="grid-floor queue">
                  <OpportunityFloor
                    queueFloor={true}
                    queue={queueAssociate}
                    engageOpportunity={engageOpportunity}
                    disengageOpportunity={disengageOpportunity}
                    changeOpportunitySequence={changeOpportunitySequence}
                    changeOpportunityStatus={changeOpportunityStatus}
                    exitOpportunity={exitOpportunity}
                    getCurrentUserPosition={getCurrentUserPosition}
                  />
                </div>
              </Grid>

              {/* Engaging Floor */}
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <div className="grid-header">With Guest</div>
                <div className="grid-floor">
                  <OpportunityFloor
                    queueFloor={false}
                    queue={engagingAssociate}
                    engageOpportunity={engageOpportunity}
                    disengageOpportunity={disengageOpportunity}
                    exitOpportunity={exitOpportunity}
                    associateInfoStack={associateInfoStack}
                    checkInEmpIdRef={checkInEmpIdRef}
                    questions={questions}
                  />
                </div>
              </Grid>
            </Grid>
          </TabPanel>

          {/* Dashboard */}
          {dashboardPermission === 'manager' && (
            <TabPanel>
              <OpportunityDashboard
                storeId={currentUserInformation.storeId}
                isManager={true}
              />
            </TabPanel>
          )}

          {dashboardPermission === 'associate' && (
            <TabPanel>
              <OpportunityDashboard
                storeId={currentUserInformation.storeId}
                isManager={false}
                //maybe pass questions props here
              />
            </TabPanel>
          )}

          {/* Notepad */}
          <TabPanel>
            <OpportunityNotepad engagedData={engagingAssociate} />
          </TabPanel>
        </Tabs>

        <div className="right-float">
          {!associateJoinedQueue() ? (
            // Join
            <ImEnter
              size={30}
              title="Join GuestConnect"
              onClick={() => joinOpportunity()}
            />
          ) : (
            // Exit only available to those not in engage
            !associateCurrentlyEngaged() && (
              <ImExit
                size={30}
                title="Exit GuestConnect"
                onClick={() =>
                  associateJoinedQueue() ? exitOpportunity() : null
                }
              />
            )
          )}
        </div>
      </Navbar>
    </div>
  );
}
