import { Spinner } from 'reactstrap';
import React from 'react';

//Style
import './SpinnerLoading.scss';

function SpinnerLoading({ showLoadingText }) {
  return (
    <div className="spinner">
      <Spinner />
      {showLoadingText ? <span>Loading...</span> : ''}
    </div>
  );
}

export default SpinnerLoading;
