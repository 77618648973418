import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';
import bobboostLogo from '../business-module/img/bobboost.PNG';
import { BobsBucket } from '../../assets/img/menus';
import { getBucketSummary } from './data';
import Grid from '@mui/material/Grid';

const CenterContent = () => {
  const [currentDate, setCurrentDate] = useState(getCurrentDate());
  const [bucketSummary, setBucketSummary] = useState(null);
  const [actionableOrders, setActionableOrders] = useState(0);
  const [deliveredSales, setDeliveredSales] = useState(0);

  const [CncUnread, setCncUnread] = useState(0);
  const [CncUnknowledge, setCncUnknowledge] = useState(0);

  useEffect(() => {
    const dateInterval = setInterval(() => {
      setCurrentDate(getCurrentDate());
    }, 1000);

    return () => {
      clearInterval(dateInterval);
    };
  }, []);

  useEffect(() => {
    fetchBucketSummary();
    let homeBadge = document.getElementById('comm-collab-badge');
    let homeBadgeUnAck = document.getElementById('comm-collab-badge-unack');

    if (homeBadge) {
      let homeBadgevalue = homeBadge.innerHTML;
      console.log(homeBadgevalue);
      setCncUnread(homeBadgevalue || 0);
    }

    if (homeBadgeUnAck) {
      let homeBadgeUnAckvalue = homeBadgeUnAck.innerHTML;
      console.log(homeBadgeUnAckvalue);
      setCncUnknowledge(homeBadgeUnAckvalue || 0);
    }
  }, []);

  const fetchBucketSummary = async () => {
    try {
      const response = await getBucketSummary();
      if (response.success) {
        const labels = Object.keys(response.result).map(
          (key) => response.result[key].bucket,
        );
        const values = Object.keys(response.result).map(
          (key) => response.result[key].totalScheduled || '0',
        );

        //sorted in desc order
        const sortedData = values
          .map((value, index) => ({ value, label: labels[index] }))
          .sort((a, b) => b.value - a.value);

        const sortedLabels = sortedData.map((data) => data.label);
        const sortedValues = sortedData.map((data) => data.value);

        const filteredData = sortedLabels.reduce((data, label, index) => {
          data[label] = {
            header: label,
            value: sortedValues[index],
          };
          return data;
        }, {});

        setBucketSummary(filteredData);

        //calculate actionable orders (sum of notCompleted)
        const totalNotCompleted = Object.values(response.result).reduce(
          (sum, item) => sum + item.notCompleted,
          0,
        );
        setActionableOrders(totalNotCompleted);

        //calculate delivered sales (sum of totalScheduled)
        const totalScheduled = Object.values(response.result).reduce(
          (sum, item) => sum + Math.ceil(item.totalScheduled || 0),
          0,
        );
        setDeliveredSales(totalScheduled);
      } else {
        console.error('Failed to fetch bucket summary:', response.msg);
      }
    } catch (error) {
      console.error('Error fetching bucket summary:', error);
    }
  };

  function getCurrentDate() {
    const currentDate = new Date();
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    const month = months[currentDate.getMonth()];
    const day = currentDate.getDate();
    const year = currentDate.getFullYear();
    return `${month} ${day}, ${year}`;
  }

  const bucketOptions = {
    maintainAspectRatio: false,
    tooltips: { enabled: false },
    hover: { mode: null },
    scales: {
      xAxes: [
        {
          barThickness: 40,
          maxBarThickness: 40,
          ticks: {
            fontSize: 9,
            maxRotation: 0,
            callback: function (value) {
              const parts = value.split(' ');
              return parts;
            },
            fontStyle: 'bold',
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            callback: function (value) {
              return `$${value.toLocaleString()}`;
            },
          },
        },
      ],
    },
    legend: {
      display: false,
    },
    plugins: {
      datalabels: {
        display: true,
        color: 'black',
        anchor: 'end',
        align: 'top',
        clamp: true,
        clip: true,
        offset: -4,
        formatter: function (value) {
          return `$${value.toLocaleString()}`;
        },
      },
    },
  };

  function getBucketData() {
    if (!bucketSummary) {
      return null;
    }

    const labels = Object.keys(bucketSummary);
    const values = labels.map((label) => Math.ceil(bucketSummary[label].value));

    return {
      labels: labels,
      datasets: [
        {
          backgroundColor: ['#D0281B', '#D0281B', '#D0281B', '#D0281B'],
          data: values,
        },
      ],
    };
  }

  const bucketData = getBucketData();

  return (
    <div className="app-container">
      <Grid container spacing={3} style={{ height: '100%' }}>
        <Grid item xs={12} className="header" style={{ height: '17%' }}>
          <header>
            <img src={bobboostLogo} className="iss-logo1" alt="" />
            <label>My Business - {currentDate}</label>
          </header>
        </Grid>

        <Grid
          item
          xs={12}
          className="bucket-summary-container"
          style={{ height: '60%' }}
        >
          <label>Bucket Summary</label>
          {bucketSummary && deliveredSales !== 0 ? (
            <p>
              You've got <b>{actionableOrders}</b> actionable orders which could
              result in delivered sales of{' '}
              <b>
                {new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                  minimumFractionDigits: 0,
                }).format(Math.ceil(deliveredSales))}
              </b>
            </p>
          ) : (
            <div>
              {bucketSummary && deliveredSales === 0 && (
                <>
                  <p style={{ marginTop: '20px' }}>
                    You've got <b> {actionableOrders}</b> actionable orders
                    which could result in delivered sales of{' '}
                    <b>
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        minimumFractionDigits: 0,
                      }).format(Math.ceil(deliveredSales))}
                    </b>
                  </p>
                  {bucketSummary && deliveredSales === 0 && (
                    <img src={BobsBucket.default} alt="bucket empty" />
                  )}
                </>
              )}
            </div>
          )}
          <div style={{ height: '70%' }}>
            {bucketData && deliveredSales !== 0 ? (
              <Bar
                data={bucketData}
                options={bucketOptions}
                style={{ height: '70%' }}
              />
            ) : null}
          </div>
        </Grid>

        <Grid
          item
          xs={12}
          className="c-and-c-summary-container"
          style={{ height: '23%' }}
        >
          <label>C&C Summary</label>
          <p style={{ textAlign: 'left', marginTop: '20px' }}>
            You've got{' '}
            <b style={{ textDecoration: 'underline' }}> {CncUnread} unread </b>
            C&C Messages
          </p>
          <p style={{ textAlign: 'left' }}>
            You have{' '}
            <b style={{ textDecoration: 'underline' }}> {CncUnknowledge}</b> C&C
            messages that can be{' '}
            <b style={{ textDecoration: 'underline' }}>acknowledged</b>
          </p>
        </Grid>
      </Grid>
    </div>
  );
};

export default CenterContent;
