// Enums helper
const enums = require('../enums');
const helper = require('../utils/helper');
const ux = require('../application-center/ux-tracking-center');

export const getOpenOrderBucket = (id) => {
  let buckets = [];
  Object.keys(enums.openOrderBucket).forEach(
    (key) =>
      (buckets[enums.openOrderBucket[key].value] = enums.openOrderBucket[key]),
  );
  return buckets[id];
};

export const getWriteSalesBucket = (id) => {
  let buckets = [];
  Object.keys(enums.writeSalesBucket).forEach(
    (key) =>
      (buckets[enums.writeSalesBucket[key].value] =
        enums.writeSalesBucket[key]),
  );
  return buckets[id];
};

export const getRepeatSalesBucket = (id) => {
  let buckets = [];
  Object.keys(enums.repeatSalesBucket).forEach(
    (key) =>
      (buckets[enums.repeatSalesBucket[key].value] =
        enums.repeatSalesBucket[key]),
  );
  return buckets[id];
};

export const generateEnums = async (enums) => {
  await fetch(helper.getAPIHost() + '/api/MenuSetups', helper.apiHeaders())
    .then(async (response) => {
      let data = await response.json();
      if (data.status === 'OK') {
        helper.catchServer(data);
        let sorted = data.result.sort(function (a, b) {
          let seqA = a.sequence;
          let seqB = b.sequence;
          if (seqA < seqB) return -1;
          if (seqA > seqB) return 1;
          return 0;
        });

        let d_menuSet = [];
        let d_parent = [];
        let w_menuSet = [];
        let w_parent = [];
        let r_menuSet = [];
        let r_parent = [];
        d_parent[2] = { uid: 2, menuLabel: 'Root' };
        w_parent[10] = { uid: 10, menuLabel: 'Root' };
        r_parent[15] = { uid: 15, menuLabel: 'Root' };
        sorted.forEach((element) => {
          if (element.menuType === 'BUCKET' && element.parentId === 2) {
            d_menuSet.push(element);
            d_parent[element.uid] = element;
            sorted.forEach((sub) => {
              if (sub.parentId === element.uid) {
                sub.show = true;
                d_menuSet.push(sub);
              }
            });
          }

          if (element.menuType === 'BUCKET' && element.parentId === 10) {
            w_menuSet.push(element);
            w_parent[element.uid] = element;
            sorted.forEach((sub) => {
              if (sub.parentId === element.uid) {
                sub.show = true;
                w_menuSet.push(sub);
              }
            });
          }

          if (element.menuType === 'BUCKET' && element.parentId === 15) {
            r_menuSet.push(element);
            r_parent[element.uid] = element;
            sorted.forEach((sub) => {
              if (sub.parentId === element.uid) {
                sub.show = true;
                r_menuSet.push(sub);
              }
            });
          }
        });

        helper.updateOpenOrderEnum(enums, d_menuSet);
        helper.updateWriteSalesEnum(enums, w_menuSet);
        helper.updateRepeatSalesEnum(enums, r_menuSet);
      } else {
        ux.logError(
          'enumDict',
          'generateEnums',
          'Unable to get menu/enum setup',
          JSON.stringify(data),
        );
      }
    })
    .catch((error) => {
      ux.logError(
        'enumDict',
        'generateEnums',
        'Unable to get menu/enum setup',
        error,
      );
    });
};
