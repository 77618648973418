const helper = require('../../../utils/helper');
const ux = require('../../../application-center/ux-tracking-center');
const enums = require('../../../enums');

export const orderNumberClicked = (event, orderNumber) => {
  //console.log(event)
  //console.log(orderNumber)
  let sapURL = '';
  let parameters = JSON.parse(localStorage.getItem('Parameters') || '{}');

  let isDesk = (parameters.BOB_DESK_GROUP || '').includes(
    helper.getUserGroup(),
  );

  if (helper.isLocal()) {
    sapURL = isDesk
      ? parameters.LOCAL_ORDER_DESK
      : parameters.LOCAL_ORDER_GUEST;
  }

  if (helper.isDEV()) {
    sapURL = isDesk ? parameters.DEV_ORDER_DESK : parameters.DEV_ORDER_GUEST;
  }

  if (helper.isQA()) {
    sapURL = isDesk ? parameters.QA_ORDER_DESK : parameters.QA_ORDER_GUEST;
  }

  if (helper.isProd()) {
    sapURL = isDesk ? parameters.PROD_ORDER_DESK : parameters.PROD_ORDER_GUEST;
  }

  // Check for undefined or null
  if (
    sapURL !== '' &&
    (sapURL === undefined ||
      sapURL === null ||
      sapURL === 'undefined' ||
      sapURL === 'null')
  ) {
    sapURL = '';
  }

  // Check for last char, make sure is ended by /
  sapURL = sapURL.trim();
  if (sapURL.length > 0) {
    let lastChar = sapURL.slice(-1);
    if (lastChar !== '/' && lastChar !== '=') sapURL += '/';
  }

  if (sapURL) {
    let orderNum = '';
    if (orderNumber) orderNum = orderNumber;
    else orderNum = event.target.value;

    orderNum = orderNum.replace(/\s/g, '');
    sapURL += orderNum;

    var opt = parameters.ORDER_OPEN_OPTION || '';
    var timeDelay = parameters.ORDER_OPEN_DELAY || 1;

    if (
      window.openedWindow === undefined ||
      window.openedWindow === null ||
      window.openedWindow.closed
    ) {
      // not open or window is closed
      BobDeskOrderTracking(orderNum, isDesk);
      window.openedWindow = window.open(sapURL, 'FioriOrder');
      window.openedOrder = orderNum;
      return true;
    } else {
      // if window is opened, check is it different order number, if same order, set it focus, otherwise replace it.
      if (window.openedOrder === orderNum) {
        window.openedWindow.focus();
        return true;
      } else {
        if (opt.toUpperCase() === '1A') {
          // close & re-open
          if (
            window.confirm(
              'Another order already opened, do you want to proceed with this order?',
            )
          ) {
            window.openedWindow.close();
            window.openedWindow = null;
            BobDeskOrderTracking(orderNum, isDesk);
            setTimeout(function () {
              window.openedWindow = window.open(sapURL, 'FioriOrder');
            }, timeDelay * 1000);

            window.openedOrder = orderNum;

            return true;
          } else {
            window.openedWindow.focus();
            return true;
          }
        } else if (opt.toUpperCase() === '1B') {
          // prompt message only
          alert(
            'You already opened a BobSuite tab using an order link in BobBoost.  Before opening another order you must close the existing BobSuite tab.',
          );
        }
      }
    }
  }
  return false;
};

export const BobDeskOrderTracking = (orderNumber, isDesk) => {
  if (orderNumber) {
    ux.logAction(
      'OrderFramework',
      'OrderTracking',
      'OrderNum: ' + orderNumber + ' ; ' + (isDesk ? 'BobDesk' : 'BobGuest'),
    );
  }
};

export const handleReactNotiOrderClick = (e) => {
  let orderNum = e.target.getAttribute('order-num');

  if (orderNum) {
    if (orderNumberClicked(e, orderNum))
      window.message.util.notiRemoveOrders(orderNum);
  }
};

export const handlePodium = (bucketType, event) => {
  let parameters = JSON.parse(localStorage.getItem('Parameters'));
  let url = parameters.PODIUM_URL || '';

  let pnumber = event.target.getAttribute('data-phone');
  let pname = event.target.getAttribute('data-name');
  let puid = event.target.getAttribute('data-uid');
  let orderNum = event.target.getAttribute('data-order');

  // Check for last char, make sure is ended by /
  url = url.trim();
  if (url.length > 0) {
    let lastChar = url.slice(-1);
    if (lastChar !== '/' && lastChar !== '=') url += '/';
  }

  if (
    pnumber === null ||
    pnumber === undefined ||
    pnumber?.trim() === '' ||
    pnumber?.trim().toUpperCase() === 'NULL'
  ) {
    window.message.notification(
      'No phone number found!',
      enums.notificationType.W,
    );
    return;
  }

  if (url) {
    url = url + 'phone-number/' + pnumber.replaceAll('-', '');
    url += '?name=' + pname;
    url += '&locationUid=' + puid;
    console.log('Podium: ' + url);

    // send to backend
    let custId = event.target.getAttribute('data-custid');
    updatePodium(orderNum, url, custId, bucketType);

    if (
      window.podiumWindow === undefined ||
      window.podiumWindow === null ||
      window.podiumWindow.closed
    ) {
      // not open or window is closed
      window.podiumWindow = window.open(url, 'Podium');
      window.podiumOrder = orderNum;
    } else {
      // if window is opened, check is it different order number, if same order, set it focus, otherwise replace it.
      if (window.podiumOrder === orderNum) {
        window.podiumWindow.focus();
      } else {
        window.podiumWindow.location = url;
        window.podiumOrder = orderNum;
        window.podiumWindow.focus();
      }
    }
  }
};

export const updatePodium = async (orderNum, url, custId, bucketType) => {
  console.log('Order to Update - Podium');

  let apiPath = 'salesDocNumber=' + orderNum + '&podiumURL=' + url;
  ux.logAction('OrderFramework (' + bucketType + ')', 'updatePodium', apiPath);

  let data = {
    salesDocNbr: orderNum,
    podiumURL: url,
    profitCenterNbr: helper.getStorelId(),
    salesAssociate1_ID: helper.getEmplId(),
    customerNrPK: custId,
  };
  if (helper.isTrainer()) {
    this.notification('Trainer: Podium Upated!', enums.notificationType.S);
    return;
  }

  let apiurl = '';
  // if (bucketType === "d")
  // apiurl = "/api/DeliverSales/postPodiumLog";
  // else if (bucketType === "w")
  // apiurl = "/api/DeliverSales/postPodiumLog";

  if (bucketType === 'd' && helper.getUI_NEW_ORDER_API())
    apiurl = '/api/DeliverSales/postPodiumLog';
  else if (bucketType === 'w' && helper.getUI_NEW_ORDER_API())
    apiurl = '/api/DeliverSales/postPodiumLog';
  if (bucketType === 'd' && !helper.getUI_NEW_ORDER_API())
    apiurl = '/api/OpenOrders/postPodiumLog';
  else if (bucketType === 'w' && !helper.getUI_NEW_ORDER_API())
    apiurl = '/api/OpenOrders/postPodiumLog';

  await fetch(helper.getAPIHost() + apiurl, {
    method: 'POST',
    body: JSON.stringify(data),
    ...helper.apiHeaders(),
  })
    .then(async (response) => {
      console.log('Updated Podium: ' + JSON.stringify(apiPath));

      // not required for current version
      /*
            var orders_list = this.state.orders;
            orders_list.map(order => {
                if (order.orderNumber === orderNum)
                    order.contacted = true;
            });
    
            this.setState({
                orders: orders_list
            });*/
    })
    .catch((error) => {
      ux.logError(
        'OpenFramework (' + bucketType + ')',
        'updatePodium',
        error,
        JSON.stringify(apiPath),
      );
    });
};

export const updateOrderScheduling = async (orderNum) => {
  await fetch(
    helper.getAPIHost() +
      '/api/DeliverSales/updateOrderScheduling?SalesDocNbr=' +
      orderNum,
    {
      method: 'PUT',
      ...helper.apiHeaders(),
    },
  )
    .then(async (response) => {
      if (response.ok) {
        console.log('Scheduling order updated: ' + orderNum);
      } else {
        ux.logError(
          'OpenFramework',
          'updateOrderScheduling',
          JSON.stringify(response),
          orderNum,
        );
      }
    })
    .catch((error) => {
      ux.logError('OpenFramework', 'updateOrderScheduling', error, orderNum);
      console.log(error);
    });
};

export const updateOrderState = (oldOrders, newOrders) => {
  let found = false;
  let itemFound = false;

  let orderFields = [];
  let itemFields = [];

  // from new, add / update to old
  newOrders.forEach((nOrder) => {
    found = false;
    oldOrders = oldOrders.map((oOrder) => {
      if (nOrder.orderNumber === oOrder.orderNumber) {
        found = true;

        // ----------------------------------------------------------------
        // In this section, update new results into existing orders
        // ----------------------------------------------------------------

        // get keys
        if (orderFields.length === 0) {
          let sample = JSON.parse(JSON.stringify(nOrder));
          delete sample.items;
          orderFields = Object.keys(sample);
        }

        // ORDER: compare & update
        compareUpdateObject(orderFields, nOrder, oOrder);

        // process for items, update / add to old
        nOrder.items.forEach((nItem) => {
          itemFound = false;
          oOrder.items = oOrder.items.map((oItem) => {
            // get the correct item line
            if (oItem.sku === nItem.sku && oItem.itemNbr === nItem.itemNbr) {
              itemFound = true;

              // get item keys
              if (itemFields.length === 0) {
                itemFields = Object.keys(nItem);
              }

              // ITEM: compare & update
              compareUpdateObject(itemFields, nItem, oItem);
            }
            return oItem;
          });

          if (!itemFound) {
            oOrder.items.push(nItem);
          }
        });

        // process for items, remove items
        oOrder.items.forEach((oItem, oIdx) => {
          itemFound = false;

          nOrder.items.forEach((nItem) => {
            if (oItem.sku === nItem.sku && oItem.itemNbr === nItem.itemNbr) {
              itemFound = true;
            }
          });

          if (!itemFound) {
            // remove old order
            delete oOrder.items[oIdx];
          }
        });

        // remove undefined elements
        oOrder.items = oOrder.items.filter(function (x) {
          return x !== undefined && x !== null;
        });
      }
      return oOrder;
    });

    if (!found) {
      // new order found
      oldOrders.push(nOrder);
    }
  });

  // from old orders check in new orders --> to remove
  oldOrders.forEach((oOrder, i) => {
    found = false;
    newOrders.forEach((nOrder) => {
      if (nOrder.orderNumber === oOrder.orderNumber) {
        found = true;
      }
    });
    if (!found) {
      // remove old order
      delete oOrder[i];
    }
  });

  // remove undefined elements
  oldOrders = oldOrders.filter(function (x) {
    return x !== undefined && x !== null;
  });

  return oldOrders;
};

export const compareUpdateObject = (keys, newObj, oldObj) => {
  keys.forEach((key) => {
    if (newObj[key] !== oldObj[key]) {
      oldObj[key] = newObj[key];
    }
  });
  return oldObj;
};

export const handleReactNotiOrderClickURL = (refURL, e) => {
  // let url = e.target.getAttribute('noti-url');

  if (refURL !== '') {
    window.location.href = refURL;
  }
};

export const reasonOptions = () => {
  return {
    [enums.openOrderBucket.ScheduledIncompletesectionals.code]: [
      {
        value: 1,
        label:
          'I have spoken to the customer and explained the risks associated with getting partial delivery of sectionals (die lot color match issues, exposed unfinished edges, potential significant delay for remaining pieces, etc.) and the customer still wants delivery.',
      },
      {
        value: 2,
        label:
          'I have spoken to the customer and they would rather wait to get everything all at once so the order is being rescheduled or put on a block',
      },
      {
        value: 3,
        label:
          'I have spoken to the customer and they asked me to cancel the order.',
      },
      {
        value: 4,
        label:
          'I was not able to contact the customer and I am waiting to hear back from them.  Let’s keep this on my task list',
      },
    ],
    [enums.openOrderBucket.ScheduledAllAvailableImmediatelySooner.code]: [
      {
        value: 1,
        label:
          'I contacted the customer and they wanted to pull the order forward so I rescheduled it.',
      },
      {
        value: 2,
        label: 'The customer wants to keep their existing delivery date.',
      },
      {
        value: 3,
        label:
          'I reached out to the customer but did not hear back.  Please remind me to follow up again.',
      },
      {
        value: 4,
        label:
          "The day that this is currently scheduled for is the earliest date that is available in the customer's area.",
      },
    ],
    // [enums.repeatSalesBucket.MedalliaNextRoom.code]: [
    //   {
    //     value: 1,
    //     label: "Unable to contact."
    //   },
    //   {
    //     value: 2,
    //     label: "Made contact. Customer interested."
    //   },
    //   {
    //     value: 3,
    //     label: "Customer not interested."
    //   },
    //   {
    //     value: 4,
    //     label: "Customer purchased next room."
    //   },
    // ]
  };
};
