import React from 'react';
import { Label, Input, Spinner } from 'reactstrap';
import PropTypes from 'prop-types';
import enums from '../../../../enums';
import Cookies from 'js-cookie';

const helper = require('../../../../utils/helper');
const ux = require('../../../../application-center/ux-tracking-center');

window.Cust360CommentBlurTime = 0;
window.Cust360CommentTimer = null;
window.Cust360CommentCheckTime = 15e3; // refer to 20 seconds

class BDFCust360AddComment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      orderNumberList: [],
      inputOrderNumber: '',
      inputComment: '',
      extOrder: '',
      isExternal: false,
      custName: '',
      custID: '',
      orderType: '',
      caller: {},
      saveDisable: true,
      showUnsavedMsg: false,
      showConfirmMsg: false,
      savingCommentFinish: true,
      disableSaveNotes: false,
    };

    this.schedulingCheckForComment = this.schedulingCheckForComment.bind(this);
    this.handleAddComment = this.handleAddComment.bind(this);
    this.updateCookieFlag = this.updateCookieFlag.bind(this);
  }

  patchOrderNumber = (OrderID, orderNumberList) => {
    // attach order number into list
    if (OrderID) {
      // console.log(JSON.stringify(orderNumberList));
      let orderFound = false;
      orderNumberList.map((o) => {
        if (o.orderNumber === OrderID) orderFound = true;
        return o;
      });
      if (!orderFound) {
        let d = new Date();
        orderNumberList.push({
          orderNumber: OrderID,
          orderTransactionDate:
            d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate(),
        });
      }
      // console.log(JSON.stringify(orderNumberList));
    }

    return orderNumberList;
  };

  componentDidMount() {
    const {
      orderNumberList,
      custName,
      custID,
      caller,
      OrderID,
      isExternal,
      orderType,
    } = this.props;
    const orderNumberList_v2 = this.patchOrderNumber(OrderID, orderNumberList);

    window.Cust360CommentBlurTime = 0;
    if (window.Cust360CommentTimer) clearTimeout(window.Cust360CommentTimer);
    window.Cust360CommentTimer = null;
    this.updateCookieFlag('');

    this.setState({
      orderNumberList: orderNumberList_v2.sort(
        helper.sort('orderTransactionDate', 'desc'),
      ),
      inputOrderNumber: OrderID || '',
      extOrder: OrderID || '',
      isExternal: isExternal,
      custName: custName,
      custID: custID,
      caller: caller,
      orderType: orderType,
    });
  }

  componentDidUpdate() {
    const { orderNumberList, OrderID } = this.props;

    const orderNumberList_v2 = this.patchOrderNumber(OrderID, orderNumberList);

    if (orderNumberList_v2.length !== this.state.orderNumberList.length) {
      this.setState({
        orderNumberList: orderNumberList_v2.sort(
          helper.sort('orderTransactionDate', 'desc'),
        ),
      });
    }
  }

  handleInputChanged = (event) => {
    let name = event.target.name;
    let toset = {};
    toset[`input${name}`] = event.target.value;

    if (name === 'Comment') {
      if (toset.inputComment === '') {
        localStorage.setItem('commentIsDirty', false);
        if (this.state.isExternal)
          window.removeEventListener('beforeunload', this.onUnload);
        this.setState({ saveDisable: true });
      } else {
        localStorage.setItem('commentIsDirty', true);
        if (this.state.isExternal)
          window.addEventListener('beforeunload', this.onUnload);
        this.setState({ saveDisable: false });
      }
    }

    this.setState(toset);
  };

  onUnload = (e) => {
    // the method that will be used for both add and remove event
    e.preventDefault();
    console.log('test onunload');
    e.returnValue = '';
  };

  handleAddComment = async (event) => {
    if (!this.state.saveDisable) {
      if (!this.state.inputComment) {
        alert('Please dont leave comment blank');
        return;
      }
      if (this.state.inputOrderNumber === '') {
        alert('Please select order #');
        return;
      }

      this.setState({ disableSaveNotes: true });

      let user = {};
      let ext = false;
      if (this.state.isExternal) {
        user = helper.extUser();
        ext = true;
      }
      let payload = {
        source: 'Order Notes',
        customerNbr: this.state.custID,
        customerNm: this.state.custName,
        profitCenterNbr: ext ? user.storeId : helper.getStorelId(),
        profitCenterNm: ext
          ? user.storeName
          : localStorage.getItem('StoreName'),
        salesAssociate: ext ? user.emplId : helper.getEmplId(),
        salesAssociateNm: ext ? user.name : localStorage.getItem('EmplName'),
        salesDocNbr: this.state.inputOrderNumber,
        details: this.state.inputComment,
        messageName: ext ? this.state.orderType : null,
        salesAssociateClass: localStorage.getItem('empClass'),
        author: localStorage.getItem('SamAccountName'),
      };

      if (this.state.isExternal) {
        payload.type = 'Ext';
        payload.referenceID = helper.extSource()?.toLowerCase();
      }

      await fetch(
        helper.getAPIHost() + '/api/Customer360/postCustomerTimeline',
        {
          method: 'POST',
          body: JSON.stringify(payload),
          ...helper.apiHeaders(),
        },
      )
        .then(async (res) => {
          if (res.status === 200) {
            window.message.notification(
              `Comment Added Successfully`,
              enums.notificationType.S,
            );
            this.setState(
              {
                inputComment: '',
                showUnsavedMsg: false,
                showConfirmMsg: false,
                savingCommentFinish: true,
              },
              () => {
                setTimeout(() => {
                  this.state.caller.reloadConatctTimeline();
                }, 1000);
                this.updateCookieFlag('');
              },
            );
            ux.logAction(
              'Customer Profile',
              'Add Comment',
              'CustId: ' +
                this.state.custID +
                '; OrderNum: ' +
                this.state.inputOrderNumber +
                '; path: ' +
                ux.getPath(),
            );
          } else {
            this.setState({
              showUnsavedMsg: true,
              showConfirmMsg: false,
              savingCommentFinish: true,
            });
            window.message.notification(
              `Add Comment Failed`,
              enums.notificationType.E,
            );
            ux.logError(
              'Add Comment',
              'postCustomerTimeline',
              res.statusText,
              JSON.stringify(payload),
            );
          }
        })
        .catch((err) => {
          this.setState({
            showUnsavedMsg: true,
            showConfirmMsg: false,
            savingCommentFinish: true,
          });
          window.message.notification(
            `Add Comment Failed, ${err}`,
            enums.notificationType.E,
          );
          ux.logError(
            'Add Comment',
            'postCustomerTimeline',
            err,
            JSON.stringify(payload),
          );
        })
        .finally(() => {
          this.setState({ disableSaveNotes: false });
        });
      window.removeEventListener('beforeunload', this.onUnload);
      localStorage.setItem('commentIsDirty', false);
    }
  };

  onCommentBlur = () => {
    if (this.state.isExternal) {
      if (document.getElementById('txtCust360Comment').value !== '') {
        window.Cust360CommentBlurTime = new Date().getTime();
        window.Cust360CommentTimer = setTimeout(() => {
          this.schedulingCheckForComment();
        }, 1000);
      }

      this.updateCookieFlag('1');
      this.setState({
        showUnsavedMsg:
          document.getElementById('txtCust360Comment').value !== '',
      });
    }
  };

  schedulingCheckForComment = () => {
    // only if the object is exists, otherwise will cause error
    if (document.getElementById('txtCust360Comment')) {
      if (
        document.getElementById('txtCust360Comment').value &&
        this.state.showUnsavedMsg
      ) {
        const curTime = new Date().getTime();

        if (
          curTime - window.Cust360CommentBlurTime >
          window.Cust360CommentCheckTime
        ) {
          if (
            this.state.showConfirmMsg === false &&
            window.confirm(
              'You have unsaved comments. Would you like to save them before proceeding?\nClick OK to Save or Cancel to ignore this message.',
            )
          ) {
            window.Cust360CommentBlurTime = new Date().getTime(); // update latest blur time
            this.setState({
              showConfirmMsg: true,
              savingCommentFinish: false,
            });
            this.handleAddComment();
          } else {
            if (this.state.savingCommentFinish === true)
              this.setState({
                showUnsavedMsg: false,
              });
            this.updateCookieFlag('');
          }
        }
      }
      window.Cust360CommentTimer = setTimeout(() => {
        this.schedulingCheckForComment();
      }, 1000);
    } else {
      if (window.Cust360CommentTimer) clearTimeout(window.Cust360CommentTimer);
    }
  };

  updateCookieFlag = (value) => {
    Cookies.set('BobBoostCommentFlag', value, {
      domain: '.mybobs.com',
    });
    if (this.state.isExternal) window.top.postMessage(value, '*');
  };

  render() {
    //console.log(this.state);

    return (
      <div>
        <div className="title"></div>
        <div className="orderSelect">
          <Label>Order # (Order Date)</Label>
          <Input
            type="select"
            name="OrderNumber"
            value={this.state.inputOrderNumber}
            onChange={this.handleInputChanged.bind(this)}
            disabled={this.state.isExternal && this.state.extOrder}
          >
            <option value=""></option>
            {this.state.orderNumberList.map((order, i) => {
              return (
                <option key={i} value={order.orderNumber}>
                  {order.orderNumber} (
                  {helper.formatDate(order.orderTransactionDate)})
                </option>
              );
            })}
          </Input>
        </div>
        <div
          className={`form ${this.state.showUnsavedMsg ? 'unsaveComment' : ''}`}
        >
          <Input
            type="textarea"
            name="Comment"
            id="txtCust360Comment"
            value={this.state.inputComment}
            onChange={this.handleInputChanged.bind(this)}
            onBlur={this.onCommentBlur.bind(this)}
            placeholder="Comment"
          />
          <button
            className={`${
              this.state.inputComment === '' || this.state.disableSaveNotes
                ? 'iss-button-grey'
                : 'iss-button-red'
            } saveButton${this.state.isExternal ? 'BobSuite' : ''}`}
            disabled={
              this.state.inputComment === '' || this.state.disableSaveNotes
            }
            onClick={this.handleAddComment.bind(this)}
          >
            {this.state.disableSaveNotes && <Spinner size="sm" />} Save Note
          </button>
        </div>
      </div>
    );
  }
}

BDFCust360AddComment.propTypes = {
  caller: PropTypes.object,
  orderNumberList: PropTypes.array,
  custName: PropTypes.string,
  custID: PropTypes.string,
  OrderID: PropTypes.string,
  orderType: PropTypes.string,
  isExternal: PropTypes.bool,
};

BDFCust360AddComment.defaultProps = {
  caller: {},
  orderNumberList: [],
  custName: '',
  custID: '',
  OrderID: '',
  orderType: '',
  isExternal: false,
};

export default BDFCust360AddComment;
