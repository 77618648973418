const helper = require('../../utils/helper');

export const notificationSceduler = async () => {
  let res = [];
  try {
    await fetch(helper.getAPIHost() + '/api/getNotification', {
      method: 'GET',
      ...helper.apiHeaders(),
    }).then(async (data) => {
      res = await data.json();
      helper.catchServer(res);
    });

    if (res?.status === 'OK') {
      return {
        success: true,
        result: res?.result,
      };
    } else {
      return {
        success: false,
        msg: 'Failed to get notification',
      };
    }
  } catch (r) {
    console.log('Error on check notification');
    console.log(r);
    return {
      success: false,
      msg: 'Failed to get notification',
    };
  }
};

export const getAllFeatureNotes = async () => {
  let res = [];
  try {
    await fetch(
      helper.getAPIHost() + '/api/FeatureReleases/getAllFeatureNotes',
      {
        method: 'GET',
        ...helper.apiHeaders(),
      },
    ).then(async (data) => {
      res = await data.json();
      helper.catchServer(res);
    });

    if (res?.status === 'OK') {
      return {
        success: true,
        result: res?.result,
      };
    } else {
      return {
        success: false,
        msg: 'Failed to get getAllFeatureNotes',
      };
    }
  } catch (r) {
    console.log('Error on check getAllFeatureNotes');
    console.log(r);
    return {
      success: false,
      msg: 'Failed to get getAllFeatureNotes',
    };
  }
};

export const getArchivedNotification = async () => {
  let res = [];
  try {
    await fetch(helper.getAPIHost() + '/api/getArchivedNotification', {
      method: 'GET',
      ...helper.apiHeaders(),
    }).then(async (data) => {
      res = await data.json();
      helper.catchServer(res);
    });

    if (res?.status === 'OK') {
      return {
        success: true,
        result: res?.result,
      };
    } else {
      return {
        success: false,
        msg: 'Failed to get Archived Notification',
      };
    }
  } catch (r) {
    console.log('Error on check Archived Notification');
    console.log(r);
    return {
      success: false,
      msg: 'Failed to get Archived Notification',
    };
  }
};

export const getBucketSummary = async () => {
  try {
    let res = [];

    await fetch(
      helper.getAPIHost() + '/api/DeliverSales/getBuckets?isBusinessMod=true',
      {
        method: 'POST',
        ...helper.apiHeaders(),
      },
    ).then(async (response) => {
      res = await response.json();
    });

    if (res?.status === 'OK') {
      let data = res?.result;

      // eslint-disable-next-line array-callback-return
      data?.map((val) => {
        if (val.bucketID === 22) {
          val.bucket = 'All Available Immediately';
        } else if (val.bucketID === 4) {
          val.bucket = 'Available Within 2 Weeks';
        }
      });
      return {
        success: true,
        result: data,
      };
    } else {
      return {
        success: false,
        msg: 'Failed to get getBuckets',
      };
    }
  } catch (error) {
    console.error(error);
    return {
      success: false,
      msg: 'failes Get Data from API',
    };
  }
};

export const closeNotification = async (msgId, isBobAssist) => {
  console.log('close notification message ' + msgId, isBobAssist);

  let UID = parseInt(msgId.uid);

  console.log(UID);

  await fetch(
    helper.getAPIHost() +
      '/api/updateNotification?uid=' +
      UID +
      '&isBobAssist=' +
      isBobAssist,
    {
      method: 'POST',
      ...helper.apiHeaders(),
    },
  )
    .then(async (response) => {
      let data = await response.json();
      if (data.status === 'OK') {
        console.log('Notification has been send to archived!');
        console.log(data);
        helper.catchServer(data);
        return {
          success: true,
          result: data,
        };
      }
    })
    .catch((error) => {
      console.error(error);
      return {
        success: false,
        msg: 'failes Get Data from API',
      };
    });
};

export const getTodayStorePulse = async () => {
  let res = [];
  try {
    await fetch(helper.getAPIHost() + '/api/StorePulse/getTodayStorePulse', {
      method: 'GET',
      ...helper.apiHeaders(),
    }).then(async (data) => {
      res = await data.json();
      helper.catchServer(res);
    });

    if (res?.status === 'OK') {
      return {
        success: true,
        result: res?.result,
      };
    } else {
      return {
        success: false,
        msg: 'Failed to get today StorePulse',
      };
    }
  } catch (r) {
    console.log('Error on check today StorePulse');
    console.log(r);
    return {
      success: false,
      msg: 'Failed to get today StorePulse',
    };
  }
};

export const updateStorePulse = async (storePulseData) => {
  try {
    console.log('StorePulse data update', storePulseData);

    const response = await fetch(
      helper.getAPIHost() + '/api/StorePulse/updateStorePulse',
      {
        method: 'PUT',
        body: JSON.stringify(storePulseData),
        ...helper.apiHeaders(),
      },
    );

    const data = await response.json();
    console.log('StorePulse response update', data);

    if (data.status === 'OK') {
      return {
        success: true,
        result: data.result,
        msg: 'storePulse update successfully',
      };
    } else {
      return {
        success: false,
        msg: 'Failed to update storePulse',
      };
    }
  } catch (error) {
    console.error('Error updating storePulse', error);
    return {
      success: false,
      msg: 'Failed to update storePulse',
    };
  }
};

export const muteStorePulse = async (storePulseData) => {
  try {
    console.log('StorePulse mute data update', storePulseData);

    const response = await fetch(
      helper.getAPIHost() + '/api/StorePulse/muteStorePulse',
      {
        method: 'PUT',
        body: JSON.stringify(storePulseData),
        ...helper.apiHeaders(),
      },
    );

    const data = await response.json();
    console.log('StorePulse response muted', data);

    if (data.status === 'OK') {
      return {
        success: true,
        result: data.result,
        msg: 'storePulse mute successfully',
      };
    } else {
      return {
        success: false,
        msg: 'Failed to mute storePulse',
      };
    }
  } catch (error) {
    console.error('Error updating storePulse', error);
    return {
      success: false,
      msg: 'Failed to update storePulse',
    };
  }
};

export const getNewStorePulseMute = async () => {
  let res = [];
  try {
    await fetch(helper.getAPIHost() + '/api/StorePulse/getNewStorePulseMute', {
      method: 'GET',
      ...helper.apiHeaders(),
    }).then(async (data) => {
      res = await data.json();
      helper.catchServer(res);
    });

    if (res?.status === 'OK') {
      return {
        success: true,
        result: res?.result,
      };
    } else {
      return {
        success: false,
        msg: 'Failed to update StorePulse mute',
      };
    }
  } catch (r) {
    console.log('Error on check StorePulse mute');
    console.log(r);
    return {
      success: false,
      msg: 'Failed to update StorePulse mute',
    };
  }
};

export const getStorePulseHistory = async () => {
  let res = [];
  try {
    await fetch(helper.getAPIHost() + '/api/StorePulse/getStorePulseHistory', {
      method: 'GET',
      ...helper.apiHeaders(),
    }).then(async (data) => {
      res = await data.json();
      helper.catchServer(res);
    });

    if (res?.status === 'OK') {
      return {
        success: true,
        result: res?.result,
      };
    } else {
      return {
        success: false,
        msg: 'Failed to get StorePulse history',
      };
    }
  } catch (r) {
    console.log('Error on check StorePulse history');
    console.log(r);
    return {
      success: false,
      msg: 'Failed to get StorePulse history',
    };
  }
};
