import $ from 'jquery';
const config = require('../../config');
const helper = require('../../utils/helper');

const initWindowLog = () => {
  if (typeof window.UXLogSending === 'undefined') window.UXLogSending = false;

  if (typeof window.UXLogs === 'undefined') window.UXLogs = [];

  if (typeof window.UXLogsPending === 'undefined') window.UXLogsPending = [];
};

export const addUXLog = (data = null) => {
  // for mutiple
  initWindowLog();

  if (data !== null) {
    if (helper.isExternal()) {
      sendUxLog(data);
    } else {
      data = setData(data);

      if (window.UXLogSending === false) {
        if (window.UXLogsPending.length > 0) {
          // merge log
          window.UXLogs.concat(window.UXLogsPending);
        }
        // Push new log
        window.UXLogs.push(data);
      } else {
        window.UXLogsPending.push(data);
      }
    }
  }
};

export const sendUXLogScheduler = () => {
  initWindowLog();

  // set for next call
  setTimeout(() => {
    sendUXLogScheduler();
  }, 10 * 1000); // schedule for every 10 s, if log exists

  if (window.UXLogs.length > 0) {
    //window.UXLogSending === false &&
    window.UXLogSending = true;

    try {
      $.ajax({
        url: helper.getAPIHost() + '/api/uxtracking',
        data: JSON.stringify(window.UXLogs),
        method: 'post',
        headers: config.api.headers,
      })
        .done((res) => {
          console.log('UX Tracking - triggered', res);
          window.UXLogs = [];
          window.UXLogSending = false;
          helper.catchServer(res);
        })
        .fail((error) => {
          console.error('UX Error: ', error);
          window.UXLogSending = false;
        });
    } catch (e) {
      console.log('UXLog error');
      window.UXLogSending = false;
    }
  }
};

export const sendUxLog = (data) => {
  // for single
  if (data === undefined || data === null) return;

  data = setData(data);

  let l_uxlogs = [];
  l_uxlogs.push(data);

  try {
    $.ajax({
      async: false,
      url: helper.getAPIHost() + '/api/uxtracking',
      data: JSON.stringify(l_uxlogs),
      method: 'post',
      headers: config.api.headers,
    })
      .done((res) => {
        res.json().then((data) => {
          if (!res.ok) {
            console.log('UX Error-: ' + JSON.stringify(data) || 'HTTP error');
            console.log(res);
          } else {
            helper.catchServer(res);
          }
          return data;
        });
      })
      .fail((error) => {
        console.error('UX Error: ', error);
      });
  } catch (e) {
    console.log('UXLog error');
  }
};

export const setData = (logData) => {
  // to set common information
  let appServer = (localStorage.getItem('APP_SERVER') || '-') + ';';
  let webServer = (localStorage.getItem('WEB_SERVER') || '-') + ';';
  logData = {
    ...logData,
    empID: helper.isExternal() ? helper.extEmpId() : helper.getEmplId(),
    storeID: helper.isExternal()
      ? helper.extUser()?.storeId || ''
      : helper.getStorelId(),
    host: helper.isExternal()
      ? helper.extSource()
      : window.location.hostname.toLowerCase(),
    createdDate: new Date().toISOString().replace('Z', ''),
    webVersion: webServer + helper.getVersion(),
    appVersion: appServer + (localStorage.getItem('appVersion') || ''),
    userAgent:
      navigator.userAgent + helper.isExternal()
        ? '; Source: ' + helper.extSource() + ';'
        : '',
  };

  if (!logData.data) logData.data = '';

  return logData;
};

export const getPath = () => window.location.pathname || '';

// Customized Log Type --------------------------------------

// Log for logon
export const logAuthenticated = (isSSO) => {
  let data = {
    level: helper.isExternal() ? 21 : 1,
    screen: 'Logon',
    action: helper.isExternal()
      ? 'ExternalLogin '
      : 'Logon' + (isSSO ? ' (SSO)' : ''),
    data: helper.extSource(),
  };
  console.log('Version: ' + helper.getVersion());
  sendUxLog(data); // Send Instantly
};

// Log for logon
export const logExit = (last = false) => {
  let login = parseInt(localStorage.getItem('LoginTime') || 0);
  let curr = new Date().getTime();
  let usedTime = curr - login;
  let data = {
    level: 1,
    screen: 'Logout',
    action: 'Logout',
    data: (usedTime / 1000).toFixed(0),
  };
  if (!last) {
    sendUxLog(data); // Send Instantly
  } else {
    navigator.sendBeacon(
      helper.getAPIHost() + '/api/uxtracking',
      JSON.stringify(data),
    );
  }
};

// Log for navigation
export const logScreen = (screen, action, extraData) => {
  if (!screen) return;

  action = action || 'click';
  let data = {
    level: helper.isExternal() ? 21 : 1,
    screen: screen,
    action: action,
    data: typeof extraData === 'object' ? JSON.stringify(extraData) : extraData,
  };

  addUXLog(data);
};

// Log for action
export const logAction = (screen, action, extraData) => {
  if (!screen || !action) return;

  action = action || 'click';
  let data = {
    level: helper.isExternal() ? 22 : 2,
    screen: screen,
    action: action,
    data: typeof extraData === 'object' ? JSON.stringify(extraData) : extraData,
  };
  addUXLog(data);
};

// Log for info
export const logInfo = (screen, action, info) => {
  if (!screen || !info) return;

  let data = {
    level: helper.isExternal() ? 23 : 3,
    screen: screen,
    action: action,
    data: typeof info === 'object' ? JSON.stringify(info) : info,
  };

  addUXLog(data);
};

// Log for errors
export const logError = (
  screen,
  action,
  errorMsg,
  extraData,
  isExternal = false,
) => {
  if (!screen || !errorMsg) return;

  let data = {
    level: helper.isExternal() || isExternal ? 24 : 4,
    screen: screen,
    action: action,
    error: errorMsg,
    data: typeof extraData === 'object' ? JSON.stringify(extraData) : extraData,
  };

  sendUxLog(data); // Send instantly
};

// Log for performance
export const logPerformance = (screen, action, timeUsed) => {
  if (!screen) return;

  let data = {
    level: helper.isExternal() ? 299 : 99,
    screen: screen,
    action: action,
    data: timeUsed + ' ms',
  };

  addUXLog(data);
};

// Log for Merchandise Insights Image not found
export const logMIImageNotFound = (screen, type, url) => {
  if (!screen) return;

  let data = {
    level: 98,
    screen: screen,
    action: 'No Image',
    data: type + ': ' + url,
  };

  addUXLog(data);
};
