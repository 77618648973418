import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Modal,
} from '@mui/material';
import { LocalizationProvider, DesktopTimePicker } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';

//Custom Component
import { RedButton } from '../../components/CommonComponent';
import {
  add1HourToEndTime,
  convertToStoreTimeZone,
  getStoreHour,
  getTimeInAMPMFormat,
  isStoreOpened,
  resetToCurrentHourMark,
} from '.';

//Enums
import enums from '../../enums';

//Luxon
import { DateTime } from 'luxon';

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

export const HostOnDutyModal = ({
  addHostOnDutyFunc,
  takeOverHostOnDutyFunc,
  getHostOnDutyFunc,
  isOpen,
  toggleModal,
  hostData,
  nextHostData,
  toExtendTime,
  toTakeOver,
  toAddNextShift,
  forceModalOpen,
}) => {
  const [defaultStartTimeValue, setDefaultStartTimeValue] = useState(
    resetToCurrentHourMark(),
  );
  const [defaultEndTimeValue, setDefaultEndTimeValue] = useState(
    resetToCurrentHourMark(null, true),
  );
  const [minTimeStart, setMinTimeStart] = useState(resetToCurrentHourMark());
  const [minTimeEnd, setMinTimeEnd] = useState(
    resetToCurrentHourMark(null, true),
  );
  const [startTime, setStartTime] = useState(resetToCurrentHourMark());
  const [endTime, setEndTime] = useState(resetToCurrentHourMark());

  const [isOpenBlockedModal, setOpenBlockedModal] = useState(false);
  const previousForceModalOpen = usePrevious(forceModalOpen);
  const [dismissedCountdown, setDismissedCountdown] = useState(null);
  const [dismissedBtnDisabled, setDismissedBtnDisabled] = useState(null);
  const [dismissedTimerCreated, setDismissTimerCreated] = useState(false);

  const [openStart, setOpenStart] = useState(false);
  const [openEnd, setOpenEnd] = useState(false);

  const [isOpenConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  const warnLongHours =
    parseInt(
      JSON.parse(localStorage.getItem('Parameters'))?.HOD_WARN_EXTENDED_SHIFT ||
        0,
    ) || 99;

  //Current Host Acknowledgement Modal
  // const [openHostAcknowledgeModal, setOpenHostAcknowledgementModal] =
  //   useState(false);
  // const [currentHODAck, setCurrentHODAck] = useState(null);

  const checkAlertDialog = () => {
    const durationInHours = endTime.hour - startTime.hour;

    // Check if the duration is greater than 6 hours
    const isDurationGreaterThanWarnHours = durationInHours > warnLongHours;

    if (isDurationGreaterThanWarnHours) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (!isOpen) {
      //Reset on modal close
      setStartTime(resetToCurrentHourMark());
      setEndTime(resetToCurrentHourMark(null, true));
      setOpenStart(false);
      setOpenEnd(false);
    } else {
      //When modal opened
      const { openHour, closeHour } = getStoreHour();

      if (toExtendTime || toTakeOver || toAddNextShift) {
        //Controlled reset
        if (toExtendTime || toTakeOver) {
          if (toTakeOver) {
            if (
              !isStoreOpened() &&
              convertToStoreTimeZone(DateTime.now()).toMillis() <
                openHour.toMillis()
            ) {
              //If outside of store hour and current time have NOT pass the store open hour, use open time
              setStartTime(openHour);
            } else {
              setStartTime(convertToStoreTimeZone(DateTime.now()));
            }
          } else {
            setStartTime(hostData.startTime);
          }
          setEndTime(hostData.endTime);

          //Get min time
          getMinTimeAllowed(hostData.startTime, hostData.endTime);
        } else if (toAddNextShift) {
          setStartTime(hostData.endTime);
          setEndTime(add1HourToEndTime(hostData.endTime));

          //Get min time
          if (
            !isStoreOpened() &&
            convertToStoreTimeZone(DateTime.now()).toMillis() <
              openHour.toMillis()
          ) {
            //If outside of store hour and current time have NOT pass the store open hour, use open time
            getMinTimeAllowed(openHour, closeHour);
          } else {
            getMinTimeAllowed(hostData.endTime, hostData.endTime);
          }
        }
      } else if (!isStoreOpened() && localStorage.getItem('storeOpenHour')) {
        //For when modal opened outside of store operation time
        setStartTime(openHour);
        setEndTime(add1HourToEndTime(openHour), true);

        //Get min time
        getMinTimeAllowed(openHour, openHour);
      } else {
        //Default reset
        setDefaultStartTimeValue(resetToCurrentHourMark());
        setDefaultEndTimeValue(resetToCurrentHourMark(null, true));
        setStartTime(resetToCurrentHourMark());
        setEndTime(resetToCurrentHourMark(null, true));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    //If modal was no longer forced, and the first modal is closed, and second modal is open, close the second modal
    if (
      previousForceModalOpen &&
      forceModalOpen !== previousForceModalOpen &&
      !isOpen &&
      isOpenBlockedModal
    ) {
      setOpenBlockedModal(false);
      setDismissedCountdown(null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toExtendTime, toTakeOver, toAddNextShift, forceModalOpen]);

  useEffect(() => {
    // eslint-disable-next-line no-unused-vars
    const timerForCheckingHostAnother5min = () => {
      if (dismissedTimerCreated) {
        //If already setup the timer, return
        return;
      }

      //Timeout timer
      const timeoutInMS = 300000; //5 minutes

      const timer = setTimeout(() => {
        setDismissTimerCreated(false);
        getHostOnDutyFunc();
      }, timeoutInMS);

      // Clear the timer if the component unmounts before the time difference is reached
      return () => {
        setDismissTimerCreated(false);
        clearTimeout(timer);
      };
    };

    const timerForDisablingModalDismissButton = () => {
      if (dismissedTimerCreated) {
        //If already setup the timer, return
        return;
      }
      //Timeout timer
      const timeoutInMS = 300000; //5 minutes

      const timer = setTimeout(() => {
        setDismissTimerCreated(false);
        setDismissedBtnDisabled(true);
      }, timeoutInMS);

      // Clear the timer if the component unmounts before the time difference is reached
      return () => {
        setDismissTimerCreated(false);
        setDismissedBtnDisabled(false);
        clearTimeout(timer);
      };
    };

    if (dismissedCountdown) {
      //If dismissed once, set a timer to popup again if there is no host
      // timerForCheckingHostAnother5min();
      timerForDisablingModalDismissButton();
      setDismissTimerCreated(true);
    } else {
      setDismissedBtnDisabled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dismissedCountdown, getHostOnDutyFunc]);

  useEffect(() => {
    setOpenStart(false);
    setOpenEnd(false);
  }, [isOpenConfirmationDialog]);

  const getMinTimeAllowed = (startTime, endTime) => {
    setMinTimeStart(resetToCurrentHourMark(startTime));
    setMinTimeEnd(resetToCurrentHourMark(endTime, true));
  };

  useEffect(() => {
    //Set min time based on selected start time
    setMinTimeEnd(resetToCurrentHourMark(startTime, true));
  }, [startTime]);

  const handleDismissModal = (e) => {
    //Close first modal
    toggleModal(!isOpen);

    if (isOpenBlockedModal) {
      //Do not close blocked modal if closing the schedule modal when they should be still blocked
      return;
    }

    if (forceModalOpen && isStoreOpened()) {
      //Open second modal if the first modal is forced and store is open
      setOpenBlockedModal(!isOpenBlockedModal);
    }
    return;
  };

  const handleSubmitHost = (e) => {
    let { openHour, closeHour } = getStoreHour();

    //Validation
    if (toAddNextShift && startTime.toMillis() < hostData.endTime.toMillis()) {
      window.message.notification(
        `Start time must be later than ${getTimeInAMPMFormat(
          hostData.endTime,
        )} from previous host`,
        enums.notificationType.E,
      );
      return;
    }

    if (endTime.toMillis() <= startTime.toMillis()) {
      window.message.notification(
        'End time must be later than start time',
        enums.notificationType.E,
      );
      return;
    }

    if (!hostData && startTime) {
      let allowStartTime = resetToCurrentHourMark(startTime);
      let allowEndTime = resetToCurrentHourMark(endTime);

      //Check if start & end time selected is in store open/close hours
      if (
        allowStartTime.toMillis() < openHour.toMillis() ||
        allowStartTime.toMillis() > closeHour.toMillis() ||
        allowEndTime.toMillis() < openHour.toMillis() ||
        allowEndTime.toMillis() > closeHour.toMillis()
      ) {
        window.message.notification(
          `Please select valid store hour from ${getTimeInAMPMFormat(
            openHour,
          )} to ${getTimeInAMPMFormat(closeHour)}`,
          enums.notificationType.E,
        );
        return;
      }
    }

    console.log('Validation correct');

    //API call and form reset
    if (toTakeOver) {
      takeOverHostOnDutyFunc(
        hostData.employeeID,
        startTime.toUTC(),
        endTime.toUTC(),
        localStorage.getItem('EmplId'),
      );
    } else {
      addHostOnDutyFunc(
        localStorage.getItem('EmplId'),
        startTime.toUTC(),
        endTime.toUTC(),
      );
    }
  };

  return (
    <>
      <Modal
        open={isOpen}
        aria-labelledby="host-duty-modal"
        className="host-duty-modal"
        disablePortal
      >
        <Box>
          <div className="modal-header">
            <h5>Host On Duty</h5>
          </div>

          <div className="modal-body">
            <div
              className="modal-body-form"
              style={{
                borderRight: `${
                  nextHostData?.length > 0 ? '2px solid lightgrey' : 'none'
                }`,
              }}
            >
              {/* Instruction label */}
              {toExtendTime && (
                <span>
                  Extend your time as Host on Duty to end at{' '}
                  {getTimeInAMPMFormat(hostData.endTime)}?
                </span>
              )}

              {toTakeOver && (
                <span>
                  Take over host shift from {hostData.employeeName}? Their shift
                  is scheduled to end at {getTimeInAMPMFormat(hostData.endTime)}
                  , please review the shift timing.
                </span>
              )}

              {forceModalOpen && !toTakeOver && (
                <span>
                  There is currently no Host on Duty. BobBoost manager access is
                  restricted until a Host on Duty is assigned. Are you the Host
                  on Duty?
                </span>
              )}

              {!toExtendTime && !toTakeOver && !forceModalOpen && (
                <span>Are you the next host?</span>
              )}

              <div className="time-picker">
                <div>
                  {/* Start Time */}
                  <div>Start at</div>
                  <LocalizationProvider dateAdapter={AdapterLuxon}>
                    <DesktopTimePicker
                      open={openStart}
                      className="host-time-picker"
                      defaultValue={defaultStartTimeValue}
                      minTime={minTimeStart}
                      maxTime={resetToCurrentHourMark(getStoreHour().closeHour)}
                      views={['hours']}
                      onChange={(val) => setStartTime(val)}
                      value={startTime}
                      disabled={toExtendTime || toTakeOver ? true : false}
                      slotProps={{
                        textField: {
                          onKeyDown: (e) => e.preventDefault(),
                          onClick: () => {
                            setOpenEnd(false);
                            setOpenStart(!openStart);
                          },
                        },
                        actionBar: {
                          onClick: () => setOpenStart(!openStart),
                        },
                      }}
                    />
                  </LocalizationProvider>
                </div>

                {/* End Time */}
                <div>
                  <div>End at</div>
                  <LocalizationProvider dateAdapter={AdapterLuxon}>
                    <DesktopTimePicker
                      open={openEnd}
                      defaultValue={defaultEndTimeValue}
                      minTime={minTimeEnd}
                      maxTime={resetToCurrentHourMark(getStoreHour().closeHour)}
                      views={['hours']}
                      value={endTime}
                      onChange={(val) => setEndTime(val)}
                      slotProps={{
                        textField: {
                          onKeyDown: (e) => e.preventDefault(),
                          onClick: () => {
                            setOpenStart(false);
                            setOpenEnd(!openEnd);
                          },
                        },
                        actionBar: {
                          onClick: () => setOpenEnd(!openEnd),
                        },
                      }}
                    />
                  </LocalizationProvider>
                </div>
              </div>

              {/* Confirm/Cancel button */}
              <div className="footer-button">
                <RedButton
                  label="Confirm"
                  onClick={(e) => {
                    if (checkAlertDialog()) {
                      setOpenConfirmationDialog(!isOpenConfirmationDialog);
                    } else {
                      handleSubmitHost(e);
                    }
                  }}
                />
                <RedButton label="No" onClick={(e) => handleDismissModal(e)} />
              </div>
            </div>

            {/* Next host schedule information */}
            {nextHostData?.length > 0 && (
              <div className="modal-body-next-host">
                <span>Next host:</span>
                <br />

                {nextHostData.map((next, index) => {
                  return (
                    <>
                      <div key={next.employeeID}>
                        <span>
                          {index + 1}. {next.employeeName} from{' '}
                          {getTimeInAMPMFormat(next.startTime)} to{' '}
                          {getTimeInAMPMFormat(next.endTime)}
                        </span>
                      </div>
                      <br />
                    </>
                  );
                })}
              </div>
            )}
          </div>
        </Box>
      </Modal>

      {/* Confirmation Dialog Before Submitting HOD */}
      <Dialog
        open={isOpenConfirmationDialog}
        onClose={() => setOpenConfirmationDialog(!isOpenConfirmationDialog)}
        aria-labelledby="alert-dialog-confirmation"
        aria-describedby="alert-dialog-confirmation-description"
      >
        <DialogTitle id="alert-dialog-confirmation">
          {`Shift longer than ${warnLongHours} hours`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-confirmation-description">
            You are scheduling Host On Duty from{' '}
            <b>{getTimeInAMPMFormat(startTime)}</b> to{' '}
            <b>{getTimeInAMPMFormat(endTime)}</b>. Please confirm if you would
            like to proceed with this schedule.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <RedButton
            label="Confirm"
            onClick={(e) => {
              setOpenConfirmationDialog(false);
              handleSubmitHost(e);
            }}
          />
          <RedButton
            label="Cancel"
            onClick={(e) => {
              setOpenConfirmationDialog(false);
            }}
          />
        </DialogActions>
      </Dialog>

      <Modal
        open={isOpenBlockedModal}
        aria-labelledby="host-duty-modal"
        className="host-duty-modal-forced"
        disablePortal
      >
        <Box>
          <div className="modal-header">
            <h5>Host On Duty</h5>
          </div>

          <div className="modal-body">
            <span>
              Host on Duty is a required responsibility by a member of
              management during store open hours.
            </span>

            <div className="footer-button">
              {dismissedBtnDisabled && isStoreOpened() && (
                <RedButton
                  label="Add my host shift"
                  onClick={(e) => {
                    toggleModal(!isOpen);
                  }}
                />
              )}

              <RedButton
                // disabled={dismissedBtnDisabled}
                label="Dismiss"
                onClick={(e) => {
                  setOpenBlockedModal(!isOpenBlockedModal);
                  setDismissedCountdown(DateTime.now());
                }}
              />
            </div>
          </div>
        </Box>
      </Modal>

      {/* BCP-2780 : HOD: Remove Hourly Acknowledgment pop-up */}
      {/* <Modal
        open={openHostAcknowledgeModal}
        aria-labelledby="host-duty-modal"
        className="host-duty-acknowledge"
        disablePortal
      >
        <Box>
          <div className="modal-header">
            <h5>Host On Duty</h5>
          </div>

          <div className="modal-body">
            <span>
              Your host on duty shift has started and will end at{' '}
              {getTimeInAMPMFormat(hostData?.endTime)}
            </span>

            <div className="footer-button">
              <RedButton
                label="Acknowledge"
                onClick={() => {
                  setOpenHostAcknowledgementModal(!openHostAcknowledgeModal);
                  setCurrentHODAck(localStorage.getItem('EmplId'));
                }}
              />
            </div>
          </div>
        </Box>
      </Modal> */}
    </>
  );
};
