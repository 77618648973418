import React, { useEffect, useState, useContext, useCallback } from 'react';
import { Dropdown } from '../../components/CommonComponent';
import { Box, Grid } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
//Custom Component
import SpinnerLoading from '../../components/SpinnerLoading';
import { MdEdit } from 'react-icons/md';
import { OpportunityContext } from './opportunity-context';
import UserQuestionView from '../setups/DispositionQuestion/user-view-disposition-question';

const helper = require('../../utils/helper');
const enums = require('../../enums');
const ux = require('../../application-center/ux-tracking-center');

export default function OpportunityDashboard({ storeId, isManager }) {
  const [isLoading, setIsLoading] = useState(true);
  const [rowData, setRowData] = useState([]);
  const [totalData, setTotalData] = useState();
  const [associateData, setAssociateData] = useState([]);
  const [isQuestionModalOpen, setIsQuestionModalOpen] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [opportunityTicket, setOpportunityTicket] = useState('');
  const [filterStatus, setFilterStatus] = useState('incomplete');
  const userGroup = helper.getUserGroup();

  const currentUserInformation =
    useContext(OpportunityContext).currentUserInformation;

  const options = [
    { value: 'incomplete', label: 'Incomplete' },
    { value: 'complete', label: 'Complete' },
  ];

  const managerColumnData = [
    {
      field: 'empName',
      headerName: 'GX Specialist',
      headerAlign: 'left',
      flex: 1,
    },
    {
      field: 'totalGuest',
      headerName: 'Total Guests',
      headerAlign: 'center',
      type: 'number',
      flex: 1,
      align: 'center',
    },
    {
      field: 'totalOpportunities',
      headerName: 'Total Sales Opportunities',
      headerAlign: 'center',
      type: 'number',
      flex: 1,
      align: 'center',
    },
    {
      field: 'totalSales',
      headerName: 'Total Sales',
      headerAlign: 'center',
      type: 'number',
      flex: 1,
      align: 'center',
    },
    {
      field: 'closeRate',
      headerName: 'Close Rate',
      headerAlign: 'center',
      type: 'number',
      flex: 1,
      align: 'center',
      valueFormatter: (params) =>
        helper.formatNumber(params.value, 1, null, '%'),
    },
    {
      field: 'writtenSale',
      headerName: 'Written Sales',
      headerAlign: 'center',
      type: 'number',
      flex: 1,
      align: 'center',
      valueFormatter: (params) =>
        helper.formatNumber(params.value || 0, 2, '$'),
    },
  ];

  const associateColumnData = [
    {
      field: 'createdDateTime',
      headerName: 'Time',
      headerAlign: 'center',
      flex: 1,
      align: 'center',
      valueFormatter: (params) => {
        const date = new Date(params.value + 'Z');
        return isNaN(date.getTime())
          ? 'Invalid Date'
          : date.toLocaleTimeString('en-US', {
              hour: '2-digit',
              minute: '2-digit',
              hour12: true,
            });
      },
    },
    {
      field: 'opportunityTitle',
      headerName: 'Title',
      headerAlign: 'center',
      flex: 1,
      align: 'center',
    },
    {
      field: 'opportunityType',
      headerName: 'Opportunity Type',
      headerAlign: 'center',
      flex: 1,
      align: 'center',
    },
    {
      field: 'opportunityResult',
      headerName: 'Opportunity Result',
      headerAlign: 'center',
      flex: 1,
      align: 'center',
    },
    {
      field: 'isComplete',
      headerName: 'Disposition Questions',
      headerAlign: 'center',
      flex: 1,
      align: 'center',
      valueFormatter: (params) =>
        params.value === 'Complete' ? 'Complete' : 'Incomplete',
    },
    {
      field: 'edit',
      headerName: 'Edit',
      headerAlign: 'center',
      flex: 1,
      align: 'center',
      renderCell: (params) => (
        <MdEdit
          onClick={() => handleEditClick(params.row.opportunityTicket)}
          style={{ cursor: 'pointer' }}
        />
      ),
    },
  ];

  const getManagerOpportunityDashboard = async (userData) => {
    let url =
      helper.getSignalRHost() +
      `/api/OpportunityList/getOpportunityDashboard?timezoneOffset=${new Date().getTimezoneOffset()}`;
    await fetch(url, { method: 'GET', ...helper.apiHeaders() })
      .then(async (response) => {
        let data = await response.json();

        if (data.status === 'OK') {
          setRowData(data.result);
        }
      })
      .catch((error) => {
        window.message.notification(
          'Unable to get Opportunity Dashboard',
          enums.notificationType.E,
        );
        ux.logError(
          'GuestConnect',
          'getOpportunityDashboard',
          'Unable to get data from API',
          JSON.stringify(error),
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const fetchQuestionsForOpportunity = async (opportunityTicket) => {
    try {
      let url =
        helper.getAPIHost() +
        `/api/OpportunityList/GetOpportunitySurvey?opportunityTicket=${opportunityTicket}`;
      let response = await fetch(url, {
        method: 'GET',
        ...helper.apiHeaders(),
      });
      const data = await response.json();
      if (data.status === 'OK' && data.result) {
        setQuestions(data.result);
      } else {
        console.error('Error fetching questions:', data.errorMsg);
      }
    } catch (error) {
      console.error('Error fetching questions:', error);
    }
  };

  const handleEditClick = async (ticket) => {
    setOpportunityTicket(ticket);
    await fetchQuestionsForOpportunity(ticket);
    setIsQuestionModalOpen(true);
  };

  const fetchAssociateData = useCallback(async () => {
    try {
      const [employeeResponse, todayTicketsResponse] = await Promise.all([
        fetch(
          helper.getAPIHost() +
            `/api/OpportunityList/GetEmployeeOpportunityDashboard?timezoneOffset=${new Date().getTimezoneOffset()}&employeeId=${
              currentUserInformation.empId
            }`,
          { method: 'GET', ...helper.apiHeaders() },
        ),
        fetch(
          helper.getAPIHost() +
            `/api/OpportunityList/GetTodayOpportunityTickets?storeId=${storeId}`,
          { method: 'GET', ...helper.apiHeaders() },
        ),
      ]);

      const employeeData = await employeeResponse.json();
      const todayTicketsData = await todayTicketsResponse.json();

      if (employeeData.status === 'OK' && todayTicketsData.status === 'OK') {
        setAssociateData([...employeeData.result, ...todayTicketsData.result]);
      } else {
        console.error(
          'Error fetching associate data:',
          employeeData.errorMsg,
          todayTicketsData.errorMsg,
        );
      }
    } catch (error) {
      console.error('Fetch error:', error);
      ux.logError(
        'Disposition Question Admin Console',
        'Fetch Data from API',
        'Unable to get data from API',
      );
    } finally {
      setIsLoading(false);
    }
  }, [currentUserInformation.empId, storeId]);

  useEffect(() => {
    if (isManager) {
      getManagerOpportunityDashboard();
    } else {
      fetchAssociateData();
    }
  }, [isManager, fetchAssociateData]);

  const refreshData = () => {
    if (isManager) {
      getManagerOpportunityDashboard();
    } else {
      fetchAssociateData();
    }
  };

  useEffect(() => {
    if ((isManager && rowData.length > 0) || associateData.length > 0) {
      const sourceData = isManager ? rowData : associateData;

      const totals = sourceData.reduce((acc, obj) => {
        Object.keys(obj).forEach((key) => {
          if (key !== 'empId' && key !== 'empName') {
            acc[key] = (acc[key] || 0) + obj[key];
          }
        });
        return acc;
      }, {});

      // Calculate closeRate based on totalSales and totalOpportunities
      totals.closeRate =
        totals.totalOpportunities > 0
          ? (totals.totalSales / totals.totalOpportunities) * 100
          : 0;

      setTotalData(totals);
    }
  }, [rowData, associateData, isManager]);

  if (isLoading) {
    return <SpinnerLoading />;
  }

  const filteredData = (isManager ? rowData : associateData).filter((row) => {
    if (filterStatus === 'incomplete') {
      return row.isComplete !== 'Complete';
    }
    return row.isComplete === 'Complete';
  });

  const renderDashboardSummary = () => {
    return (
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs>
          <Box className="matrix-box">
            <div>Total Guest</div>
            <div>
              {isManager
                ? totalData?.totalGuest || 0
                : associateData[0]?.totalGuest || 0}
            </div>
          </Box>
        </Grid>
        <Grid item xs>
          <Box className="matrix-box">
            <div>Total Sales Opportunities</div>
            <div>
              {isManager
                ? totalData?.totalOpportunities || 0
                : associateData[0]?.totalOpportunities || 0}
            </div>
          </Box>
        </Grid>
        <Grid item xs>
          <Box className="matrix-box">
            <div>Total Sales</div>
            <div>
              {isManager
                ? totalData?.totalSales || 0
                : associateData[0]?.totalSales || 0}
            </div>
          </Box>
        </Grid>
        <Grid item xs>
          <Box className="matrix-box">
            <div>Close Rate</div>
            <div>
              {helper.formatNumber(
                isManager
                  ? totalData?.closeRate || 0
                  : associateData[0]?.closeRate || 0,
                1,
                null,
                '%',
              )}
            </div>
          </Box>
        </Grid>
        <Grid item xs>
          <Box className="matrix-box">
            <div>Written Sales</div>
            <div>
              {helper.formatNumber(
                isManager
                  ? totalData?.writtenSale || 0
                  : associateData[0]?.writtenSale || 0,
                2,
                '$',
              )}
            </div>
          </Box>
        </Grid>
      </Grid>
    );
  };

  const renderDataGrid = () => {
    const columns = isManager ? managerColumnData : associateColumnData;
    const rows = isManager
      ? rowData
      : filteredData.filter(
          (row) =>
            row.createdDateTime &&
            !isNaN(new Date(row.createdDateTime).getTime()),
        );

    return (
      <div className="dashboard-data-grid">
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            sorting: {
              sortModel: [
                {
                  field: isManager ? 'totalGuest' : 'createdDateTime',
                  sort: 'desc',
                },
              ],
            },
          }}
          pageSizeOptions={[]}
          disableColumnMenu={true}
          checkboxSelection={false} // Set to false to remove checkbox
          disableRowSelectionOnClick={true} // Set to true to disable row selection on click
          getRowId={(row) =>
            isManager
              ? row.empId || row.empName
              : row.createdDateTime ||
                `${row.opportunityTitle}-${row.opportunityResult}`
          }
        />
      </div>
    );
  };

  console.log('showModal:', isQuestionModalOpen);
  console.log('toggleModal:', () => setIsQuestionModalOpen(false));
  console.log('questions:', questions);
  console.log('opportunityTicket:', opportunityTicket);

  return (
    <div className="opportunity-dashboard">
      {renderDashboardSummary()}
      {userGroup !== enums.userGroups.StoreManager &&
        userGroup !== enums.userGroups.SeniorGxs && (
          <div className="opportunity-question-filter">
            <label>Status:</label>
            <Dropdown
              id="filter-status"
              placeholder="Please select one"
              value={options.find((option) => option.value === filterStatus)}
              onChange={(selectedOption) =>
                setFilterStatus(selectedOption.value)
              }
              options={options}
              isSearchable={false}
              isClearable={false}
            />
          </div>
        )}
      {renderDataGrid()}
      {/* Disposition Question Modal */}
      <UserQuestionView
        showModal={isQuestionModalOpen}
        toggleModal={() => setIsQuestionModalOpen(false)}
        questions={questions}
        opportunityTicket={opportunityTicket}
        refreshData={refreshData}
      />
    </div>
  );
}
