export const Home = require('./Home.png');
export const Close = require('./Close.png');
export const CoachingLaunchpad = require('./CoachingLaunchpad.png');
export const Clock = require('./Clock.png');
export const Customer360 = require('./Customer360.png');
export const DeliverSales = require('./DeliverSales.png');
export const Feedback = require('./Feedback.png');
export const MerchandiseInsights = require('./MerchandiseInsights.png');
export const RepeatSales = require('./RepeatSales.png');
export const Touch = require('./Touch.png');
export const WriteSales = require('./WriteSales.png');
export const Pipeline = require('./Pipelines.png');
export const Products = require('./products.png');
export const Money = require('./money.png');
export const bobassist = require('./bobassist.png');
export const HelpCenter = require('./HelpCenter.png');
export const BobsNoti = require('./BobsNotification.png');
export const BobsBucket = require('./BobsBucket.png');
//deliver value icon notification
export const Sofa = require('./sofa.png');
export const Hand = require('./hand.png');
export const House = require('./house.png');
export const Table = require('./table.png');
export const Helpicon = require('./help.png');
export const ArchivedIcon = require('./Archive-icon.png');
