import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  IconButton,
  TextareaAutosize,
} from '@mui/material';

//Icons
import { MdExpandMore, MdOutlineMarkEmailUnread } from 'react-icons/md';
import { PiNotepadLight } from 'react-icons/pi';

//Custom Component
import SpinnerLoading from '../../components/SpinnerLoading';
import { RedButton } from '../../components/CommonComponent';

//Context
import { OpportunityContext } from './opportunity-context';

//Helpers
const helper = require('../../utils/helper');

export default function OpportunityNotepad({ engagedData }) {
  const [isLoading, setIsLoading] = useState(true);
  const [myOpportunity, setMyOpportunity] = useState([]);
  const [otherOpportunity, setOtherOpportunity] = useState([]);
  const [selectedOpportunity, setSelectedOpportunity] = useState(null); //Store UID
  const [input, setInput] = useState('');

  //Notepad Read Tracker
  const storedData = localStorage.getItem('readNotepadTrackingData');
  const notepadCountTrackingData = useRef(
    new Map(storedData ? JSON.parse(storedData) : []),
  );

  let { currentUserInformation, notepadInfo, updateNotepadInfo } =
    useContext(OpportunityContext);

  const handleUpdateNotepad = () => {
    //Find current data to process
    let currentStackData = null;
    engagedData.forEach((item) => {
      let index = item.stackData.findIndex(
        (i) => i.uid === selectedOpportunity,
      );
      if (index !== -1) {
        currentStackData = item.stackData[index];
      }
    });

    //Initialize new notepad
    if (Object.keys(notepadInfo).length <= 0) {
      notepadInfo = {
        version: 0,
        updatedTime: new Date(),
        opportunity: [],
        storeId: currentStackData.opportunityStoreID,
      };
    }

    //Create new opportunity if not exist
    if (
      notepadInfo.opportunity &&
      !notepadInfo.opportunity.some((x) => x.uid === selectedOpportunity)
    ) {
      let newOpportunityNote = {
        uid: selectedOpportunity,
        employeeName: currentStackData.employeeName,
        employeeId: currentStackData.employeeID,
        userAction: currentStackData.userAction || 'default',
        title: currentStackData.opportunityTitle,
        notes: [],
      };

      notepadInfo.opportunity.push(newOpportunityNote);
    }

    //Create new notes
    let newOpportunityNote = {
      updatedTime: new Date(),
      updatedBy: currentUserInformation.empId,
      updatedByName: currentUserInformation.empName,
      updatedByJobTitle: currentUserInformation.empJobTitle,
      note: input,
    };

    //Push new notes
    notepadInfo.opportunity
      .find((x) => x.uid === selectedOpportunity)
      .notes.push(newOpportunityNote);

    //Always update based on engagement for title/useraction
    notepadInfo.opportunity.find((x) => x.uid === selectedOpportunity).title =
      currentStackData.opportunityTitle;
    notepadInfo.opportunity.find(
      (x) => x.uid === selectedOpportunity,
    ).userAction = currentStackData.userAction;

    //Increase main version
    notepadInfo.version++;
    notepadInfo.updatedTime = new Date();

    updateNotepadInfo(notepadInfo);

    //To send broadcast API
    refreshNotepad(notepadInfo);

    setInput('');
    updateNoteCountData(
      selectedOpportunity,
      notepadInfo.opportunity.find((x) => x.uid === selectedOpportunity).notes
        .length,
    );

    // console.log('Update complete', notepadInfo);
  };

  const updateNoteCountData = (uid, notesCount) => {
    if (uid !== null && uid !== undefined) {
      notepadCountTrackingData.current.set(uid, notesCount);

      localStorage.setItem(
        'readNotepadTrackingData',
        JSON.stringify(Array.from(notepadCountTrackingData.current.entries())),
      );
    }
  };

  useEffect(() => {
    setMyOpportunity(
      engagedData.filter((x) => x.employeeID === currentUserInformation.empId),
    );

    setOtherOpportunity(
      engagedData.filter((x) => x.employeeID !== currentUserInformation.empId),
    );

    //Reset selected opportunity notepad when its no longer available
    if (
      engagedData.length > 0 &&
      engagedData.every((x) =>
        x.stackData.every((x) => x.uid !== selectedOpportunity),
      )
    ) {
      setSelectedOpportunity(null);
    }

    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [engagedData]);

  useEffect(() => {
    setInput('');

    let currentNoteData = notepadInfo?.opportunity?.find(
      (x) => x.uid === selectedOpportunity,
    );

    // Mark current opportunity as read
    if (currentNoteData) {
      //Notepad initialized and have notes, get the correct notes count
      updateNoteCountData(currentNoteData.uid, currentNoteData.notes.length);
    } else {
      updateNoteCountData(selectedOpportunity, 0);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOpportunity]);

  return isLoading ? (
    <SpinnerLoading />
  ) : Object.keys(notepadInfo).length === 0 ? (
    <div className="initialize-notepad">
      <IconButton
        aria-label="initialize"
        disableRipple
        onClick={() => startNotepad()}
      >
        <PiNotepadLight size={100} />
        <span>START</span>
      </IconButton>
    </div>
  ) : (
    <div className="opportunity-notepad">
      <Grid container spacing={2} direction="row" justifyContent="center">
        <Grid item xs={5} className="opportunity-display">
          {/* Own View */}
          <div className="title">My opportunities</div>
          <RenderAccordion
            data={myOpportunity}
            isPersonalView={true}
            selectedOpportunity={selectedOpportunity}
            setSelectedOpportunity={setSelectedOpportunity}
          />

          {/* Manager View */}
          {currentUserInformation.isManager && (
            <>
              <hr />
              <div className="title">Other opportunities</div>
              <RenderAccordion
                data={otherOpportunity}
                isPersonalView={false}
                selectedOpportunity={selectedOpportunity}
                setSelectedOpportunity={setSelectedOpportunity}
              />
            </>
          )}
        </Grid>
        <Grid item xs={7}>
          <Box>
            <div
              className="notepad-area"
              key={`notepad-area-${selectedOpportunity}`}
            >
              <div className="header">Notes</div>
              <div className="body">
                {notepadInfo.opportunity &&
                  notepadInfo.opportunity
                    .filter((x) => x.uid === selectedOpportunity)
                    .flatMap(
                      (opportunity) =>
                        opportunity.notes?.map((note, index) => (
                          <div key={index} className={`notes-bubble`}>
                            <div
                              className={`notes ${
                                note.updatedBy === currentUserInformation.empId
                                  ? 'own'
                                  : 'others'
                              }`}
                            >
                              {note.note}
                            </div>
                            <div
                              className={`timestamp ${
                                note.updatedBy === currentUserInformation.empId
                                  ? 'own'
                                  : 'others'
                              }`}
                            >
                              {note.updatedByName},{' '}
                              {new Date(note.updatedTime).toLocaleDateString(
                                'en-US',
                                {
                                  hour: 'numeric',
                                  minute: 'numeric',
                                  hour12: true,
                                },
                              )}
                            </div>
                          </div>
                        )) || [],
                    )}
              </div>

              <TextareaAutosize
                minRows={2}
                onInput={(e) => setInput(e.currentTarget.value)}
                disabled={!selectedOpportunity}
                value={input}
              />
              <RedButton
                label={'Save'}
                outline={true}
                disabled={!input}
                onClick={() => {
                  let currentNoteData = notepadInfo?.opportunity?.filter(
                    (x) => x.uid === selectedOpportunity,
                  )[0];
                  if (
                    (currentNoteData && currentNoteData.note !== input) ||
                    !currentNoteData
                  ) {
                    //Update note if first time or input changed only
                    handleUpdateNotepad();
                  }
                }}
              />
            </div>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

const RenderAccordion = ({
  data,
  isPersonalView,
  selectedOpportunity,
  setSelectedOpportunity,
}) => {
  const [expanded, setExpanded] = useState('');
  let { currentUserInformation, notepadInfo } = useContext(OpportunityContext);

  const handleAccordionChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const checkUnreadNotes = (allCurrentUid) => {
    let currentReadNotes = new Map(
      JSON.parse(localStorage.getItem('readNotepadTrackingData')) ?? [],
    );

    // Check if any of the UIDs in the array are unread
    const hasUnreadNotes = allCurrentUid.some(
      (uid) => !currentReadNotes.has(uid) && currentReadNotes.get(uid) > 0,
    );

    if (hasUnreadNotes) {
      // If there are unread notes, return true
      return true;
    } else {
      // If all UIDs are in currentReadNotes, further check if counts are the same if needed
      const countsMatch = allCurrentUid.every((uid) => {
        const readCount = currentReadNotes.get(uid) || 0;
        const expectedCount =
          notepadInfo.opportunity?.find((item) => item.uid === uid)?.notes
            ?.length || 0;

        return readCount === expectedCount;
      });

      return countsMatch ? false : true;
    }
  };

  useEffect(() => {
    if (data.length > 0 && data.every((x) => x.uid !== expanded)) {
      if (isPersonalView) {
        let currentEmpData = data.find(
          (x) => x.employeeID === currentUserInformation.empId,
        );
        setExpanded(currentEmpData?.uid); //Open own opportunity

        if (!selectedOpportunity)
          setSelectedOpportunity(currentEmpData?.stackData[0]?.uid); //Select the first opportunity
      } else if (!isPersonalView) {
        setExpanded(data[0].uid); //Open the first of other opportunity
      } else {
        setExpanded('');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserInformation.empId, data, isPersonalView]);

  return (
    data.length > 0 &&
    data.map((stack, index) => {
      //For each user looping.
      return (
        <Accordion
          key={stack.uid}
          onChange={handleAccordionChange(stack.uid)}
          expanded={expanded === stack.uid}
        >
          <AccordionSummary
            expandIcon={<MdExpandMore size={40} />}
            aria-controls={`panel-${stack.uid}-content`}
            id={stack.uid}
          >
            {checkUnreadNotes(stack.stackData.map((x) => x.uid)) && (
              <MdOutlineMarkEmailUnread
                size={30}
                className="unread-notepad-icon"
              />
            )}

            {stack.employeeName}
          </AccordionSummary>
          <AccordionDetails>
            {stack.stackData.map((x) => {
              return (
                <Box key={x.uid} onClick={(e) => setSelectedOpportunity(x.uid)}>
                  <div
                    className={`record-box ${
                      selectedOpportunity === x.uid ? ' selected' : ''
                    }`}
                  >
                    <div>{x.opportunityTitle}</div>
                    <div>{x.userAction}</div>
                  </div>
                </Box>
              );
            })}
          </AccordionDetails>
        </Accordion>
      );
    })
  );
};

export const refreshNotepad = async (notepadData) => {
  try {
    await fetch(
      helper.getSignalRHost() + '/api/OpportunityList/RefreshNotepad',
      {
        method: 'POST',
        body: JSON.stringify(notepadData),
        ...helper.apiHeaders(),
      },
    );
  } catch (e) {
    console.log('Sending message failed.', e);
  }
};

export const startNotepad = async () => {
  try {
    await fetch(helper.getSignalRHost() + '/api/OpportunityList/StartNotepad', {
      method: 'POST',
      ...helper.apiHeaders(),
    });
  } catch (e) {
    console.log('Sending message failed.', e);
  }
};
