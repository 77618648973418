const helper = require('../../utils/helper');
const ux = require('../../application-center/ux-tracking-center');

export const GetCartlist = async (guestEmail) => {
  console.log('real email', guestEmail);
  // guestEmail = 'Damodhar.Dyava%40mybobs.com';
  try {
    let res = null;
    const url =
      helper.getAPIHost() +
      `/api/OnlineCartsWishlist/getCartlist?customerNo=` +
      guestEmail;
    console.log('url', url);

    ux.logAction('OnlineCartsWishlist', 'getCartlist', url);

    const response = await fetch(url, {
      method: 'GET',
      ...helper.apiHeaders(),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    res = await response.json();

    // console.log('response geArticleSearch', res?.status, res?.result);

    if (res?.status === 'OK') {
      return res?.result;
    } else {
      throw new Error(res.error);
    }
  } catch (error) {
    console.error(error);
    ux.logError(
      'OnlineCartsWishlist',
      'Get Data from API',
      `Init Data API: OnlineCartsWishlist 1 - ${error}`,
    );
  }
};

export const GetAllWishlist = async (guestEmail) => {
  console.log('real email', guestEmail);
  // guestEmail = 'Damodhar.Dyava%40mybobs.com';
  try {
    let res = null;
    const url =
      helper.getAPIHost() +
      `/api/OnlineCartsWishlist/getAllWishlist?customerNo=` +
      guestEmail;
    console.log('url', url);

    ux.logAction('OnlineCartsWishlist', 'getAllWishlist', url);

    const response = await fetch(url, {
      method: 'GET',
      ...helper.apiHeaders(),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    res = await response.json();

    // console.log('response geArticleSearch', res?.status, res?.result);

    if (res?.status === 'OK') {
      return res?.result;
    } else {
      throw new Error(res.error);
    }
  } catch (error) {
    console.error(error);
    ux.logError(
      'OnlineCartsWishlist',
      'Get Data from API',
      `Init Data API: OnlineCartsWishlist 1 - ${error}`,
    );
  }
};
