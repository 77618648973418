import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import { Redirect } from 'react-router-dom';

// import DOMPurify from "dompurify";

import { Table, Input } from 'reactstrap';
import { Spinner } from 'reactstrap';
import PropTypes from 'prop-types';
import './customer360.scss';
import * as customer360Icon from '../../../assets/img/customer360';
// import { MdClose } from 'react-icons/md';
import { CgSmileNeutral, CgSmileSad, CgSmile } from 'react-icons/cg';
import { Tab, Tabs } from '@material-ui/core';
import BDFCust360ContactTimeline from './components/Cust360ContactTimeline';
import BDFCust360OrderChangedHistory from './components/Cust360OrderChangedHistory';
import BDFCust360OrderTimeline from './components/Cust360OrderTimeline';
import BDFCust360AddComment from './components/Cust360AddComment';
import BDFCust360FollowUp from './components/Cust360FollowUp';
import enums from '../../../enums';
import { ModalBtnClose } from '../../../components/CommonComponent';

const helper = require('../../../utils/helper');
const ux = require('../../../application-center/ux-tracking-center');
const orderLib = require('../../../bdf-applications/orders-management/lib/orders');

class BDFCustomer360 extends React.Component {
  state = {
    redirect: '',
    viewList: [],
    nextList: [],
    podiumModalDataList: [],
    OCTdataList: [],

    toggle: {},
    custID: '',
    custName: '',
    custEmail: '',
    cusrPhone: '',
    orderNum: '',
    orderType: '',

    dateFilterValue: '',
    typeFilterValue: '',
    orderFilterValue: '',
    OCTlineFilterValue: '',
    OCTtypeFilterValue: '',
    OCTorderFilterValue: '',

    monthList: [],
    dateFilter: [],
    typeFilter: [],
    orderFilter: [],
    OCTlineFilter: [],
    OCTtypeFilter: [],
    OCTorderFilter: [],
    enums: {},
    monthDisplay: [],
    OCTmonthDisplay: [],
    customerProfile: {},
    aboutThisCustomer: {},
    contactTimelimeToggle: false,
    contactPreferenceEdit: false,
    parmOrderFound: false,

    podiumModal: false,

    // show progress
    numOfProgress: 0,
    completedProcess: 0,
    progress: 0,

    isloading: 0,
    custProfileIsloading: false,
    selectedTabsBottom: 0,
    selectedTabsTop: 0,

    showEventTab: false,
    showLogContactTab: false,

    //hide component
    showAboutThisCustomer: 0,

    externalAccess: false,

    //For Podium
    podiumUID: '',
    podiumNumber: '',

    //multicheck filter
    NewtypeFilter: [],
    NewtypeFilterValue: [],
  };

  OCTtestData = [
    {
      num: '10',
      status: 'Delivery Date Removed',
      salesAssociateNm: 'Yvette Cruz',
      actionDate: '2021-09-02T14:34:00',
      startDate: '10/2/2021',
      endDate: null,
    },
    {
      num: 'H',
      status: 'Blocked added',
      salesAssociateNm: 'Manny Smith',
      actionDate: '2021-09-04T16:51:00',
      startDate: null,
      endDate: 'Waiting on merchandise',
    },
    {
      num: '10',
      status: 'Rescherduled',
      salesAssociateNm: 'Yvette Cruz',
      actionDate: '2021-09-04T11:02:00',
      startDate: '9/28/2021',
      endDate: '10/2/2021',
    },
    {
      num: '10',
      status: 'Scheduled',
      salesAssociateNm: 'Bobbot',
      actionDate: '2021-09-02T22:21:00',
      startDate: null,
      endDate: '9/28/2021',
    },
  ];

  highlightSetup = [
    // {key: 'cancellationPropensity', title:'LIKELINESS <br/>TO CANCEL', tdClass: '', valueClass: 'value', titleClass: 'title',
    // colspan:3, methodValue:"processLikelinessToCancel", hideIfNull: true},

    {
      key: 'orderCount',
      title: 'ORDERS',
      tdClass: '',
      valueClass: 'value',
      titleClass: 'title',
      isBlue: true,
      onClick: {
        method: 'handleHighlightRedirectClicked',
        value: 'value set in render section',
      },
      clickable: true,
    },

    {
      key: 'returnOrderCount',
      title: 'RETURN',
      tdClass: '',
      valueClass: 'value',
      titleClass: 'title',
      isBlue: true,
      onClick: {
        method: 'handleHighlightRedirectClicked',
        value: 'value set in render section',
      },
      clickable: true,
    },

    {
      key: 'ticketCount',
      title: 'TICKETS',
      tdClass: '',
      valueClass: 'value',
      titleClass: 'title',
      onClick: { method: 'handleHighlightClicked', value: 'Zendesk Case' },
      isBlue: true,
      clickable: true,
    },

    {
      key: 'totalSales',
      title: 'TOTAL SALES',
      tdClass: 'totalSales',
      valueClass: 'value',
      titleClass: 'title',
      isMoney: true,
      onClick: {
        method: 'handleHighlightRedirectClicked',
        value: 'value set in render section',
      },
      clickable: true,
    },

    {
      key: 'averageRating',
      title: 'GOOGLE<br />RATING',
      tdClass: 'googleRating',
      valueClass: 'value',
      titleClass: 'title',
      displayNA: true,
      clickable: true,
      onClick: { method: 'handleHighlightClicked', value: 'Google Review' },
    },

    {
      key: 'storePurchaserNPS',
      title: 'STORE<br/>PURCHASER<br/>NPS',
      tdClass: 'storePurchaserNPS',
      valueClass: 'value',
      titleClass: 'title',
      displayNA: true,
      clickable: true,
      onClick: { method: 'handleHighlightClicked', value: 'Survey' },
      methodValue: 'processAvgNPS',
    },
  ];

  availableFundsSetup = [
    { title: 'store Credit', value: 'storeCredit', isMoney: true },
    {
      title: '',
      key: 'primaryProviderID',
      value: 'primaryFinancing',
      isMoney: true,
    },
    {
      title: '',
      key: 'secondaryProviderID',
      value: 'secondaryFinancing',
      isMoney: true,
    },
    {
      title: '',
      key: 'tertiaryProviderID',
      value: 'tertiaryFinancing',
      isMoney: true,
    },
    { title: 'gift card', value: 'giftCards', isMoney: true },
  ];

  async getCustomerProfile() {
    if (!this.state.externalAccess)
      this.setState({ custProfileIsloading: true }, async () => {
        await fetch(
          helper.getAPIHost() +
            '/api/Customer360/getCustomersTimelineProfile?customerID=' +
            this.state.custID,
          helper.apiHeaders(),
        )
          .then(async (response) => {
            let res = await response.json();
            helper.catchServer(res);
            if (res.status === 'OK') {
              console.log('getCustomersTimelineProfile: ', res.result);

              // validate is parmOrder in customerProfile (impact to external access only)
              let isOrderFound = false;
              if (res.result?.allOrderNumber.length > 0) {
                res.result.allOrderNumber.map((o) => {
                  if (o.orderNumber === this.state.orderNum)
                    isOrderFound = true;
                  return isOrderFound;
                });
              }

              this.setState({
                customerProfile: res.result,
                parmOrderFound: isOrderFound,
                custProfileIsloading: false,
                custName:
                  this.state.custName?.length > 0
                    ? this.state.custName
                    : res.result.profile.customerName,
                podiumUID: res.result.podiumUID,
                podiumNumber: res.result.podiumNumber,
              });
            } else {
              this.setState({
                customerProfile: {},
                custProfileIsloading: false,
              });
            }
          })
          .catch((err) => {
            this.setState({ customerProfile: {}, custProfileIsloading: false });
          });
      });
  }

  componentDidMount() {
    const {
      toggle,
      custID,
      custName,
      custEmail,
      custPhone,
      orderNum,
      enums,
      focusOnOrderTimeline,
      externalAccess,
      orderType,
    } = this.props;

    console.log(window.location);
    ux.logScreen(
      'Customer Profile',
      'Main Screen',
      'CustId: ' +
        custID +
        '; OrderNum: ' +
        orderNum +
        '; path: ' +
        ux.getPath(),
    );
    console.log(custID);
    let parameters = JSON.parse(localStorage.getItem('Parameters'));
    // let currentMonth = new Date().getMonth()
    // let month = [currentMonth, currentMonth - 1, currentMonth - 2]
    this.setState(
      {
        toggle: toggle,
        custID: custID,
        custName: custName,
        custEmail: custEmail,
        custPhone: custPhone,
        // monthList: month,
        orderNum: orderNum,
        enums: enums,
        selectedTabsBottom: focusOnOrderTimeline === true ? 1 : 0, //Auto focus to Order Timeline tabs
        showAboutThisCustomer: parseInt(
          parameters?.UI_C360_ABOUT_CUSTOMER || 0,
        ),
        externalAccess: externalAccess,
        orderType: orderType,
      },
      () => {
        // this.getdata()
        // this.getOrderList()
        this.getCustomerProfile();
      },
    );
  }

  reloadConatctTimeline() {
    this.setState({ contactTimelimeToggle: !this.state.contactTimelimeToggle });
  }

  handleTabsChange = async (name, event, newValue) => {
    let toset = {};
    toset[`selectedTabs${name}`] = newValue;
    this.setState(toset);
  };

  a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }

  processAvgNPS(score) {
    if (!score) return 'N/A';
    else if (score <= 6) {
      return <CgSmileSad className="avgNPSSad" size={35} />;
    } else if (score > 6 && score <= 8) {
      return <CgSmileNeutral className="avgNPSMeh" size={35} />;
    } else if (score > 8 && score <= 10) {
      return <CgSmile className="avgNPSHappy" size={35} />;
    }
  }

  processLikelinessToCancel(score) {
    if (score > 6 && score <= 8) {
      return <span className="likelinessModerate">{score}</span>;
    } else if (score > 8 && score <= 10) {
      return <span className="likelinessHigh">{score}</span>;
    } else {
      return '';
    }
  }

  processLikelinessToCancel2(score) {
    // if (score >= 5 && score <= 7) {
    //     return 'medium'
    // } else
    // if (score >= 8 && score <= 10) {
    //     return 'high'
    if (parseInt(score) === 1) return 'high';
    else {
      return '';
    }
  }

  handleHighlightClicked = (value, event) => {
    if (!value) return;
    let NewtypeFilterValue = this.state.NewtypeFilterValue;
    NewtypeFilterValue.push({ label: value, value: value });
    this.setState({
      typeFilterValue: value,
      NewtypeFilterValue: NewtypeFilterValue,
    });
  };

  notification(message, type) {
    window.message.notification(message, type);
  }

  handleHighlightRedirectClicked = (name, event) => {
    this.state.toggle();
    // let date = this.state.customerProfile.profile.since
    // if(!date) date = new Date(`${new Date().getFullYear()}-01-01`)
    // let formattedYear = new Date(date).getFullYear()
    // let formattedMonth = helper.formatNumber2(parseInt(new Date(date).getMonth())+1, 2)
    // let formattedDate = helper.formatNumber2(new Date(date).getDate() ,2)
    // let formattedFullDate = `${formattedYear}-${formattedMonth}-${formattedDate}`
    // this.setState({redirect:`/customer?name=${name}&date=${formattedFullDate}`})
    //this.setState({redirect:`/customer?id=${this.state.custID}`})
    window.redirect(`/customer?id=${this.state.custID}`);
  };

  handleContactPreferenceEditToggle = () => {
    this.setState({ contactPreferenceEdit: true });
  };

  handleContactPreferenceChanged = async (e) => {
    // let customerProfile = this.state.customerProfile
    // /api/Customer360/saveCustomerContactPreference
    let payload = {
      customerID: this.state.custID,
      contactPreference: e.target.value,
    };

    await fetch(
      helper.getAPIHost() + '/api/Customer360/saveCustomerContactPreference',
      {
        method: 'PUT',
        body: JSON.stringify(payload),
        ...helper.apiHeaders(),
      },
    ).then(async (response) => {
      let ret = await response.json();
      helper.catchServer(ret);
      if (ret.status === 'OK') {
        this.setState(
          {
            contactPreferenceEdit: false,
          },
          () => {
            this.notification(
              'Contact preference updated successfully!',
              enums.notificationType.S,
            );
            this.getCustomerProfile();
          },
        );
      } else {
        this.setState({
          contactPreferenceEdit: false,
        });
        this.notification(ret.errorMsg, enums.notificationType.E);
        ux.logError(
          'Customer360 Error on saveCustomerContactPreference',
          ret.errorMsg,
        );
      }
    });
    // customerProfile.profile.contactPreference = e.target.value
  };

  checkValue(tableData) {
    if (!isNaN(tableData) && Math.abs(Math.ceil(parseFloat(tableData))) === 0) {
      return Math.abs(tableData);
    }
    return tableData;
  }

  handleCopyToClipboard = (e) => {
    // console.log(e.target.getAttribute('data-phone'));
    let phoneNum = e.target.getAttribute('data-phone');
    let formattedPhoneNum = phoneNum.replaceAll('-', '');

    if (typeof navigator.clipboard == 'undefined') {
      var textArea = document.createElement('textarea');
      textArea.value = formattedPhoneNum;
      textArea.style.position = 'fixed'; //avoid scrolling to bottom
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        // var successful = document.execCommand('copy');
        // var msg = successful ? 'successful' : 'unsuccessful';
        this.notification(
          `Mobile phone ${formattedPhoneNum} copied!`,
          enums.notificationType.S,
        );
      } catch (err) {
        // toastr.warning('Was not possible to copy te text: ', err);
      }

      document.body.removeChild(textArea);
      return;
    }

    navigator.clipboard.writeText(formattedPhoneNum);
    this.notification(
      `Mobile phone ${formattedPhoneNum} copied!`,
      enums.notificationType.S,
    );
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    let {
      profile = {},
      lastOrder = {},
      availableFunds = {},
      highlightMetrics: highlights = {},
    } = this.state.customerProfile;
    let aboutThisCustomerShow =
      profile.since ||
      profile.contactPreference ||
      profile.nextProject ||
      profile.nextFollowUp ||
      profile.segment ||
      profile.goofLikelihood ||
      profile.pastStylePreferences;

    //proccess highlight
    let tileGroup = {
      0: [],
    };
    let rowCount = 0;
    let colCount = 0;
    // colspan
    this.highlightSetup.forEach((tile, index) => {
      //td className
      let dynamicTdClass = '';

      // if(tile.hasOwnProperty('clickable') && tile.clickable)
      // {
      //     if(tile.hasOwnProperty('displayNA') && tile.displayNA)
      //     {
      //         if(highlights[tile.key]) dynamicTdClass+='clickable'
      //     }else{
      //         dynamicTdClass+='clickable'
      //     }
      // }

      if (
        tile.hasOwnProperty('clickable') &&
        tile.clickable &&
        highlights[tile.key]
      ) {
        dynamicTdClass += 'clickable';
      }

      //td onclick
      let tdClickMethod = null;
      if (tile.hasOwnProperty('onClick')) {
        switch (tile.onClick.method) {
          case 'handleHighlightClicked':
            if (tile.hasOwnProperty('displayNA') && tile.displayNA) {
              if (highlights[tile.key]) {
                tdClickMethod = this[tile.onClick.method].bind(
                  this,
                  tile.onClick.value,
                );
              }
            } else {
              tdClickMethod = this[tile.onClick.method].bind(
                this,
                tile.onClick.value,
              );
            }
            break;
          case 'handleHighlightRedirectClicked':
            if (highlights[tile.key]) {
              tdClickMethod = this[tile.onClick.method].bind(
                this,
                this.state.custName,
              );
            }
            break;
          default:
            break;
        }
      }

      //value class
      let dynamicValueClass = '';
      if (tile.hasOwnProperty('isBlue') && tile.isBlue) {
        dynamicValueClass += 'blue';
      }

      //value
      let dynamicValue = 0;
      //is money
      if (tile.hasOwnProperty('isMoney') && tile.isMoney) {
        if (highlights[tile.key] > 999)
          dynamicValue = helper.formatNumber(
            highlights[tile.key] / 1000,
            1,
            '$',
            'K',
          );
        else dynamicValue = helper.formatNumber(highlights[tile.key], 0, '$');
      }
      //Display N/A if value =0 or null
      else if (
        tile.hasOwnProperty('displayNA') &&
        tile.displayNA &&
        !highlights[tile.key]
      ) {
        dynamicValue = 'N/A';
      }
      //use special method to Calculate Value
      else if (tile.hasOwnProperty('methodValue')) {
        dynamicValue = this[tile.methodValue](highlights[tile.key]);
      }
      //normal value display
      else if (highlights[tile.key]) {
        dynamicValue = highlights[tile.key];
      }

      //hideIfNull
      if (tile.hasOwnProperty('hideIfNull') && tile.hideIfNull && !dynamicValue)
        return;

      //Calculate Row and Col
      if (!tileGroup[rowCount]) tileGroup[rowCount] = [];
      let colSpan = 1;
      if (tile.hasOwnProperty('colspan')) {
        colSpan = tile.colspan;
      }
      colCount += colSpan;

      //push content
      tileGroup[rowCount].push(
        <td
          className={`${tile.tdClass} ${dynamicTdClass}`}
          onClick={tdClickMethod}
          colSpan={colSpan}
        >
          <div className={`${tile.valueClass}  ${dynamicValueClass}`}>
            {dynamicValue}
          </div>
          <div
            className={`${tile.titleClass}`}
            dangerouslySetInnerHTML={{ __html: tile.title }}
          />
        </td>,
      );

      if (colCount === 3) {
        rowCount++;
        colCount = 0;
      }
    });

    return (
      <div>
        <Table>
          {!this.state.externalAccess && (
            <tr className="topBar">
              <td colSpan="2">
                <div>
                  <span className="name">
                    <div>Customer Name</div>
                    <div>{profile.customerName || this.state.custName}</div>
                  </span>
                  <span className="id">
                    <div>Customer ID</div>
                    <div>{profile.customerID || this.state.custID}</div>
                  </span>
                  <span className="phone">
                    <div>Mobile Phone</div>
                    <div>
                      <span>
                        {profile.mobileNumber ||
                          profile.telephoneNumber ||
                          this.state.custPhone}
                      </span>
                      <span
                        className="hyperlink"
                        style={{ cursor: 'pointer' }}
                        data-uid={this.state.podiumUID}
                        data-order={this.state.orderNum}
                        data-phone={
                          profile.mobileNumber ||
                          profile.telephoneNumber ||
                          this.state.custPhone
                        }
                        data-name={encodeURI(this.state.custName)}
                        data-custid={this.state.custID}
                        onClick={orderLib.handlePodium.bind(
                          this.state.caller,
                          'd',
                        )}
                      >
                        {' '}
                        Text
                      </span>{' '}
                      |
                      <span
                        className="hyperlink"
                        style={{ cursor: 'pointer' }}
                        data-uid={this.state.podiumUID}
                        data-order={this.state.orderNum}
                        data-phone={
                          profile.mobileNumber ||
                          profile.telephoneNumber ||
                          this.state.custPhone
                        }
                        data-name={encodeURI(this.state.custName)}
                        data-custid={this.state.custID}
                        onClick={this.handleCopyToClipboard.bind(this)}
                      >
                        {' '}
                        Copy
                      </span>
                    </div>
                  </span>
                  <span className="email">
                    <div>Email</div>
                    <div className="hyperlink">
                      {profile.email || this.state.custEmail}
                    </div>
                  </span>
                  <span className="lastOrder">
                    <div>Last Order</div>
                    {lastOrder && (
                      <div>
                        {lastOrder.orderTypeDescription} #{' '}
                        <span className="hyperlink">
                          {lastOrder.orderNumber}
                        </span>
                      </div>
                    )}
                  </span>
                  <span className="lastOrderStatus">
                    <div>Last Order Status</div>
                    {lastOrder && <div>{lastOrder.orderStatus}</div>}
                  </span>

                  {!this.state.externalAccess && (
                    <ModalBtnClose
                      className="custom-modal-btn-close"
                      onClick={this.state.toggle}
                    />
                  )}
                </div>
              </td>
            </tr>
          )}
          <tr>
            {!this.state.externalAccess && (
              <td className="leftBar" rowSpan="2">
                {this.state.custProfileIsloading && (
                  <div className="loading">
                    <Spinner color="dark" />
                    <div>Loading...</div>
                  </div>
                )}

                {!this.state.custProfileIsloading && (
                  <div>
                    <Accordion
                      preExpanded={['highlights']}
                      allowZeroExpanded={true}
                    >
                      <AccordionItem uuid="highlights">
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            <div className="header">Highlights</div>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <Table className="highlights">
                            {this.processLikelinessToCancel2(
                              profile.cancellationPropensity,
                            ) && (
                              <tr>
                                <td
                                  colSpan="3"
                                  className={`likelineesToCancel ${this.processLikelinessToCancel2(
                                    profile.cancellationPropensity,
                                  )}`}
                                >
                                  {this.processLikelinessToCancel2(
                                    profile.cancellationPropensity,
                                  ) === 'high' && (
                                    <img
                                      src={customer360Icon.Upset.default}
                                      alt="Upset Face"
                                    />
                                  )}{' '}
                                  Current Order #{this.state.orderNum} -
                                  Likelihood to cancel is{' '}
                                  {this.processLikelinessToCancel2(
                                    profile.cancellationPropensity,
                                  )}
                                </td>
                              </tr>
                            )}
                            {Object.keys(tileGroup).map((row, i) => {
                              return (
                                <tr key={i}>
                                  {tileGroup[row][0]}
                                  {tileGroup[row][1]}
                                  {tileGroup[row][2]}
                                </tr>
                              );
                            })}
                          </Table>

                          {highlights && false && (
                            <Table className="highlights">
                              <tr>
                                <td
                                  className="clickable"
                                  onClick={this.handleHighlightRedirectClicked.bind(
                                    this,
                                    profile.customerName || this.state.custName,
                                  )}
                                >
                                  <div className="value">
                                    {highlights.orderCount}
                                  </div>
                                  <div className="title">ORDERS</div>
                                </td>
                                <td
                                  className="clickable"
                                  onClick={this.handleHighlightRedirectClicked.bind(
                                    this,
                                    profile.customerName || this.state.custName,
                                  )}
                                >
                                  <div className="value">
                                    {highlights.returnOrderCount}
                                  </div>
                                  <div className="title">RETURN</div>
                                </td>
                                <td
                                  className="clickable"
                                  onClick={this.handleHighlightClicked.bind(
                                    this,
                                    'Zendesk Case',
                                  )}
                                >
                                  <div className="value">
                                    {highlights.ticketCount}
                                  </div>
                                  <div className="title">TICKETS</div>
                                </td>
                              </tr>
                              <tr>
                                <td
                                  onClick={this.handleHighlightRedirectClicked.bind(
                                    this,
                                    profile.customerName || this.state.custName,
                                  )}
                                  className="totalSales clickable"
                                >
                                  <div className="value">
                                    {highlights.totalSales > 999
                                      ? helper.formatNumber(
                                          highlights.totalSales / 1000,
                                          1,
                                          '$',
                                          'K',
                                        )
                                      : helper.formatNumber(
                                          highlights.totalSales,
                                          0,
                                          '$',
                                        )}
                                  </div>
                                  <div className="title">TOTAL SALES</div>
                                </td>
                                <td
                                  className={`googleRating ${
                                    highlights.averageRating === 0
                                      ? ''
                                      : 'clickable'
                                  }`}
                                  onClick={this.handleHighlightClicked.bind(
                                    this,
                                    highlights.averageRating === 0
                                      ? ''
                                      : 'Google Review',
                                  )}
                                >
                                  <div className="value">
                                    {highlights.averageRating === 0
                                      ? 'N/A'
                                      : highlights.averageRating}
                                  </div>
                                  <div className="title">
                                    GOOGLE
                                    <br />
                                    RATING
                                  </div>
                                </td>
                                <td
                                  className={`storePurchaserNPS ${
                                    this.processAvgNPS(
                                      highlights.storePurchaserNPS,
                                    ) === 'N/A'
                                      ? ''
                                      : 'clickable'
                                  }`}
                                  onClick={this.handleHighlightClicked.bind(
                                    this,
                                    this.processAvgNPS(
                                      highlights.storePurchaserNPS,
                                    ) === 'N/A'
                                      ? ''
                                      : 'Survey',
                                  )}
                                >
                                  <div className="value">
                                    {this.processAvgNPS(
                                      highlights.storePurchaserNPS,
                                    )}
                                  </div>
                                  <div className="title">
                                    STORE
                                    <br />
                                    PURCHASER
                                    <br />
                                    NPS
                                  </div>
                                </td>
                              </tr>
                            </Table>
                          )}
                        </AccordionItemPanel>
                      </AccordionItem>
                    </Accordion>

                    <Accordion
                      allowZeroExpanded={true}
                      preExpanded={['availableFunds']}
                    >
                      <AccordionItem uuid="availableFunds">
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            <div className="header">Available funds</div>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className="availableFunds">
                          <div>
                            {this.availableFundsSetup
                              .filter((item) => availableFunds[item.value])
                              .map((item, index) => {
                                let title = '';
                                let value = availableFunds[item.value];
                                if (
                                  item.hasOwnProperty('isMoney') &&
                                  item.isMoney
                                ) {
                                  if (value < 1000) value = `$${value}`;
                                  else if (value % 1000 === 0)
                                    value = helper.formatNumber(
                                      value / 1000,
                                      0,
                                      '$',
                                      'K',
                                    );
                                  else
                                    value = helper.formatNumber(
                                      value / 1000,
                                      1,
                                      '$',
                                      'K',
                                    );
                                }
                                if (item.hasOwnProperty('key'))
                                  title = availableFunds[item.key];
                                else title = item.title;
                                return (
                                  <div key={index}>
                                    <div>{value}</div>
                                    <div>{title}</div>
                                  </div>
                                );
                              })}
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                    </Accordion>

                    {this.state.showAboutThisCustomer === 1 && (
                      <Accordion
                        preExpanded={['aboutThisCustomer']}
                        allowZeroExpanded={true}
                      >
                        <AccordionItem uuid="aboutThisCustomer">
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              <div className="header">About this customer</div>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel className="aboutThisCustomer">
                            {aboutThisCustomerShow && (
                              <Table responsive>
                                {profile.since && (
                                  <tr>
                                    <th>Customer Since: </th>
                                    <td>{helper.formatDate(profile.since)}</td>
                                  </tr>
                                )}

                                <tr>
                                  <th>Contact preference: </th>
                                  <td className="blue">
                                    <Input
                                      disabled={
                                        !this.state.contactPreferenceEdit
                                      }
                                      type="select"
                                      value={profile.contactPreference}
                                      onChange={this.handleContactPreferenceChanged.bind(
                                        this,
                                      )}
                                      className={`contactPreference ${
                                        this.state.contactPreferenceEdit
                                          ? ''
                                          : 'display'
                                      }`}
                                    >
                                      <option value="Phone Call">
                                        Phone Call
                                      </option>
                                      <option value="Text Message">
                                        Text Message
                                      </option>
                                      <option value="Email">Email</option>
                                    </Input>

                                    {!this.state.contactPreferenceEdit && (
                                      <img
                                        alt="pencil"
                                        onClick={this.handleContactPreferenceEditToggle.bind(
                                          this,
                                        )}
                                        src={customer360Icon.Pencil2.default}
                                      />
                                    )}
                                  </td>
                                </tr>
                                {profile.nextProject && (
                                  <tr>
                                    <th>Likely Next Project: </th>
                                    <td className="blue">
                                      {profile.nextProject}
                                    </td>
                                  </tr>
                                )}
                                {profile.nextFollowUp && (
                                  <tr>
                                    <th>Next Project Follow Up: </th>
                                    <td className="blue">
                                      {helper.formatDate(profile.nextFollowUp)}
                                    </td>
                                  </tr>
                                )}
                                {/* {profile.segment && <tr>
                                                    <th>Customer Segment: </th>
                                                    <td>{profile.segment}</td>
                                                </tr>} */}
                                {profile.goofLikelihood && (
                                  <tr>
                                    <th>Goof Likelihood: </th>
                                    <td>{profile.goofLikelihood}</td>
                                  </tr>
                                )}
                                {profile.pastStylePreferences && (
                                  <tr>
                                    <th>Past Style Preference: </th>
                                    <td>{profile.pastStylePreferences}</td>
                                  </tr>
                                )}
                              </Table>
                            )}
                            <Table responsive>
                              <thead>
                                <tr>
                                  <th colSpan="3">Purchase history by room</th>
                                </tr>
                              </thead>
                              <tbody className="room">
                                <tr>
                                  <th></th>
                                  <th>All Time</th>
                                  <th>Last Year</th>
                                </tr>
                                <tr>
                                  <th>Living Room</th>
                                  <td>
                                    {profile.livingRoom &&
                                      helper.formatNumber(
                                        this.checkValue(
                                          profile.livingRoom.allTime,
                                        ),
                                        0,
                                        '$',
                                      )}
                                  </td>
                                  <td>
                                    {profile.livingRoom &&
                                      helper.formatNumber(
                                        this.checkValue(
                                          profile.livingRoom.lastYear,
                                        ),
                                        0,
                                        '$',
                                      )}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Adult Bedroom</th>
                                  <td>
                                    {profile.adultBedroom &&
                                      helper.formatNumber(
                                        this.checkValue(
                                          profile.adultBedroom.allTime,
                                        ),
                                        0,
                                        '$',
                                      )}
                                  </td>
                                  <td>
                                    {profile.adultBedroom &&
                                      helper.formatNumber(
                                        this.checkValue(
                                          profile.adultBedroom.lastYear,
                                        ),
                                        0,
                                        '$',
                                      )}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Kids Bedroom</th>
                                  <td>
                                    {profile.kidsBedroom &&
                                      helper.formatNumber(
                                        this.checkValue(
                                          profile.kidsBedroom.allTime,
                                        ),
                                        0,
                                        '$',
                                      )}
                                  </td>
                                  <td>
                                    {profile.kidsBedroom &&
                                      helper.formatNumber(
                                        this.checkValue(
                                          profile.kidsBedroom.lastYear,
                                        ),
                                        0,
                                        '$',
                                      )}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Dining Room</th>
                                  <td>
                                    {profile.diningRoom &&
                                      helper.formatNumber(
                                        this.checkValue(
                                          profile.diningRoom.allTime,
                                        ),
                                        0,
                                        '$',
                                      )}
                                  </td>
                                  <td>
                                    {profile.diningRoom &&
                                      helper.formatNumber(
                                        this.checkValue(
                                          profile.diningRoom.lastYear,
                                        ),
                                        0,
                                        '$',
                                      )}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Mattresses</th>
                                  <td>
                                    {profile.mattresses &&
                                      helper.formatNumber(
                                        this.checkValue(
                                          profile.mattresses.allTime,
                                        ),
                                        0,
                                        '$',
                                      )}
                                  </td>
                                  <td>
                                    {profile.mattresses &&
                                      helper.formatNumber(
                                        this.checkValue(
                                          profile.mattresses.lastYear,
                                        ),
                                        0,
                                        '$',
                                      )}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Outdoor</th>
                                  <td>
                                    {profile.outdoor &&
                                      helper.formatNumber(
                                        this.checkValue(
                                          profile.outdoor.allTime,
                                        ),
                                        0,
                                        '$',
                                      )}
                                  </td>
                                  <td>
                                    {profile.outdoor &&
                                      helper.formatNumber(
                                        this.checkValue(
                                          profile.outdoor.lastYear,
                                        ),
                                        0,
                                        '$',
                                      )}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Outlet</th>
                                  <td>
                                    {profile.outlet &&
                                      helper.formatNumber(
                                        this.checkValue(profile.outlet.allTime),
                                        0,
                                        '$',
                                      )}
                                  </td>
                                  <td>
                                    {profile.outlet &&
                                      helper.formatNumber(
                                        this.checkValue(
                                          profile.outlet.lastYear,
                                        ),
                                        0,
                                        '$',
                                      )}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Other</th>
                                  <td>
                                    {profile.other &&
                                      helper.formatNumber(
                                        this.checkValue(profile.other.allTime),
                                        0,
                                        '$',
                                      )}
                                  </td>
                                  <td>
                                    {profile.other &&
                                      helper.formatNumber(
                                        this.checkValue(profile.other.lastYear),
                                        0,
                                        '$',
                                      )}
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </AccordionItemPanel>
                        </AccordionItem>
                      </Accordion>
                    )}
                  </div>
                )}
              </td>
            )}
            {
              /* BCP-1891*/ !this.state.externalAccess && (
                <td className="right1">
                  <Tabs
                    value={this.state.selectedTabsTop}
                    indicatorColor="dark"
                    textColor="primary"
                    onChange={this.handleTabsChange.bind(this, 'Top')}
                    //centered
                  >
                    <Tab
                      label="Add Comment"
                      className={
                        this.state.selectedTabsTop !== 0 ? '' : 'selected'
                      }
                      {...this.a11yProps(0)}
                    />
                    {!this.state.externalAccess && (
                      <Tab
                        label="Add Follow-Up"
                        className={
                          this.state.selectedTabsTop !== 1 ? '' : 'selected'
                        }
                        {...this.a11yProps(1)}
                      />
                    )}
                    {!this.state.externalAccess && this.state.showEventTab && (
                      <Tab
                        label="New Event"
                        className={
                          this.state.selectedTabsTop !== 2 ? '' : 'selected'
                        }
                        {...this.a11yProps(2)}
                      />
                    )}
                    {!this.state.externalAccess &&
                      this.state.showLogContactTab && (
                        <Tab
                          label="Log Contact"
                          className={
                            this.state.selectedTabsTop !== 3 ? '' : 'selected'
                          }
                          {...this.a11yProps(3)}
                        />
                      )}
                  </Tabs>

                  <TabPanel
                    value={this.state.selectedTabsTop}
                    index={0}
                    className="content comment"
                  >
                    {this.state.custName && this.state.custID && (
                      <BDFCust360AddComment
                        orderNumberList={
                          this.state.customerProfile.allOrderNumber
                        }
                        custName={this.state.custName}
                        custID={this.state.custID}
                        caller={this}
                        OrderID={this.state.orderNum}
                        isExternal={this.state.externalAccess}
                        orderType={this.state.orderType}
                      />
                    )}
                  </TabPanel>

                  <TabPanel
                    value={this.state.selectedTabsTop}
                    index={1}
                    className="content Follow-up"
                  >
                    {this.state.custName && this.state.custID && (
                      <BDFCust360FollowUp
                        orderNumberList={
                          this.state.customerProfile.allOrderNumber
                        }
                        custName={this.state.custName}
                        custID={this.state.custID}
                        caller={this}
                        OrderID={this.state.orderNum}
                        isExternal={this.state.externalAccess}
                      />
                    )}
                  </TabPanel>

                  <TabPanel
                    value={this.state.selectedTabsTop}
                    index={2}
                    className="content event"
                  >
                    <div>New Event</div>
                  </TabPanel>

                  <TabPanel
                    value={this.state.selectedTabsTop}
                    index={3}
                    className="content contact"
                  >
                    <div>Log Contact</div>
                  </TabPanel>
                </td>
              ) /* BCP-1891*/
            }
          </tr>
          <tr>
            <td className={`right2 ${this.state.externalAccess ? 'ext' : ''}`}>
              {/* {!this.state.externalAccess && (
                <button className="btnClose" onClick={this.state.toggle}>
                  <MdClose size={20} />
                </button>
              )} */}

              <Tabs
                value={this.state.selectedTabsBottom}
                indicatorColor="dark"
                textColor="primary"
                onChange={this.handleTabsChange.bind(this, 'Bottom')}
                //centered
              >
                <Tab
                  label="Contact Timeline"
                  className={
                    this.state.selectedTabsBottom !== 0 ? '' : 'selected'
                  }
                  {...this.a11yProps(0)}
                />
                {((this.state.externalAccess && this.state.parmOrderFound) ||
                  !this.state.externalAccess) && (
                  <Tab
                    label="Order Timeline"
                    className={
                      this.state.selectedTabsBottom !== 1 ? '' : 'selected'
                    }
                    {...this.a11yProps(1)}
                  />
                )}
                {((this.state.externalAccess && this.state.parmOrderFound) ||
                  !this.state.externalAccess) && (
                  <Tab
                    label="Order Change History"
                    className={
                      this.state.selectedTabsBottom !== 2 ? '' : 'selected'
                    }
                    {...this.a11yProps(2)}
                  />
                )}
              </Tabs>

              {/* Contact Timeline new*/}
              <TabPanel value={this.state.selectedTabsBottom} index={0}>
                {this.state.custID /*this.state.orderNum &&*/ && (
                  <BDFCust360ContactTimeline
                    custID={this.state.custID}
                    orderNum={this.state.orderNum}
                    custEmail={this.state.custEmail}
                    enums={this.state.enums}
                    typeFilterValue={this.state.typeFilterValue}
                    contactTimelimeToggle={this.state.contactTimelimeToggle}
                    NewtypeFilterValue={this.state.NewtypeFilterValue}
                    isExternal={this.state.externalAccess}
                  />
                )}
              </TabPanel>

              {/* Order Timeline*/}
              <TabPanel value={this.state.selectedTabsBottom} index={1}>
                {
                  <BDFCust360OrderTimeline
                    custID={this.state.custID}
                    focusOrderNumber={this.state.orderNum}
                  />
                }
              </TabPanel>

              {/* Order Change History new*/}
              <TabPanel value={this.state.selectedTabsBottom} index={2}>
                {
                  <BDFCust360OrderChangedHistory
                    custID={this.state.custID}
                    lastOrderNumber={
                      this.state.customerProfile.hasOwnProperty('lastOrder')
                        ? this.state.customerProfile.lastOrder.orderNumber
                        : ''
                    }
                  />
                }
              </TabPanel>
            </td>
          </tr>
        </Table>
      </div>
    );
  }
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

BDFCustomer360.propTypes = {
  toggle: PropTypes.func,
  custID: PropTypes.string,
  custName: PropTypes.string,
  custEmail: PropTypes.string,
  custPhone: PropTypes.string,
  orderNum: PropTypes.string,
  enums: PropTypes.object,
  focusOnOrderTimeline: PropTypes.bool,
  externalAccess: PropTypes.bool,
};

BDFCustomer360.defaultProps = {
  toggle: () => {},
  custID: '',
  custName: '',
  custEmail: '',
  custPhone: '',
  orderNum: '',
  enums: {},
  focusOnOrderTimeline: false,
  externalAccess: false,
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default BDFCustomer360;
