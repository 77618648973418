import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
// import DOMPurify from "dompurify";

import { Table, Label, Input } from 'reactstrap';
import PropTypes from 'prop-types';
import '../customer360.scss';
import { CgSmileNeutral, CgSmileSad, CgSmile } from 'react-icons/cg';

const helper = require('../../../../utils/helper');
const ux = require('../../../../application-center/ux-tracking-center');

class BDFCust360OrderChangedHistory extends React.Component {
  state = {
    viewList: [],
    nextList: [],
    podiumModalDataList: [],
    OCTdataList: [],

    toggle: {},
    custID: '',
    custName: '',
    custEmail: '',
    cusrPhone: '',
    orderNum: '',

    dateFilterValue: '',
    typeFilterValue: '',
    orderFilterValue: '',
    OCTlineFilterValue: '',
    OCTtypeFilterValue: '',
    OCTorderFilterValue: '',

    monthList: [],
    dateFilter: [],
    typeFilter: [],
    orderFilter: [],
    OCTlineFilter: [],
    OCTtypeFilter: [],
    OCTorderFilter: [],
    enums: {},
    monthDisplay: [],
    OCTmonthDisplay: [],
    customerProfile: {},

    podiumModal: false,

    // show progress
    numOfProgress: 0,
    completedProcess: 0,
    progress: 0,

    isloading: 0,
    custProfileIsloading: false,
    selectedTabs: 0,

    //No data
    noData: false,
  };

  OCTtestData = [
    {
      num: '10',
      status: 'Delivery Date Removed',
      salesAssociateNm: 'Yvette Cruz',
      actionDate: '2021-09-02T14:34:00',
      startDate: '10/2/2021',
      endDate: null,
    },
    {
      num: 'H',
      status: 'Blocked added',
      salesAssociateNm: 'Manny Smith',
      actionDate: '2021-09-04T16:51:00',
      startDate: null,
      endDate: 'Waiting on merchandise',
    },
    {
      num: '10',
      status: 'Rescherduled',
      salesAssociateNm: 'Yvette Cruz',
      actionDate: '2021-09-04T11:02:00',
      startDate: '9/28/2021',
      endDate: '10/2/2021',
    },
    {
      num: '10',
      status: 'Scheduled',
      salesAssociateNm: 'Bobbot',
      actionDate: '2021-09-02T22:21:00',
      startDate: null,
      endDate: '9/28/2021',
    },
  ];

  secondsToHms(d) {
    d = Number(d);
    let h = Math.floor(d / 3600);
    let m = Math.floor((d % 3600) / 60);
    let s = Math.floor((d % 3600) % 60);

    let display =
      helper.formatNumber2(h, 2) +
      ':' +
      helper.formatNumber2(m, 2) +
      ':' +
      helper.formatNumber2(s, 2);
    return display;
  }

  dateTimeFormat(formattedItem, datetime) {
    let plusOneDay = false;
    let time = new Date(datetime).toLocaleTimeString();
    let split = time.split(':');
    let h = parseInt(split[0]);
    let m = parseInt(split[1]);
    let s = parseInt(split[2].split(' ')[0]);
    let daynight = split[2].split(' ')[1];
    if (s > 30) {
      m++;
      if (m > 59) {
        h++;
        m -= 60;
        if (daynight === 'AM' && h === 12) {
          daynight = 'PM';
        } else if (daynight === 'PM' && h === 12) {
          daynight = 'AM';
          plusOneDay = true;
        } else if (h > 12) {
          h -= 12;
        }
      }
    }

    formattedItem['Time'] =
      helper.formatNumber2(h, 2) +
      ':' +
      helper.formatNumber2(m, 2) +
      ' ' +
      daynight;

    if (plusOneDay) {
      datetime = new Date(
        new Date(datetime).setDate(new Date(datetime).getDate() + 1),
      );
      formattedItem['Date'] =
        helper.formatNumber2(parseInt(datetime.getMonth()) + 1, 2) +
        '/' +
        helper.formatNumber2(parseInt(datetime.getDate()), 2) +
        '/' +
        datetime.getFullYear();
    } else {
      formattedItem['Date'] = helper.formatDate(datetime);
    }

    formattedItem['rawDateTime'] = datetime;
  }

  sortdate(list) {
    let newList = list;
    let field = 'Date';
    let field2 = 'Time';
    newList.sort((a, b) => {
      if (a[field] < b[field]) {
        return 1;
      } else if (a[field] > b[field]) {
        return -1;
      }

      if (a[field2] < b[field2]) {
        return 1;
      } else if (a[field2] > b[field2]) {
        return -1;
      }
      return 0;
    });
    return newList;
  }

  OCTfilterJob(data) {
    let pass = true;
    let line = this.state.OCTlineFilterValue;
    let type = this.state.OCTtypeFilterValue;
    let order = this.state.OCTorderFilterValue;

    if (line) pass = pass && data.itemNbr === line;
    if (type) pass = pass && data.status === type;
    if (order) pass = pass && data.salesDocNbr === order;
    if (data.hide) pass = false;

    return pass;
  }

  scrollItem = (e) => {
    // // console.log("TONY", document.getElementsByClassName("content")[0])
    // console.log("TONY", e.target.getBoundingClientRect().top)
    // console.log("TONY", e.target.offsetTop )
    // document.getElementsByClassName("content")[0].scrollTo({top: 100, behavior: "smooth"})
    // //scrollTop
  };

  componentDidMount() {
    const { custID, lastOrderNumber } = this.props;

    ux.logScreen(
      'Customer Profile',
      'Order Change History',
      'CustId: ' +
        custID +
        '; OrderNum: ' +
        lastOrderNumber +
        '; path: ' +
        ux.getPath(),
    );
    this.setState(
      {
        custID: custID,
        lastOrderNumber: lastOrderNumber,
      },
      () => {
        if (lastOrderNumber !== '') this.getOrderChangedHistory();
      },
    );
  }

  componentDidUpdate() {
    const { lastOrderNumber } = this.props;
    if (lastOrderNumber !== this.state.lastOrderNumber) {
      this.setState(
        {
          lastOrderNumber: lastOrderNumber,
        },
        () => {
          this.getOrderChangedHistory();
        },
      );
    }
  }

  handlefilterChanged = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    let toset = {};
    toset[name] = value;
    if (name === 'OCTorderFilterValue') {
      toset['OCTlineFilterValue'] = '';
      toset['OCTtypeFilterValue'] = '';
    }
    this.setState(toset, () => {
      this.loadFilter();
    });
  };

  loadFilter() {
    let lineList = [];
    let typeList = [];
    let monthList = [];

    this.state.OCTdataList.filter(
      (data) => data.salesDocNbr === this.state.OCTorderFilterValue,
    ).forEach((data) => {
      if (data.itemNbr && lineList.indexOf(data.itemNbr) === -1 && !data.hide)
        lineList.push(data.itemNbr);
      if (data.status && typeList.indexOf(data.status) === -1 && !data.hide)
        typeList.push(data.status);
    });

    this.state.OCTdataList.filter(
      (data) =>
        data.salesDocNbr === this.state.OCTorderFilterValue &&
        (data.status === this.state.OCTtypeFilterValue ||
          !this.state.OCTtypeFilterValue) &&
        (data.itemNbr === this.state.OCTlineFilterValue ||
          !this.state.OCTlineFilterValue),
    ).forEach((data) => {
      if (
        monthList.indexOf(new Date(data.dateOperation).getMonth()) === -1 &&
        !data.hide
      ) {
        let month = new Date(data.dateOperation).getMonth();
        let year = new Date(data.dateOperation).getFullYear();

        if (monthList.some((e) => e.month === month && e.year === year)) {
          return; //Skip existing month/year
        }

        monthList.push({
          month,
          year,
        });

        //Sort array
        monthList.sort((a, b) => {
          return b.year - a.year || b.month - a.month;
        });
      }
    });

    this.setState({
      OCTlineFilter: lineList.sort(),
      OCTtypeFilter: typeList.sort(),
      OCTmonthDisplay: monthList,
    });
  }

  async getOrderChangedHistory() {
    // this.setState({
    //     OCTorderFilterValue: this.state.lastOrderNumber
    // })
    if (this.state.OCTdataList.length === 0) {
      await fetch(
        helper.getAPIHost() +
          '/api/Customer360/getOrderChangeHistory?customerID=' +
          this.state.custID,
        helper.apiHeaders(),
      ).then(async (response) => {
        let res = await response.json();
        if (res.status === 'OK') {
          let dataList = res.result;
          let orderList = [];
          let orderListCheck = [];
          dataList.forEach((data) => {
            // if(data.itemNbr && lineList.indexOf(data.itemNbr) === -1) lineList.push(data.itemNbr)
            // if(data.status && typeList.indexOf(data.status) === -1) typeList.push(data.status)
            if (
              data.status === 'Availability date  Changed' &&
              data.dayDiff === 1
            )
              data.hide = true;
            if (data.orderLevel === 'header') data.itemNbr = 'H';
            if (
              data.salesDocNbr &&
              orderListCheck.indexOf(data.salesDocNbr) === -1 &&
              !data.hide
            ) {
              orderList.push({
                orderNum: data.salesDocNbr,
                date: data.orderTransactionDate,
              });
              orderListCheck.push(data.salesDocNbr);
            }
            // if(monthList.indexOf(new Date(data.dateOperation).getMonth())=== -1) monthList.push(new Date(data.dateOperation).getMonth())
          });

          orderList = orderList.sort(helper.sort('date', 'desc'));

          this.setState(
            {
              OCTdataList: dataList.sort((a, b) => {
                //sort by item nbr if 'H' first then asc
                if (isNaN(a.itemNbr) && !isNaN(b.itemNbr)) return -1;
                if (!isNaN(a.itemNbr) && isNaN(b.itemNbr)) return 1;
                if (a.itemNbr > b.itemNbr) return 1;
                if (a.itemNbr < b.itemNbr) return -1;

                //sort by dateOperation desc
                if (new Date(a.dateOperation) > new Date(b.dateOperation))
                  return -1;
                if (new Date(a.dateOperation) < new Date(b.dateOperation))
                  return 1;
                return 0;
              }),
              // OCTlineFilter: lineList.sort(),
              OCTorderFilter: orderList,
              OCTorderFilterValue:
                orderList.length > 0 ? orderList[0].orderNum : '',

              // OCTtypeFilter: typeList.sort(),
              // OCTmonthDisplay: monthList.sort((a,b)=> b-a),
            },
            () => {
              if (this.state.OCTdataList.length === 0) {
                this.setState({ noData: true });
              }
              this.loadFilter();
            },
          );
          console.log('getOrderChangeHistory: ', res.result);
        }
      });
    }
  }

  a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }

  processAvgNPS(score) {
    if (!score) return 'N/A';
    else if (score <= 6) {
      return <CgSmileSad className="avgNPSSad" size={35} />;
    } else if (score > 6 && score <= 8) {
      return <CgSmileNeutral className="avgNPSMeh" size={35} />;
    } else if (score > 8 && score <= 10) {
      return <CgSmile className="avgNPSHappy" size={35} />;
    }
  }

  valueCheckType(value) {
    switch (value) {
      case 'Delivery Fee  Removed':
      case 'Goof Proof Fee Changed':
      case 'Delivery Fee  Changed':
      case 'Special Order Fee  Removed':
      case 'Unit Price  Changed':
      case 'Goof Proof Fee Removed':
        return 'currency';
      default:
        return '';
    }
  }

  render() {
    return (
      <div>
        {/* Order Chnage History */}
        <div className="filter octFilter">
          <Label>
            Filter by Line #:
            <Input
              type="select"
              value={this.state.OCTlineFilterValue}
              name="OCTlineFilterValue"
              onChange={this.handlefilterChanged.bind(this)}
            >
              <option value="">All</option>
              {this.state.OCTlineFilter.map((line, i) => {
                return (
                  <option key={i} value={line}>
                    {line}
                  </option>
                );
              })}
            </Input>
          </Label>
          <Label>
            Filter by Type:
            <Input
              type="select"
              value={this.state.OCTtypeFilterValue}
              name="OCTtypeFilterValue"
              onChange={this.handlefilterChanged.bind(this)}
            >
              <option value="">All</option>
              {this.state.OCTtypeFilter.map((type, i) => {
                return (
                  <option key={i} value={type}>
                    {type}
                  </option>
                );
              })}
            </Input>
          </Label>
          <Label>
            Filter by Order:
            <Input
              type="select"
              value={this.state.OCTorderFilterValue}
              name="OCTorderFilterValue"
              onChange={this.handlefilterChanged.bind(this)}
            >
              {/* <option value=''>All</option> */}
              {this.state.OCTorderFilter.map((order, i) => {
                return (
                  <option key={i} value={order.orderNum}>
                    {order.orderNum}{' '}
                    {order.date && `(${helper.formatDate(order.date)})`}
                  </option>
                );
              })}
            </Input>
          </Label>
        </div>
        <div className="content">
          {this.state.noData ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                //alignItems: "center",
                height: '100%',
                margin: '0',
                paddingTop: '30px',
              }}
            >
              <div>No data was found for this customer</div>
            </div>
          ) : (
            <Accordion preExpanded={['month0']} allowZeroExpanded={true}>
              {this.state.OCTmonthDisplay.map((month, index) => {
                return (
                  <AccordionItem uuid={`month${index}`} key={index}>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        <div className="header">
                          {helper.getMonthName(month.month + 1)} {month.year}
                        </div>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <Table className="OCTtable">
                        <tr>
                          <td>Line</td>
                          <td>Status</td>
                          <td className="by">By</td>
                          <td className="fromSA">From</td>
                          <td className="on">On</td>
                          <td className="from">From</td>
                          <td className="to">To</td>
                        </tr>
                        {this.state.OCTdataList.filter(
                          (data) =>
                            new Date(data.dateOperation).getMonth() ===
                              month.month &&
                            new Date(data.dateOperation).getFullYear() ===
                              month.year &&
                            this.OCTfilterJob(data),
                        ).map((data, index) => {
                          let dateTime = {};
                          this.dateTimeFormat(dateTime, data.dateOperation);
                          let from = '';
                          switch (data.userProfitCenterName) {
                            case 'SCC-A':
                            case 'SCC-B':
                              from = 'Bob’s Squad';
                              break;
                            case 'CC':
                              from = 'Customer Care';
                              break;
                            default:
                              from = data.userProfitCenterName;
                              break;
                          }
                          let oldValue = '';

                          if (
                            helper.formatDate(data.valueOld).split('/')[0] !==
                              'NaN' &&
                            helper.formatDate(data.valueOld).split('/')[0] !==
                              'undefined'
                          )
                            oldValue = helper.formatDate(data.valueOld);
                          else if (
                            this.valueCheckType(data.status) === 'currency'
                          )
                            oldValue = helper.formatNumber(
                              data.valueOld,
                              2,
                              '$',
                            );
                          else oldValue = data.valueOld;

                          let newValue = '';
                          if (
                            helper.formatDate(data.valueNew).split('/')[0] !==
                              'NaN' &&
                            helper.formatDate(data.valueNew).split('/')[0] !==
                              'undefined'
                          )
                            newValue = helper.formatDate(data.valueNew);
                          else if (
                            this.valueCheckType(data.status) === 'currency'
                          )
                            newValue = helper.formatNumber(
                              data.valueNew,
                              2,
                              '$',
                            );
                          else newValue = data.valueNew;

                          return (
                            // <tr className={`OCTrow ${index%2 !== 0? 'second': ''}`}>
                            //     <td class="orderNumber blue"># <span>{data.salesDocNbr}</span></td>
                            //     <td className="line">{data.itemNbr}</td>
                            //     <td className="status">{data.status}</td>
                            //     <td className="connector">by</td>
                            //     <td className="salesAssociate blue">{data.userByName}</td>
                            //     <td className="connector">from</td>
                            //     <td className="profitCenter blue">{from}</td>
                            //     <td className="connector">on</td>
                            //     <td className="date">{dateTime.Date} {dateTime.Time}</td>
                            //     <td className="connector">from</td>
                            //     <td className="old">{helper.formatDate(data.valueOld).split("/")[0] !== 'undefined'? helper.formatDate(data.valueOld): data.valueOld}</td>
                            //     <td className="connector">to</td>
                            //     <td className="new">{helper.formatDate(data.valueNew).split("/")[0] !== 'undefined'? helper.formatDate(data.valueNew): data.valueNew}</td>

                            // </tr>
                            <tr
                              key={index}
                              className={`OCTrow ${
                                index % 2 === 0 ? 'second' : ''
                              }`}
                            >
                              <td>{data.itemNbr}</td>
                              <td>{data.status}</td>
                              <td className="by">
                                <span className="blue">{data.userByName}</span>
                              </td>
                              <td className="fromSA">
                                <span className="blue">{from}</span>
                              </td>
                              <td className="on">
                                {dateTime.Date} {dateTime.Time}
                              </td>
                              <td className="from">{oldValue}</td>
                              <td className="to">{newValue}</td>
                            </tr>
                          );
                        })}
                      </Table>
                    </AccordionItemPanel>
                  </AccordionItem>
                );
              })}
            </Accordion>
          )}
        </div>
      </div>
    );
  }
}

BDFCust360OrderChangedHistory.propTypes = {
  custID: PropTypes.string,
  lastOrderNumber: PropTypes.string,
};

BDFCust360OrderChangedHistory.defaultProps = {
  custID: '',
  lastOrderNumber: '',
};

export default BDFCust360OrderChangedHistory;
