//React
import { useEffect, useState } from 'react';

//Component
import { Table } from 'reactstrap';
import { Modal } from '@mui/material';

//File Component
import BDFCustomer360 from '../../bdf-applications/customers/customer-timelines';

//Icons
import { BsChevronRight } from 'react-icons/bs';

//SCSS
import './customer-profile.scss';

//Enums
import enums from '../../enums';
//Modal Customer Profile
export const ModalCustomerProfile = ({
  callerToggleToReset,
  orderNumber,
  customerID,
  customerName,
  customerEmail,
  customerPhone,
}) => {
  const [toggleModal, setToggleModal] = useState(false);
  const [firstLanding, setFirstLanding] = useState(true);

  useEffect(() => {
    setToggleModal(true);
    setFirstLanding(false);

    console.clear();
    console.log('Customer Profile', {
      orderNumber,
      customerID,
      customerName,
      customerEmail,
      customerPhone,
    });
  }, [orderNumber, customerID, customerName, customerEmail, customerPhone]);

  useEffect(() => {
    if (!toggleModal && !firstLanding) callerToggleToReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleModal, firstLanding]);

  return (
    <Modal
      open={toggleModal}
      onClose={() => setToggleModal(!toggleModal)}
      fade={false}
      id="modal-customer-profile"
      className={`modal-customer-profile customer360 ${
        toggleModal ? 'open' : ''
      }`}
    >
      <Table>
        <tr>
          <td className="slider" onClick={() => setToggleModal(!toggleModal)}>
            <BsChevronRight />
          </td>
          <td className="body">
            <BDFCustomer360
              enums={{
                w: enums.writeSalesBucket,
                d: enums.openOrderBucket,
                r: enums.repeatSalesBucket,
              }}
              toggle={() => setToggleModal(!toggleModal)}
              custID={customerID}
              custName={customerName}
              custEmail={customerEmail}
              custPhone={customerPhone}
              orderNum={orderNumber}
            />
          </td>
        </tr>
      </Table>
    </Modal>
  );
};
