import React from 'react';
import { Redirect } from 'react-router-dom';
import logo from '../../assets/img/logo/bobboost.PNG';
import loading from '../../assets/img/loading.gif';
import loginLogo from '../../assets/img/bobsfurniture_logo.jpg';
import * as auth from './lib/authentication';
import './landing.scss';
import { Form, FormGroup, Input } from 'reactstrap';
import PageSpinner from '../../components/PageSpinner';

const helper = require('../../utils/helper');
const initData = require('./lib/dataInitialization');

class BDFLandingLegacyPage extends React.Component {
  state = {
    isAdmin: false,
    isFloater: false,
    process: 'Checking login credential...',
    error: '',
    userId: '',
    goToHome: false,
    checked: false,

    // login
    loginProcess: false,
    loginUserName: '',
    loginEmplId: '',
    loginStoreId: '',
    floaterEmpId: '',
    floaterUserId: '',
    loginRegion: '',

    adminEmpId: '',
    adminUserId: '',

    // for local test
    localStore: '',

    //variableforstore
    AllStore: [],
    Allregion: [],

    mockSSO: localStorage.getItem('SSOUser'),
  };

  ssoLoginCall(userId, fromSSOCheck) {
    console.log(fromSSOCheck + ' ' + userId);
    // localStorage.removeItem('isAdminLoginSSo');
    if (fromSSOCheck === 'TO-LOGIN') {
      window.location = '/login';
    }
    //console.log("--" + helper.getQSParam("floaterId") + "--")
    if (
      helper.getQSParam('floaterId') !== null &&
      helper.getQSParam('floaterId') !== ''
    ) {
      userId = helper.getQSParam('floaterId');
      console.log('impersonate to floaterId ' + userId);
      this.setState(
        {
          isFloater: true,
        },
        () => this.getAllRegionName(),
      );
      return;
    }

    if (
      helper.getQSParam('impId') !== null &&
      helper.getQSParam('impId') !== ''
    ) {
      userId = helper.getQSParam('impId');
      console.log('impersonate to ' + userId);
    }

    if (userId) {
      if (userId.indexOf('\\') >= 0) {
        let tmp = userId.split('\\');
        userId = tmp[1];
      }
      console.log('SSO-User: ' + userId);
      this.setState({ userId: userId });
      auth.resetUserData();
      auth
        .doLogin(userId, '', true) // true for SSO
        .then((result) => {
          if (result.status) {
            localStorage.setItem('SSOSuccess', '1');
            var scope = this;
            if (
              result.isAdmin !== null &&
              result.isAdmin !== undefined &&
              result.isAdmin === true
            ) {
              console.log('IS ADMIN');
              setTimeout(function () {
                scope.setState({
                  isAdmin: true,
                });
              }, 100);
              return;
            } else if (
              result.isFloater !== null &&
              result.isFloater !== undefined &&
              result.isFloater === true
            ) {
              console.log('IS FLOATER');
              localStorage.setItem('FloaterID', userId);
              this.getAllRegionName(userId);
              setTimeout(function () {
                scope.setState({
                  isFloater: true,
                  floaterUserId: userId,
                });
              }, 100);
              return;
            } else {
              console.log('SSO-Login success');
              this.setState({
                process: 'Login successful, initializing your session',
              });
              Promise.all([
                initData.initFiscalMonth(),
                initData.initDashboardKPI(),
                // if more data need to init, append here
              ]).then(([ret1, ret2]) => {
                //this.props.history.push("/home");
                setTimeout(function () {
                  window.location.href = '/home';
                }, 500);
              });
            }
          } else {
            console.log('SSO-Login failed');
            this.setState({
              checked: true,
              error:
                'Access denied.\nYour account is not associated with a security group. Please contact Help Desk Team (860-812-1555 ext. 01555).',
            });
          }
        });
    } else {
      this.setState({
        checked: true,
        error: '', //"Access denied.\nYour account is not associated with a security group. Please contact Help Desk Team (860-812-1555 ext. 01555)."
      });
    }
  }

  ssoLogin() {
    auth.checkSSOOldMethod(this.ssoLoginCall.bind(this)); // true for SSO
    console.log(helper.getEnvironment());
  }

  goToLogin(event) {
    event.preventDefault();
    window.location = '/login';
  }

  handleUsernameChange = (event) => {
    this.setState({
      loginUserName: event.target.value,
      loginEmplId: '',
      loginStoreId: '',
    });
  };

  handleEmployeeChange = (event) => {
    this.setState({
      loginUserName: '',
      loginEmplId: event.target.value,
      loginStoreId: '',
    });
  };

  handleStoreChange = (event) => {
    let val = event.target.value.replace(/[^\d.-]/g, '');
    this.setState({ loginStoreId: val, loginUserName: '', loginEmplId: '' });
  };

  handleRegionChange = (event) => {
    let val = event.target.value || '';
    let loginRegion = '';

    loginRegion = val;

    console.log(loginRegion);
    this.setState(
      {
        loginRegion: loginRegion,
      },
      () => this.getAllStoresName(loginRegion),
    );
  };

  handleLocalStoreChange = (event) => {
    let val = event.target.value.replace(/[^\d.-]/g, '');
    this.setState({ localStore: val });
  };

  getAllRegionName = async (empid) => {
    let region = [];
    let loginRegion = '';
    let userid = empid || '';

    console.log('userid =' + userid);

    try {
      let url =
        helper.getAPIHost() + '/api/MDStoreDirectories/getAllRegionsName';
      await fetch(url, { method: 'GET', ...helper.apiHeaders() })
        .then(async (response) => {
          let data = await response.json();
          if (data.status === 'OK') {
            console.log('Region data');
            console.log(data.result);
            helper.catchServer(data);
            data.result.forEach((val) => {
              if (userid === val.empid) {
                loginRegion = val.region;
              }
              region.push({ label: val.region, value: val.region });
            });
            // storeName = data.result;
          } else {
            console.log(
              'Floater',
              'Get Data from API',
              'Unable to get data from server!',
              url + ' ' + data.errorMsg,
            );
            // ux.logError("Customer350Home", "Get Data from API", "Unable to get data from server!", url + " " + data.errorMsg);
          }
        })
        .catch((error) => {
          console.log(
            'Floater',
            'Get Data from API',
            'Init Data API:getAllRegionsName - ' + error,
            url,
          );
          // ux.logError("Customer350Home", "Get Data from API", "Init Data API:GetAllStoresName - " + error, url);
        });
    } catch (error) {
      console.log(
        'Floater',
        'Get Data from API',
        'Init Data API: getAllRegionsName - ' + error,
      );
      // ux.logError("Customer350Home", "Get Data from API", "Init Data API: GetAllStoresName - " + error);
    }

    this.setState(
      {
        Allregion: region,
        loginRegion: loginRegion,
      },
      () => this.getAllStoresName(loginRegion),
    );
  };

  getAllStoresName = async (loginRegion) => {
    let storeName = [];
    let region = '';

    if (loginRegion !== '') {
      //   region = encodeURIComponent(loginRegion.value.trim());
      region = encodeURIComponent(loginRegion.trim());
    }

    try {
      let url =
        helper.getAPIHost() +
        '/api/MDStoreDirectories/getAllStoresName?region=' +
        region;
      console.log(url);
      await fetch(url, { method: 'GET', ...helper.apiHeaders() })
        .then(async (response) => {
          let data = await response.json();
          if (data.status === 'OK') {
            console.log('Store data');
            console.log(data.result);
            storeName = data.result;
          } else {
            console.log(
              'Header',
              'Get Data from API',
              'Unable to get data from server!',
              url + ' ' + data.errorMsg,
            );
            // ux.logError("Customer350Home", "Get Data from API", "Unable to get data from server!", url + " " + data.errorMsg);
          }
        })
        .catch((error) => {
          console.log(
            'Header',
            'Get Data from API',
            'Init Data API:GetAllStoresName - ' + error,
            url,
          );
          // ux.logError("Customer350Home", "Get Data from API", "Init Data API:GetAllStoresName - " + error, url);
        });
    } catch (error) {
      console.log(
        'Header',
        'Get Data from API',
        'Init Data API: GetAllStoresName - ' + error,
      );
      // ux.logError("Customer350Home", "Get Data from API", "Init Data API: GetAllStoresName - " + error);
    }

    // return storeName;
    this.setState({
      AllStore: storeName,
    });
  };

  handlecheckbox = (selected) => {
    let loginRegion = '';
    if (this.state.loginRegion === '') {
      loginRegion = selected[0];
    } else {
      loginRegion = selected[1];
    }

    if (selected.length === 0) {
      loginRegion = '';
    }

    this.setState(
      {
        loginRegion: loginRegion,
      },
      () => this.getAllStoresName(loginRegion),
    );
  };

  handleSubmit = (event) => {
    event.preventDefault();

    // for local use only
    if (
      this.state.localStore !== '' &&
      this.state.loginEmplId.trim().length > 0
    ) {
      this.setState({ loginProcess: true, errorMessage: '' });
      let empId = this.state.loginEmplId.trim();
      auth
        .doLogin('', '', true, empId, this.state.localStore.trim())
        .then((result) => {
          if (result.status) {
            Promise.all([
              initData.initFiscalMonth(),
              initData.initDashboardKPI(),
              // if more data need to init, append here
            ]).then(([ret1, ret2]) => {
              window.location.href = '/home';
            });
          } else {
            this.setState({
              errorMessage:
                'Your user account is not in a security group with access to BobBoost. Ext. 01555',
              loginProcess: false,
            });
          }
        });
    }

    if (
      this.state.isAdmin &&
      (this.state.loginUserName.trim().length > 0 ||
        this.state.loginEmplId.trim().length > 0)
    ) {
      this.setState({ loginProcess: true, errorMessage: '' });

      auth
        .doLogin(
          this.state.loginUserName.trim(),
          '',
          true,
          this.state.loginEmplId.trim(),
        )
        .then((result) => {
          if (result.status) {
            console.log('Login success');
            console.log(result);
            if (result.isFloater === true) {
              console.log('Go to floater ' + this.state.loginEmplId.trim());
              localStorage.setItem(
                'FloaterID',
                this.state.loginUserName.trim(),
              );

              if (result.floaterStore) {
                // direct login to floater store 1
                auth
                  .doLogin(
                    this.state.loginUserName.trim(),
                    '',
                    true,
                    this.state.loginEmplId.trim(),
                    result.floaterStore,
                  )
                  .then((result) => {
                    if (result.status) {
                      console.log('Login success');
                      localStorage.setItem(
                        'FloaterID',
                        this.state.loginUserName.trim(),
                      );
                      Promise.all([
                        initData.initFiscalMonth(),
                        initData.initDashboardKPI(),
                        // if more data need to init, append here
                      ]).then(([ret1, ret2]) => {
                        window.location.href = '/home';
                      });
                    } else {
                      console.log('Login failed');

                      this.setState({
                        errorMessage:
                          'Your user account is not in a security group with access to BobBoost. Ext. 01555',
                        loginProcess: false,
                      });
                    }
                  });
              } else {
                this.setState(
                  {
                    floaterEmpId: this.state.loginEmplId.trim() || result.empID,
                    floaterUserId: this.state.loginUserName.trim(),
                  },
                  () => this.goToFloater(),
                );
              }
            }
            if (result.isAdmin === true) {
              console.log(
                'Go to admin (impersonate again) ' +
                  this.state.loginEmplId.trim(),
              );
              this.setState(
                {
                  adminEmpId: this.state.loginEmplId.trim(),
                  adminUserId: this.state.loginUserName.trim(),
                },
                () => this.goToAdmin(),
              );
            } else {
              Promise.all([
                initData.initFiscalMonth(),
                initData.initDashboardKPI(),
                // if more data need to init, append here
              ]).then(([ret1, ret2]) => {
                window.location.href = '/home';
              });
            }
          } else {
            console.log('Login failed');

            this.setState({
              errorMessage:
                'Your user account is not in a security group with access to BobBoost. Ext. 01555',
              loginProcess: false,
            });
          }
        });
    } else if (
      this.state.isFloater &&
      this.state.loginStoreId.trim().length > 0
    ) {
      this.setState({ loginProcess: true, errorMessage: '' });

      let username = this.state.floaterUserId;
      let empId = this.state.floaterEmpId;
      if (empId !== '') {
        username = '';
      }

      console.log('Landing Floater');
      console.log('EmpId ' + empId);
      console.log('username ' + username);

      if (
        helper.getQSParam('floaterId') !== null &&
        helper.getQSParam('floaterId') !== ''
      ) {
        username = helper.getQSParam('floaterId');
        console.log('impersonate to floaterId ' + username);
      }

      auth
        .doLogin(username, '', true, empId, this.state.loginStoreId.trim())
        .then((result) => {
          if (result.status) {
            console.log('Login success');
            localStorage.setItem('FloaterID', username);
            Promise.all([
              initData.initFiscalMonth(),
              initData.initDashboardKPI(),
              // if more data need to init, append here
            ]).then(([ret1, ret2]) => {
              window.location.href = '/home';
            });
          } else {
            console.log('Login failed');

            this.setState({
              errorMessage:
                'Your user account is not in a security group with access to BobBoost. Ext. 01555',
              loginProcess: false,
            });
          }
        });
    } else {
      if (this.state.isAdmin)
        this.setState({
          errorMessage: 'Please fill in Username OR Employee ID!',
        });
      else if (this.state.isFloater)
        this.setState({ errorMessage: 'Please pick a Store !' });
    }
  };

  goToFloater() {
    localStorage.setItem('isAdmin', true); // for testing

    let empid = '';

    if (this.state.floaterEmpId !== '') {
      empid = this.state.floaterEmpId;
    }

    this.setState(
      {
        isFloater: true,
        isAdmin: false,
        loginProcess: false,
        adminEmpId: '',
        adminUserId: '',
      },
      () => this.getAllRegionName(empid),
    );
  }

  goToAdmin() {
    localStorage.setItem('isAdmin', true);
    this.setState({
      isFloater: false,
      isAdmin: true,
      loginProcess: false,
      loginEmplId: '',
      loginUserName: '',
    });
  }

  componentDidMount() {
    auth.resetUserData();
    // check protocol
    //console.log(helper.getEnvironment(), window.location)
    if (
      (helper.isLocal() || helper.isDEV()) &&
      window.location.protocol === 'https:'
    ) {
      console.log(
        'TOBE: https://' + window.location.host + window.location.pathname,
      );
      window.location.href =
        'http://' + window.location.host + window.location.pathname;
    }

    // do not allow for PROD first
    //|| helper.isProd()
    if (helper.isQA() && window.location.protocol === 'http:') {
      console.log('Is QA');
      window.location.href =
        'https://' + window.location.host + window.location.pathname;
    } else {
      console.log('To Impersonate');
      // Auto login
      this.getAllRegionName();
      if (!auth.isAuthenticated()) {
        this.ssoLogin();
      } else {
        this.setState({
          goToHome: true,
        });
      }
    }
  }

  /*
    async testAxiosGetRegion() { // OK
        let host = helper.getAPIHost();
        axios({
            method: 'GET',
            url: host + "/api/MDStoreDirectories/getAllRegionsName"
        })
        .then((res) => {
            console.log(res)
            
        }, (error) => {
            console.log(error);
        })
    }

    async testAxiosGetAD() { // OK
        let host = helper.getAPIHost();
        axios({
            method: 'POST',
            url: host + "/getADuser",
            data: {
                "empid": "bchong",
                "floaterStoreId": "",
                "external": false
            }
        })
        .then((res) => {
            console.log(res)
        }, (error) => {
            console.log(error);
        })
    }

    async testJgetUser() {
        let host = helper.getAPIHost();
        $.ajax({
            url: host + "/getLoginUser",
            type: "GET",
            xhrFields: {
                withCredentials: true
            },
            //crossDomain: true,
            //headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'},
            success: (res) => {
                console.log(res)
            },
            error: (xhr, status, error) => {
                console.log(xhr, status, error)
            },
            complete: function (jqXHR, textStatus) {
                if (textStatus == 'error') {
                    console.log(jqXHR.responseText);
                }
            }
        })
        
    }
    async testAxiosGetUser() {
        let host = helper.getAPIHost();
        axios({
            withCredentials: true,
            method: 'GET',
            url: host + "/getLoginUser",
            headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'}
        })
        .then((res) => {
            console.log(res)
        }, (error) => {
            console.log(error);
        })
    }

    async testJLogin() {
        let host = helper.getAPIHost();
        $.ajax({
            url: host + "/Login",
            type: "POST",
            data: {
                "username": "bchong",
                "password": "Fall2021!"
              }
        })
        .done(function (data) {
            console.log(data)
        })
        .catch(e => {
            console.log(e)
        });
    }

    async testAxiosLogin() {
        let host = helper.getAPIHost();
        axios({
            method: 'POST',
            url: host + "/Login",
            data: {
                "username": "bchong",
                "password": "Fall2021!"
              }
        })
        .then((res) => {
            console.log(res)
        }, (error) => {
            console.log(error);
        })
    }
    

    userAccount = (e) => {
        let val = e.target.value

        this.setState({
            mockSSO: val
        })        
    }

    mockSSOAccount = () => {
        if (this.state.mockSSO) {
            localStorage.setItem('SSOUser', this.state.mockSSO);
            localStorage.setItem('isAdmin', 'true');
            localStorage.setItem('SSOSuccess', 1);
            window.location.reload();
        } else {
            alert("Please key in AD name")
        }
    }
    */
  render() {
    if (this.state.goToHome) return <Redirect to="/home" />;

    let usernameInputProps = {
      id: 'username',
      type: 'text',
      placeholder: 'Username',
    };
    let emplIdInputProps = {
      id: 'empid',
      type: 'text',
      placeholder: 'Employee ID: A12345',
    };

    let storeInputProps = {
      id: 'storeId',
      type: 'select',
      placeholder: 'Store ID',
    };

    let Allregion = this.state.Allregion;
    let AllStore = this.state.AllStore;

    return (
      <div className="siteLanding">
        <div className="logo">
          <img src={logo} alt="Logo" /> (Legacy Landing)
        </div>

        <div
          className="clearcache"
          onClick={() => (window.location.href = '/clear.html')}
        >
          Clear login information
        </div>
        {/*process.env.REACT_APP_MANUAL_LOGIN === 'true' &&
                <div className='dev_test'>
                    DEV Only
                    <div onClick={this.testAxiosGetRegion}>AXIOS GET REGION -- OK</div>
                    <div onClick={this.testAxiosGetAD}>AXIOS IMPERSONATE -- OK</div>
                    
                    <br />
                    ------Pending------Check console log<br />
                    SSO
                    <div onClick={this.testAxiosGetUser}>AXIOS GET NAME - 401</div>
                    <div onClick={this.testJgetUser}>JQuery GET NAME - CORS</div>
                    <br />
                    Login
                    <div onClick={this.testAxiosLogin}>AXIOS Login -- OK</div>
                    <div onClick={this.testJLogin}>JQuery Login</div>

                </div>*/}

        {process.env.REACT_APP_MANUAL_LOGIN === 'true' && (
          <div className="login">
            <button onClick={this.goToLogin}>Login [DEV only]</button>
            <br />
            <br />
            {this.state.isAdmin && (
              <button onClick={this.goToFloater.bind(this)}>
                Floater [DEV only]
              </button>
            )}
            {this.state.isFloater && (
              <button onClick={this.goToAdmin.bind(this)}>
                Impersonate [DEV only]
              </button>
            )}
            {/*<div>
                            Alternative Login<br />
                            <label>AD name: <input onChange={this.userAccount} value={this.state.mockSSO} /></label>
                            <input type="button" onClick={this.mockSSOAccount} value="Go" />
                        </div>*/}
          </div>
        )}

        {!this.state.isAdmin && !this.state.isFloater && (
          <div>
            {!this.state.checked && (
              <div className="loading">
                <div>
                  <img src={loading} alt="Loading" />
                  <div className="loadingTxt">Loading...</div>
                </div>
                <div className="process">
                  {this.state.userId !== '' && (
                    <span>User ID found: {this.state.userId}</span>
                  )}
                </div>
                <div className="process">{this.state.process}</div>
              </div>
            )}
            {this.state.error && (
              <div>
                <div className="error">
                  {this.state.error.split('\n').map((str, i) => (
                    <div key={i}>{str}</div>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}

        {this.state.isAdmin && (
          <div className="login-form">
            <div>
              <img src={loginLogo} alt="Login Logo" />
            </div>
            <div className="title">Enter ID to Impersonate</div>
            <div className="msg">{this.state.errorMessage}</div>

            {this.state.loginProcess === true && <PageSpinner />}
            <Form onSubmit={this.handleSubmit}>
              <FormGroup>
                <div className="login-field-wrapper">
                  <span>User Name</span>
                  <Input
                    {...usernameInputProps}
                    value={this.state.loginUserName}
                    onChange={this.handleUsernameChange}
                  />
                </div>
              </FormGroup>

              <div className="sep-line">OR</div>

              <FormGroup>
                <div className="login-field-wrapper">
                  <span>Employee ID</span>
                  <Input
                    {...emplIdInputProps}
                    value={this.state.loginEmplId}
                    onChange={this.handleEmployeeChange}
                  />
                </div>
              </FormGroup>

              {process.env.REACT_APP_MANUAL_LOGIN === 'true' &&
                process.env.REACT_APP_ENV !== 'DEV' && (
                  <FormGroup>
                    <div className="login-field-wrapper">
                      <span>Store ID (LOCAL ONLY)</span>
                      <Input
                        value={this.state.localStore}
                        onChange={this.handleLocalStoreChange}
                      />
                    </div>
                  </FormGroup>
                )}
              <button
                size="lg"
                className="iss-login-button"
                block="true"
                onClick={this.handleSubmit}
              >
                Impersonate
              </button>
            </Form>
          </div>
        )}

        {this.state.isFloater && (
          <div className="login-form floater">
            <div>
              <img src={loginLogo} alt="Login Logo" />
            </div>
            <div className="title">Floater</div>
            <div className="msg">{this.state.errorMessage}</div>

            {this.state.loginProcess === true && <PageSpinner />}
            {/* <Form onSubmit={this.handleSubmit}>
                        <FormGroup>
                            <div className="login-field-wrapper">
                                <span>Store ID</span>
                                <Input {...storeInputProps} value={this.state.loginStoreId} onChange={this.handleStoreChange} />
                            </div>
                        </FormGroup>

                        <button
                            size="lg"
                            className="iss-login-button"
                            block="true"
                            onClick={this.handleSubmit}>
                            Login
                        </button>

                    </Form> */}
            <div className="region">
              <span>
                <b>Region: </b>
              </span>
              <span className="region-check">
                {/* <ReactMultiSelectCheckboxes 
                                options={Allregion}
                                placeholderButtonLabel="Region"
                                className="region-select"
                                styles={styles.articlePackageProductFilter}
                                onChange={this.handlecheckbox}
                                value={this.state.loginRegion}
                                isDisabled={this.state.loginProcess}
                                /> */}
                <Input
                  {...storeInputProps}
                  value={this.state.loginRegion}
                  onChange={this.handleRegionChange}
                >
                  <option id="0" value={''} region="">
                    Region
                  </option>
                  {Allregion.length > 0 &&
                    Allregion.map((item, i) => {
                      return (
                        <option
                          id={i + 1}
                          key={i}
                          value={item.value}
                          region={item.value}
                        >
                          {item.value}
                        </option>
                      );
                    })}
                </Input>
              </span>
            </div>
            <div className="store">
              <span>
                <b>Store Name: </b>
              </span>
              <span className="store-check">
                <Input
                  {...storeInputProps}
                  value={this.state.loginStoreId}
                  onChange={this.handleStoreChange}
                >
                  <option id="0" value={null} outlet="">
                    Store
                  </option>
                  {AllStore.length > 0 &&
                    AllStore.map((item, i) => {
                      return (
                        <option
                          id={i + 1}
                          key={i}
                          value={item.storeNumber}
                          outlet={item.outletNumber}
                        >
                          {item.store}
                        </option>
                      );
                    })}
                </Input>
              </span>
            </div>

            <button
              size="lg"
              className="iss-login-button"
              block="true"
              onClick={this.handleSubmit}
            >
              Login
            </button>
          </div>
        )}
      </div>
    );
  }
}

export default BDFLandingLegacyPage;
