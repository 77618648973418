import React from 'react';
import step1 from './step1.png';
import step2 from './step2.png';
import step3 from './step3.png';

class TabletHelp extends React.Component {
  render() {
    return (
      <div style={{ fontSize: '1.2rem', margin: '10px' }}>
        For more accurate tablet view, we use browser&apos;s &quot;Inspect
        Element&quot;
        <br />
        1. Right click on any blank space, and choose Inspect Element
        <br />
        <img src={step1} alt="step1" />
        <br />
        2. Make sure the &apos;Toggle device emulation&apos; is turned on.
        <br />
        3. Change Dimension to 1200 x 750 (near to Surface Go screen size)
        <br />
        <img src={step2} alt="step2" />
        <br />
        4. Expected view
        <br />
        <img src={step3} alt="step3" />
      </div>
    );
  }
}

export default TabletHelp;

/*
- setup IIS api for window auth
- setup both web & app SSL


*/
