import React, { createContext, useState } from 'react';

export const CommunicationContext = createContext({
  headerUnread: 0,
  headerNotAck: 0,
  setHeaderUnread: () => {},
  setHeaderNotAck: () => {},
});

export const CommunicationProvider = ({ children }) => {
  const [headerUnread, setHeaderUnread] = useState(0);
  const [headerNotAck, setHeaderNotAck] = useState(0);

  return (
    <CommunicationContext.Provider
      value={{ headerUnread, headerNotAck, setHeaderUnread, setHeaderNotAck }}
    >
      {children}
    </CommunicationContext.Provider>
  );
};
