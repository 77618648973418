import {
  Box,
  ClickAwayListener,
  IconButton,
  Popper,
  MenuItem,
} from '@mui/material';
import React, { useState, useEffect, useContext } from 'react';

//Custom Components
import {
  BeginOpportunityModal,
  CloseOpportunityModal,
  OpportunityInformationModal,
  TransferOpportunityModal,
  UnavailableOpportunityModal,
  beginOpportunityActions,
  pendingOpportunityAction,
  unavailableActions,
  CheckInTracking,
  // fetchManagerCheckOut,
} from './opportunity-modal';
import OpportunityRemoveDialog from './opportunity-remove-dialog';
import UserQuestionView from '../setups/DispositionQuestion/user-view-disposition-question';

//Icons
import {
  BsFillPauseFill,
  BsFillPlayFill,
  BsInfoCircleFill,
} from 'react-icons/bs';
import { MdMeetingRoom, MdOutlineCancel, MdMoreVert } from 'react-icons/md';
import { BiTransferAlt } from 'react-icons/bi';
import { ImMoveUp, ImMoveDown } from 'react-icons/im';

//Context
import { OpportunityContext } from './opportunity-context';
import { ModalBtnClose } from '../../components/CommonComponent';
import { IoMdAdd, IoMdRemove } from 'react-icons/io';

const helper = require('../../utils/helper');
const enums = require('../../enums');
const ux = require('../../application-center/ux-tracking-center');

export default function OpportunityFloor({
  queueFloor,
  queue,
  engageOpportunity,
  disengageOpportunity,
  changeOpportunitySequence,
  changeOpportunityStatus,
  exitOpportunity,
  associateInfoStack,
  getCurrentUserPosition,
  checkInEmpIdRef,
  questions = [],
}) {
  const { currentUserInformation } = useContext(OpportunityContext);

  const [data, setData] = useState([]);
  const [associateToProcess, setAssociateToProcess] = useState({});
  const [associateInfoModalToProcess, setAssociateInfoModalToProcess] =
    useState(null);
  const [associateTransferModalToProcess, setAssociateTransferModalToProcess] =
    useState(null);
  const [uidToProcess, setUIDToProcess] = useState();
  const [toggleBeginModal, setToggleBeginModal] = useState(false);
  const [toggleCloseModal, setToggleCloseModal] = useState(false);
  const [toggleUnavailableModal, setToggleUnavailableModal] = useState(false);
  const [toggleInfoModal, setToggleInfoModal] = useState(false);
  const [toggleTransferModal, setToggleTransferModal] = useState(false);
  const [toggleUserInfoPopper, setToggleUserInfoPopper] = useState(false);
  const [userInfoPopper, setUserInfoPopper] = useState(null);
  const [closeRateInfo, setCloseRateInfo] = useState(null);
  const [isStack, setIsStack] = useState(false);
  const [isRemoveDialogOpen, setRemoveDialogOpen] = useState(false);
  const [toRemoveOpportunity, setToRemoveOpportunity] = useState(null);
  const [isQuestionModalOpen, setIsQuestionModalOpen] = useState(false);
  const [opportunityTicket, setOpportunityTicket] = useState('');
  const [anchorEl, setAnchorEl] = useState({});

  useEffect(() => {
    if (isQuestionModalOpen) {
      console.log('Questions passed to OpportunityFloor:', questions);
    }
  }, [isQuestionModalOpen, questions]);

  const handleCloseOpportunity = () => {
    setToggleCloseModal(!toggleCloseModal);
    setIsQuestionModalOpen(true);
  };

  //Checked-in State
  const [checkedInData, setCheckedInData] = useState({});

  useEffect(() => {
    setData(queue);
    getOpenedUserInfoPopper();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queue]);

  useEffect(() => {
    const shouldHideScrollBarOverflow = (hide) => {
      let drawerPaperElement = document.getElementById(
        'opportunity-list-drawer',
      );

      if (drawerPaperElement) {
        let paperElement = drawerPaperElement.childNodes[2];

        if (paperElement) {
          if (hide) {
            paperElement.style.overflow = 'hidden';
          } else {
            paperElement.style.overflow = 'auto';
          }
        }
      }
    };

    if (!toggleBeginModal && !toggleCloseModal && !toggleUnavailableModal) {
      setAssociateToProcess(null); //To reset once modal closed
      setUIDToProcess(null);
      shouldHideScrollBarOverflow(false);
    } else {
      shouldHideScrollBarOverflow(true);
    }
  }, [
    toggleBeginModal,
    toggleCloseModal,
    toggleUnavailableModal,
    toggleInfoModal,
  ]);

  useEffect(() => {
    if (!queueFloor && checkInEmpIdRef && queue.length > 0) {
      let checkInData = queue.find((x) => x.employeeID === checkInEmpIdRef);

      setCheckedInData({
        uid: checkInData.uid,
        employeeID: checkInData.employeeID,
        employeeName: checkInData.employeeName,
      });
    }

    if (!checkInEmpIdRef) {
      setCheckedInData(null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkInEmpIdRef, queue]);

  const getOpenedUserInfoPopper = () => {
    // Select all elements with IDs starting with "user-info-popper-"
    const popperElements = document.querySelectorAll(
      '[id^="user-info-popper-"]',
    );

    if (popperElements.length > 0 && currentUserInformation.isManager) {
      setToggleUserInfoPopper(false);
      setUserInfoPopper(null);
      setCloseRateInfo(null);

      // Now, popperElements is a NodeList containing all matching elements
      // Loop through the elements or perform any other operations you need
      popperElements.forEach((element) => {
        setTimeout(() => {
          // Use a regular expression to extract the numeric part 'user-info-popper-2' return uid 2
          const match = element.id.match(/\d+/);

          // Extract the matched number (if any)
          const numberPart = match ? match[0] : null;

          let anchorElem = document.getElementById(
            'user-info-anchor-popper-' + numberPart,
          );

          if (anchorElem) {
            anchorElem.click();
          }
        }, 1000);
      });
    }
  };

  const calculateElapsedMinutes = (startTime) => {
    let start = new Date(startTime);
    let now = new Date();

    const elapsedTimeInMilliseconds = now - start;
    const elapsedTimeInMinutes = Math.floor(elapsedTimeInMilliseconds / 60000);
    return elapsedTimeInMinutes;
  };

  const DisplayEngagedDuration = ({
    startTime,
    queueFloor,
    userAction,
    position,
  }) => {
    const [elapsedMinutes, setElapsedMinutes] = useState(
      calculateElapsedMinutes(startTime),
    );

    useEffect(() => {
      const interval = setInterval(() => {
        const newElapsedMinutes = calculateElapsedMinutes(startTime);
        setElapsedMinutes(newElapsedMinutes);
      }, 60000);

      return () => clearInterval(interval);
    }, [startTime]);

    if (
      queueFloor &&
      ![
        unavailableActions.lunchBreak,
        unavailableActions.crmActivity,
        unavailableActions.notAvailable,
        unavailableActions.assistanceRequested,
        unavailableActions.associateUnavailable,
      ].includes(userAction) && //To show all unavailable status duration
      position > 0
    ) {
      return null;
    } else {
      return (
        <div className="small-details">Duration: {elapsedMinutes} min</div>
      );
    }
  };

  const getOpportunityCloseRate = async (userData) => {
    setUserInfoPopper(userData);

    let url =
      helper.getSignalRHost() +
      `/api/OpportunityList/GetOpportunityCloseRate?EmployeeId=${
        userData.employeeID
      }&timezoneOffset=${new Date().getTimezoneOffset()}`;
    await fetch(url, { method: 'GET', ...helper.apiHeaders() })
      .then(async (response) => {
        let data = await response.json();

        if (data.status === 'OK') {
          setToggleUserInfoPopper(!toggleUserInfoPopper);
          setCloseRateInfo(data.result);
        }
      })
      .catch((error) => {
        window.message.notification(
          'Unable to get Opportunity Close Rate',
          enums.notificationType.E,
        );
        ux.logError(
          'GuestConnect',
          'GetOpportunityCloseRate',
          'Unable to get data from API',
          JSON.stringify(error),
        );
      })
      .finally(() => {});
  };

  return (
    <>
      <div className={`${!queueFloor && 'custom-grid'}`}>
        {data.map((x, index) => (
          <Box
            key={x.uid}
            className={`${
              x.employeeID === currentUserInformation.empId ? 'own' : ''
            }`}
            style={{ gridRow: `span ${x.stackData.length}` }}
          >
            <div
              className={`floor-avatar ${
                x.employeeCheckedIn === 0 &&
                x.floorType === 'ENGAGE' &&
                currentUserInformation.isManager
                  ? 'shadeflags'
                  : ''
              }
              ${
                !x.isActive
                  ? 'inactive'
                  : x.userAction === 'Ghost'
                    ? 'ghost'
                    : ''
              }`}
            >
              {/* Content */}
              {x.stackData.map((stack, stackIndex) => {
                return (
                  <React.Fragment key={stackIndex}>
                    <div className="floor-avatar-stack">
                      {/* Show Queue Number */}
                      {queueFloor && stack.isActive && (
                        <div className="queue-counter">
                          {getCurrentUserPosition(stack.employeeID, false)}
                        </div>
                      )}

                      <div
                        className={`floor-avatar-content ${
                          (currentUserInformation.isManager ||
                            currentUserInformation.empId ===
                              stack.employeeID) &&
                          'manager-highlight'
                        }`}
                        id={'user-info-anchor-popper-' + stack.uid}
                        onClick={() => {
                          if (
                            currentUserInformation.isManager ||
                            currentUserInformation.empId === stack.employeeID
                          ) {
                            //This is only for manager & current user only
                            if (!toggleUserInfoPopper) {
                              getOpportunityCloseRate(stack);
                            } else {
                              //Closing
                              setCloseRateInfo(null);
                              setToggleUserInfoPopper(!toggleUserInfoPopper);
                            }
                          }
                        }}
                      >
                        {stackIndex === 0 && (
                          <div className="name">{stack.employeeName}</div>
                        )}

                        <div className="small-details">
                          {(stack.opportunityTitle ||
                            stack.numberOfParty > 0) && (
                            <span>
                              {stack.customerNm && stack.customerNm + ' - '}
                              {stack.opportunityTitle &&
                                stack.opportunityTitle + ','}{' '}
                              party of {stack.numberOfParty} <br />
                            </span>
                          )}

                          {queueFloor &&
                          stack.userAction ===
                            unavailableActions.associateUnavailable &&
                          stack.modifiedBy &&
                          (currentUserInformation.isManager ||
                            currentUserInformation.empId === stack.employeeID)
                            ? `${stack.userAction} by ${stack.modifiedByName}` //For queue floor of Associate Unavailable for Manager or Self to display modified by
                            : stack.userAction === 'Ghost'
                              ? stack.firstUserAction // For Ghost record, show first user action
                              : (stack.userAction || '') +
                                `${
                                  !queueFloor &&
                                  stack.modifiedBy !== stack.employeeID
                                    ? ` by ${stack.modifiedByName}`
                                    : ''
                                }`}
                        </div>

                        <DisplayEngagedDuration
                          startTime={
                            new Date(
                              stack.startAction ||
                                stack.startQueueFirstPosition,
                            ) > new Date()
                              ? new Date()
                              : stack.startAction ||
                                stack.startQueueFirstPosition ||
                                new Date()
                          }
                          queueFloor={queueFloor}
                          userAction={
                            stack.userAction === 'Ghost'
                              ? stack.firstUserAction
                              : stack.userAction
                          }
                          position={index}
                        />
                      </div>

                      {/* Actionables */}
                      <div className="floor-actionables">
                        {/* Actionables for Queue Floor */}
                        {((queueFloor && stack.userAction !== 'Ghost') ||
                          stack.firstUserAction ===
                            unavailableActions.assistanceRequested) && (
                          <div>
                            {/* Only manager able to remove associate from queue */}
                            {currentUserInformation.isManager && (
                              <IconButton
                                aria-label="Remove"
                                disableRipple={true}
                                onClick={() => {
                                  setRemoveDialogOpen(true);
                                  setToRemoveOpportunity(stack);
                                }}
                              >
                                <MdOutlineCancel title="Remove" />
                              </IconButton>
                            )}

                            {stack.isActive ? (
                              <>
                                <IconButton
                                  aria-label="Engage"
                                  disableRipple={true}
                                  onClick={() => {
                                    stack.walkInEnable =
                                      index === 0 ||
                                      (index === 1 &&
                                        localStorage.getItem(
                                          'isMultiEntrancesStore',
                                        ) === 'true')
                                        ? true
                                        : false; //Overwrite local walkInEnable just to check Begin Opportunity "Walk-in" should hide or not on auto opened begin opportunity modal.
                                    setAssociateToProcess(stack.employeeID);
                                    setAssociateInfoModalToProcess(stack);
                                    setToggleBeginModal(
                                      //If current user is manager, do not open begin opportunity modal when
                                      //  --> It's for own record since we'll assign and unattended guest
                                      //Don't allow other users to add opportunity type
                                      (currentUserInformation.isManager &&
                                        !currentUserInformation.isSnrGXS &&
                                        !currentUserInformation.isAssociate &&
                                        stack.employeeID ===
                                          currentUserInformation.empId) ||
                                        stack.employeeID !==
                                          currentUserInformation.empId
                                        ? false
                                        : true,
                                    );
                                    //Open guest info modal for manager auto-unattended guest
                                    setToggleInfoModal(
                                      currentUserInformation.isManager &&
                                        !currentUserInformation.isSnrGXS &&
                                        !currentUserInformation.isAssociate &&
                                        stack.employeeID ===
                                          currentUserInformation.empId
                                        ? true
                                        : false,
                                    );
                                    engageOpportunity(
                                      stack.employeeID,
                                      currentUserInformation.isManager &&
                                        !currentUserInformation.isSnrGXS &&
                                        !currentUserInformation.isAssociate &&
                                        stack.employeeID ===
                                          currentUserInformation.empId
                                        ? beginOpportunityActions.unattendedGuest
                                        : pendingOpportunityAction,
                                      '',
                                      //WalkInEnable tracker
                                      index === 0 ||
                                        (index === 1 &&
                                          localStorage.getItem(
                                            'isMultiEntrancesStore',
                                          ) === 'true')
                                        ? true
                                        : false,
                                    );
                                  }}
                                >
                                  <IoMdAdd title="Engage" />
                                </IconButton>

                                <IconButton
                                  aria-label="Unavailable"
                                  disableRipple={true}
                                  onClick={() => {
                                    setAssociateToProcess(stack.employeeID);
                                    setToggleUnavailableModal(true);
                                  }}
                                >
                                  <BsFillPauseFill title="Unavailable" />
                                </IconButton>
                              </>
                            ) : (
                              (currentUserInformation.isManager ||
                                (currentUserInformation.empId ===
                                  stack.employeeID &&
                                  stack.userAction !==
                                    unavailableActions.associateUnavailable)) && (
                                <IconButton
                                  aria-label="Rejoin"
                                  disableRipple={true}
                                  onClick={() => {
                                    changeOpportunityStatus(
                                      stack.employeeID,
                                      null,
                                    );
                                  }}
                                >
                                  <BsFillPlayFill title="Rejoin" />
                                </IconButton>
                              )
                            )}

                            {/* Only show move up from 2nd place */}
                            {index !== 0 &&
                              stack.isActive &&
                              currentUserInformation.isManager && (
                                <IconButton
                                  aria-label="Move up"
                                  disableRipple={true}
                                  onClick={() => {
                                    changeOpportunitySequence(
                                      stack.employeeID,
                                      true,
                                    );
                                  }}
                                >
                                  <ImMoveUp title="Move up" />
                                </IconButton>
                              )}

                            {/* Only show move down from 2nd last place and the queue is active */}
                            {index !== queue.length - 1 &&
                              (queue[index + 1]?.isActive ||
                                queue[index + 1]?.firstUserAction ===
                                  unavailableActions.assistanceRequested) &&
                              stack.isActive &&
                              currentUserInformation.isManager && (
                                <IconButton
                                  aria-label="Move down"
                                  disableRipple={true}
                                  onClick={() => {
                                    changeOpportunitySequence(
                                      stack.employeeID,
                                      false,
                                    );
                                  }}
                                >
                                  <ImMoveDown title="Move down" />
                                </IconButton>
                              )}
                          </div>
                        )}

                        {/* Actionables for Engaging Floor */}
                        {!queueFloor && (
                          <div className="more-buttons-manager">
                            {/* Check-In Tracking button */}
                            {stackIndex === 0 &&
                              currentUserInformation.isManager && (
                                <IconButton
                                  aria-label="Check-In Tracking"
                                  disableRipple={true}
                                  onClick={() => {
                                    setCheckedInData({
                                      uid: stack.uid,
                                      employeeID: stack.employeeID,
                                      employeeName: stack.employeeName,
                                    });
                                  }}
                                >
                                  <MdMeetingRoom title="Check-In Tracking" />
                                </IconButton>
                              )}

                            {/* Dropdown Menu for other buttons */}
                            {currentUserInformation.isManager ? (
                              <div>
                                <IconButton
                                  aria-label="See More"
                                  disableRipple={true}
                                  onClick={(e) =>
                                    setAnchorEl({
                                      [stack.uid]: e.currentTarget,
                                    })
                                  }
                                >
                                  <MdMoreVert />
                                </IconButton>
                                <Popper
                                  anchorEl={anchorEl[stack.uid]}
                                  open={Boolean(anchorEl[stack.uid])}
                                  onClose={() =>
                                    setAnchorEl({ [stack.uid]: null })
                                  }
                                  className="custom-popper"
                                  placement="bottom-start"
                                  modifiers={[
                                    {
                                      name: 'offset',
                                      options: {
                                        offset: [0, 0],
                                      },
                                    },
                                    {
                                      name: 'flip',
                                      enabled: false,
                                    },
                                  ]}
                                >
                                  <ClickAwayListener
                                    onClickAway={() =>
                                      setAnchorEl({ [stack.uid]: null })
                                    }
                                  >
                                    <Box className="custom-menu">
                                      {/* Only manager able to disengage & remove associate from engagement */}
                                      <MenuItem
                                        onClick={() => {
                                          setRemoveDialogOpen(true);
                                          setToRemoveOpportunity(stack);
                                          setAnchorEl({ [stack.uid]: null });
                                        }}
                                        className="menu-item"
                                      >
                                        <MdOutlineCancel
                                          title="Disengage & Remove"
                                          className="menu-icon"
                                        />
                                        Disengage & Remove
                                      </MenuItem>

                                      {/* Info button for managers */}
                                      {stack.userAction !==
                                        pendingOpportunityAction && (
                                        <MenuItem
                                          onClick={() => {
                                            if (
                                              stack.userAction ===
                                              pendingOpportunityAction
                                            ) {
                                              setAssociateToProcess(
                                                stack.employeeID,
                                              );
                                              setAssociateInfoModalToProcess(
                                                stack,
                                              );
                                              setToggleBeginModal(true);
                                              setIsStack(stackIndex !== 0); // To handle stack on Pending Opportunities
                                            } else {
                                              setAssociateInfoModalToProcess(
                                                stack,
                                              );
                                              setToggleInfoModal(
                                                !toggleInfoModal,
                                              );
                                            }
                                            setAnchorEl({ [stack.uid]: null });
                                          }}
                                          className="menu-item"
                                        >
                                          <BsInfoCircleFill
                                            title="Info"
                                            className="menu-icon"
                                          />
                                          Info
                                        </MenuItem>
                                      )}

                                      {x.stackData.every(
                                        (d) =>
                                          d.userAction !==
                                          pendingOpportunityAction,
                                      ) && (
                                        // Only show below Transfer, Disengage and Stack if there are no pending opportunity for every records
                                        <>
                                          {/* Only manager and self can transfer their opportunity */}
                                          <MenuItem
                                            onClick={() => {
                                              setAssociateTransferModalToProcess(
                                                stack,
                                              );
                                              setToggleTransferModal(
                                                !toggleTransferModal,
                                              );
                                              setAnchorEl({
                                                [stack.uid]: null,
                                              });
                                            }}
                                            className="menu-item"
                                          >
                                            <BiTransferAlt
                                              title="Transfer"
                                              className="menu-icon"
                                            />
                                            Transfer
                                          </MenuItem>

                                          {/* Only self can disengage */}
                                          {stack.employeeID ===
                                            currentUserInformation.empId && (
                                            <MenuItem
                                              onClick={() => {
                                                setAssociateToProcess(
                                                  stack.employeeID,
                                                );
                                                setUIDToProcess(stack.uid);
                                                setOpportunityTicket(
                                                  stack.opportunityTicket,
                                                );
                                                console.log(
                                                  'Questions passed to OpportunityFloor:',
                                                  stack.questions,
                                                  stack.opportunityTicket,
                                                );
                                                setToggleCloseModal(true);
                                                handleCloseOpportunity();
                                                setIsQuestionModalOpen(false);
                                                setAnchorEl({
                                                  [stack.uid]: null,
                                                });
                                              }}
                                              className="menu-item"
                                            >
                                              <IoMdRemove
                                                title="Disengage"
                                                className="menu-icon"
                                              />
                                              Disengage
                                            </MenuItem>
                                          )}

                                          {/* Only manager or self can stack */}
                                          {stackIndex === 0 && (
                                            <MenuItem
                                              onClick={() => {
                                                setAssociateToProcess(
                                                  stack.employeeID,
                                                );
                                                setAssociateInfoModalToProcess(
                                                  null,
                                                ); // To use from API --> associateInfoStack
                                                setToggleBeginModal(true);
                                                setIsStack(true);
                                                engageOpportunity(
                                                  stack.employeeID,
                                                  pendingOpportunityAction,
                                                );
                                                setAnchorEl({
                                                  [stack.uid]: null,
                                                });
                                              }}
                                              className="menu-item"
                                            >
                                              <IoMdAdd
                                                title="Stack"
                                                className="menu-icon"
                                              />
                                              Stack
                                            </MenuItem>
                                          )}
                                        </>
                                      )}
                                    </Box>
                                  </ClickAwayListener>
                                </Popper>
                              </div>
                            ) : (
                              <>
                                {stack.employeeID ===
                                  currentUserInformation.empId && (
                                  <div>
                                    {/* Info button for self-info */}

                                    <IconButton
                                      aria-label="Info"
                                      disableRipple={true}
                                      onClick={() => {
                                        setAssociateInfoModalToProcess(stack);
                                        setToggleInfoModal(!toggleInfoModal);
                                      }}
                                    >
                                      <BsInfoCircleFill title="Info" />
                                    </IconButton>

                                    {/* Only show below Disengage and Stack if there are no pending opportunity for every records*/}
                                    {x.stackData.every(
                                      (d) =>
                                        d.userAction !==
                                        pendingOpportunityAction,
                                    ) && (
                                      <>
                                        {/* Disengage button for self-disengage */}
                                        <IconButton
                                          aria-label="Disengage"
                                          disableRipple={true}
                                          onClick={() => {
                                            setAssociateToProcess(
                                              stack.employeeID,
                                            );
                                            setUIDToProcess(stack.uid);
                                            setOpportunityTicket(
                                              stack.opportunityTicket,
                                            );
                                            setToggleCloseModal(true);
                                            handleCloseOpportunity();
                                            setIsQuestionModalOpen(false);
                                          }}
                                        >
                                          <IoMdRemove title="Disengage" />
                                        </IconButton>

                                        {/* Stack button for self-stack */}
                                        {stackIndex === 0 && (
                                          <IconButton
                                            aria-label="Stack"
                                            disableRipple={true}
                                            onClick={() => {
                                              setAssociateToProcess(
                                                stack.employeeID,
                                              );
                                              setAssociateInfoModalToProcess(
                                                null,
                                              );
                                              setToggleBeginModal(true);
                                              setIsStack(true);
                                              engageOpportunity(
                                                stack.employeeID,
                                                pendingOpportunityAction,
                                              );
                                            }}
                                          >
                                            <IoMdAdd title="Stack" />
                                          </IconButton>
                                        )}
                                      </>
                                    )}
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        )}
                      </div>
                    </div>

                    {x.stackData[stackIndex + 1] && <hr />}
                  </React.Fragment>
                );
              })}
            </div>

            {userInfoPopper && (
              <ClickAwayListener
                onClickAway={() =>
                  setToggleUserInfoPopper(!toggleUserInfoPopper)
                }
              >
                <Popper
                  id={`user-info-popper-` + userInfoPopper.uid}
                  open={toggleUserInfoPopper}
                  anchorEl={document.getElementById(
                    'user-info-anchor-popper-' + userInfoPopper.uid,
                  )}
                  placement="right-start"
                  className="user-info-popper"
                  disablePortal={true}
                >
                  <div>
                    <h6>{userInfoPopper.employeeName} Opportunities</h6>
                    <ModalBtnClose
                      onClick={() =>
                        setToggleUserInfoPopper(!toggleUserInfoPopper)
                      }
                    />
                  </div>
                  <div>
                    <span>Total Guests: {closeRateInfo?.totalGuest || 0}</span>
                    <br />
                    <span>
                      Total Sales Opportunities:{' '}
                      {closeRateInfo?.totalOpportunities || 0}
                    </span>
                    <br />
                    <span>Total Sales: {closeRateInfo?.totalSales || 0}</span>
                    <br />
                    <span>
                      Close Rate:{' '}
                      {helper.formatNumber(closeRateInfo?.closeRate || 0, 1)}%
                    </span>
                  </div>
                </Popper>
              </ClickAwayListener>
            )}
          </Box>
        ))}
      </div>

      {/* Begin Opportunity Modal */}
      {toggleBeginModal && (
        <BeginOpportunityModal
          open={toggleBeginModal}
          onClose={() => {
            setToggleBeginModal(!toggleBeginModal);
            setIsStack(false);
          }}
          engageOpportunity={engageOpportunity}
          disengageOpportunity={disengageOpportunity}
          setToggleInfoModal={setToggleInfoModal}
          associateData={associateInfoModalToProcess || associateInfoStack}
          isStack={isStack}
        />
      )}

      {/* Close Opportunity Modal */}
      {toggleCloseModal && (
        <CloseOpportunityModal
          open={toggleCloseModal}
          onClose={() => {
            handleCloseOpportunity();
          }}
          disengageOpportunity={disengageOpportunity}
          associateData={data
            .find((x) => x.employeeID === associateToProcess)
            .stackData.find((x) => x.uid === uidToProcess)}
          isLastRecord={
            data.find((x) => x.employeeID === associateToProcess).stackData
              .length === 1
          }
          opportunityTicket={opportunityTicket}
        />
      )}

      {/* Unavailable Opportunity Modal */}
      {toggleUnavailableModal && (
        <UnavailableOpportunityModal
          open={toggleUnavailableModal}
          onClose={() => setToggleUnavailableModal(!toggleUnavailableModal)}
          changeOpportunityStatus={changeOpportunityStatus}
          associateData={data.find((x) => x.employeeID === associateToProcess)}
        />
      )}

      {/* Guest Information Modal */}
      {toggleInfoModal && (
        <OpportunityInformationModal
          open={toggleInfoModal}
          onClose={() => setToggleInfoModal(!toggleInfoModal)}
          associateData={associateInfoModalToProcess || associateInfoStack}
        />
      )}

      {/* Transfer Opportunity Modal */}
      {toggleTransferModal && (
        <TransferOpportunityModal
          open={toggleTransferModal}
          onClose={() => setToggleTransferModal(!toggleTransferModal)}
          associateData={associateTransferModalToProcess}
        />
      )}

      {/* Check-in Modal */}
      {checkedInData && Object.keys(checkedInData)?.length > 0 && (
        <CheckInTracking
          checkedInData={checkedInData}
          setCheckedInData={setCheckedInData}
          inSession={
            checkedInData &&
            Object.keys(checkedInData)?.length > 0 &&
            checkInEmpIdRef?.length > 0
          }
        />
      )}

      {/* Disposition Question Modal */}
      {isQuestionModalOpen &&
        helper.getUserGroup() !== enums.userGroups.StoreManager &&
        helper.getUserGroup() !== enums.userGroups.SeniorGxs && (
          <UserQuestionView
            showModal={isQuestionModalOpen && questions.length > 0}
            toggleModal={() => setIsQuestionModalOpen(false)}
            questions={questions}
            opportunityTicket={opportunityTicket}
          />
        )}

      <OpportunityRemoveDialog
        isOpen={isRemoveDialogOpen}
        setRemoveDialogOpen={() => {
          setRemoveDialogOpen(false);
          setToRemoveOpportunity(null);
        }}
        callbackConfirmFunc={() => {
          if (toRemoveOpportunity.floorType === 'ENGAGE') {
            disengageOpportunity(
              toRemoveOpportunity.uid,
              toRemoveOpportunity.employeeID,
              'Manager Close',
              true, //Should totally remove
            );
          } else {
            exitOpportunity(toRemoveOpportunity.employeeID);
          }
        }}
        isEngageFloor={
          toRemoveOpportunity && toRemoveOpportunity?.floorType === 'ENGAGE'
        }
      />
    </>
  );
}
