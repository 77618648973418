import React, { useState, useEffect } from 'react';
import BobsStore from '../business-module/img/bobsStorePulse.png';
import Moment from 'moment';
import {
  getAllFeatureNotes,
  updateStorePulse,
  getStorePulseHistory,
  muteStorePulse,
  getNewStorePulseMute,
} from './data.js';
import { ModalBtnClose } from '../../components/CommonComponent';
import { RedButton } from '../../components/CommonComponent';
import Grid from '@mui/material/Grid';
import { Dropdown } from '../../components/CommonComponent';
import { Checkbox } from '@material-ui/core';
import { components } from 'react-select';
import { DataGrid } from '@mui/x-data-grid';
import { FaBell } from 'react-icons/fa6';

const CustomTextArea = ({ label, value, onChange, maxLength }) => {
  return (
    <>
      <b>{label}:</b>
      <span className="char-limit" style={{ float: 'right' }}>
        Remaining characters: {maxLength - value.length}
      </span>
      <textarea
        placeholder="Start typing..."
        value={value}
        maxLength={maxLength}
        onChange={(e) => onChange(e.target.value)}
        style={{ margin: '0px' }}
      ></textarea>
    </>
  );
};

const columns = [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'Reasons', headerName: 'Reasons', width: 300 },
  { field: 'Others', headerName: 'Others', width: 120 },
  { field: 'Traffic', headerName: 'Traffic', width: 120 },
  { field: 'Conversion', headerName: 'Conversion', width: 120 },
  { field: 'AOV', headerName: 'AOV', width: 100 },
];

const reasonsMap = [
  { key: 'isWeather', label: 'Weather' },
  { key: 'isAnchorStoreClosure', label: 'Anchor Store Closure' },
  { key: 'isStaffing', label: 'Staffing' },
  { key: 'isNaturalDisaster', label: 'Natural Disaster' },
  { key: 'isPowerOutage', label: 'Power Outage' },
  { key: 'isConstruction', label: 'Construction' },
  { key: 'isOlderCleanUp', label: 'Older Clean Up' },
  { key: 'isLargeOrderCancellation', label: 'Large Order Cancellation' },
  { key: 'isHolidayShift', label: 'Holiday Shift' },
  { key: 'isOther', label: 'Other' },
];

function getReasons(item) {
  const reasons = [];
  reasonsMap.forEach((reason) => {
    if (item.storePulseInfo[reason.key]) {
      reasons.push(reason.label);
    }
  });
  return reasons;
}

export default function RightContent({
  todayStorePulseData,
  newTodayStorePulse,
}) {
  const [showFeedbackmodule, setShowFeedbackmodule] = useState(false);
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
  const [releaseData, setReleaseData] = useState([]);
  const [latestData, setLatestData] = useState([]);
  const [selectedReasons, setSelectedReasons] = useState([]);
  const [textAreaLimit, setTextAreaLimit] = useState('');
  const [AovTextAreaLimit, setAovTextAreaLimit] = useState('');
  const [conversionTextAreaLimit, setConversionTextAreaLimit] = useState('');
  const [trafficTextAreaLimit, setTrafficTextAreaLimit] = useState('');
  const [rows, setRows] = useState([]);
  const [showMuteModal, setShowMuteModal] = useState(false);
  const [muteReason, setMuteReason] = useState('');
  const [, setIsStorePulseMuted] = useState(false);
  const [currentMuteData, setCurrentMuteData] = useState(null);

  useEffect(() => {
    feactIniatialData();
    fetchStorePulseHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log('Store data6', todayStorePulseData);
  }, [todayStorePulseData]);

  const feactIniatialData = React.useCallback(async () => {
    let data = await getAllFeatureNotes();
    let result = data?.result;
    const maxVersionObject = result.reduce((maxObject, currentObject) => {
      const maxVersion = parseInt(maxObject.version);
      const currentVersion = parseInt(currentObject.version);
      return currentVersion > maxVersion ? currentObject : maxObject;
    });
    setLatestData(maxVersionObject);
    setReleaseData(result);
  }, []);

  const UpdateStorePulse = async () => {
    try {
      const uid = 294;

      const storePulseData = {
        uid: uid,
        salesAssociateId: 'string',
        salesAssociateName: 'string',
        isWeather: selectedReasons.some((reason) => reason.value === 'weather'),
        isAnchorStoreClosure: selectedReasons.some(
          (reason) => reason.value === 'Anchor Store Closure',
        ),
        isStaffing: selectedReasons.some(
          (reason) => reason.value === 'Staffing',
        ),
        isNaturalDisaster: selectedReasons.some(
          (reason) => reason.value === 'Natural Disaster',
        ),
        isPowerOutage: selectedReasons.some(
          (reason) => reason.value === 'Power Outage',
        ),
        isConstruction: selectedReasons.some(
          (reason) => reason.value === 'Construction',
        ),
        isOlderCleanUp: selectedReasons.some(
          (reason) => reason.value === 'Older Clean Up',
        ),
        isLargeOrderCancellation: selectedReasons.some(
          (reason) => reason.value === 'Large Order Cancellation',
        ),
        isHolidayShift: selectedReasons.some(
          (reason) => reason.value === 'Holiday Shift',
        ),
        isOther: selectedReasons.some((reason) => reason.value === 'Other'),
        otherComment: textAreaLimit,
        trafficComment: trafficTextAreaLimit,
        conversionComment: conversionTextAreaLimit,
        aovComment: AovTextAreaLimit,
      };

      const updateResult = await updateStorePulse(storePulseData);

      if (updateResult.success) {
        console.log('StorePulse updated successfully');
      } else {
        console.error('Failed to update storePulse', updateResult.msg);
      }
    } catch (error) {
      console.error('Error updating storePulse', error);
    }
  };

  const fetchStorePulseHistory = async () => {
    try {
      const historyData = await getStorePulseHistory();

      if (historyData.success) {
        const formattedRows = historyData.result.map((item) => {
          const reasons = getReasons(item);

          return {
            id: item.storePulseInfo.uid,
            Reasons: reasons.join(', '),
            Others: getCommentsByReasonType(item, 'Other'),
            Traffic: getCommentsByReasonType(item, 'Traffic'),
            Conversion: getCommentsByReasonType(item, 'Conversion'),
            AOV: getCommentsByReasonType(item, 'AOV'),
          };
        });
        setRows(formattedRows);
        console.log('Reasons:', formattedRows);
      } else {
        console.error('Failed to fetch StorePulse history', historyData.msg);
      }
    } catch (error) {
      console.error('Error fetching StorePulse history', error);
    }
  };

  const getCommentsByReasonType = (item, reasonType) => {
    if (item.storePulseComments) {
      const comments = item.storePulseComments
        .filter((comment) => comment.reasonType === reasonType)
        .map((comment) => comment.comment);
      return comments.join(', ');
    }
    return '';
  };

  const toggleFeedbackmodule = () => {
    setShowFeedbackmodule(!showFeedbackmodule);
  };

  const handleMuteClick = async () => {
    setShowMuteModal(true);
    await toggleMuteStorePulse();
  };

  const toggleHistoryModal = () => {
    setShowHistoryModal(!showHistoryModal);
  };

  const handleButtonClick = () => {
    console.log(releaseData);
    console.log(latestData);
    window.open(latestData?.link);
  };

  const handleSubmitFeedback = async () => {
    setFeedbackSubmitted(true);
    toggleFeedbackmodule();
    await UpdateStorePulse();
  };

  const writtenSalesAmtDV =
    todayStorePulseData?.result?.storePulseInfo?.writtenSalesAmtDV;
  const roundedNumber = Math.round(writtenSalesAmtDV / 2) * 2;
  const downPercentage = Math.abs(roundedNumber);
  console.log('Number2:', roundedNumber);

  const reasonList = [
    { value: 'weather', label: 'Weather' },
    { value: 'Anchor Store Closure', label: 'Anchor Store Closure' },
    { value: 'Natural Disaster', label: 'Natural Disaster' },
    { value: 'Power Outage', label: 'Power Outage' },
    { value: 'Construction', label: 'Construction' },
    { value: 'Order Cleanup', label: 'Order Cleanup' },
    { value: 'Large Order Cancellation', label: 'Large Order Cancellation' },
    { value: 'Holiday Shift', label: 'Holiday Shift' },
    { value: 'other', label: 'Other' },
  ];

  console.log('Store data', todayStorePulseData);

  const toggleMuteStorePulse = async () => {
    try {
      const muteData = await getNewStorePulseMute();
      console.log('Mute data from API:', muteData);
      if (muteData.success) {
        //show
        setCurrentMuteData(muteData.result);
        const muteEndDate = new Date(muteData.result.muteEndDate);
        const currentDate = new Date();
        if (currentDate > muteEndDate) {
          handleAgree(muteData.result);
        }
      } else {
        //notifacio
        //gettoday
        console.error('Failed to get mute data for StorePulse', muteData.msg);
      }
    } catch (error) {
      console.error('Error toggling StorePulse mute', error);
    }
  };

  //if agree null, display windows message something
  const handleAgree = async (passMuteData) => {
    if (!passMuteData) {
      window.message.notification('Mute data is not available.', 'error');
      return;
    }
    console.log('handleAgree is called', passMuteData);
    try {
      const muteData = {
        profitCenterNbr: passMuteData.profitCenterNbr,
        muteDuration: passMuteData.muteDuration,
        muteStartDate: passMuteData.muteStartDate,
        muteEndDate: passMuteData.muteEndDate,
        reason: muteReason,
        salesAssociateId: localStorage.getItem('EmplId'),
        salesAssociateName: localStorage.getItem('EmplName'),
      };

      const muteResponse = await muteStorePulse(muteData);
      console.log('API response', muteResponse);
      if (muteResponse.success) {
        console.log('StorePulse muted successfully');
        setIsStorePulseMuted(true);
        setShowMuteModal(false);
        newTodayStorePulse();
      } else {
        console.error('Failed to mute StorePulse:', muteResponse.msg);
      }
    } catch (error) {
      console.error('Error during mute operation:', error);
    }
  };

  return (
    <div className="app-container">
      <Grid container spacing={2} style={{ height: '100%' }}>
        <Grid
          item
          xs={12}
          className="release-notes-container"
          style={{ height: '40%' }}
        >
          <div className="release-notes">
            <label>Release Notes</label>
            <p>
              Release{' '}
              <b style={{ display: 'contents' }}>{latestData?.version}</b>{' '}
              launched on{' '}
              <b style={{ display: 'contents' }}>
                {Moment(latestData?.updatedTime).format('MM/DD/YYYY')}!
              </b>{' '}
              Click below for the latest release notes or for the full library.
            </p>
          </div>
          <div className="button-group">
            <RedButton label="Release" onClick={handleButtonClick} />
            <RedButton
              label="Library"
              onClick={() =>
                window.open(
                  'http://sharepoint/stores/Store%20Information/Forms/AllItems.aspx?RootFolder=%2Fstores%2FStore%20Information%2FBobBoost%20Release%20Notes&FolderCTID=0x012000E35AFF4BF6C7A245A082188308E8F787',
                  '_blank',
                )
              }
            />
          </div>
        </Grid>

        <Grid
          item
          xs={12}
          className="store-pulse-container"
          style={{ height: '60%' }}
        >
          <label>StorePulse</label>
          {todayStorePulseData.result !== null && !feedbackSubmitted && (
            <FaBell
              onClick={handleMuteClick}
              style={{
                float: 'right',
                marginRight: '10px',
                color: '#ac0505',
                fontSize: '26px',
                cursor: 'pointer',
              }}
            />
          )}
          {showMuteModal && (
            <div className="mute-module">
              <p>
                Are you sure you want to mute StorePulse for 7 days? StorePulse
                will be muted during this period.
              </p>
              <textarea
                placeholder="Reason to mute..."
                value={muteReason}
                onChange={(e) => setMuteReason(e.target.value)}
              />
              <RedButton
                onClick={() => handleAgree(currentMuteData)}
                label="Agree"
              />
              <RedButton
                onClick={() => setShowMuteModal(false)}
                label="Disagree"
              />
            </div>
          )}

          {feedbackSubmitted ? (
            <>
              <p>Thank you for submitting your StorePulse this week!</p>
              <img src={BobsStore} alt="bobs store pulse" />
            </>
          ) : (
            <>
              {todayStorePulseData.result !== null ? (
                <>
                  <p>
                    • BobBoost has noticed that your store's written sales are
                    down{' '}
                    <b style={{ display: 'contents' }}>{downPercentage}%</b>{' '}
                    compared to this week last year. <br />
                    <br />
                    Please click the Feedback button below to provide details on
                    what might be affecting your business.
                  </p>
                  <div className="button-group">
                    <RedButton
                      label="Feedback"
                      onClick={toggleFeedbackmodule}
                    />
                    <RedButton label="History" onClick={toggleHistoryModal} />
                  </div>
                </>
              ) : (
                <>
                  <p>StorePulse is currently muted</p>
                  <img src={BobsStore} alt="bobs store pulse" />
                </>
              )}
            </>
          )}
        </Grid>
      </Grid>

      {/* Feedback modal */}
      {showFeedbackmodule && (
        <div className="feedback-module">
          <ModalBtnClose
            onClick={toggleFeedbackmodule}
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              marginRight: '2px',
              marginTop: '1px',
            }}
          />
          <h3>StorePulse</h3>
          <p>
            BobBoost has noticed that your store's written sales are down{' '}
            <b>{downPercentage}%</b> compared to this week last year.
          </p>
          <p>
            Based on your observations, what do you observe to be affecting your
            business?
          </p>
          <div className="feedback-module_reasons">
            <Dropdown
              id="reasons"
              className="row-input"
              value={selectedReasons}
              options={reasonList}
              onChange={(obj) => {
                setSelectedReasons(obj);
              }}
              placeholder="Choose reasons"
              isMulti
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              isSearchable={false}
              components={{
                Option: ({ innerProps, label, data, isSelected }) => {
                  return (
                    <div
                      {...innerProps}
                      className={`custom-option ${isSelected && 'selected'}`}
                    >
                      <Checkbox
                        checked={isSelected}
                        disableRipple={true}
                        onChange={() => {
                          const updatedReasons = isSelected
                            ? selectedReasons.filter(
                                (reason) => reason.value !== data.value,
                              )
                            : [...selectedReasons, data];

                          setSelectedReasons(updatedReasons);
                        }}
                      />
                      <span className="label">{data.label}</span>
                    </div>
                  );
                },
                MultiValue: ({ children, ...innerProps }) => {
                  const { data } = innerProps;
                  const isFirst =
                    selectedReasons.length > 0 &&
                    selectedReasons[0].value === data.value;

                  return (
                    <components.MultiValueContainer {...innerProps}>
                      {isFirst ? (
                        selectedReasons.length > 1 ? (
                          <div>{selectedReasons.length} selected</div>
                        ) : (
                          children
                        )
                      ) : null}
                    </components.MultiValueContainer>
                  );
                },
              }}
            />
          </div>
          <CustomTextArea
            label="Please add clarifying details"
            value={textAreaLimit}
            onChange={(content) => setTextAreaLimit(content)}
            maxLength={150}
          />

          <CustomTextArea
            label="Traffic"
            value={trafficTextAreaLimit}
            onChange={(content) => setTrafficTextAreaLimit(content)}
            maxLength={150}
          />

          <CustomTextArea
            label="Conversion"
            value={conversionTextAreaLimit}
            onChange={(content) => setConversionTextAreaLimit(content)}
            maxLength={150}
          />

          <CustomTextArea
            label="AOV"
            value={AovTextAreaLimit}
            onChange={(content) => setAovTextAreaLimit(content)}
            maxLength={150}
          />
          <div className="submit-button-container">
            <RedButton label="Submit" onClick={handleSubmitFeedback} />
          </div>
        </div>
      )}

      {/* History modal */}
      {showHistoryModal && (
        <div className="feedback-module" style={{ width: '50%' }}>
          <ModalBtnClose onClick={toggleHistoryModal} />
          <h3>StorePulse History</h3>
          <div
            style={{
              width: '80%',
              height: '80%',
              margin: 'auto',
              marginTop: '50px',
            }}
          >
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[10, 25, 50]}
              pagination
            />
          </div>
        </div>
      )}
    </div>
  );
}
