import { useEffect, useRef, useState } from 'react';
import { HubConnectionBuilder } from '@microsoft/signalr';
import * as signalR from '@microsoft/signalr';
const helper = require('../../utils/helper');

const signalRURL = helper.getSignalRHost() + '/queHub';

const useSignalRConnection = (appToken) => {
  const connectionRef = useRef(null);
  const [isConnected, setConnected] = useState(false);

  useEffect(() => {
    if (isConnected || !appToken) return; // If already connected or no token, do nothing
    console.log('signalR before connection', isConnected);
    const newConnection = new HubConnectionBuilder()
      .withUrl(signalRURL, {
        accessTokenFactory: () => appToken, // Use the provided appToken
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
      })
      .withAutomaticReconnect()
      .build();

    newConnection
      .start()
      .then(() => {
        connectionRef.current = newConnection; // Save the connection to the ref
        setConnected(true);
      })
      .catch((e) => {
        console.log('Connection failed: ', e);
      });
    console.log('signalR after connection', isConnected);
    return () => {
      // Cleanup function to stop the connection when unmounting
      if (connectionRef.current && isConnected) {
        // If connected, stop the connection when unmounting
        connectionRef.current.stop();
      }
    };
  }, [appToken, isConnected]);

  // Use another useEffect to listen for changes in isConnected and perform cleanup
  useEffect(() => {
    return () => {
      if (isConnected && connectionRef.current) {
        // If connected, stop the connection when unmounting
        connectionRef.current.stop();
      }
    };
  }, [isConnected]);

  return { connection: connectionRef.current, isConnected };
};

export default useSignalRConnection;
