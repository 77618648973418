import Cookies from 'js-cookie';
import $ from 'jquery';
import axios from 'axios';

const config = require('../../../config');
const helper = require('../../../utils/helper');
const ux = require('../../ux-tracking-center');
const enums = require('../../../enums');

export const asLocalAdmin = () => {
  return (
    helper.getEnvironment() === 'LOCAL' || helper.getEnvironment() === 'ISS'
  ); // this is for local server use only
};

export const getAccessToken = () => localStorage.getItem('appToken');

export const isAuthenticated = () => {
  return !!getAccessToken();
};

export const doLogin = async (
  username,
  password,
  isSSO,
  empId,
  storeId,
  associatesView = false,
) => {
  resetUserData();

  if (username === 'local') {
    // for local server use
    localStorage.setItem('isAdmin', true);
    return {
      status: true,
      message: '',
      isAdmin: true,
      isFloater: false,
    };
  }

  if (username === 'store') {
    // for local server use
    return {
      status: true,
      message: '',
      isAdmin: false,
      isFloater: true,
    };
  }

  try {
    let logindata = { username: username, password: password };
    let url = '/Login/';

    /*if (helper.isLocal()) {
      isSSO = false;
      url = "/Login/";
      
      if (username)
        logindata = { username: username, password: password };
      else
        logindata = { username: empId, password: "" };
    }*/

    if (isSSO) {
      url = '/getADuser/';

      if (
        storeId !== '' &&
        storeId !== 'null' &&
        storeId !== 'undefined' &&
        storeId !== undefined
      ) {
        if (empId.trim() !== '')
          logindata = {
            impEmp: empId,
            floaterStoreId: storeId,
            external: false,
            associatesView: associatesView,
          };
        else
          logindata = {
            empid: username,
            floaterStoreId: storeId,
            external: false,
            associatesView: associatesView,
          };
      } else {
        storeId = '';
        if (username.trim() !== '')
          logindata = {
            empid: username,
            floaterStoreId: storeId,
            external: false,
            associatesView: associatesView,
          };
        else
          logindata = {
            impEmp: empId,
            floaterStoreId: storeId,
            external: false,
            associatesView: associatesView,
          };
      }
    }

    const requestOptions = {
      crossDomain: true,
      method: 'POST',
      headers: { ...config.api.headers },
      body: JSON.stringify(logindata),
    };

    console.log(helper.getAPIHost() + url, requestOptions);
    return await axios({
      url: helper.getAPIHost() + url,
      method: 'POST',
      data: logindata,
    })
      .then(async (response) => {
        console.log(response);
        let data = response.data;

        const result = data.result;
        // let isAdminLoginSSo = localStorage.getItem("isAdminLoginSSo") || false; // for admin login again using the same id after SSo
        if (data.status === 'OK') {
          const user = result.user;
          if (
            typeof user.userGroupId !== 'undefined' &&
            user.userGroupId !== null &&
            parseInt(user.userGroupId) === enums.userGroups.SystemAdmin &&
            !user.isFloating /*&& !isAdminLoginSSo*/
          ) {
            ux.logAuthenticated(isSSO);
            localStorage.setItem('isAdmin', true);
            // localStorage.setItem("isAdminLoginSSo", true);
            return {
              status: true,
              message: '',
              isAdmin: true,
              isFloater: false,
            };
          } else if (
            typeof user.userGroupId !== 'undefined' &&
            user.userGroupId !== null &&
            user.isFloating &&
            (user.storeId === '' || user.storeId === null)
          ) {
            ux.logAuthenticated(isSSO);
            localStorage.setItem('isFloater', true);
            localStorage.setItem('FloaterID', username);
            return {
              status: true,
              message: '',
              isFloater: true,
              empID: user.emplId,
            };
          } else if (
            typeof user.userGroupId !== 'undefined' &&
            user.userGroupId !== null &&
            user.isFloating &&
            user.storeId === '1' &&
            storeId === ''
          ) {
            // special for store 1
            ux.logAuthenticated(isSSO);
            localStorage.setItem('isFloater', true);
            localStorage.setItem('FloaterID', username);
            return {
              status: true,
              message: '',
              isFloater: true,
              floaterStore: user.storeId,
              empID: user.emplId,
            };
          } else {
            if (user.userGroupId !== 0) {
              //const user = result.user;
              const expDate = new Date(result.tokenExpiration);
              Cookies.set('appToken', result.appToken, { expires: expDate });
              localStorage.setItem('appToken', result.appToken);
              localStorage.setItem(
                'authentication',
                'Bearer ' + result.appToken,
              );
              localStorage.setItem('TokenExpiration', expDate);

              // Employee data
              localStorage.setItem('isTrainer', user.isTrainer);
              localStorage.setItem('isHomeOffice', user.isHomeOffice);
              localStorage.setItem('EmplId', user.emplId);
              localStorage.setItem('EmplName', user.preferredName || user.name); // BCP-2397 - change all name to preferredName
              localStorage.setItem('StoreId', user.storeId || '');
              console.log(
                'StoreId in localstorage',
                localStorage.getItem('StoreId'),
              );

              await getStoreEntrances(user.storeId);

              localStorage.setItem('StoreAddress', user.storeAddress || '');
              localStorage.setItem('OutletId', user.outletId || '');
              localStorage.setItem('StoreName', user.storeName || '');
              localStorage.setItem('JobTitle', user.jobTitle);
              localStorage.setItem('Tier', user.tier || 1); // default to 1
              localStorage.setItem(
                'zoomLevel',
                result.userPerferences?.zoomLevel || 80,
              );
              localStorage.setItem('isB4B', user.isB4B);

              localStorage.setItem('ProfileInitial', user.profileInitial);
              localStorage.setItem('ProfilePicture', user.profilePicture || '');

              localStorage.setItem('UserGroupId', user.userGroupId);
              localStorage.setItem('appVersion', result.appsVersion?.version);
              localStorage.setItem('LoginTime', new Date().getTime());
              localStorage.setItem('IsANOWStore', user.isAnow);
              localStorage.setItem(
                'TertiaryFinancingStore',
                user.tertiaryFinancing,
              );
              localStorage.setItem('storeOpenHour', user.storeHour?.storeOpen);
              localStorage.setItem(
                'storeCloseHour',
                user.storeHour?.storeClose,
              );
              localStorage.setItem('isGXGGroup', user.isGxgGroup);
              localStorage.setItem('timeZone', user.siteTimeZone?.timeZone);
              localStorage.setItem(
                'timeZoneName',
                user.siteTimeZone?.timeZoneName,
              );
              helper.setTimeZone();

              //For Manager bob squied view
              localStorage.setItem('squadStoreId', user.squadStoreId || '');
              localStorage.setItem('isBobSquad', user.isBobSquad || '');
              localStorage.setItem(
                'BobSquidManagerView',
                user.isBobSquadManager || false,
              );
              localStorage.setItem('squadDirector', user.squadDirector);
              localStorage.setItem('isSeniorGXS', user.isSeniorGXS);
              localStorage.setItem('zip', user.zip || '');
              localStorage.setItem('empClass', user.empClass);
              localStorage.setItem('empSubGroup', user.empSubGroup);
              localStorage.setItem(
                'SamAccountName',
                result.user.samAccountName,
              );
              localStorage.setItem('isSalesChat', user.isSalesChat || '');
              // for admin login again using the same id after SSo
              // localStorage.setItem("isAdminLoginSSo", false);

              //for aassocationList storage
              localStorage.setItem('salesAssociateListStorage', []);

              let params = [];
              if (result.parameterSetup) {
                params = result.parameterSetup;
              }

              let paramKeys = {};
              params.map((p, i) => {
                paramKeys[p.paramName] = p.paramValue;
                return 0;
              });
              console.log(paramKeys);
              localStorage.setItem('Parameters', JSON.stringify(paramKeys));

              updateECCAndZendeskParam();

              ux.logAuthenticated(isSSO);

              return {
                status: true,
                message: '',
                isAdmin: false,
                isFloater: false,
              };
            } else {
              ux.logError(
                'Framework',
                'Login',
                'Invalid usergroup from Login',
                JSON.stringify(result),
              );
              return {
                status: false,
                message: '',
                isAdmin: false,
                isFloater: false,
              };
            }
          }
        } else {
          ux.logError(
            'Framework',
            'Login',
            'Invalid username or password',
            JSON.stringify(logindata),
          );
          return {
            status: false,
            message: isSSO ? '' : 'Invalid Username or Password!',
          };
        }
      })
      .catch((error) => {
        ux.logError(
          'Framework',
          'Login',
          'Unable to connect to server!',
          JSON.stringify(logindata),
        );
        console.log(error);
        return {
          status: false,
          message: 'Unable to connect to server!',
        };
      });
  } catch (error) {
    ux.logError('Framework', 'Login', 'Error on login process!', error);
    Promise.resolve({
      status: false,
      message: 'Error on login process!',
    });
  }
};

export const extSSOAccess = async (empId, loginId, appId, source, token) => {
  try {
    let url = '/getADuser/';
    let logindata = {
      impEmp: empId,
      empId: loginId,
      external: true,
      appId: appId,
      source: source,
    };

    /*const requestOptions = {
      crossDomain: true,
      method: 'POST',
      headers: { ...config.api.headers, ...{ Authorization: '' } },
      body: JSON.stringify(logindata),
    };*/
    console.log('ExtSSO', helper.getAPIHost() + url);
    return await axios({
      url: helper.getAPIHost() + url,
      method: 'POST',
      data: logindata,
    })
      //return await fetch(helper.getAPIHost()+url, requestOptions)
      .then(async (response) => {
        console.log(response);
        let data = response.data;

        //console.log(response)
        //return response.json();
        //})
        //.then(data => {
        const result = data.result;
        if (data.status === 'OK') {
          console.log(result);
          const expDate = new Date(result.tokenExpiration);
          localStorage.setItem('appVersion', result.appsVersion?.version);
          localStorage.setItem(
            'external_auth',
            'Bearer ' + result.appToken || '',
          );
          localStorage.setItem('external_auth_exp', expDate.toISOString());
          localStorage.setItem('external_token', result.appToken || '');
          localStorage.setItem('external_EmpId', result.user.emplId || '');
          localStorage.setItem('external_LoginId', loginId || '');
          localStorage.setItem('external_user', JSON.stringify(result.user));
          localStorage.setItem('external_source', source);
          localStorage.setItem('empClass', result.user.empClass);
          localStorage.setItem('empSubGroup', result.user.empSubGroup);
          localStorage.setItem('SamAccountName', result.user.samAccountName);
          let params = [];
          if (result.parameterSetup) {
            params = result.parameterSetup;
          }

          let paramKeys = {};
          params.map((p, i) => {
            paramKeys[p.paramName] = p.paramValue;
            return 0;
          });
          localStorage.setItem('Parameters', JSON.stringify(paramKeys));

          updateECCAndZendeskParam();

          ux.logAuthenticated();

          return {
            status: true,
            message: '',
          };
        } else {
          ux.logError(
            'Framework',
            'ExternalLogin',
            'Invalid Employee ID',
            JSON.stringify(logindata) + '; token ' + token,
          );

          if (empId === undefined || empId === null) {
            return {
              status: false,
              errMsg: 'EmpId is missing!',
            };
          } else {
            return {
              status: false,
              errMsg: `Invalid EmpId (${empId})!`,
            };
          }
        }
      })
      .catch((error) => {
        ux.logError(
          'Framework',
          'ExternalLogin',
          'Unable to connect to server!',
          JSON.stringify(logindata) + '; token ' + token,
        );
        console.log(error);
        return {
          status: false,
          errMsg: 'Unable to connect to server!',
        };
      });
  } catch (error) {
    ux.logError('Framework', 'ExternalLogin', 'Error on login process!', error);
    Promise.resolve({
      status: false,
      errMsg: 'Error on login process!',
    });
  }
};

export const checkSSO = (callback) => {
  console.log('SSO: find username start');
  if (
    asLocalAdmin() &&
    (localStorage.getItem('SSOUser') === '' ||
      localStorage.getItem('SSOUser') === null)
  ) {
    console.log('SSO for local');
    localStorage.setItem('SSOUser', 'local');

    // For normal use
    callback('local', 'SSO (ssoLoginCall): Call from auth.checkSSO');

    // For test no AD found Special
    //callback('', "TO-LOGIN");
    return;
  }

  // if already have userid
  if (
    localStorage.getItem('SSOUser') !== '' &&
    localStorage.getItem('SSOUser') !== null
  ) {
    console.log('SSO from local userid');
    ux.logAction(
      'Framework',
      'Login',
      'SSO-Login with existing user',
      localStorage.getItem('SSOUser'),
    );
    callback(
      localStorage.getItem('SSOUser'),
      'SSO (ssoLoginCall): Call from auth.checkSSO',
    );
    return; //HotFIx8.7
  }

  try {
    let url = '/getLoginUser';
    let host = helper.getAPISSOHost();
    if (host !== '') {
      console.log(host + url);

      /*fetch(host + url)
      .then(async response => {
        console.log(response)
        return response.json();
      })
      .then(data => {
        console.log(data)
      });*/

      $.ajax({
        url: host + url,
        type: 'GET',
        xhrFields: {
          withCredentials: true,
        },
      })
        .done(function (data) {
          console.log(data);
          let user = data.result.id || '';
          let usrArr = user.split('\\');

          if (usrArr.length > 1) {
            let ssoDomain = usrArr[0].toUpperCase();
            let ssoUser = usrArr[1];

            if (
              process.env.REACT_APP_VALID_DOMAIN === '' ||
              ssoDomain === process.env.REACT_APP_VALID_DOMAIN
            ) {
              if (ssoUser.substr(0, 3).toUpperCase() === 'LA_')
                ssoUser = ssoUser.substr(3);
              console.log('SSO-User Name, send to auth: ' + ssoUser);
              localStorage.setItem('SSOUser', ssoUser);
              ux.logAction('Framework', 'Login-SSO-get user id', ssoUser);
              callback(ssoUser, 'SSO (ssoLoginCall): Call from auth.checkSSO');
            } else {
              ux.logError(
                'Framework',
                'Login',
                'SSO-This domain not register for SSO!',
                ssoDomain,
              );
              console.log('SSO-This domain not register for SSO!');
              callback('', 'TO-LOGIN');
            }
          } else {
            ux.logError(
              'Framework',
              'Login',
              'SSO-Username format invalid',
              user,
            );
            console.log('SSO-Username format invalid');
            callback('', 'TO-LOGIN');
          }
        })
        .fail(function (error) {
          ux.logError('Framework', 'Login', 'SSO-HTTP Error (fail)', error);
          console.log('SSO-HTTP Error: ');
          console.log(error);
          callback('', '');
        });
    } else {
      ux.logError(
        'Framework',
        'Login',
        'SSO-Check Host not define',
        'Please setup in config file',
      );
      console.log('SSO-Check Host not define');
      callback('', '');
    }
  } catch (error) {
    ux.logError('Framework', 'Login', 'SSO-Error (catch)', error);
    console.log('SSO-Error: ');
    console.log(error);
    callback('', '');
  }
};

export const checkSSOOldMethod = (callback) => {
  console.log('SSO (Legacy): find username start');

  // if already have userid
  if (
    localStorage.getItem('SSOUser') !== '' &&
    localStorage.getItem('SSOUser') !== null
  ) {
    console.log('SSO from local userid');
    ux.logAction(
      'Framework',
      'Login',
      'SSO-Login with existing user',
      localStorage.getItem('SSOUser'),
    );
    callback(
      localStorage.getItem('SSOUser'),
      'SSO (ssoLoginCall): Call from auth.checkSSO',
    );
    return; //HotFIx8.7
  }

  try {
    let url = '/user';
    let host = helper.getAPISSOHostLegacy();
    if (host !== '') {
      $.ajax({
        url: host + url,
        type: 'GET',
        xhrFields: {
          withCredentials: true,
        },
      })
        .done(function (data) {
          let user = data.userName || '';
          let usrArr = user.split('\\');

          if (usrArr.length > 1) {
            let ssoDomain = usrArr[0].toUpperCase();
            let ssoUser = usrArr[1];

            if (
              process.env.REACT_APP_VALID_DOMAIN === '' ||
              ssoDomain === process.env.REACT_APP_VALID_DOMAIN
            ) {
              if (ssoUser.substr(0, 3).toUpperCase() === 'LA_')
                ssoUser = ssoUser.substr(3);
              console.log('SSO-User Name, send to auth: ' + ssoUser);
              localStorage.setItem('SSOUser', ssoUser);
              ux.logAction('Framework', 'Login-SSO-get user id', ssoUser);
              callback(ssoUser, 'SSO (ssoLoginCall): Call from auth.checkSSO');
            } else {
              ux.logError(
                'Framework',
                'Login',
                'SSO-This domain not register for SSO!',
                ssoDomain,
              );
              console.log('SSO-This domain not register for SSO!');
              callback('', 'TO-LOGIN');
            }
          } else {
            ux.logError(
              'Framework',
              'Login',
              'SSO-Username format invalid',
              user,
            );
            console.log('SSO-Username format invalid');
            callback('', 'TO-LOGIN');
          }
        })
        .fail(function (error) {
          ux.logError('Framework', 'Login', 'SSO-HTTP Error (fail)', error);
          console.log('SSO-HTTP Error: ');
          console.log(error);
          callback('', '');
        });
    } else {
      ux.logError(
        'Framework',
        'Login',
        'SSO-Check Host not define',
        'Please setup in config file',
      );
      console.log('SSO-Check Host not define');
      callback('', '');
    }
  } catch (error) {
    ux.logError('Framework', 'Login', 'SSO-Error (catch)', error);
    console.log('SSO-Error: ');
    console.log(error);
    callback('', '');
  }
};

export const doLogout = async () => {
  console.log('In Logout');
  if (localStorage.getItem('appToken')) {
    const requestOptions = {
      method: 'POST',
      body: {},
      ...helper.apiHeaders(),
    };
    await fetch(helper.getAPIHost() + '/api/Logout/', requestOptions).then(
      async (response) => {
        try {
          console.log('Logout from server');
        } catch (e) {}
      },
    );
  }
  ux.logExit();
  resetUserData();
  localStorage.removeItem('isAdmin');
  localStorage.removeItem('isFloater');
  // for admin login again after SSo
  // localStorage.removeItem('isAdminLoginSSo');
  //history.push("/login")
  setTimeout(function () {
    window.location.href = '/login?fromLogout=logout';
  }, 1000);

  return true;
};

export const resetUserData = () => {
  Cookies.set('appToken', '', { expires: -1 });

  // Token information
  localStorage.removeItem('appToken');
  localStorage.removeItem('TokenExpiration');
  localStorage.removeItem('authentication');
  //localStorage.removeItem("SSOUser");// cannot remove

  // Employee data
  localStorage.removeItem('isTrainer');
  localStorage.removeItem('isHomeOffice');
  localStorage.removeItem('EmplId');
  localStorage.removeItem('EmplName');
  localStorage.removeItem('ProfileInitial');
  localStorage.removeItem('ProfilePicture');
  localStorage.removeItem('StoreId');
  localStorage.removeItem('StoreAddress');

  localStorage.removeItem('OutletId');
  localStorage.removeItem('StoreName');
  localStorage.removeItem('JobTitle');
  localStorage.removeItem('UserGroupId');
  localStorage.removeItem('SalesPerformanceData');
  localStorage.removeItem('FloaterID');
  localStorage.removeItem('zoomLevel');
  localStorage.removeItem('IsANOWStore');
  localStorage.removeItem('TertiaryFinancingStore');
  localStorage.removeItem('storeOpenHour');
  localStorage.removeItem('storeCloseHour');
  localStorage.removeItem('isGXGGroup');
  localStorage.removeItem('isSeniorGXS');
  localStorage.removeItem('isB4B');

  // Setup information
  localStorage.removeItem('FiscalCurrentMonth');
  localStorage.removeItem('FiscalMonths');
  localStorage.removeItem('FiscalMonthsCoaching');
  localStorage.removeItem('FiscalFutureMonths');
  localStorage.removeItem('DashboardKPIs');
  localStorage.removeItem('GaugeUpdated');
  localStorage.removeItem('SalesPerformanceUpdated');
  localStorage.removeItem('SetupMenus'); // admin menus

  localStorage.removeItem('tabCount');

  // Other information
  localStorage.removeItem('CoachingUpdated');
  localStorage.removeItem('coachingData_teams');
  localStorage.removeItem('coachingData');
  localStorage.removeItem('orderLastCheck');
  localStorage.removeItem('notifications');
  localStorage.removeItem('privacyToggle');

  //localStorage.removeItem('Parameters');
  localStorage.removeItem('appVersion');
  localStorage.removeItem('LoginTime');

  //Bob Squad
  localStorage.removeItem('BobSquidManagerView');
  localStorage.removeItem('squadStoreId');
  localStorage.removeItem('isBobSquad');
  localStorage.removeItem('zip');
  localStorage.removeItem('isSalesChat');

  //Feature Release Notes
  localStorage.removeItem('ReleaseNotes');

  //Session Storage
  sessionStorage.removeItem('eccPass');
  sessionStorage.removeItem('zendeskProdAuthToken');
  sessionStorage.removeItem('zendeskDevAuthToken');

  //GuestConnect
  localStorage.removeItem('readNotepadTrackingData');

  localStorage.removeItem('isMultiEntrancesStore');

  //for aassocationList storage
  localStorage.removeItem('salesAssociateListStorage');

  console.log('Data cleared');

  // KPIs
};

//BCP-1528 : To remove from parameters
//BCP-2517 : To remove zendesk credentials from parameters
const updateECCAndZendeskParam = () => {
  let param = JSON.parse(localStorage.getItem('Parameters'));

  let pass = Buffer.from(
    `${param.ECC_PRINT_USERNAME}:${param.ECC_PRINT_PASS}`,
    'utf-8',
  ).toString('base64');
  sessionStorage.setItem('eccPass', pass);

  if (helper.isProd()) {
    let _prodToken = Buffer.from(
      `${param.PROD_ZENDESK_AUTH_TOKEN}`,
      'utf-8',
    ).toString();
    sessionStorage.setItem('zendeskProdAuthToken', _prodToken);
  } else {
    let _devToken = Buffer.from(
      `${param.DEV_ZENDESK_AUTH_TOKEN}`,
      'utf-8',
    ).toString();
    sessionStorage.setItem('zendeskDevAuthToken', _devToken);
  }

  delete param.ECC_PRINT_USERNAME;
  delete param.ECC_PRINT_PASS;
  delete param.DEV_ZENDESK_AUTH_TOKEN;
  delete param.PROD_ZENDESK_AUTH_TOKEN;

  //Remove old Parameters
  localStorage.removeItem('Parameters');

  //Set new parameters
  localStorage.setItem('Parameters', JSON.stringify(param));
};

const getStoreEntrances = async (storeId) => {
  let url =
    helper.getAPIHost() +
    `/api/MDStoreDirectories/getStoreEntrances?store=${storeId}`;

  await fetch(url, { method: 'GET', ...helper.apiHeaders() })
    .then(async (response) => {
      let data = await response.json();

      if (data.status === 'OK' && Object.keys(data.result)?.length > 0) {
        localStorage.setItem('isMultiEntrancesStore', true);
      } else {
        localStorage.setItem('isMultiEntrancesStore', false);
      }
    })
    .catch((error) => {
      localStorage.setItem('isMultiEntrancesStore', false);
      ux.logError(
        'Authentication',
        'getStoreEntrances',
        'Unable to get data from API',
        JSON.stringify(error),
      );
    })
    .finally(() => {});
};

/*export const checkBrowserTab = () => {
  if (isNaN(localStorage.tabCount))
    localStorage.tabCount = 0;
  console.log("TabCount-1:" + +localStorage.tabCount)
  //if (localStorage.tabCount ===  NaN)
  if (+localStorage.tabCount > 1) {
    alert('Opening too many tabs will affect application performance, please complete your work in an existing tab and close it before proceeding.');
  } else {
    //localStorage.tabCount = 0;
  }
  console.log("TabCount-2:" + +localStorage.tabCount)
  localStorage.tabCount = +localStorage.tabCount + 1;

  console.log("TabCount-3:" + +localStorage.tabCount)
  
  window.onunload = function () {
    localStorage.tabCount = +localStorage.tabCount - 1;
    console.log("TabCount-4:" + +localStorage.tabCount)
    if (localStorage.tabCount < 1) {
      console.log("Ext")
      ux.logExit();
    }
  };
}*/

window.onunload = function () {
  ux.logExit(true);
};
