import React, { useState, useEffect, useRef } from 'react';
import './styles.scss';
import { Badge } from 'reactstrap';
import LeftContent from './LeftContent';
import CenterContent from './CenterContent';
import RightContent from './RightContent';
import { AiOutlineBell } from 'react-icons/ai';
import Grid from '@mui/material/Grid';
import {
  notificationSceduler,
  getArchivedNotification,
  getTodayStorePulse,
} from './data.js';
//import { CommunicationContext, CommunicationProvider } from '../communication-collaboration/cncContext';
import { CommunicationProvider } from '../communication-collaboration/cncContext';

export default function BusinessModule() {
  const [showNotifications, setShowNotifications] = useState(false);
  const [notificationList, setNotificationList] = useState([]);
  const [archivedNotificationList, setArchivedNotificationList] = useState([]);
  const [todayStorePulseData, setTodayStorePulseData] = useState([]);
  const [loading, setLoading] = useState(true);
  const modulePopupRef = useRef();
  const [showOverlay, setShowOverlay] = useState(false);

  useEffect(() => {
    const isFirstLoginOfDay = true;
    if (isFirstLoginOfDay) {
      setShowNotifications(true);
    }
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  useEffect(() => {
    fetchNotifications();
  }, []);

  useEffect(() => {
    console.log('Store data1', todayStorePulseData);
  }, [todayStorePulseData]);

  const fetchNotifications = async () => {
    try {
      const notifications = await notificationSceduler();
      const archivedNotifications = await getArchivedNotification();
      const todayStorePulse = await getTodayStorePulse();

      console.log('Store data4:', todayStorePulse);
      setNotificationList(notifications?.result?.generalNotification || []);
      setArchivedNotificationList(
        archivedNotifications?.result?.archivedNotification || [],
      );
      setTodayStorePulseData(todayStorePulse || []);
      console.log('Store data5:', todayStorePulse);
    } catch (error) {
      console.log('Error fetching data', error);
    } finally {
      setLoading(false);
    }
  };

  /*fetchNotifications();s
  }, []);*/
  //   if (showNotifications) {
  //     fetchNotifications();
  //   }
  // }, [showNotifications]);

  const handleClick = (event) => {
    if (
      modulePopupRef.current &&
      !modulePopupRef.current.contains(event.target) &&
      event.target.tagName !== 'svg'
    ) {
      setShowNotifications(false);
    }
  };

  const toggleNotifications = () => {
    setShowNotifications((prevShowNotifications) => !prevShowNotifications);
    setShowOverlay((prevShowOverlay) => !prevShowOverlay);
  };

  const getProperty = () => {
    fetchNotifications();
  };

  useEffect(() => {
    console.log('Store data8', todayStorePulseData);
  }, [todayStorePulseData]);

  const newTodayStorePulseData = async () => {
    const todayStorePulse = await getTodayStorePulse();
    if (todayStorePulse) {
      setTodayStorePulseData(todayStorePulse || []);
    }
  };

  return (
    <div
      className={`business-module-container ${
        showNotifications ? 'overlay-visible' : ''
      }`}
    >
      <div id="pop-notification" className="notification-icon">
        <div className="overlay" onClick={toggleNotifications}></div>
        <div className="click" onClick={toggleNotifications}></div>
        <AiOutlineBell
          style={{ marginRight: '4px', marginLeft: '-8px', marginTop: '8px' }}
          size={22}
          className={notificationList.length ? 'animated swing infinite' : ''}
        />
        {notificationList.length > 0 && (
          <Badge style={{ marginTop: '8px' }} color="success">
            {notificationList.length}
          </Badge>
        )}
      </div>

      {showOverlay && <div className="overlay-content"></div>}

      {showNotifications && (
        <div className="module-popup" ref={modulePopupRef}>
          <div className="app-container">
            {loading ? (
              <div>Loading...</div>
            ) : (
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <LeftContent
                    notification={notificationList}
                    archivedNotification={archivedNotificationList}
                    getProperty={getProperty}
                    setShowNotifications={setShowNotifications}
                  />
                </Grid>
                <Grid item xs={4}>
                  <CommunicationProvider>
                    {/* <CommunicationContext
                      headerNotAck={headerNotAck}
                      headerUnread={headerUnread}
                    />  */}
                    <CenterContent />
                  </CommunicationProvider>
                </Grid>
                <Grid item xs={4}>
                  <RightContent
                    todayStorePulseData={todayStorePulseData}
                    newTodayStorePulse={newTodayStorePulseData}
                  />
                </Grid>
              </Grid>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
