import React from 'react';
import sign from 'jwt-encode';
import './test-iframe.scss';
import Cookies from 'js-cookie';

class ExternalTestPage extends React.Component {
  state = {
    isLoading: 1,
    appId: 'bbe2e3fb-2dad-4ed9-9c29-c78ba116b81f',
    source: 'bobsuite',
    empId: '',
    loginId: '',

    orderNum: '',
    custId: '',
    custName: 'dummy name',
    orderType: '',

    token: {},
    url: '',
  };

  // URL:
  // http://localhost:3000/orderTimeline?orderNum=16382742&custId=8704067&custName=FELIZ%20RODRIGUEZ%20/ZOILA%20MARMOL&token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBJZCI6ImJiZTJlM2ZiLTJkYWQtNGVkOS05YzI5LWM3OGJhMTE2YjgxZiIsInNvdXJjZSI6ImJvYnN1aXRlIiwiZW1wSWQiOiJBODI1NSJ9.F0MJKuK7lBiRGKUuYR1F9RNwuX1VvChIXIXyWLJn9U8
  componentDidMount() {
    // token format:
    /*let tokenJSON = {
        "appId": "bbe2e3fb-2dad-4ed9-9c29-c78ba116b81f",
        "source": "bobsuite",
        "empId": "A8255"
      }*/

    window.addEventListener('message', (e) => {
      const flag = e.data;
      console.log(flag);
    });
  }

  handleTextChange = (e) => {
    e.preventDefault();
    let n = e.target.id;
    let v = e.target.value;

    this.setState({
      [n]: v,
    });
  };

  go = () => {
    let url = window.location.origin + '/orderTimeline?';

    // check mandatory
    if (
      this.state.appId &&
      this.state.empId &&
      this.state.source &&
      this.state.custId &&
      this.state.custName
    ) {
      let tokenData = {
        appId: this.state.appId,
        source: this.state.source,
        empId: this.state.empId,
        loginId: this.state.loginId,
      };
      const jwt = sign(tokenData, '');

      const params = new URLSearchParams({
        orderNum: this.state.orderNum?.trim() || '',
        custId: this.state.custId?.trim() || '',
        custName: this.state.custName?.trim() || '',
        orderType: this.state.orderType?.trim() || '',

        token: jwt,

        rand: Math.random(),
      });
      console.log(params.toString());
      this.setState(
        {
          url: url + params.toString(),
          token: tokenData,
        },
        () => {
          console.log(this.state.url);
        },
      );
    } else {
      alert('Please fill in Session Info & Mandatory fields');
    }
  };

  goTest = () => {
    this.setState({
      url: 'https://google.com',
    });
  };

  createCookie = () => {
    Cookies.set('testCookie', Math.random(), {
      domain: '.mybobs.com',
    });
    alert('Cookie created');
  };

  readCookie = () => {
    alert('Value = ' + Cookies.get('testCookie'));
  };

  render() {
    if (this.state.isLoading === 1) {
      return (
        <div className="extTest">
          <form>
            <fieldset>
              <legend>Session Info</legend>
              <label>
                EmpId:{' '}
                <input
                  id="empId"
                  value={this.state.empId}
                  onChange={this.handleTextChange}
                />
              </label>
              <label>
                LoginId:{' '}
                <input
                  id="loginId"
                  value={this.state.loginId}
                  onChange={this.handleTextChange}
                />
              </label>
              <label>
                AppID:{' '}
                <input
                  id="appId"
                  className="long"
                  value={this.state.appId}
                  onChange={this.handleTextChange}
                />
              </label>
              <label>
                Source:{' '}
                <input
                  id="source"
                  value={this.state.source}
                  onChange={this.handleTextChange}
                />
              </label>
            </fieldset>
            <fieldset>
              <legend>Mandatory</legend>
              <label>
                Customer #:{' '}
                <input
                  id="custId"
                  value={this.state.custId}
                  onChange={this.handleTextChange}
                />
              </label>
              <label>
                Customer Name:{' '}
                <input
                  id="custName"
                  value={this.state.custName}
                  onChange={this.handleTextChange}
                />
              </label>
            </fieldset>
            <fieldset>
              <legend>Optional</legend>
              <label>
                Order #:{' '}
                <input
                  id="orderNum"
                  value={this.state.orderNum}
                  onChange={this.handleTextChange}
                />
              </label>

              <label>
                Order Type :{' '}
                <input
                  id="orderType"
                  value={this.state.orderType}
                  onChange={this.handleTextChange}
                />
              </label>
            </fieldset>
            <label>
              <input type="button" value="Go" onClick={this.go} />
              <input
                type={'button'}
                value="test other tab trigger unsaved message"
                onClick={this.goTest}
              />

              <input
                type={'button'}
                value="Create Cookie"
                onClick={this.createCookie}
              />

              <input
                type={'button'}
                value="Read Cookie"
                onClick={this.readCookie}
              />
            </label>
          </form>
          {this.state.url && (
            <div>
              <div>
                URL: <pre>{this.state.url}</pre>
              </div>
              <div>
                Token raw data: <pre>{JSON.stringify(this.state.token)}</pre>
              </div>
              <div>Preview</div>
              <iframe
                title="preview"
                className="frameborder"
                src={this.state.url}
                width={800}
                height={800}
              />
            </div>
          )}
        </div>
      );
    } else if (this.state.isLoading === -1) {
      return <div>Access Denied</div>;
    }
  }
}

export default ExternalTestPage;
