import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import { Table, Label, Input, Spinner } from 'reactstrap';
import PropTypes from 'prop-types';

//SCSS
import '../Customer360OrderTimeline.scss';
import * as OrderTimelineIcon from '../../../../assets/img/customer360/OrderTimeline';

//Helper
const helper = require('../../../../utils/helper');
const ux = require('../../../../application-center/ux-tracking-center');

const labelPartialDeliveryCompleted = 'Partial Delivery Completed';
const labelPartialDeliveryScheduled = 'Partial Delivery Scheduled';
const labelWaitingOnInventory =
  'Waiting on Inventory (Suppliers/Warehouse Transfer)';
const labelFullFinalDeliveryScheduled = 'Full/Final Delivery Scheduled';
const labelDeliveredPickup = 'Delivered/Picked Up';
const labelReturnEvenExchange = 'Return/Even Exchange';

class BDFCust360OrderTimeline extends React.Component {
  state = {
    OCTdataList: [],
    custID: '',
    orderNum: '',

    OCTlineFilterValue: '',
    OCTtypeFilterValue: '',
    OCTorderFilterValue: '',

    OCTlineFilter: [],
    OCTtypeFilter: [],
    OCTorderFilter: [],
    NewOCTorderFilter: [],

    //Show Stage Control
    showStageDeliveryCompleted: false,
    showStageDeliveryScheduled: false,
    showStageWaitingInventory: false,
    showStageFullFinal: false,
    showStageDeliveredPickup: false,
    showStageReturnEvenExchange: false,

    //Auto Expand Control
    typeSelected: false,

    //No Data Control
    noData: false,
    apiError: false,
    isLoading: true, //Set first loading as true
  };

  componentDidMount() {
    //console.clear();

    const { custID, focusOrderNumber } = this.props;
    ux.logScreen(
      'Customer Profile',
      'Order Timeline',
      'CustId: ' +
        custID +
        '; OrderNum: ' +
        focusOrderNumber +
        '; path: ' +
        ux.getPath(),
    );
    this.setState(
      {
        custID: custID,
        orderNum: focusOrderNumber,
      },
      () => {
        this.GetOrderTimeline();
      },
    );
  }

  componentDidUpdate() {
    //Do something
  }

  handlefilterChanged = (e) => {
    this.HideAllStage(); //Hide all to re-render

    let name = e.target.name;
    let value = e.target.value;

    if (name === 'OCTorderFilterValue') {
      ux.logScreen(
        'Customer Profile',
        'Order Timeline',
        'CustId: ' +
          this.state.custID +
          '; OrderNum: ' +
          value +
          '; path: ' +
          ux.getPath(),
      );

      this.setState(
        {
          OCTorderFilterValue: value, //Set to selected value
          OCTtypeFilterValue: 'All', //Reset to All stage type
          typeSelected: false, //Collapse all stage
        },
        () => this.ShowHideLogicForStage(),
      );
    }
    if (name === 'OCTtypeFilterValue') {
      if (value === 'All') {
        this.setState(
          {
            typeSelected: false,
            OCTtypeFilterValue: value,
          },
          () => this.ShowHideLogicForStage(),
        );
      } else {
        //Set individual stage to show as true
        if (value === labelPartialDeliveryCompleted) {
          this.setState({ showStageDeliveryCompleted: true });
        }
        if (value === labelPartialDeliveryScheduled) {
          this.setState({ showStageDeliveryScheduled: true });
        }
        if (value === labelWaitingOnInventory) {
          this.setState({ showStageWaitingInventory: true });
        }
        if (value === labelFullFinalDeliveryScheduled) {
          this.setState({ showStageFullFinal: true });
        }
        if (value === labelDeliveredPickup) {
          this.setState({ showStageDeliveredPickup: true });
        }
        if (value === labelReturnEvenExchange) {
          this.setState({ showStageReturnEvenExchange: true });
        }

        this.setState({
          typeSelected: true,
          OCTtypeFilterValue: value,
        });
      }
    }
  };

  HideAllStage() {
    this.setState({
      showStageDeliveryCompleted: false,
      showStageDeliveryScheduled: false,
      showStageWaitingInventory: false,
      showStageFullFinal: false,
      showStageDeliveredPickup: false,
      showStageReturnEvenExchange: false,
    });
  }

  ShowAllStage() {
    this.setState({
      showStageDeliveryCompleted: true,
      showStageDeliveryScheduled: true,
      showStageWaitingInventory: true,
      showStageFullFinal: true,
      showStageDeliveredPickup: true,
      showStageReturnEvenExchange: true,
    });
  }

  ShowHideLogicForStage() {
    let allStage = [
      labelPartialDeliveryCompleted,
      labelPartialDeliveryScheduled,
      labelWaitingOnInventory,
      labelFullFinalDeliveryScheduled,
      labelDeliveredPickup,
      labelReturnEvenExchange,
    ];
    let typeList = []; //For filter type load

    this.HideAllStage(); //Reset visible to all selected stage to false

    //Get current selected order
    this.state.OCTdataList.filter(
      (order) => order.salesDocNbr === this.state.OCTorderFilterValue,
    ).forEach((data) => {
      allStage.forEach((stage) => {
        //Partial Delivery Completed
        if (stage === labelPartialDeliveryCompleted) {
          //Hide if ItemDelivered 0, ItemDelivered = TotalItem and DeliveredOn null
          if (
            data.itemDelivered > 0 &&
            data.itemDelivered < data.totalItem &&
            data.deliveredOn !== null &&
            data.deliveredOn.length > 0
          ) {
            typeList.push(stage); //Push the stage to type filter
            this.setState({
              showStageDeliveryCompleted: true, //Set visible to true
            });
          }
        }
        //Partial Delivery Scheduled
        else if (stage === labelPartialDeliveryScheduled) {
          if (
            data.partialDeliveryList !== null &&
            data.partialDeliveryList.length > 0
          ) {
            typeList.push(stage); //Push the stage to type filter
            this.setState({
              showStageDeliveryScheduled: true, //Set visible to true
            });
          }
        }
        //Waiting on Inventory (Suppliers/Warehouse Transfer)
        else if (stage === labelWaitingOnInventory) {
          if (
            data.waitingOnInventoryList !== null &&
            data.waitingOnInventoryList.length > 0
          ) {
            typeList.push(stage); //Push the stage to type filter
            this.setState({
              showStageWaitingInventory: true, //Set visible to true
            });
          }
        }
        //Full/Final Delivery Scheduled
        else if (stage === labelFullFinalDeliveryScheduled) {
          //Show when Waiting Inventory hidden and fullFinalDeliveryList have data
          if (
            data.waitingOnInventoryList === null &&
            data.fullFinalDeliveryList !== null &&
            data.fullFinalDeliveryList.length > 0
          ) {
            typeList.push(stage); //Push the stage to type filter
            this.setState({
              showStageFullFinal: true, //Set visible to true
            });
          }
        }
        //Delivered/Picked Up
        else if (stage === labelDeliveredPickup) {
          if (
            (data.totalDeliveries !== null && data.totalDeliveries > 0) ||
            (data.totalPickups !== null && data.totalPickups > 0)
          ) {
            typeList.push(stage); //Push the stage to type filter
            this.setState({
              showStageDeliveredPickup: true, //Set visible to true
            });
          }
        }
        //Return/Even Exchange
        else if (stage === labelReturnEvenExchange) {
          if (
            data.returnEvenExchangeList !== null &&
            data.returnEvenExchangeList.length > 0
          ) {
            typeList.push(stage); //Push the stage to type filter
            this.setState({
              showStageReturnEvenExchange: true, //Set visible to true
            });
          }
        }
      }); //End all stage

      if (typeList.length > 0) {
        this.setState({
          OCTtypeFilter: typeList,
        });
      }
    });
  }

  async GetOrderTimeline() {
    await fetch(
      helper.getAPIHost() +
        '/api/Customer360/GetOrderTimeline?custID=' +
        this.state.custID,
      helper.apiHeaders(),
    )
      .then(async (response) => {
        let res = await response.json();
        if (res.status === 'OK') {
          let dataList = res.result;
          // let orderList = [];

          let orderNumberFilterList = [];
          let NewrderNumberFilterList = [];

          if (dataList.length > 0) {
            dataList.forEach((data) => {
              //To process each order #
              orderNumberFilterList.push(data.salesDocNbr); //Filter for orderNumber
              NewrderNumberFilterList.push({
                orderNum: data.salesDocNbr,
                date: data.orderTransactionDate,
              });
              // BCP-2397
              data.employeeName =
                data.employeePreferredName || data.employeeName;
            });

            //Get Unique value for each
            orderNumberFilterList = [...new Set(orderNumberFilterList)];
            orderNumberFilterList.sort();

            // orderList = orderList.sort(helper.sort('date', 'desc'));

            let defaultFirstOrder = '';
            if (
              orderNumberFilterList.length > 0 &&
              this.state.orderNum !== '' &&
              orderNumberFilterList.includes(this.state.orderNum)
            ) {
              defaultFirstOrder = this.state.orderNum;
            } else if (orderNumberFilterList.length > 0) {
              defaultFirstOrder = orderNumberFilterList[0];
            } else {
              defaultFirstOrder = '';
            }
            // console.log(orderNumberFilterList)
            //Set OCTorderFilterValue to focusOrderNumber from Pipeline if passed, else use the first Order Number in API array, else, nothing
            this.setState(
              {
                isLoading: false,
                OCTdataList: dataList,
                OCTorderFilter: orderNumberFilterList,
                NewOCTorderFilter: NewrderNumberFilterList,
                OCTorderFilterValue: defaultFirstOrder,
              },
              () => {
                this.ShowHideLogicForStage();
              },
            );
            console.log('GetOrderTimeline: ', res.result);
          } else {
            //To handle if no data was found
            this.setState({ noData: true, isLoading: false });
            console.log('No data');
          }
        } else {
          this.setState({ apiError: true, isLoading: false });
        }
      })
      .catch((e) => {
        this.setState({
          apiError: true,
          isLoading: false,
        });
      });
  }

  render() {
    return (
      <div>
        {this.state.noData && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              margin: '0',
              paddingTop: '30px',
            }}
          >
            <div>No data was found for this customer</div>
          </div>
        )}

        {this.state.apiError && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              margin: '0',
              paddingTop: '30px',
            }}
          >
            <div>
              It appears we have some issues. Sorry for the inconvenience
              caused.
            </div>
          </div>
        )}

        {/* Filter */}
        <div className="filter typeFilter">
          {/* <Label>Filter by Date:
                        <Input type="select" value={this.state.OCTlineFilterValue} name="OCTlineFilterValue" onChange={this.handlefilterChanged.bind(this)}>
                            <option value=''>All</option>
                            {this.state.OCTlineFilter.map(line => {
                                return (<option value={line}>{line}</option>)
                            })}
                        </Input>
                    </Label> */}

          {!this.state.isLoading && this.state.OCTtypeFilter.length > 0 && (
            <Label>
              Filter by Type:
              <Input
                type="select"
                value={this.state.OCTtypeFilterValue}
                name="OCTtypeFilterValue"
                onChange={this.handlefilterChanged.bind(this)}
              >
                <option value="All">All</option>
                {this.state.OCTtypeFilter.map((type, i) => {
                  return (
                    <option key={i} value={type}>
                      {type}
                    </option>
                  );
                })}
              </Input>
            </Label>
          )}

          {!this.state.isLoading && this.state.OCTorderFilter.length > 0 && (
            <Label>
              Filter by Order #:
              <Input
                type="select"
                value={this.state.OCTorderFilterValue}
                name="OCTorderFilterValue"
                onChange={this.handlefilterChanged.bind(this)}
              >
                {this.state.NewOCTorderFilter.map((order, i) => {
                  // return (<option value={order}>{order}</option>)
                  return (
                    <option key={i} value={order.orderNum}>
                      {order.orderNum}{' '}
                      {order.date && `(${helper.formatDate(order.date)})`}
                    </option>
                  );
                })}
              </Input>
            </Label>
          )}
        </div>

        {/* Body */}
        <div className="content">
          {this.state.isLoading && (
            <div
              className="loading"
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                margin: '0',
              }}
            >
              <Spinner color="dark" />
              <div>&nbsp;Loading...</div>
            </div>
          )}

          {!this.state.isLoading &&
            this.state.OCTdataList.map((order) => {
              //Build italic style for Current Block and Shipping Method
              var currentBlock = '';
              var shippingMethod = '';
              var merchCategory = '';

              if (
                order.currentBlock !== null &&
                order.currentBlock.includes('(')
              ) {
                let startIndex = order.currentBlock.indexOf('(') + 1; //Index start at 0
                let endIndex = order.currentBlock.indexOf(')');
                currentBlock = order.currentBlock.substring(
                  startIndex,
                  endIndex,
                );
              }

              if (
                order.shippingMethod !== null &&
                order.shippingMethod.includes('(')
              ) {
                let startIndex = order.shippingMethod.indexOf('(') + 1; //Index start at 0
                let endIndex = order.shippingMethod.indexOf(')');
                shippingMethod = order.shippingMethod.substring(
                  startIndex,
                  endIndex,
                );
              }

              if (
                order.merchCategory !== null &&
                order.merchCategory.includes('(')
              ) {
                let startIndex = order.merchCategory.indexOf('(') + 1; //Index start at 0
                let endIndex = order.merchCategory.indexOf(')');
                merchCategory = order.merchCategory.substring(
                  startIndex,
                  endIndex,
                );
              }

              //Show Hide Logic
              var showCurrentBlock = false;
              var showShippingMethod = false;

              if (
                order.currentBlock !== null &&
                order.currentBlock.trim() !== ''
              ) {
                showCurrentBlock = true;
              }

              if (
                order.shippingMethod !== null &&
                order.shippingMethod.trim() !== ''
              ) {
                showShippingMethod = true;
              }

              return (
                order.salesDocNbr === this.state.OCTorderFilterValue && (
                  <>
                    <Accordion
                      allowZeroExpanded={true}
                      preExpanded={['default', 'typeSelected']}
                      allowMultipleExpanded={true}
                      className="accordianOrderTimeline"
                    >
                      <AccordionItem key={order.salesDocNbr} uuid="default">
                        <AccordionItemHeading className="accordianHeader">
                          <AccordionItemButton key={order.salesDocNbr}>
                            <div className="title">
                              <img
                                src={OrderTimelineIcon.OrderCreate.default}
                                alt="orderTimelineIcon"
                              />
                              <div className="orderTitle">
                                <div>
                                  Order{' '}
                                  <div className="highlightTitle">
                                    #{order.salesDocNbr}
                                  </div>{' '}
                                  created on{' '}
                                  {helper.formatDate(
                                    order.orderTransactionDate,
                                  )}
                                </div>
                                {order.employeeName?.length > 0 &&
                                  order.employeeLocation?.length > 0 && (
                                    <>
                                      <div className="highlightTitle">
                                        {order.employeeName}
                                      </div>
                                      &nbsp;from&nbsp;
                                      <div className="highlightTitle">
                                        {order.employeeLocation}
                                      </div>
                                    </>
                                  )}
                              </div>
                            </div>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <Table className="tableDetails">
                            <tr key="paymentType">
                              <th scope="row">Primary Payment Type:</th>
                              <td>{order.primaryPaymentType}</td>
                            </tr>
                            <tr key="totalOrderValue">
                              <th scope="row">Total Order Value:</th>
                              <td>
                                {order.totalOrderAmount === null
                                  ? helper.formatNumber(0, 2, '$')
                                  : helper.formatNumber(
                                      order.totalOrderAmount,
                                      2,
                                      '$',
                                    )}
                              </td>
                            </tr>
                            <tr key="balanceDue">
                              <th scope="row">Balance Due:</th>
                              <td>
                                {order.balanceDue === null
                                  ? helper.formatNumber(0, 2, '$')
                                  : helper.formatNumber(
                                      order.balanceDue,
                                      2,
                                      '$',
                                    )}
                              </td>
                            </tr>
                            <tr key="merchCategory">
                              <th scope="row">Merch Category:</th>
                              <td>
                                {/* Use italic for Mixed if API return string contains () */}
                                {merchCategory.length > 0 ? (
                                  <div>
                                    Mixed (
                                    <i style={{ padding: 0 }}>
                                      {merchCategory}
                                    </i>
                                    )
                                  </div>
                                ) : (
                                  order.merchCategory
                                )}
                              </td>
                            </tr>

                            {/* Hide Current Block if value null or empty */}
                            {showCurrentBlock && (
                              <tr key="currentBlock">
                                <th scope="row">Current Block:</th>
                                <td>
                                  {/* Use italic for Mixed if API return string contains () */}
                                  {currentBlock.length > 0 ? (
                                    <div>
                                      Mixed (
                                      <i style={{ padding: 0 }}>
                                        {currentBlock}
                                      </i>
                                      )
                                    </div>
                                  ) : (
                                    order.currentBlock
                                  )}
                                </td>
                              </tr>
                            )}

                            {/* Hide Shipping Method if value null or empty*/}
                            {showShippingMethod && (
                              <tr key="shippingMethod">
                                <th scope="row">Shipping Method:</th>
                                <td>
                                  {/* Use italic for Mixed if API return string contains () */}
                                  {shippingMethod.length > 0 ? (
                                    <div>
                                      Mixed (
                                      <i style={{ padding: 0 }}>
                                        {shippingMethod}
                                      </i>
                                      )
                                    </div>
                                  ) : (
                                    order.shippingMethod
                                  )}
                                </td>
                              </tr>
                            )}
                          </Table>
                        </AccordionItemPanel>
                      </AccordionItem>

                      {/* Partial Delivery Completed */}
                      {this.state.showStageDeliveryCompleted && (
                        <AccordionItem
                          key={labelPartialDeliveryCompleted}
                          uuid={
                            this.state.typeSelected ? 'typeSelected' : 'noType1'
                          }
                        >
                          <AccordionItemHeading className="accordianHeader">
                            <AccordionItemButton>
                              <div className="title">
                                <img
                                  src={OrderTimelineIcon.Deliver.default}
                                  alt="orderTimelineIcon"
                                />
                                <div className="orderTitle">
                                  <div>{labelPartialDeliveryCompleted}</div>
                                </div>
                              </div>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <Table className="tableDetails">
                              <tr key="itemsDelivered">
                                <th scope="row">Items Delivered:</th>
                                <td>
                                  {order.itemDelivered} of {order.totalItem}
                                </td>
                              </tr>
                              <tr key="amountDelivered">
                                <th scope="row">Amount Delivered:</th>
                                <td>
                                  {order.amountDelivered === null
                                    ? helper.formatNumber(0, 2, '$')
                                    : helper.formatNumber(
                                        order.amountDelivered,
                                        2,
                                        '$',
                                      )}
                                </td>
                              </tr>
                              <tr key="deliveredOn">
                                <th
                                  scope="row"
                                  style={{ verticalAlign: 'top' }}
                                >
                                  Delivered On:
                                </th>

                                <td>
                                  {order.deliveredOn !== null &&
                                    order.deliveredOn.map((item, index) => {
                                      return (
                                        <div key={index}>
                                          {helper.formatDate(item)}
                                        </div>
                                      );
                                    })}
                                </td>
                              </tr>
                            </Table>
                          </AccordionItemPanel>
                        </AccordionItem>
                      )}

                      {/* Partial Delivery Scheduled */}
                      {this.state.showStageDeliveryScheduled && (
                        <AccordionItem
                          key={labelPartialDeliveryScheduled}
                          uuid={
                            this.state.typeSelected ? 'typeSelected' : 'noType2'
                          }
                        >
                          <AccordionItemHeading className="accordianHeader">
                            <AccordionItemButton>
                              <div className="title">
                                <img
                                  src={OrderTimelineIcon.Deliver.default}
                                  alt="orderTimelineIcon"
                                />
                                <div className="orderTitle">
                                  <div>{labelPartialDeliveryScheduled}</div>
                                </div>
                              </div>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <Table className="tableDetails">
                              <tr>
                                <th>SKU</th>
                                <th>Product</th>
                                <th>Scheduled Date</th>
                              </tr>
                              {order.partialDeliveryList.map((data) => {
                                return (
                                  <tr key={data.sku}>
                                    <td>{data.sku}</td>
                                    <td>{data.product}</td>
                                    <td>
                                      {helper.formatDate(data.scheduledDate)}
                                    </td>
                                  </tr>
                                );
                              })}
                            </Table>
                          </AccordionItemPanel>
                        </AccordionItem>
                      )}

                      {/* Waiting on Inventory (Suppliers/Warehouse Transfer) */}
                      {this.state.showStageWaitingInventory && (
                        <AccordionItem
                          key={labelWaitingOnInventory}
                          uuid={
                            this.state.typeSelected ? 'typeSelected' : 'noType3'
                          }
                        >
                          <AccordionItemHeading className="accordianHeader">
                            <AccordionItemButton>
                              <div className="title">
                                <img
                                  src={OrderTimelineIcon.Trolley.default}
                                  alt="orderTimelineIcon"
                                />
                                <div className="orderTitle">
                                  <div>{labelWaitingOnInventory}</div>
                                </div>
                              </div>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <Table className="tableDetails">
                              <tr>
                                <th>SKU</th>
                                <th>Article</th>
                                {/* Hide Available if Current Block have 1 to 1 Special Orders value, show if Mixed */}
                                {!(order.currentBlock === 'Special Order') && (
                                  <th>Available</th>
                                )}
                              </tr>
                              {order.waitingOnInventoryList.map((data) => {
                                return (
                                  <tr key={data.sku}>
                                    <td>{data.sku}</td>
                                    <td>{data.article}</td>
                                    {/* Hide Available if Current Block have 1 to 1 Special Orders value, show if Mixed */}
                                    {!(
                                      order.currentBlock === 'Special Order'
                                    ) && (
                                      <td>
                                        {helper.formatDate(data.availableDate)}
                                      </td>
                                    )}
                                  </tr>
                                );
                              })}
                            </Table>
                          </AccordionItemPanel>
                        </AccordionItem>
                      )}

                      {/* Full/Final Delivery Scheduled */}
                      {this.state.showStageFullFinal && (
                        <AccordionItem
                          key={labelFullFinalDeliveryScheduled}
                          uuid={
                            this.state.typeSelected ? 'typeSelected' : 'noType4'
                          }
                        >
                          <AccordionItemHeading className="accordianHeader">
                            <AccordionItemButton>
                              <div className="title">
                                <img
                                  src={OrderTimelineIcon.Deliver.default}
                                  alt="orderTimelineIcon"
                                />
                                <div className="orderTitle">
                                  <div>{labelFullFinalDeliveryScheduled}</div>
                                </div>
                              </div>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <Table className="tableDetails">
                              <tr>
                                <th>SKU</th>
                                <th>Product</th>
                                <th>Scheduled Date</th>
                              </tr>
                              {order.fullFinalDeliveryList.map((data) => {
                                return (
                                  <tr key={data.sku}>
                                    <td>{data.sku}</td>
                                    <td>{data.product}</td>
                                    <td>
                                      {helper.formatDate(data.scheduledDate)}
                                    </td>
                                  </tr>
                                );
                              })}
                            </Table>
                          </AccordionItemPanel>
                        </AccordionItem>
                      )}

                      {/* Delivered/Picked Up */}
                      {this.state.showStageDeliveredPickup && (
                        <AccordionItem
                          key={labelDeliveredPickup}
                          uuid={
                            this.state.typeSelected ? 'typeSelected' : 'noType5'
                          }
                        >
                          <AccordionItemHeading className="accordianHeader">
                            <AccordionItemButton>
                              <div className="title">
                                <img
                                  src={OrderTimelineIcon.Deliver.default}
                                  alt="orderTimelineIcon"
                                />
                                <div className="orderTitle">
                                  <div>{labelDeliveredPickup}</div>
                                </div>
                              </div>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <Table className="tableDetails">
                              {order.totalDeliveries > 0 ? (
                                <tr key="totalDeliveries">
                                  <th scope="row">Total Deliveries:</th>
                                  <td>
                                    {order.totalDeliveries === '' ||
                                    order.totalDeliveries === null
                                      ? 0
                                      : order.totalDeliveries}
                                  </td>
                                </tr>
                              ) : (
                                ''
                              )}

                              {order.totalPickups > 0 ? (
                                <tr key="totalPickups">
                                  <th scope="row">Total Pickups:</th>
                                  <td>
                                    {order.totalPickups === '' ||
                                    order.totalPickups === null
                                      ? 0
                                      : order.totalPickups}
                                  </td>
                                </tr>
                              ) : (
                                ''
                              )}
                            </Table>
                          </AccordionItemPanel>
                        </AccordionItem>
                      )}

                      {/* Return/Even Exchange */}
                      {this.state.showStageReturnEvenExchange && (
                        <AccordionItem
                          key={labelReturnEvenExchange}
                          uuid={
                            this.state.typeSelected ? 'typeSelected' : 'noType6'
                          }
                        >
                          <AccordionItemHeading className="accordianHeader">
                            <AccordionItemButton>
                              <div className="title">
                                <img
                                  src={OrderTimelineIcon.ReturnBox.default}
                                  alt="orderTimelineIcon"
                                />
                                <div className="orderTitle">
                                  <div>{labelReturnEvenExchange}</div>
                                </div>
                              </div>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <Table className="tableDetails">
                              <tr key="referencedOrder">
                                <th scope="row">Referenced Order:</th>
                                <td>
                                  {
                                    order.returnEvenExchangeList[0]
                                      .referencedOrder
                                  }
                                </td>
                              </tr>
                              <tr key="amountReturned">
                                <th scope="row">Amount Returned/Exchanged:</th>
                                <td>
                                  {order.returnEvenExchangeList[0]
                                    .amountReturnedExchanged === null
                                    ? helper.formatNumber(0, 2, '$')
                                    : helper.formatNumber(
                                        order.returnEvenExchangeList[0]
                                          .amountReturnedExchanged,
                                        2,
                                        '$',
                                      )}
                                </td>
                              </tr>
                            </Table>
                          </AccordionItemPanel>
                        </AccordionItem>
                      )}
                    </Accordion>
                  </>
                )
              );
            })}
        </div>
      </div>
    );
  }
}

BDFCust360OrderTimeline.propTypes = {
  custID: PropTypes.string,
  focusOrderNumber: PropTypes.string,
};

BDFCust360OrderTimeline.defaultProps = {
  custID: '',
  focusOrderNumber: '',
};

export default BDFCust360OrderTimeline;
