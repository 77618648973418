import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
// import DOMPurify from "dompurify";
import ReactHtmlParser from 'react-html-parser';

import {
  Table,
  Label,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from 'reactstrap';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import { Spinner } from 'reactstrap';
import PropTypes from 'prop-types';
import '../customer360.scss';
import * as customer360Icon from '../../../../assets/img/customer360';
// import { MdClose } from 'react-icons/md';
import { CgSmileNeutral, CgSmileSad, CgSmile } from 'react-icons/cg';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { IoMdStar } from 'react-icons/io';
import { enGB } from 'date-fns/locale';
import { DatePickerCalendar } from 'react-nice-dates';
import enums from '../../../../enums';
import { FcCalendar } from 'react-icons/fc';
import {
  ModalBtnClose,
  RedButton,
} from '../../../../components/CommonComponent';
import { Popper, TextField } from '@material-ui/core';

const helper = require('../../../../utils/helper');
const ux = require('../../../../application-center/ux-tracking-center');

class BDFCust360ContactTimeline extends React.Component {
  state = {
    viewList: [],
    nextList: [],
    podiumModalDataList: [],
    OCTdataList: [],

    toggle: {},
    custID: '',
    custName: '',
    custEmail: '',
    cusrPhone: '',
    orderNum: '',

    dateFilterValue: '',
    typeFilterValue: '',
    orderFilterValue: '',
    OCTlineFilterValue: '',
    OCTtypeFilterValue: '',
    OCTorderFilterValue: '',

    monthList: [],
    dateFilter: [],
    typeFilter: [],
    orderFilter: [],
    OCTlineFilter: [],
    OCTtypeFilter: [],
    OCTorderFilter: [],
    enums: {},
    monthDisplay: [],
    OCTmonthDisplay: [],
    customerProfile: {},

    //multicheck filter
    NewtypeFilter: [],
    NewtypeFilterValue: [],

    podiumModal: false,
    contactTimelimeToggle: this.props.contactTimelimeToggle,

    // show progress
    numOfProgress: 0,
    completedProcess: 0,
    progress: 0,

    isloading: 0,
    custProfileIsloading: false,
    selectedTabs: 0,

    //Modal for Reminder
    reminderModal: false,
    reminderDate: new Date(),
    reminderDateSelected: false,
    reminderType: '',
    reminderUid: '',
    remindermessage: '',
    reminderordernumber: '',

    //External Access Iframe
    isExternal: false,

    //expand the notes
    isExpandAll: false,

    //Search keyword
    searchKeyword: '',
    searchCount: 0,
    searchNextData: [],
    searchCurrentIndex: 0,
    searchIsFirst: true,

    //Filter
    filterByFlag: null,
    filterByComment: null,
  };

  OCTtestData = [
    {
      num: '10',
      status: 'Delivery Date Removed',
      salesAssociateNm: 'Yvette Cruz',
      actionDate: '2021-09-02T14:34:00',
      startDate: '10/2/2021',
      endDate: null,
    },
    {
      num: 'H',
      status: 'Blocked added',
      salesAssociateNm: 'Manny Smith',
      actionDate: '2021-09-04T16:51:00',
      startDate: null,
      endDate: 'Waiting on merchandise',
    },
    {
      num: '10',
      status: 'Rescherduled',
      salesAssociateNm: 'Yvette Cruz',
      actionDate: '2021-09-04T11:02:00',
      startDate: '9/28/2021',
      endDate: '10/2/2021',
    },
    {
      num: '10',
      status: 'Scheduled',
      salesAssociateNm: 'Bobbot',
      actionDate: '2021-09-02T22:21:00',
      startDate: null,
      endDate: '9/28/2021',
    },
  ];

  surveyScore(item) {
    let icon, text;
    switch (item.likelihoodScore) {
      case 9:
      case 10:
        icon = <CgSmile size={30} />;
        text = 'Promoter';
        break;
      case 7:
      case 8:
        icon = <CgSmileNeutral size={30} />;
        text = 'Passive';
        break;
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
        icon = <CgSmileSad size={30} />;
        text = 'Detractor';
        break;
      default:
    }
    if (text)
      return (
        <div className="details_survey details_background">
          <span className="icon">{icon}</span>
          <span className="text">{text}</span>
          <span className="score">{item.likelihoodScore}</span>
          <span className="comment">{item.likelihoodComment}</span>
        </div>
      );
    else return;
  }

  secondsToHms(d) {
    d = Number(d);
    let h = Math.floor(d / 3600);
    let m = Math.floor((d % 3600) / 60);
    let s = Math.floor((d % 3600) % 60);

    let display =
      helper.formatNumber2(h, 2) +
      ':' +
      helper.formatNumber2(m, 2) +
      ':' +
      helper.formatNumber2(s, 2);
    return display;
  }

  ZendeskVia(via) {
    let peocessedVia = '';
    switch (via) {
      case 'chat':
      case 'email':
      case 'facebook':
      case 'twitter':
      case 'web':
        peocessedVia = via[0].toUpperCase() + via.substring(1);
        break;
      case 'instagram_dm':
        peocessedVia = 'Instagram';
        break;
      case 'sms':
        peocessedVia = 'SMS';
        break;
      default:
    }
    return peocessedVia;
  }

  ZendeskRemarks(item) {
    return (
      <div>
        <div>{item.subject}</div>
        <div>
          With <span className="hyperlink">{item.salesAssociateNm}</span> from{' '}
          <span className="hyperlink">{item.ticketGroupName}</span>
        </div>
        {item.spanishRequired === '-spanish-_yes' && (
          <div>Spanish Speaking</div>
        )}
      </div>
    );
  }

  dateTimeFormat(formattedItem, datetime) {
    let plusOneDay = false;
    let time = new Date(datetime).toLocaleTimeString();
    let split = time.split(':');
    let h = parseInt(split[0]);
    let m = parseInt(split[1]);
    let s = parseInt(split[2].split(' ')[0]);
    let daynight = split[2].split(' ')[1];
    if (s > 30) {
      m++;
      if (m > 59) {
        h++;
        m -= 60;
        if (daynight === 'AM' && h === 12) {
          daynight = 'PM';
        } else if (daynight === 'PM' && h === 12) {
          daynight = 'AM';
          plusOneDay = true;
        } else if (h > 12) {
          h -= 12;
        }
      }
    }

    formattedItem['Time'] =
      helper.formatNumber2(h, 2) +
      ':' +
      helper.formatNumber2(m, 2) +
      ' ' +
      daynight;

    if (plusOneDay) {
      datetime = new Date(
        new Date(datetime).setDate(new Date(datetime).getDate() + 1),
      );
      formattedItem['Date'] =
        helper.formatNumber2(parseInt(datetime.getMonth()) + 1, 2) +
        '/' +
        helper.formatNumber2(parseInt(datetime.getDate()), 2) +
        '/' +
        datetime.getFullYear();
    } else {
      formattedItem['Date'] = helper.formatDate(datetime);
    }

    formattedItem['rawDateTime'] = new Date(datetime);
  }

  SAPRemarks(item) {
    let from;
    switch (item.salesAssociateClass) {
      case 'SCC-A':
      case 'SCC-B':
        from = 'Bob’s Squad';
        break;
      case 'CC':
        from = 'Customer Care';
        break;
      default:
        from = item.profitCenterNm;
        break;
    }
    return (
      <div>
        Added by <span className="hyperlink">{item.salesAssociateNm}</span> from{' '}
        <span className="hyperlink">{from}</span>
      </div>
    );
  }

  formatitem(item) {
    let formattedItem = {};
    let Remarks;
    let itemFlag = item.flag; //BCP-1855
    let extraData = [];

    switch (item.source) {
      case 'Call Center':
        let storeName =
          item.callReceiverType?.toUpperCase().trim() === 'STORE'
            ? item.profitCenterNm
            : item.callReceiverType;
        Remarks = (
          <div>
            With <span className="hyperlink">{item.salesAssociateNm}</span> from{' '}
            <span className="hyperlink">{storeName}</span>
          </div>
        );
        extraData.push(item.salesAssociateNm);
        extraData.push(storeName);
        extraData.push(item.phoneNbr);
        formattedItem = {
          Type: 'Call Center',
          Title: 'Phone Call (' + item.phoneNbr + ')',
          Remarks: Remarks,
          Status: 'Closed',
          Details: (
            <div className="details_callCenter details_background">
              <div>
                <span>Call Duration: </span>
                <span>{this.secondsToHms(item.duration)}</span>
                {/*<span>{item.type}: {item.details}</span>*/}
              </div>
              {/* <div><span>Ring Time:  </span><span>{this.secondsToHms(item.ringTime)}</span></div> */}
              {/* <div><span>Delay Time: </span><span>{this.secondsToHms(item.delayTime)}</span></div> */}
              <div>
                <span>Hold Time: </span>
                <span>{this.secondsToHms(item.holdTime)}</span>
              </div>
              <div>
                <span>Talk Time: </span>
                <span>{this.secondsToHms(item.talkTime)}</span>
              </div>
            </div>
          ),
          OrderNumber: '',
          Icon: customer360Icon.Contact.default,
          bucketactiontype: false,
          extraInfoData: extraData,
        };
        break;
      case 'SAP':
        extraData.push(item.salesAssociateNm);
        extraData.push(item.details);
        formattedItem = {
          Type: item.type,
          Title: item.type,
          Remarks: this.SAPRemarks(item),
          Status: '',
          Details: <div className="details_background">{item.details}</div>,
          OrderNumber: item.salesDocNbr,
          Icon: customer360Icon.SAP.default,
          extraInfoData: extraData,
          bucketactiontype: false,
        };
        break;
      case 'Medallia':
        if (helper.getSprint() > 20) {
          switch (item.surveyType) {
            case 'Delivery':
              Remarks = (
                <div>
                  For <span className="hyperlink">{item.driverName}</span> from{' '}
                  <span className="hyperlink">{item.profitCenterNm}</span>
                </div>
              );
              break;
            case 'Customer Pickup':
              Remarks = (
                <div>
                  For <span className="hyperlink">{item.profitCenterNm}</span>
                </div>
              );
              break;
            default:
              Remarks = (
                <div>
                  For <span className="hyperlink">{item.salesAssociateNm}</span>{' '}
                  from <span className="hyperlink">{item.profitCenterNm}</span>
                </div>
              );

              break;
          }
        } else {
          let For = '';
          switch (item.surveyType) {
            case 'Delivery':
              For = item.driverName;
              break;
            default:
              For = item.salesAssociateNm;
              break;
          }
          Remarks = (
            <div>
              For <span className="hyperlink">{For}</span> from{' '}
              <span className="hyperlink">{item.profitCenterNm}</span>
            </div>
          );
        }

        extraData.push(item.likelihoodComment);
        extraData.push(item.driverName);
        extraData.push(item.salesAssociateNm);

        formattedItem = {
          Type: 'Survey',
          Title: 'Voice of the Customer - ' + item.surveyType + ' survey',
          Remarks: Remarks,
          Status: '',
          Details: this.surveyScore(item),
          OrderNumber: '',
          Icon: customer360Icon.Survey.default,
          bucketactiontype: false,
          extraInfoData: extraData,
        };
        break;
      case 'Zendesk':
        extraData.push(item.details);
        extraData.push(item.salesAssociateNm);
        formattedItem = {
          uid: item.uid,
          Type: 'Zendesk Case',
          Title: 'Zendesk Case #' + item.ticketID + ' ' + item.messageName, //this.ZendeskVia(item.r['via']),
          Remarks: this.ZendeskRemarks(item),
          Status: item.status
            ? item.status[0].toUpperCase() + item.status.substring(1)
            : '',
          flag: itemFlag, //BCP-1855
          flagBy: item.flagBy,
          flagEmpID: item.flagEmpID,
          flagLoc: item.flagLocation,
          flagTime: item.flagTime,
          detailItems: item.details,
          Details: (
            //Details here not using
            <div className="details_background order-notes-flag">
              <div>{item.details}</div>

              <RedButton
                uid={item.uid}
                currentflag={itemFlag ? 1 : 0}
                label={itemFlag ? 'Unflag' : 'Flag'}
                outline={false}
                onClick={(e) => this.flagUnflag(e, false)}
              />
            </div>
          ),
          OrderNumber: '',
          Icon: customer360Icon.Chat.default,
          bucketactiontype: false,
          extraInfoData: extraData,
        };
        item.dateTime = item.dateTime + 'Z'; //Treat as UTC
        break;
      case 'Cordial':
        if (helper.getSprint() > 20) {
          let processedDetails = item.details;
          let type = '';
          if (
            processedDetails?.includes('(Customer Name)') &&
            item.customerNm?.length > 0
          ) {
            processedDetails = processedDetails.replace(
              '(Customer Name)',
              item.customerNm,
            ); //Update template to use real Customer Name
          }

          if (
            item.ticketGroupName === 'rest' &&
            processedDetails &&
            processedDetails.includes('mediaUrl')
          ) {
            //BCP-1187
            let mediaUrl = processedDetails
              .split('body:')[0]
              .replace(/mediaUrl: /, ''); //get mediaURL
            let rest = processedDetails.split('body:')[1]; //detials after mediURL
            processedDetails = `<img src='${mediaUrl.trim()}'/> <br/>${rest}`; //make medtails URL in to image
            processedDetails = processedDetails
              .replace(/\n/g, '<br/>')
              .replace(/type:/g, '')
              .replace(/body:/g, '')
              .replace(/"/g, ''); //make UI clean
          }

          if (item.ticketGroupName === 'rest') {
            type = 'Automated SMS';
          } else {
            type = 'Automated Email';
          }

          let itemDetails = item.itemlist.sort((a, b) => {
            return a.lineItems - b.lineItems;
          });

          let details = (
            <div
              className={`details_cordial details_background ${
                item.ticketGroupName === 'rest' ? 'sms' : ''
              }`}
            >
              <div dangerouslySetInnerHTML={{ __html: item.subject }} />
              <div dangerouslySetInnerHTML={{ __html: processedDetails }} />

              {/* BCP-1581 - Cordial Properties */}
              {itemDetails.length > 0 && (
                <Table>
                  <th>Item</th>
                  <th>Product</th>
                  {item.messageName.includes('ATP') ? (
                    <th>Estimated Date</th>
                  ) : (
                    <th>Scheduled Date</th>
                  )}

                  {itemDetails.map((data, index) => {
                    extraData.push(data.articleDescription);
                    return (
                      <tr key={data.recordID + index}>
                        <td>{data.lineItems}</td>
                        <td>{data.articleDescription}</td>
                        <td>{helper.formatDate(data.deliveryDate)}</td>
                      </tr>
                    );
                  })}
                </Table>
              )}

              {/* BCP-1653 - Cordial Properties - In Store*/}
              {(item.messageName.toUpperCase().includes('IN STORE') ||
                item.messageName.toUpperCase().includes('IN-STORE')) &&
              item.instoreItemList?.length > 0 ? (
                <Table className="inStoreProperties">
                  {item.instoreItemList.some(
                    (data) => data.itemNbr?.length > 0,
                  ) && <th>Item</th>}
                  {item.instoreItemList.some(
                    (data) => data.sku?.length > 0,
                  ) && <th>SKU</th>}
                  {item.instoreItemList.some(
                    (data) => data.qty?.length > 0,
                  ) && <th>Qty</th>}
                  {item.instoreItemList.some(
                    (data) => data.price?.length > 0,
                  ) && <th>Price</th>}

                  {item.instoreItemList.map((data, index) => {
                    extraData.push(data.sku);
                    return (
                      <tr key={index}>
                        {data.itemNbr?.length > 0 && <td>{data.itemNbr}</td>}
                        {data.sku?.length > 0 && <td>{data.sku}</td>}
                        {data.qty > 0 && <td>{data.qty}</td>}
                        {data.price > 0 && (
                          <td>
                            {helper.formatNumber(
                              data.price !== null ? data.price : 0,
                              2,
                              '$',
                            )}
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </Table>
              ) : null}
            </div>
          );

          extraData.push(processedDetails);
          extraData.push(item.messageName);

          if (!processedDetails) details = null;
          formattedItem = {
            Type: type,
            Title: `Automated  ${
              item.ticketGroupName
                ? item.ticketGroupName.includes('email')
                  ? 'email'
                  : 'SMS'
                : 'email'
            } sent to customer`,
            Remarks: item.messageName,
            Status: this.cordialEmailStatus(item.messageAction),
            Details: details,
            OrderNumber: item.salesDocNbr,
            Icon: customer360Icon.Cordial.default,
            bucketactiontype: false,
            extraInfoData: extraData,
          };

          item.dateTime = item.dateTime + 'Z'; //Treat as UTC
        } else {
          formattedItem = {
            Type: 'Email',
            Title: item.messageName || '',
            Remarks: '',
            Status: this.cordialEmailStatus(item.messageAction),
            Details: null,
            OrderNumber: '',
            Icon: customer360Icon.Cordial.default,
            bucketactiontype: false,
          };
        }
        break;
      case 'Google Review': //BCP-1098
        let startedAt = {};
        if (item.startedAt) this.dateTimeFormat(startedAt, item.startedAt);

        extraData.push(item.profitCenterNm);
        extraData.push(item.likelihoodComment);
        extraData.push(item.author);
        extraData.push(item.details);
        extraData.push('Response');

        formattedItem = {
          Type: 'Google Review',
          Title: 'Google Review',
          Remarks: (
            <div>
              For <span className="hyperlink">{item.profitCenterNm}</span>
            </div>
          ),
          Status: '',
          Details: (
            <div className="details_googleReview details_background">
              <div>
                <span>
                  {item.likelihoodScore}
                  <IoMdStar />
                </span>
                <span>{item.likelihoodComment}</span>
              </div>
              <div className="response">
                <span></span>
                <span>
                  <div>
                    <b>
                      Response from the {item.author} on {startedAt.Date}{' '}
                      {startedAt.Time}
                    </b>
                  </div>
                  <div>{item.details}</div>
                </span>
              </div>
            </div>
          ),
          OrderNumber: '',
          Icon: customer360Icon.Survey.default,
          bucketactiontype: false,
          extraInfoData: extraData,
        };
        break;
      case 'Calendly': //BCP-1099
        extraData.push(item.profitCenterNm);

        formattedItem = {
          Type: 'Calendly ',
          Title: 'Calendly ' + item.type + ' Appointment',
          Remarks: (
            <div>
              For <span className="hyperlink">{item.profitCenterNm}</span>
            </div>
          ),
          Status: item.status,
          Details: null,
          OrderNumber: '',
          Icon: customer360Icon.Calendly.default,
          bucketactiontype: false,
          extraInfoData: extraData,
        };
        break;
      case 'Order Notes': //BCP-1099
        let bucketactiontype = false;
        let action =
          item.type === 'Snooze'
            ? 'Snooze'
            : item.type === 'Escalate'
            ? 'Escalation'
            : 'Action';
        if (action === 'Action' && item.details === '') {
          bucketactiontype = true;
        }

        if (item.type === 'Go Back') {
          extraData.push(item.salesAssociateNm);
          extraData.push(item.profitCenterNm);

          // Go Back Request Design
          formattedItem = {
            uid: item.uid,
            Type: 'Order Notes',
            // Title: `BobBoost ${item.salesDocNbr || ''} Note`,
            Title: `Go Back Request submitted`,
            Remarks: (
              <div>
                By <span className="hyperlink">{item.salesAssociateNm}</span>{' '}
                from <span className="hyperlink">{item.profitCenterNm}</span>
              </div>
            ),
            Status: item.status,
            flag: itemFlag, //BCP-1855
            flagBy: item.flagBy,
            flagEmpID: item.flagEmpID,
            flagLoc: item.flagLocation,
            flagTime: item.flagTime,
            detailItems: item.details,
            Details: null,
            OrderNumber: item.salesDocNbr,
            Icon: customer360Icon.GoBack.default,
            bucketactiontype: bucketactiontype,
            extraInfoData: extraData,
          };
        } else {
          extraData.push(item.details);
          extraData.push(item.salesAssociateNm);
          extraData.push(item.profitCenterNm);

          formattedItem = {
            uid: item.uid,
            Type: 'Order Notes',
            // Title: `BobBoost ${item.salesDocNbr || ''} Note`,
            Title:
              item.type === 'Notes' || item.type === 'Follow Up'
                ? `Communication and Collaboration Message Order #${
                    item.salesDocNbr || ''
                  } Note`
                : item.type === 'GuestConnect'
                ? 'GuestConnect Note'
                : `BobBoost-${
                    item.type === 'Snooze'
                      ? 'Snooze'
                      : item.type === 'Escalate'
                      ? 'Escalation'
                      : 'Action'
                  } from bucket - ${item.messageName || ''} ${
                    item.ticketGroupName === 'D' &&
                    item.messageAction === '35' &&
                    item.subject
                      ? `(${
                          item.subject.toLowerCase() === 'override'
                            ? 'Merch Override'
                            : item.subject
                        })` //Overwrite 'Override' to 'Merch Override'
                      : ''
                  } Order #${item.salesDocNbr || ''} Note`,
            Remarks: this.SAPRemarks(item),
            //(
            //  <div>
            //        Added by{' '}
            //    <span className="hyperlink">{item.salesAssociateNm}</span> from{' '}
            //    <span className="hyperlink">{item.profitCenterNm}</span>
            //  </div>
            //),
            Status: '',
            flag: itemFlag, //BCP-1855
            flagBy: item.flagBy,
            flagEmpID: item.flagEmpID,
            flagLoc: item.flagLocation,
            flagTime: item.flagTime,
            detailItems:
              item.type !== 'Notes'
                ? item.details
                : item.details
                    .split('\n')
                    .filter(
                      (x) =>
                        x &&
                        !x.includes('To:') &&
                        !x.includes('From:') &&
                        !x.includes('Acknowledged:'),
                    )
                    .join('\n')
                    .replaceAll(
                      '____________________________________________________________________________________',
                      '<hr />',
                    ),
            Details: (
              //Details here not using
              <div className="details_background order-notes-flag">
                <div>{item.details}</div>

                <RedButton
                  uid={item.uid}
                  currentflag={itemFlag ? 1 : 0}
                  label={itemFlag ? 'Unflag' : 'Flag'}
                  outline={false}
                  onClick={(e) => this.flagUnflag(e, true)}
                />
              </div>
            ),
            OrderNumber: item.salesDocNbr,
            Icon: customer360Icon.SAP.default,
            bucketactiontype: bucketactiontype,
            extraInfoData: extraData,
          };
          if (item.type === 'Ext') {
            if (item.referenceID === 'bobsuite')
              formattedItem.Title = `BobSuite - Order #${
                item.salesDocNbr || ''
              } Note`;
          }
        }

        item.dateTime = item.dateTime + 'Z'; //Treat as UTC
        break;
      case 'Contact Customer Request':
        extraData.push(item.subject);
        extraData.push(item.details);
        extraData.push(item.customerNm);

        formattedItem = {
          Type: 'Request to Contact',
          Title: 'Request to Contact',
          Remarks: '',
          Status: '',
          Details: (
            <div className="details_background">
              <span>{item.subject}</span>
              <br></br>
              <br></br>
              <span>{item.details}</span>
            </div>
          ),
          OrderNumber: '',
          Icon: customer360Icon.Scroll.default,
          bucketactiontype: false,
          extraInfoData: extraData,
        };
        break;
      default:
    }
    if (item.dateTime) this.dateTimeFormat(formattedItem, item.dateTime);

    return formattedItem;
  }

  formatItemPodium(list) {
    let salesAssociateNm = '';
    let profitCenterNm = '';
    let extraData = [];

    list.forEach((item) => {
      if (item.salesAssociateNm) salesAssociateNm = item.salesAssociateNm;
      if (item.profitCenterNm) profitCenterNm = item.profitCenterNm;
    });
    let dateTime = list[0].dateTime + 'Z'; //Treat as UTC
    list.forEach((line, index) => {
      let breakLine = false;
      let thisDate = {};
      let nextDate = {};
      line.dateTime = line.dateTime + 'Z'; //Treat as UTC
      this.dateTimeFormat(thisDate, line.dateTime);
      if (index !== list.length - 1)
        this.dateTimeFormat(nextDate, list[index + 1].dateTime);
      if (thisDate.Date === nextDate.Date) {
        // Commented Section is line break for Sales Associate
        // if(line.salesAssociateNm)
        // {
        //     for(let i=index+1; i< list.length; i++)
        //     {
        //         if(list[i].salesAssociateNm!= "" && line.salesAssociateNm !== list[i].salesAssociateNm)
        //         {
        //             breakLine = true
        //             break;
        //         }else if (list[i].salesAssociateNm!= "" && line.salesAssociateNm === list[i].salesAssociateNm){
        //             breakLine = false
        //             break;
        //         }
        //     }
        // }else{
        breakLine = false;
        // }
      } else if (nextDate.Date) {
        breakLine = true;
        line.breakLineText = nextDate.Date;
      }

      line.breakLine = breakLine;
    });

    let formattedItem = {
      Type: 'Podium Conversation',
      Title: 'Podium Conversation',
      Remarks: (
        <div>
          <div>
            With <span className="hyperlink">{salesAssociateNm}</span> from{' '}
            <span className="hyperlink">{profitCenterNm}</span>
          </div>
          {/* <div><button className="btnShowConversation" onClick={this.podiumModalToggle.bind(this, list)}>Show All Conversation</button></div> */}
        </div>
      ),
      Status: '',
      Ref: list[0].referenceID,
      Details: (
        <div>
          {list.map((line, index) => {
            extraData.push(line.details);
            extraData.push(line.salesAssociateNm);

            let newDatetime = {};
            this.dateTimeFormat(newDatetime, line.dateTime);
            return (
              <div key={index} className="details_podium">
                <div className={line.salesAssociateNm ? 'agent' : 'customer'}>
                  {line.details}
                </div>
                <div
                  className={`${
                    line.salesAssociateNm ? 'agent' : 'customer'
                  } time`}
                >
                  {line.salesAssociateNm} {newDatetime['Time']}
                </div>
                {/* {line.breakLine &&<hr/>} */}
                {/* {line.breakLine && <div class="separator">{line.breakLineText}</div>} */}
              </div>
            );
          })}
        </div>
      ),
      OrderNumber: '',
      Icon: customer360Icon.Chat.default,
      extraInfoData: extraData,
    };
    if (dateTime) this.dateTimeFormat(formattedItem, dateTime);
    return formattedItem;
  }

  formatItemDispatchTrack(list) {
    // let salesAssociateNm = ''
    // let profitCenterNm = ''
    // list.forEach(item => {
    //     if (item.salesAssociateNm) salesAssociateNm = item.salesAssociateNm
    //     if (item.profitCenterNm) profitCenterNm = item.profitCenterNm
    // })
    let dateTime = list[0].dateTime;
    let extraData = [];

    let startedAt = {};
    let signed = {};
    let finishedAt = {};
    if (list[0].startedAt) this.dateTimeFormat(startedAt, list[0].startedAt);
    if (list[0].signed) this.dateTimeFormat(signed, list[0].signed);
    if (list[0].finishedAt) this.dateTimeFormat(finishedAt, list[0].finishedAt);

    let Remarks = (
      <div>
        <div>
          With <span className="hyperlink">{list[0].author}</span> from{' '}
          <span className="hyperlink">Delivery</span>
        </div>
        {/* <div>Delivery Requested on {helper.formatDate(item.requestDate)}</div> */}
        {/* <div>Delivery Scheduled on {helper.formatDate(item.scheduledAt)}</div>
        {item.startedAt && <div>Driver Started Delivery on {startedAt.Date} {startedAt.Time}</div>}
        {item.signed && <div>Customer Signed on {signed.Date} {signed.Time}</div>} */}
        {list[0].finishedAt && (
          <div>
            Driver {list[0].status} Delivery on {finishedAt.Date}{' '}
            {finishedAt.Time}
          </div>
        )}
      </div>
    );

    // list.forEach(item=>{
    //     // details +=(<div>
    //     //     <div>Delivery Scheduled on {helper.formatDate(item.scheduledAt)}</div>
    //     //     {item.startedAt && <div>Driver Started Delivery on {startedAt.Date} {startedAt.Time}</div>}
    //     //     {item.signed && <div>Customer Signed on {signed.Date} {signed.Time}</div>}{item.details}
    //     // </div>)
    //     console.log("TONY", item)
    // })

    extraData.push('Delivery');
    extraData.push('on');
    extraData.push('Driver');
    extraData.push('Scheduled');
    extraData.push('Started');
    extraData.push('Customer');
    extraData.push('Signed');

    let formattedItem = {
      Type: 'Dispatch Track',
      Title: 'Dispatch Track Comments',
      Remarks: Remarks,
      Status: list[0].status,
      Details: (
        <div className="details_background">
          <div>
            Delivery Scheduled on {helper.formatDate(list[0].scheduledAt)}
          </div>
          {list[0].startedAt && (
            <div>
              Driver Started Delivery on {startedAt.Date} {startedAt.Time}
            </div>
          )}
          {list[0].signed && list[0].status === 'Finished' && (
            <div>
              Customer Signed on {signed.Date} {signed.Time}
            </div>
          )}
          {list.map((item, i) => {
            extraData.push(item.details);
            return <div key={i}>{item.details}</div>;
          })}

          {false &&
            list.map((item, i) => {
              let lineStartedAt = {};
              let lineSigned = {};
              // let lineFinishedAt = {}
              let lineDateTime = {};
              if (item.startedAt)
                this.dateTimeFormat(lineStartedAt, item.startedAt);
              if (item.signed) this.dateTimeFormat(lineSigned, item.signed);
              // if(item.finishedAt) this.dateTimeFormat(lineFinishedAt, item.finishedAt)
              if (item.dateTime)
                this.dateTimeFormat(lineDateTime, item.dateTime);
              return (
                <div key={i} className="details_dispatchTrack">
                  <div className="details_background details_dispatchTrack">
                    <div>
                      Delivery Scheduled on{' '}
                      {helper.formatDate(item.scheduledAt)}
                    </div>
                    {item.startedAt && (
                      <div>
                        Driver Started Delivery on {lineStartedAt.Date}{' '}
                        {lineStartedAt.Time}
                      </div>
                    )}
                    {item.signed && (
                      <div>
                        Customer Signed on {lineSigned.Date} {lineSigned.Time}
                      </div>
                    )}
                    {item.details}
                  </div>
                  {<div className="time">{lineDateTime.Time}</div>}
                </div>
              );
            })}
          {/*<div> Delivery Scheduled on {helper.formatDate(list[0].scheduledAt)}</div>
            {list[0].startedAt && <div>Driver Started Delivery on {startedAt.Date} {startedAt.Time}</div>}
            {list[0].signed && <div>Customer Signed on {signed.Date} {signed.Time}</div>}
            {list[0].details} */}
          {/* {details} */}
        </div>
      ),
      OrderNumber: list[0].salesDocNbr,
      Icon: customer360Icon.DispatchTrack.default,
      extraInfoData: extraData,
    };

    if (dateTime) this.dateTimeFormat(formattedItem, dateTime);
    return formattedItem;
  }

  cordialEmailStatus(status) {
    let newStatus = '';
    switch (status) {
      case 'bounce':
        newStatus = 'Bounced';
        break;
      case 'click':
        newStatus = 'Clicked';
        break;
      case 'complaint':
        newStatus = 'Complaint';
        break;
      case 'message-sent':
        newStatus = 'Sent';
        break;
      case 'open':
        newStatus = 'Opened';
        break;
      case 'optout':
        newStatus = 'Opted Out';
        break;
      case 'store_quote':
        newStatus = 'Quote Sent';
        break;
      default:
    }
    return newStatus;
  }

  async getdata(orderNum) {
    this.setState({ isloading: this.state.isloading + 1 }, async () => {
      // let date = new Date().getFullYear() + "-" + helper.formatNumber2(new Date().getMonth() - 1, 2) + "-01"; //orginal code
      let date = new Date();

      date.setMonth(date.getMonth() - 12); //Minus 12 month, index start at 0 to make sure date is 3 month prior

      let dateString =
        date.getFullYear() +
        '-' +
        helper.formatNumber2(date.getMonth() + 1, 2) +
        '-01';

      // let params = `?startDate=${dateString}&customerId=${this.state.custID}`; //+ /*"&orderNumber="+ (orderNum || this.state.orderNum)+*/ "&customerId="+ this.state.custID

      let orderNumList = '';
      if (orderNum.length > 0) {
        // params += `&orderNumber=`
        orderNum.forEach((num, index) => {
          //params += num
          orderNumList += num;
          if (index !== orderNum.length - 1) orderNumList += `,`; //params += `,`
        });
      }

      let payload = {
        customerId: this.state.custID,
        orderNumber: orderNumList,
        startDate: dateString,
        endDate: '',
      };
      // console.log("params", params)
      console.log('payload', payload);
      // if (orderNum) params += "&orderNumber=" + orderNum
      // else params += "&customerId=" + this.state.custID
      // await fetch(helper.getAPIHost() + "/api/Customer360/getVisualization" + params, helper.apiHeaders())
      await fetch(helper.getAPIHost() + '/api/Customer360/getVisualization', {
        method: 'POST',
        body: JSON.stringify(payload),
        ...helper.apiHeaders(),
      }).then(async (response) => {
        let res = await response.json();
        if (res.status === 'OK') {
          let list = this.state.viewList;
          let uniqueDate = this.state.dateFilter;
          //let uniqueMonth = this.state.monthDisplay;
          let uniqueType = this.state.typeFilter;
          let uniqueOrder = this.state.orderFilter;
          console.log('Raw: ', res.result);
          let podiumList = {};
          let dispatchTrackList = {};
          let cordialList = {};
          console.log(res.result);
          res.result.forEach((item, index) => {
            switch (item.source) {
              case 'Podium Conversation':
                if (helper.getSprint() > 20) {
                  if (!podiumList[item.referenceID])
                    podiumList[item.referenceID] = [];
                  podiumList[item.referenceID].push(item);
                }
                break;
              case 'Cordial':
                if (helper.getSprint() > 20) {
                  if (
                    item.messageName.toUpperCase().includes('IN STORE') ||
                    item.messageName.toUpperCase().includes('IN-STORE')
                  ) {
                    if (!cordialList[item.referenceID])
                      cordialList[item.referenceID] = [];

                    cordialList[item.referenceID].push(item);
                  } else {
                    //if (!cordialList[item.referenceID])
                    cordialList[item.referenceID + index] = [];
                    cordialList[item.referenceID + index].push(item);
                  }
                } else {
                  let formattedItem = this.formatitem(item);
                  if (
                    uniqueDate.indexOf(formattedItem.Date) === -1 &&
                    formattedItem.Date
                  ) {
                    uniqueDate.push(formattedItem.Date);
                  }
                  // if (uniqueMonth.indexOf(parseInt(formattedItem.Date.split('/')[0]) - 1) === -1 && formattedItem.Date) {
                  //     uniqueMonth.push(parseInt(formattedItem.Date.split('/')[0]) - 1)
                  // }
                  if (
                    uniqueType.indexOf(formattedItem.Type) === -1 &&
                    formattedItem.Type
                  ) {
                    uniqueType.push(formattedItem.Type);
                  }
                  if (
                    uniqueOrder.indexOf(formattedItem.OrderNumber) === -1 &&
                    formattedItem.OrderNumber
                  ) {
                    uniqueOrder.push(formattedItem.OrderNumber);
                  }
                  list.push(formattedItem);
                }

                break;
              case 'Dispatch Track':
                if (helper.getSprint() > 20) {
                  let dateTime = {};
                  this.dateTimeFormat(dateTime, item.dateTime);
                  if (
                    !dispatchTrackList[
                      item.author + dateTime.Date + item.salesDocNbr
                    ]
                  )
                    dispatchTrackList[
                      item.author + dateTime.Date + item.salesDocNbr
                    ] = [];
                  dispatchTrackList[
                    item.author + dateTime.Date + item.salesDocNbr
                  ].push(item);
                }
                break;
              default:
                if (
                  helper.getSprint() > 20 ||
                  (item.source !== 'Google Review' &&
                    item.source !== 'Calendly')
                ) {
                  let formattedItem = this.formatitem(item);
                  if (
                    uniqueDate.indexOf(formattedItem.Date) === -1 &&
                    formattedItem.Date
                  ) {
                    uniqueDate.push(formattedItem.Date);
                  }
                  // if (uniqueMonth.indexOf(parseInt(formattedItem.Date.split('/')[0]) - 1) === -1 && formattedItem.Date) {
                  //     uniqueMonth.push(parseInt(formattedItem.Date.split('/')[0]) - 1)
                  // }
                  if (
                    uniqueType.indexOf(formattedItem.Type) === -1 &&
                    formattedItem.Type
                  ) {
                    uniqueType.push(formattedItem.Type);
                  }

                  if (
                    uniqueOrder.indexOf(formattedItem.OrderNumber) === -1 &&
                    formattedItem.OrderNumber
                  ) {
                    uniqueOrder.push(formattedItem.OrderNumber);
                  }
                  list.push(formattedItem);
                }
                break;
            }
          });

          // Process Cordial Conversation
          Object.keys(cordialList).forEach((key) => {
            cordialList[key].sort(helper.sort('dateTime'));
            let item = cordialList[key][cordialList[key].length - 1];

            //for BCP-1725 Cordial- Grouping and Price Display
            let groupItem = [];
            cordialList[key].forEach((val) => {
              groupItem.push({
                itemNbr: val.itemNbr,
                article: val.article,
                quantity: val.quantity,
                price: val.price,
              });
            });
            item.groupItem = groupItem;

            let formattedItem = this.formatitem(item);
            if (
              uniqueDate.indexOf(formattedItem.Date) === -1 &&
              formattedItem.Date
            ) {
              uniqueDate.push(formattedItem.Date);
            }
            // if (uniqueMonth.indexOf(parseInt(formattedItem.Date.split('/')[0]) - 1) === -1 && formattedItem.Date) {
            //     uniqueMonth.push(parseInt(formattedItem.Date.split('/')[0]) - 1)
            // }
            if (
              uniqueType.indexOf(formattedItem.Type) === -1 &&
              formattedItem.Type
            ) {
              uniqueType.push(formattedItem.Type);
            }
            if (
              uniqueOrder.indexOf(formattedItem.OrderNumber) === -1 &&
              formattedItem.OrderNumber
            ) {
              uniqueOrder.push(formattedItem.OrderNumber);
            }
            list.push(formattedItem);
          });

          //Process Podium Conversation
          Object.keys(podiumList).forEach((key) => {
            podiumList[key].sort(helper.sort('dateTime'));
            let groupbydatelist = [];
            let newpodiumList = [];
            podiumList[key].forEach((value) => {
              value.checkdate = value.dateTime;
              let nextDate = {};
              value.checkdate = value.checkdate + 'Z'; //Treat as UTC
              this.dateTimeFormat(nextDate, value.checkdate);
              if (groupbydatelist.indexOf(nextDate.Date) === -1) {
                groupbydatelist.push(nextDate.Date);
              }
            });

            groupbydatelist.forEach((val) => {
              let item = [];
              podiumList[key].forEach((value) => {
                let nextDate = {};
                this.dateTimeFormat(nextDate, value.checkdate);
                if (val === nextDate.Date) {
                  item.push(value);
                }
              });
              newpodiumList.push(item);
            });

            newpodiumList.forEach((value) => {
              // let formattedItem = this.formatItemPodium(podiumList[key],)
              let formattedItem = this.formatItemPodium(value);

              if (
                uniqueDate.indexOf(formattedItem.Date) === -1 &&
                formattedItem.Date
              ) {
                uniqueDate.push(formattedItem.Date);
              }
              // if (uniqueMonth.indexOf(parseInt(formattedItem.Date.split('/')[0]) - 1) === -1 && formattedItem.Date) {
              //     uniqueMonth.push(parseInt(formattedItem.Date.split('/')[0]) - 1)
              // }
              if (
                uniqueType.indexOf(formattedItem.Type) === -1 &&
                formattedItem.Type
              ) {
                uniqueType.push(formattedItem.Type);
              }
              if (
                uniqueOrder.indexOf(formattedItem.OrderNumber) === -1 &&
                formattedItem.OrderNumber
              ) {
                uniqueOrder.push(formattedItem.OrderNumber);
              }
              list.push(formattedItem);
            });
          });

          //Process Dispatch Track
          Object.keys(dispatchTrackList).forEach((key) => {
            dispatchTrackList[key] = dispatchTrackList[key].sort(
              helper.sort('dateTime', 'desc'),
            );

            let formattedItem = this.formatItemDispatchTrack(
              dispatchTrackList[key],
            );

            if (
              uniqueDate.indexOf(formattedItem.Date) === -1 &&
              formattedItem.Date
            ) {
              uniqueDate.push(formattedItem.Date);
            }
            // if (uniqueMonth.indexOf(parseInt(formattedItem.Date.split('/')[0]) - 1) === -1 && formattedItem.Date) {
            //     uniqueMonth.push(parseInt(formattedItem.Date.split('/')[0]) - 1)
            // }
            if (
              uniqueType.indexOf(formattedItem.Type) === -1 &&
              formattedItem.Type
            ) {
              uniqueType.push(formattedItem.Type);
            }
            if (
              uniqueOrder.indexOf(formattedItem.OrderNumber) === -1 &&
              formattedItem.OrderNumber
            ) {
              uniqueOrder.push(formattedItem.OrderNumber);
            }
            list.push(formattedItem);
          });

          this.setState({
            completedProcess: this.state.completedProcess + 1,
          });

          let NewtypeFilter = [];
          let selectedTypeFilter = [];
          NewtypeFilter.push({ label: 'All', value: '*' });

          list.forEach((data, index) => {
            //All dropdown value for type
            if (!NewtypeFilter.some((filter) => filter.value === data.Type)) {
              NewtypeFilter.push({ label: data.Type, value: data.Type });
            }

            //Set default selected with order #
            if (
              data.OrderNumber?.length > 0 &&
              this.state.orderNum === data.OrderNumber
            ) {
              if (
                !selectedTypeFilter.some((filter) => filter.value === data.Type)
              ) {
                selectedTypeFilter.push({ label: data.Type, value: data.Type });
              }
            }

            data.isexpand = undefined;
            data.id = index;
          });

          if (selectedTypeFilter.length === 0) {
            selectedTypeFilter = NewtypeFilter;
          }

          this.setState(
            {
              viewList: list.sort(helper.sort('rawDateTime', 'desc')),
              dateFilter: uniqueDate.sort((a, b) => {
                if (new Date(a) > new Date(b)) return -1;
                else if (new Date(a) < new Date(b)) return 1;
                return 0;
              }),
              typeFilter: uniqueType.sort(),
              NewtypeFilter: NewtypeFilter.sort((a, b) =>
                a.label > b.label ? 1 : -1,
              ),
              NewtypeFilterValue: selectedTypeFilter,
              orderFilter: uniqueOrder.sort(),
              orderFilterValue: uniqueOrder.includes(this.state.orderNum)
                ? this.state.orderNum
                : '',
              // monthDisplay: uniqueMonth.sort((a, b) => b - a),
              progress:
                Math.ceil(
                  this.state.completedProcess / this.state.completedProcess,
                ) * 100,
              isloading: this.state.isloading - 1,
            },
            () => {
              this.getMonthList();
              console.log('filter by month :', this.state.monthDisplay);
              console.log('ViewList', this.state.viewList);
            },
          );
        }
      });
    });
  }

  getMonthList() {
    let monthList = [];
    this.state.viewList
      .filter((data) => this.filterJob(data))
      .forEach((data) => {
        let month = new Date(data.Date).getMonth();
        let year = new Date(data.Date).getFullYear();
        let expand = undefined;
        if (monthList.some((e) => e.month === month && e.year === year)) {
          return; //Skip existing month/year
        }

        monthList.push({
          month,
          year,
          expand,
        });

        //Sort array
        monthList.sort((a, b) => {
          return b.year - a.year || b.month - a.month;
        });
      });
    this.setState({ monthDisplay: monthList }, () =>
      this.refreshSearchNextData(this.state.searchKeyword),
    );
  }

  // async getCustomerProfile() {
  //     this.setState({custProfileIsloading: true}, async()=>{
  //         await fetch(helper.getAPIHost() + "/api/Customer360/getCustomersTimelineProfile?customerID=" + this.state.custID, helper.apiHeaders())
  //         .then(async response => {
  //             let res = await response.json()
  //             if (res.status === 'OK') {
  //                 console.log("getCustomersTimelineProfile: ", res.result)
  //                 this.setState({customerProfile:res.result, custProfileIsloading: false})
  //             }else{
  //                 this.setState({customerProfile:{}, custProfileIsloading: false})
  //             }
  //         }).catch(err=>{
  //             this.setState({customerProfile:{}, custProfileIsloading: false})

  //         })
  //     })

  // }

  async getOrderList() {
    this.setState({
      numOfProgress: this.state.numOfProgress + 1,
    });

    let url = `/api/Customer360/getOrdersV2?customerId=${
      this.state.custID
    }&isBobSquadMgr=${localStorage.getItem('BobSquidManagerView') || false}`;

    if (this.state.isExternal) {
      //If external iframe then add order number to the url
      url = url + '&orderNbr=' + this.state.orderNum;
    }

    await fetch(helper.getAPIHost() + url, helper.apiHeaders())
      // await fetch(helper.getAPIHost() + "/api/Customer360/getOrders?customerId=" + this.state.custID, helper.apiHeaders())
      .then(async (response) => {
        let res = await response.json();
        if (res.status === 'OK') {
          console.log('getOrdersV2 ', res.result);
          let orderNumList = [];

          res.result.forEach((r) => {
            // this.getdata(r.orderNumber)
            orderNumList.push(r.orderNumber);
            if (r.currentOrder) {
              let nextList = this.state.nextList;
              let nextItem = {};
              let date = r.date === 'TODAY' ? r.date : r.date?.split(' ')[0];
              nextItem['Date'] = date;
              nextItem['Title'] = 'Order #' + r.orderNumber;
              nextItem['message'] = r.message;
              nextItem['type'] = r.type;
              nextItem['followUpId'] = r.followUpId;
              nextItem['orderNumber'] = r.orderNumber;
              nextItem['bucketType'] = r.bucketType;
              nextItem['orderStatus'] = r.orderStatus;
              let empty = true;

              if (r.type === 'FollowUp') {
                empty = false;
                nextItem['Title'] = 'Reminder of Follow-Up';
              } else {
                let dw = r.bucketType.toLowerCase();
                r.bucketId.forEach((id) => {
                  let bucketName = '';
                  Object.keys(this.state.enums[dw]).forEach((bucketKey) => {
                    if (
                      this.state.enums[dw][bucketKey].value === parseInt(id) &&
                      this.state.enums[dw][bucketKey].action
                    ) {
                      empty = false;
                      bucketName = this.state.enums[dw][bucketKey].label;
                    }
                  });
                  if (bucketName)
                    nextItem['Title'] = nextItem['Title'] + ', ' + bucketName;
                });
              }

              if (!empty) {
                nextList.push(nextItem);
                this.setState({ nextList: nextList });
              }
              this.setState({
                completedProcess: this.state.completedProcess + 1,
                progress:
                  Math.ceil(
                    this.state.completedProcess / this.state.completedProcess,
                  ) * 100,
              });
            }
          });

          this.getdata(orderNumList);
        }
      });
  }

  sortdate(list) {
    let newList = list;
    let field = 'Date';
    let field2 = 'Time';
    newList.sort((a, b) => {
      if (a[field] < b[field]) {
        return 1;
      } else if (a[field] > b[field]) {
        return -1;
      }

      if (a[field2] < b[field2]) {
        return 1;
      } else if (a[field2] > b[field2]) {
        return -1;
      }
      return 0;
    });
    return newList;
  }

  filterJob(row) {
    let pass = true;
    let date = this.state.dateFilterValue;
    let order = this.state.orderFilterValue;
    let type = this.state.typeFilterValue;
    let Newtype = this.state.NewtypeFilterValue;

    //old filter
    if (date) pass = pass && row.Date === date;
    if (order) pass = pass && row.OrderNumber === order;
    if (type) pass = pass && row.Type === type;

    //new filter
    let datepass = true;
    let orderpass = true;
    let typepass = true;
    let flagPass = true;
    let commentPass = true;

    if (date) datepass = row.Date === date;
    if (order) orderpass = row.OrderNumber === order;
    if (Newtype.length === this.state.NewtypeFilter) {
      typepass = true;
    } else {
      let check = Newtype.filter((o) => o.value === row.Type);
      typepass = check.length > 0 ? true : false;
    }

    if (this.state.filterByFlag) {
      flagPass = row.flag;
    }

    if (this.state.filterByComment) {
      commentPass = !(row.bucketactiontype === true && !row.detailItems);
    }
    // console.log(row)
    // console.log(datepass +" "+orderpass+" "+typepass )
    pass = datepass & orderpass & typepass && flagPass && commentPass;
    return pass;
  }

  scrollItem = (e) => {
    // // console.log("TONY", document.getElementsByClassName("content")[0])
    // console.log("TONY", e.target.getBoundingClientRect().top)
    // console.log("TONY", e.target.offsetTop )
    // document.getElementsByClassName("content")[0].scrollTo({top: 100, behavior: "smooth"})
    // //scrollTop
  };

  componentDidMount() {
    const {
      custID,
      orderNum,
      custEmail,
      enums,
      typeFilterValue,
      NewtypeFilterValue,
      isExternal,
    } = this.props;

    ux.logScreen(
      'Customer Profile',
      'Contact Timeline',
      'CustId: ' +
        custID +
        '; OrderNum: ' +
        orderNum +
        '; path: ' +
        ux.getPath(),
    );
    console.log(custID, orderNum);
    this.setState(
      {
        custID: custID,
        custEmail: custEmail,
        orderNum: orderNum,
        enums: enums,
        typeFilterValue: typeFilterValue,
        NewtypeFilterValue: NewtypeFilterValue,
        isExternal: isExternal,
      },
      () => {
        // this.getdata()
        this.getOrderList();
        // this.getCustomerProfile()
      },
    );
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(this.props.typeFilterValue) !==
      JSON.stringify(prevProps.typeFilterValue)
    ) {
      let newarray = [];
      let i = this.props.NewtypeFilterValue.length - 1;
      newarray.push(this.props.NewtypeFilterValue[i]);

      //Reset Order Filter if Zendesk Highlight was clicked
      if (newarray[0].value.trim().toUpperCase().includes('ZENDESK')) {
        this.setState({ orderFilterValue: '' });
      }

      this.setState({ NewtypeFilterValue: newarray }, () => {
        this.getMonthList();
      });
    } else if (this.props.typeFilterValue !== prevProps.typeFilterValue) {
      this.setState({ typeFilterValue: this.props.typeFilterValue }, () => {
        this.getMonthList();
      });
    } else if (
      this.props.contactTimelimeToggle !== prevProps.contactTimelimeToggle
    ) {
      this.setState(
        {
          contactTimelimeToggle: this.props.contactTimelimeToggle,
          viewList: [],
          nextList: [],
        },
        () => {
          // this.getdata()
          this.getOrderList();
        },
      );
    }
  }

  handlefilterChanged = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.filterChange(name, value);
  };

  handleMultifilterChanged = (value, event) => {
    let NewtypeFilterValue = this.state.NewtypeFilterValue;
    // console.log(value);console.log(event)

    if (event.action === 'select-option' && event.option.value === '*') {
      NewtypeFilterValue = this.state.NewtypeFilter;
    } else if (
      event.action === 'deselect-option' &&
      event.option.value === '*'
    ) {
      NewtypeFilterValue = [];
    } else if (event.action === 'deselect-option') {
      NewtypeFilterValue = value.filter((o) => o.value !== '*');
      // } else if (value.length === this.options.length - 1) {
      //     NewtypeFilterValue = this.state.NewtypeFilter;
    } else {
      NewtypeFilterValue = value;
    }

    this.setState({ NewtypeFilterValue: NewtypeFilterValue }, () => {
      this.getMonthList();
    });
  };

  filterChange(name, value) {
    let toset = {};
    toset[name] = value;
    this.setState(toset, () => {
      this.getMonthList();
    });
  }

  podiumModalToggle = async (list, event) => {
    this.setState({
      podiumModal: !this.state.podiumModal,
      podiumModalDataList: list,
    });
  };

  openFollowUpModal = (uid, message, orderNumber, type) => {
    let reminderModal = !this.state.reminderModal;
    // console.log(uid+"-"+message+"-"+"-"+orderNumber)
    // let uid = (uid)?uid:"";
    // let message = (message)?message:"";
    // let orderNumber = (orderNumber)?orderNumber:"";
    if (type !== 'FollowUp') {
      reminderModal = false;
    }

    this.setState({
      reminderModal: reminderModal,
      reminderUid: uid,
      remindermessage: message,
      reminderordernumber: orderNumber,
    });
  };

  reminderDateChange(date) {
    console.log(date);
    this.setState({
      reminderDate: date,
    });
  }

  submitFollowUp() {
    if (this.state.reminderDate === '') {
      alert('Please pick a date!');
    } else {
      this.setState(
        {
          reminderDateSelected: true,
        },
        () => this.updateFollowUp(),
      );
    }
  }

  reminderDateShortcut(e) {
    let type = e.target.getAttribute('type');

    let reminderDate = new Date();
    if (type === '1h') {
      reminderDate = reminderDate.setTime(
        reminderDate.getTime() + 1 * 60 * 60 * 1000,
      );
    }
    if (type === '4h') {
      reminderDate = reminderDate.setTime(
        reminderDate.getTime() + 4 * 60 * 60 * 1000,
      );
    }
    if (type === '1d') {
      reminderDate = reminderDate.setDate(reminderDate.getDate() + 1);
    }

    // console.log(reminderDate)
    this.setState({
      reminderDate: new Date(reminderDate),
      reminderType: type,
    });
  }

  updateFollowUp = async () => {
    // console.log(this.state.reminderUid+"-"+this.state.remindermessage+"-"+this.state.reminderordernumber+"-"+this.state.reminderDate+"-"+this.state.custID)
    let payload = {
      uid: this.state.reminderUid,
      customerId: this.state.custID,
      documentId: this.state.reminderordernumber,
      message: this.state.remindermessage,
      dateTime: this.state.reminderDate,
    };

    console.log(payload);

    await fetch(helper.getAPIHost() + '/api/Customer360/postFollowUp', {
      method: 'POST',
      body: JSON.stringify(payload),
      ...helper.apiHeaders(),
    })
      .then(async (res) => {
        if (res.status === 200) {
          let reminderModal = !this.state.reminderModal;
          this.notification(
            `Follow-up Update Successfully`,
            enums.notificationType.S,
          );
          this.setState(
            {
              remindermessage: '',
              reminderUid: '',
              reminderordernumber: '',
              reminderDate: new Date(),
              viewList: [],
              nextList: [],
              reminderModal: reminderModal,
              reminderType: '',
            },
            () => {
              ux.logAction(
                'Customer Profile',
                'Add Follow Up',
                'CustId: ' +
                  this.state.custID +
                  '; OrderNum: ' +
                  this.state.reminderordernumber +
                  '; path: ' +
                  ux.getPath(),
              );
              setTimeout(() => {
                this.getOrderList();
              }, 1000);
            },
          );
        }
      })
      .catch((err) => {
        this.notification(
          `Follow-up Update Failed, ${err}`,
          enums.notificationType.E,
        );
        ux.logError('Follow-up', 'postFollowUp', err, JSON.stringify(payload));
      });
  };

  notification(message, type) {
    window.message.notification(message, type);
  }

  redirectbucket = (orderNumber, bucketname, bucketType, orderStatus, e) => {
    if (bucketname === '' || orderNumber === '') {
      return;
    }
    let dw = bucketType.toLowerCase();
    let url = '';
    let pages = '';
    let bucketNo = '';
    let Status = orderStatus === 'C' ? 'true' : 'false';
    console.log(this.state.enums[dw]);
    Object.keys(this.state.enums[dw]).forEach((bucketKey) => {
      if (
        this.state.enums[dw][bucketKey].label === bucketname.trim() &&
        this.state.enums[dw][bucketKey].action
      ) {
        bucketNo = this.state.enums[dw][bucketKey].value;
      }
    });
    if (dw === 'd') {
      pages = '/openorders';
    } else if (dw === 'w') {
      pages = '/writesales';
    } else {
      pages = '/repeat';
    }

    if (bucketNo === 8 && dw?.toUpperCase() === 'W') {
      url =
        pages +
        '?bucket=' +
        bucketNo +
        '&orderNumber=' +
        this.state.custEmail +
        '&showComplete=' +
        Status;
    } else {
      url =
        pages +
        '?bucket=' +
        bucketNo +
        '&orderNumber=' +
        orderNumber +
        '&showComplete=' +
        Status;
    }

    console.log(url);

    if (url !== '') {
      window.location.href = url;
    }
  };

  setExpandAll() {
    let isExpandAll = !this.state.isExpandAll;
    let viewList = this.state.viewList;
    let monthDisplay = this.state.monthDisplay;

    viewList.forEach((row) => {
      if (isExpandAll) {
        row.isexpand = true;
      } else {
        row.isexpand = false;
      }
    });
    monthDisplay.forEach((mon) => {
      if (isExpandAll) {
        mon.expand = true;
      } else {
        mon.expand = false;
      }
    });

    this.setState({
      isExpandAll: isExpandAll,
      viewList: viewList,
      monthDisplay: monthDisplay,
    });
  }

  setExpandAllbyMonth(month, year, expand) {
    let isExpandAllbyMonth = !expand;
    let viewList = this.state.viewList;
    let monthDisplay = this.state.monthDisplay;

    monthDisplay.forEach((mon) => {
      if (mon.month === month && mon.year === year) {
        mon.expand = isExpandAllbyMonth;
      }
    });
    viewList.forEach((row) => {
      if (
        new Date(row.Date).getMonth() === month &&
        new Date(row.Date).getFullYear() === year &&
        this.filterJob(row)
      ) {
        if (isExpandAllbyMonth) {
          row.isexpand = true;
        } else {
          row.isexpand = false;
        }
      }
    });

    this.setState({
      viewList: viewList,
      monthDisplay: monthDisplay,
    });
  }

  setExpand(data) {
    let viewList = this.state.viewList;
    viewList.forEach((row) => {
      if (data.id === row.id) {
        row.isexpand = undefined;
      }
    });
    this.setState({ viewList: viewList });
  }

  setExpandMonth(data) {
    let monthDisplay = this.state.monthDisplay;
    monthDisplay.forEach((row) => {
      if (data.month === row.month) {
        row.expand = undefined;
      }
    });
    this.setState({ monthDisplay: monthDisplay });
  }

  flagUnflag = async (e, isOrderNotes) => {
    let currentTarget = e.currentTarget;
    let refUid = currentTarget.getAttribute('uid');
    let currentFlag = currentTarget.getAttribute('currentflag') === '1';

    let url = `/api/Customer360/AddRemoveTimelineFlag?RefUid=${refUid}&IsDelete=${currentFlag}&IsOrderNotes=${isOrderNotes}`;

    // if (currentFlag) {
    //   //Checking ownership before unflag
    //   let record = this.state.viewList.find(data => data.uid === parseInt(refUid))

    //   if (record.flagEmpID?.trim() !== helper.getEmplId()) {
    //     this.notification(`Can only be unflagged by the user who flagged this record`, enums.notificationType.E)
    //     return;
    //   }
    // }

    await fetch(helper.getAPIHost() + url, {
      method: 'PUT',
      ...helper.apiHeaders(),
    })
      .then(async (response) => {
        let data = await response.json();
        if (data.status === 'OK') {
          this.notification(
            `Record ${currentFlag ? 'unflagged' : 'flagged'} successfully`,
            enums.notificationType.S,
          );

          let currentList = [...this.state.viewList]; //All data list
          let currentDataIndex = currentList.findIndex(
            (data) => data.uid === parseInt(refUid),
          ); //updated record index
          let currentData = currentList[currentDataIndex]; //updated record
          currentData.flag = !currentFlag; //update to new flag
          currentData.flagEmpID = helper.getEmplId() || helper.extEmpId();
          currentData.flagBy =
            localStorage.getItem('EmplName') || helper.extUser().name;
          currentData.flagLoc =
            localStorage.getItem('StoreName') || helper.extUser().storeName;
          currentData.flagTime = new Date();

          currentList.splice(currentDataIndex, 1, currentData); //update all data list

          this.setState({ viewList: [...currentList] }, () => {
            //Force update label and value
            // currentTarget.setAttribute('currentflag', currentFlag ? 0 : 1)
            // currentTarget.innerText = currentFlag ? "Flag" : "Unflag"
          });
        } else {
          this.notification(data.errorMsg, enums.notificationType.E);

          ux.logError(
            'Customer Profile',
            'AddRemoveTimelineFlag',
            'Failed to AddRemoveTimelineFlag',
            `RefUid : ${refUid}, ${currentFlag ? 'Unflag' : 'Flag'}`,
          );
        }
      })
      .catch((error) => {
        this.notification(
          `Failed to ${currentFlag ? 'unflagged' : 'flagged'}`,
          enums.notificationType.E,
        );

        ux.logError(
          'Customer Profile',
          'AddRemoveTimelineFlag',
          'Failed to AddRemoveTimelineFlag',
          `RefUid : ${refUid}, ${currentFlag ? 'Unflag' : 'Flag'}`,
        );
      });
  };

  refreshSearchNextData(keyword) {
    if (keyword) {
      //Filter by other filter then find keyword
      let searchNextData = this.state.viewList
        .filter((row) => this.filterJob(row))
        .filter((row) => this.findSearchKeyword(row))
        .sort((a, b) => b.rawDateTime - a.rawDateTime);
      let counter = searchNextData.length;
      this.setState(
        {
          searchCount: counter,
          searchNextData: searchNextData,
          searchCurrentIndex: counter === 0 ? 0 : 1,
        },
        () => {
          if (this.state.searchIsFirst) {
            this.handleSearchNext(null);
          } else {
            this.handleSearchNext(null);
          }
        },
      );

      //console.log(searchNextData, counter)
    } else {
      this.setState(
        { searchCount: 0, searchCurrentIndex: 0, searchIsFirst: true },
        () => {
          if (this.state.searchIsFirst) {
            this.handleSearchNext(null);
          }
        },
      );
    }
  }

  findSearchKeyword(row) {
    //Search keyword
    let searchKey = this.state.searchKeyword;
    let searchKeyPass = false;

    //Remove null or empty
    row.extraInfoData = row.extraInfoData.filter((x) => x);

    if (searchKey) {
      let titlePass = row.Title
        ? row.Title.toLowerCase().includes(searchKey.toLowerCase())
          ? true
          : false
        : false;
      let extraInfoPass =
        row.extraInfoData?.length > 0
          ? row.extraInfoData.some((x) =>
              x?.toString().toLowerCase().includes(searchKey.toLowerCase()),
            )
          : false;
      searchKeyPass = titlePass || extraInfoPass;
    }

    return searchKeyPass;
  }

  handleSearchKeyword = (e) => {
    let currentTarget = e.currentTarget;
    let inputValue = currentTarget.value;

    this.setState({ searchKeyword: inputValue }, () => {
      this.refreshSearchNextData(inputValue);
    });
  };

  handleSearchNext = (next) => {
    //next  : search to bottom if true, search to top if false
    //console.clear()

    let searchCount = this.state.searchCount;
    let currentIndex = this.state.searchCurrentIndex;
    let currentSearchData = this.state.searchNextData;
    let allData = this.state.viewList;

    //console.log(next, searchCount, currentIndex, currentSearchData, allData)

    if (
      (searchCount === 0 ||
        (currentIndex + 1 > this.state.searchNextData.length && next) ||
        (currentIndex - 1 === 0 && !next)) &&
      next !== null
    ) {
      //Do nothing if there is no data displayed or already at the end
      return;
    }

    //Update new index for next search
    if (next !== null) {
      if (next) {
        currentIndex = currentIndex + 1;
      } else {
        currentIndex = currentIndex - 1;
      }
    }

    this.setState(
      {
        searchCurrentIndex: currentIndex,
      },
      () => {
        //Reset local index for array start at 0
        currentIndex = currentIndex - 1;

        if (currentIndex >= 0) {
          //Update isexpand
          allData.forEach((data, index) => {
            //console.log(index, currentIndex, currentSearchData[currentIndex], data)
            if (data.id === currentSearchData[currentIndex].id) {
              data.isexpand = true;
              data.focusRecord = 'true';
            } else {
              data.isexpand = false;
              data.focusRecord = 'false';
            }
          });

          this.setState(
            {
              viewList: allData,
              searchIsFirst: next !== null ? false : true,
            },
            () => this.searchScrollIntoView(),
          );
        }
      },
    );
  };

  searchScrollIntoView = () => {
    let highlightElem = document.querySelector('[focus-record=true]');

    if (highlightElem !== undefined && highlightElem !== null) {
      highlightElem.classList.add('temporary-highlight');

      let nonHighlightElem = document.querySelectorAll('[focus-record=false]');
      //console.log(nonHighlightElem)
      nonHighlightElem.forEach((x) =>
        x.classList.remove('temporary-highlight'),
      );

      highlightElem.scrollIntoView({
        block: 'nearest',
      });
    }
    return null;
  };

  render() {
    return (
      <div>
        {/* Contact Timeline */}
        <div className="filter contact-timeline">
          <div className="parent-div-filter">
            {/* Filter By Date */}
            <div>
              <Label>Filter by Date:</Label>
              <div className="parent-select">
                <Input
                  type="select"
                  value={this.state.dateFilterValue}
                  name="dateFilterValue"
                  onChange={this.handlefilterChanged.bind(this)}
                >
                  <option value="">All</option>
                  {this.state.dateFilter.map((date, i) => {
                    return (
                      <option value={date} key={i}>
                        {date}
                      </option>
                    );
                  })}
                </Input>
              </div>
            </div>

            {/* Filter By Type */}
            <div className="multi-check">
              <Label>Filter by Type:</Label>
              <ReactMultiSelectCheckboxes
                options={this.state.NewtypeFilter}
                checked={true}
                onChange={this.handleMultifilterChanged}
                value={this.state.NewtypeFilterValue}
              />
            </div>

            {/* Filter By Order */}
            <div>
              <Label>Filter by Order:</Label>
              <div className="parent-select">
                <Input
                  type="select"
                  value={this.state.orderFilterValue}
                  name="orderFilterValue"
                  onChange={this.handlefilterChanged.bind(this)}
                >
                  <option value="">All</option>
                  {this.state.orderFilter.map((order, i) => {
                    return (
                      <option value={order} key={i}>
                        {order}
                      </option>
                    );
                  })}
                </Input>
              </div>
            </div>

            <div className="filter-checkbox">
              {/* Filter By Flag */}
              <div>
                <Label style={{ display: 'flex', alignItems: 'center' }}>
                  Filter by Flag:
                  <Input
                    type="checkbox"
                    checked={this.state.filterByFlag}
                    name="flagOrderFilter"
                    onChange={() => {
                      this.setState(
                        { filterByFlag: !this.state.filterByFlag },
                        () => this.getMonthList(),
                      );
                    }}
                  ></Input>
                </Label>
              </div>

              {/* Filter By Comment */}
              <div>
                <Label style={{ display: 'flex', alignItems: 'center' }}>
                  Hide bucket actions with no comment:
                  <Input
                    type="checkbox"
                    checked={this.state.filterByComment}
                    name="commentOrderFilter"
                    onChange={() => {
                      this.setState(
                        { filterByComment: !this.state.filterByComment },
                        () => this.getMonthList(),
                      );
                    }}
                  ></Input>
                </Label>
              </div>
            </div>
          </div>

          <div className="parent-div-filter-expand-btn">
            <button
              className={`${
                !this.state.isExpandAll
                  ? 'iss-button-red'
                  : 'iss-button-grey grey'
              }`}
              onClick={this.setExpandAll.bind(this)}
            >
              {!this.state.isExpandAll ? 'Expand' : 'Collapse'} all notes
            </button>

            <TextField
              id="keyword-search"
              className="keyword-search"
              placeholder="Search by keyword"
              type="search"
              variant="outlined"
              onChange={this.handleSearchKeyword.bind(this)}
              value={this.state.searchKeyword}
            />
            <Popper
              id="keyword-search-count"
              open={this.state.searchKeyword}
              anchorEl={document.getElementById('keyword-search')}
              placement="bottom-end"
            >
              <div className="keyword-search-count">
                <IoIosArrowUp
                  onClick={this.handleSearchNext.bind(this, false)}
                />
                <IoIosArrowDown
                  onClick={this.handleSearchNext.bind(this, true)}
                />
                {this.state.searchCurrentIndex} of {this.state.searchCount}
              </div>
            </Popper>
          </div>
        </div>

        <div className="content">
          {this.state.isloading > 0 && (
            <div className="loading">
              <Spinner color="dark" />
              <div>Loading...</div>
            </div>
          )}
          <Accordion preExpanded={['next']} allowZeroExpanded={true}>
            <AccordionItem uuid="next">
              <AccordionItemHeading>
                <AccordionItemButton>
                  <div className="header">Next Step</div>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <div className="next">
                  <Accordion allowZeroExpanded={true} className="cust360-next">
                    {this.state.nextList.map((row, index) => {
                      var Title = row.Title.split(/[,]+/);
                      return (
                        <AccordionItem
                          className={`cust360-next-row ${
                            row.message === null || row.message === ''
                              ? 'noDetails'
                              : 'cursor'
                          }`}
                          key={index}
                        >
                          <AccordionItemHeading
                            className="cust360-row-content"
                            onClick={this.scrollItem.bind(this)}
                          >
                            <AccordionItemButton>
                              {/* <div className="cust360-next-row" > */}
                              <div className="icon"></div>
                              {/* <div className="title">{row.Title}</div> */}
                              {Title.map((val, i) => {
                                if (i === 0) {
                                  return (
                                    <>
                                      <div className="title">{val}</div>
                                      <div className="date">{row.Date}</div>
                                    </>
                                  );
                                } else {
                                  return (
                                    <div
                                      onClick={this.redirectbucket.bind(
                                        this,
                                        row.orderNumber,
                                        val,
                                        row.bucketType,
                                        row.orderStatus,
                                      )}
                                      title="Go to bucket"
                                      className="title link"
                                    >
                                      {val}
                                    </div>
                                  );
                                }
                              })}
                              <div className="status"></div>
                              {/* <div className="date">{row.Date}</div> */}
                              {row.type === 'FollowUp' && (
                                <div className="clock">
                                  <FcCalendar
                                    size={25}
                                    onClick={this.openFollowUpModal.bind(
                                      this,
                                      row.followUpId,
                                      row.message,
                                      row.orderNumber,
                                      row.type,
                                    )}
                                  />
                                </div>
                              )}
                              <div className="time"></div>
                              {/* {row.type === "FollowUp" && <div className="comment">{row.message}</div>} */}
                              {/* </div> */}
                            </AccordionItemButton>
                          </AccordionItemHeading>

                          {row.type === 'FollowUp' && (
                            <AccordionItemPanel className="cust360-row-details">
                              <div className="details">{row.message}</div>
                            </AccordionItemPanel>
                          )}
                        </AccordionItem>
                      );
                    })}
                  </Accordion>
                </div>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>

          {this.state.monthDisplay.map((mon, k) => {
            return (
              <Accordion
                preExpanded={['month']}
                allowZeroExpanded={true}
                allowMultipleExpanded={true}
                key={k}
              >
                <AccordionItem dangerouslySetExpanded={mon.expand} uuid="month">
                  <AccordionItemHeading
                    onClick={this.setExpandMonth.bind(this, mon)}
                  >
                    <AccordionItemButton>
                      <div className="header">
                        <span>
                          {helper.getMonthName(mon.month + 1)} {mon.year}
                        </span>
                      </div>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <span style={{ marginLeft: '10px' }}>
                    <button
                      className={`${
                        !mon.expand ? 'iss-button-red' : 'iss-button-grey grey'
                      }`}
                      onClick={this.setExpandAllbyMonth.bind(
                        this,
                        mon.month,
                        mon.year,
                        mon.expand,
                      )}
                      style={{ minHeight: '10px' }}
                    >
                      {!mon.expand ? 'Expand' : 'Collapse'} all{' '}
                      {helper.getMonthName(mon.month + 1)} notes
                    </button>
                  </span>
                  <AccordionItemPanel>
                    <div className="month">
                      <Accordion
                        allowZeroExpanded={true}
                        allowMultipleExpanded={true}
                        className="cust360-month"
                      >
                        {this.state.viewList
                          .sort((a, b) => b.rawDateTime - a.rawDateTime)
                          .filter(
                            (row) =>
                              new Date(row.Date).getMonth() === mon.month &&
                              new Date(row.Date).getFullYear() === mon.year &&
                              this.filterJob(row),
                          )
                          .map((row, index) => {
                            return (
                              <AccordionItem
                                className={`cust360-row ${
                                  (row.Details !== null ||
                                    row.Details === '') &&
                                  !row.bucketactiontype
                                    ? 'cursor'
                                    : 'noDetails'
                                }
                                  ${row.flag ? 'flag-highlight' : ''}
                                  `}
                                key={index}
                                dangerouslySetExpanded={row.isexpand}
                                focus-record={row.focusRecord}
                              >
                                <AccordionItemHeading
                                  className="cust360-row-content"
                                  onClick={this.setExpand.bind(this, row)}
                                >
                                  <AccordionItemButton>
                                    <div className="icon">
                                      <img src={row.Icon} alt="" />
                                      <div className="xtimeline"></div>
                                    </div>
                                    <div className="title">
                                      <div>
                                        {row.Title}
                                        <br />
                                        {row.Remarks}
                                      </div>
                                    </div>
                                    <div className="status">{row.Status}</div>
                                    <div className="date">{row.Date}</div>
                                    <div className="time">{row.Time}</div>
                                  </AccordionItemButton>
                                </AccordionItemHeading>

                                {row.Details !== null &&
                                  !row.bucketactiontype && (
                                    <AccordionItemPanel className="cust360-row-details">
                                      {['Order Notes', 'Zendesk Case'].includes(
                                        row.Type,
                                      ) ? (
                                        // For flag
                                        <div className="details">
                                          <div className="details_background order-notes-flag">
                                            <div>
                                              {row?.Type === 'Order Notes'
                                                ? ReactHtmlParser(
                                                    row.detailItems,
                                                  )
                                                : row.detailItems}
                                            </div>

                                            <RedButton
                                              uid={row.uid}
                                              currentflag={row.flag ? 1 : 0}
                                              label={
                                                row.flag ? 'Unflag' : 'Flag'
                                              }
                                              outline={false}
                                              onClick={(e) =>
                                                this.flagUnflag(
                                                  e,
                                                  row.Type === 'Zendesk Case'
                                                    ? false
                                                    : true,
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="details">
                                          {row.Details}
                                        </div>
                                      )}

                                      {row.flag && (
                                        <div className="flag-details">
                                          <span>
                                            Flagged by {row.flagBy} from{' '}
                                            {row.flagLoc}{' '}
                                            {new Date(
                                              typeof row.flagTime === 'string'
                                                ? row.flagTime + 'Z'
                                                : row.flagTime,
                                            ).toLocaleDateString('en-US', {
                                              hour: 'numeric',
                                              minute: 'numeric',
                                              hour12: true,
                                            })}
                                          </span>
                                        </div>
                                      )}
                                    </AccordionItemPanel>
                                  )}
                              </AccordionItem>
                            );
                          })}
                      </Accordion>
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            );
          })}
        </div>

        {/* Modal for update reminder    */}
        <Modal
          isOpen={this.state.reminderModal}
          toggle={this.openFollowUpModal.bind(this, null, null, null, null)}
          className="order-reminder-modal"
        >
          <ModalHeader>
            <ModalBtnClose
              onClick={this.openFollowUpModal.bind(
                this,
                null,
                null,
                null,
                null,
              )}
            />
            Follow Up
          </ModalHeader>
          <ModalBody className="reminder-order">
            <div>When would you like to be reminded to reach out?</div>

            <div className="shortchut">
              <span
                onClick={this.reminderDateShortcut.bind(this)}
                type="1h"
                className={
                  this.state.reminderType === '1h' ? 'dateselected' : ''
                }
              >
                1 Hours
              </span>
              <span
                onClick={this.reminderDateShortcut.bind(this)}
                type="4h"
                className={
                  this.state.reminderType === '4h' ? 'dateselected' : ''
                }
              >
                4 Hours
              </span>
              <span
                onClick={this.reminderDateShortcut.bind(this)}
                type="1d"
                className={
                  this.state.reminderType === '1d' ? 'dateselected' : ''
                }
              >
                Tomorrow
              </span>
            </div>

            <DatePickerCalendar
              locale={enGB}
              minimumDate={new Date()} // See Calendar props
              // maximumDate={this.state.snoozeEnd} // See Calendar props
              date={this.state.reminderDate}
              // format='dd/MM/yyyy HH:mm a'
              value={this.state.reminderDate}
              onDateChange={this.reminderDateChange.bind(this)}
            />
          </ModalBody>
          <ModalFooter>
            <button
              className="iss-button-red"
              onClick={this.submitFollowUp.bind(this)}
            >
              {' '}
              Save
            </button>
            <button
              className="iss-button-red"
              onClick={this.openFollowUpModal.bind(
                this,
                null,
                null,
                null,
                null,
              )}
            >
              {' '}
              Cancel
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

BDFCust360ContactTimeline.propTypes = {
  custID: PropTypes.string,
  orderNum: PropTypes.string,
  enums: PropTypes.object,
  typeFilterValue: PropTypes.string,
  contactTimelimeToggle: PropTypes.bool,
  NewtypeFilterValue: PropTypes.array,
};

BDFCust360ContactTimeline.defaultProps = {
  custID: '',
  orderNum: '',
  enums: {},
  typeFilterValue: '',
  contactTimelimeToggle: false,
  NewtypeFilterValue: [],
};

export default BDFCust360ContactTimeline;
