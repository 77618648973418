import React, {
  //createContext,
  useState,
  useEffect,
  useRef,
} from 'react';
import {
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import {
  ButtonGroup,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Spinner,
  Col,
  Row,
} from 'reactstrap';
import { DatePickerCalendar } from 'react-nice-dates';
import { enGB } from 'date-fns/locale';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import { components } from 'react-select';
import { Checkbox } from '@material-ui/core';
import { CommunicationContext } from './cncContext';
//Component
import {
  Dropdown,
  ModalBtnClose,
  RedButton,
} from '../../components/CommonComponent';
import { ModalCustomerProfile } from '../../components/customer-profile';
import PotentialCustomer from '../../components/potential-customer';
//SCSS
import './communication-collaboration.scss';
//Icons
import { FiCircle } from 'react-icons/fi';
import { MdArchive, MdForward, MdReply, MdUnarchive } from 'react-icons/md';
import { FaExclamationCircle, FaTrash /*FaDotCircle*/ } from 'react-icons/fa';
import { AiFillPushpin } from 'react-icons/ai';
import { DIVStoreGuideIcon } from '../../components/StoreGuideIcon';
//#region Constant
import enums from '../../enums';
const helper = require('../../utils/helper');
const ux = require('../../application-center/ux-tracking-center');
const orderLib = require('../../bdf-applications/orders-management/lib/orders');

const groupOptions = Object.freeze([
  { label: 'Store Management', value: 2 },
  { label: 'Guest Support Associates', value: 3 },
  { label: 'Outlet Experience Specialists', value: 4 },
  // { label: 'Bob’s Squad', value: 5 },
  { label: 'Guest Experience Specialists', value: 6 },
]);

const bobSquadGroupOptions = Object.freeze([
  { label: "Bob's Squad Associates", value: 5 },
  { label: "Bob's Squad Management", value: 2 },
]);

const CommunicationType = Object.freeze({
  MedalliaSurvey: 1,
  Shared: 2,
  Notes: 3,
  FollowUp: 4,
  // UnitPriceChanged: 5,
  // SalesAssociateChanged: 6,
  GX2Complete: 7,
  RequesToContact: 8,
});

const Folder = Object.freeze({
  Received: 0,
  Sent: 1,
  Archive: 2,
});

const read = 'READ';
const unread = 'UNREAD';
const pinned = 'PINNED';
const unpinned = 'UNPINNED';
//#endregion

// export const useCommunication = () => {
//   return useContext(CommunicationContext);
// };

export default function CommunicationCollaboration() {
  const [headerNotAck, setHeaderCount] = useState(0);
  const [headerUnread, setHeaderUnreadCount] = useState(0);
  const [headerPinned, setHeaderPinnedCount] = useState(0);

  useEffect(() => {
    let homeBadge = document.getElementById('comm-collab-badge');
    let homeBadgeUnAck = document.getElementById('comm-collab-badge-unack');

    if (homeBadge && homeBadgeUnAck) {
      // Unread
      if (headerUnread > 0) {
        homeBadge.innerHTML = headerUnread;
        homeBadge.style.display = 'block';
      } else {
        homeBadge.style.display = 'none';
      }

      //Un-acknowledged
      if (headerNotAck > 0) {
        homeBadgeUnAck.innerHTML = headerNotAck;
        homeBadgeUnAck.style.display = 'block';
      } else {
        homeBadgeUnAck.style.display = 'none';
      }

      //Unread Tab title
      document.title = `BobBoost ${
        headerUnread > 0 ? `(${headerUnread})` : ''
      }`;

      //update context value here
      CommunicationContext.headerNotAck = headerNotAck;
      CommunicationContext.headerUnread = headerUnread;
    }
  }, [headerUnread, headerNotAck]);

  return (
    <div className="communication-collaboration">
      <div>
        <AccordionItem uuid="cc" id={'BDFCommunicationCollaboration'}>
          <AccordionItemHeading>
            <AccordionItemButton>
              {'Communication and Collaboration'}{' '}
              <DIVStoreGuideIcon callerMenuCode={'CnC'} />
              <div className="not-ack-stats">
                <span className="title">
                  Not Acknowledged Notes:{' '}
                  <span
                    style={{
                      color: `${headerNotAck > 0 ? 'red' : 'inherit'}`,
                    }}
                    key={headerNotAck}
                  >
                    {headerNotAck}
                  </span>
                </span>
              </div>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <CommunicationCollaborationDetail
              setHeaderCount={setHeaderCount}
              headerNotAck={headerNotAck}
              setHeaderUnreadCount={setHeaderUnreadCount}
              headerUnread={headerUnread}
              setHeaderPinnedCount={setHeaderPinnedCount}
              headerPinned={headerPinned}
            />
          </AccordionItemPanel>
        </AccordionItem>
      </div>
    </div>
  );
}

function navigateToBucket(e) {
  let data = e;
  let url = '';

  if (data) {
    if (data.ticketGroupName === 'D') {
      url =
        '/openorders?bucket=' +
        data.messageAction +
        '&orderNumber=' +
        data.salesDocNbr +
        '&showComplete=' +
        (data.status === 'C');
    } else if (data.ticketGroupName === 'W') {
      url =
        '/writesales?bucket=' +
        data.messageAction +
        '&orderNumber=' +
        data.salesDocNbr +
        '&showComplete=' +
        (data.status === 'C');
    } else if (data.ticketGroupName === 'R') {
      url =
        '/repeat?bucket=' +
        data.messageAction +
        '&orderNumber=' +
        data.salesDocNbr +
        '&showComplete=' +
        (data.status === 'C');
    }
    if (url !== '') {
      console.log(url);
      window.redirect(url);
    }
  }
}

export const receivedFolderLogic = (data) => {
  let currentUserID = localStorage.getItem('EmplId').toString();
  let currentUserGroup = localStorage.getItem('UserGroupId');

  if (!data) {
    //Do not process
    return;
  }

  data = JSON.parse(JSON.stringify(data));

  data = data.filter(
    (set) =>
      set.messageList.some(
        (individualData) =>
          //BCP-2245 : By folder archive
          individualData.archiveReceive?.toUpperCase().trim() !== 'Y' &&
          //BCP-2245 : Received self-follow up must be greater or equal to today
          (individualData.commType === CommunicationType.FollowUp
            ? new Date(individualData.scheduledAt).setHours(0, 0, 0, 0) <=
              new Date().setHours(0, 0, 0, 0)
            : true) &&
          //OR condition below:-
          (individualData.salesAssociate === currentUserID || //For direct notes
            //For current store and current usergroup
            (!individualData.acknowledgeBy &&
              individualData.referenceID
                ?.split(',')
                .some((x) => x === currentUserGroup) &&
              individualData.targetProfitCenter === helper.getStorelId()) ||
            //For current store and current bobsquad user group
            (!individualData.acknowledgeBy &&
              individualData.referenceID
                ?.split(',')
                .some((x) => x === currentUserGroup) &&
              individualData.targetProfitCenter === 'squad' &&
              localStorage.getItem('isBobSquad') === 'true') ||
            //For individual selection
            individualData.empList?.some((x) => x.empId === currentUserID) ||
            //For notes not for manager but should see it
            ((individualData.targetProfitCenter === helper.getStorelId() ||
              (individualData.targetProfitCenter === 'squad' &&
                localStorage.getItem('isBobSquad') === 'true')) &&
              (currentUserGroup === '2' ||
                currentUserGroup === '3' ||
                currentUserGroup === '26')) ||
            //Acknowledge Group Notes only visible to the person who acknowledge for non-managers
            (individualData.acknowledgeBy &&
              !['2', '3'].includes(currentUserGroup) &&
              individualData.acknowledgeBy === currentUserID) ||
            //For medallia survey not for manager but should see it
            (individualData.commType === CommunicationType.MedalliaSurvey &&
              (currentUserGroup === '2' ||
                currentUserGroup === '3' ||
                currentUserGroup === '26')) ||
            //BCP-2040 : GXS 2
            (individualData.commType === CommunicationType.GX2Complete &&
              individualData.referenceID === currentUserID) ||
            //BCP-2340 : Shared Order
            (individualData.commType === CommunicationType.Shared &&
              individualData.referenceID === currentUserID) ||
            //BCP-2363 : Request to contact, manager should see all
            (individualData.commType === CommunicationType.RequesToContact &&
              (currentUserGroup === '2' ||
                currentUserGroup === '3' ||
                currentUserGroup === '26'))),
      ) && !set.isArchive,
  );

  //Filter out not a recipient for non-manager
  if (!['2', '3', '26'].includes(currentUserGroup)) {
    data.forEach((set) => {
      set.messageList = set.messageList.filter(
        (x) =>
          x.referenceID?.split(',').some((x) => x === currentUserGroup) ||
          x.salesAssociate === currentUserID ||
          x.referenceID === currentUserID ||
          x.empList?.some((x) => x.empId === currentUserID),
      );
    });
  }

  //Filter out not a group recipient for managers from reply other user group 2/3 stores
  if (['2', '3', '26'].includes(currentUserGroup)) {
    data.forEach((set) => {
      if (
        set.messageList.some((x) => !x.salesAssociateNm) &&
        set.commType === CommunicationType.Notes
      ) {
        //Proceed if any of messageList contains group message
        set.messageList = set.messageList.filter((x) => {
          //Return only the one for current user store
          return x.targetProfitCenter === helper.getStorelId() || //Direct target
            (x.targetProfitCenter === 'squad' &&
              localStorage.getItem('isBobSquad') === 'true')
            ? true
            : false;
        });
      }
    });
  }

  return data.filter((set) => set.messageList?.length > 0);
};

function CommunicationCollaborationDetail({
  headerNotAck,
  setHeaderCount,
  setHeaderUnreadCount,
  headerUnread,
  setHeaderPinnedCount,
  headerPinned,
}) {
  const [commCollectionOriginal, setOriginalData] = useState([]);
  const [commCollection, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [filterType, setFilterType] = useState([]);
  const [selectedFilterType, setSelectedFilterType] = useState([]);
  const [salesAssociateList, setSalesAssociateList] = useState([]);
  const [selectedSalesAssociate, setSelectedSalesAssociate] = useState('');
  const [showCreateNewCollaboration, toggleCreateNewModal] = useState(false);
  const [filterAcknowledge /*, setFilterAcknowledge*/] = useState([
    { label: 'All', value: 0 },
    { label: 'Acknowledged Notes', value: 1 },
    { label: 'Not Acknowledged Notes', value: 2 },
  ]);
  const [selectedFilterAcknowledge, setSelectedFilterAcknowledge] = useState({
    label: 'All',
    value: '',
  });
  const [disabledFilterAcknowledge, setDisableFilterAcknowledge] =
    useState(false);
  const [filterFloaterRecipient /* setFilterFloaterRecipient*/] = useState([
    { label: 'All', value: 0 },
    { label: 'Notes to Me', value: 1 },
  ]);
  const [selectedFloaterRecipient, setSelectedFloaterRecipient] = useState({
    label: 'All',
    value: 0,
  });
  const [viewType, setViewType] = useState(Folder.Received); //Default 0 - Received, 1 - Sent
  const [doNotRefresh, setDoNotRefresh] = useState(false);
  const [isReplyData, setIsReply] = useState(null);
  const [isForwardData, setIsForward] = useState(null);
  const [selectedUnreadPinned, setSelectedUnreadPinned] = useState(null);

  let currentUserGroup = localStorage.getItem('UserGroupId');

  async function getData() {
    setLoading(true);

    let url = '';
    url = helper.getAPIHost() + '/api/Customer360/getCommCollabGroupMessage';

    ux.logAction(
      'Communication and Collaboration',
      'Post data to API: getCommCollabGroupMessage',
      url,
    );

    await fetch(url, {
      method: 'POST',
      ...helper.apiHeaders(),
    })
      .then(async (response) => {
        let data = await response.json();
        if (data.status === 'OK') {
          //Fix dateTime
          if (data.result.message.length > 0) {
            //Collaboration stored as UTC. Revert to user timezone
            data.result.message
              .filter(
                (rec) =>
                  rec.commType === CommunicationType.Shared ||
                  rec.commType === CommunicationType.Notes ||
                  rec.commType === CommunicationType.FollowUp ||
                  rec.commType === CommunicationType.GX2Complete,
              )
              .forEach((rec) => {
                rec.lastMessageTimeStamp = rec.lastMessageTimeStamp + 'Z';
                rec.messageList.forEach((indRec) => {
                  indRec.dateTime = new Date(indRec.dateTime + 'Z').toString();
                });
              });

            //Add manually for displayCreatedTime in Reply/Forward with ParentUID
            data.result.message.forEach((rec) => {
              rec.messageList.forEach((indRec) => {
                indRec.lastMessageTimeStamp = rec.lastMessageTimeStamp;
                indRec.parentUID = rec.parentUID;
              });
            });

            localStorage.setItem(
              'LastTrigger',
              data.result.message[0].triggerdate === undefined
                ? new Date().toISOString()
                : data.result.message[0].triggerdate,
            );
          }

          setOriginalData([...data.result.message]);
          setData([...data.result.message]);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log('api/Customer360/getCommCollabGroupMessage error:', error);
      });
  }

  const useInterval = (callback, delay) => {
    const savedCallback = useRef();

    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }, [delay]);
  };

  const RefreshNotesFollowUp = () => {
    let timerInMS = parseInt(
      JSON.parse(localStorage.getItem('Parameters'))?.UI_CNC_SCHEDULER || 60000,
    );
    let lastTrigger = localStorage.getItem('LastTrigger');
    let payload = {
      triggerdate:
        lastTrigger === 'undefined' ||
        lastTrigger === 'null' ||
        lastTrigger === undefined ||
        lastTrigger === null
          ? new Date().toISOString()
          : lastTrigger,
    };

    useInterval(async () => {
      //Call API to update all notes
      let url = helper.getAPIHost() + '/api/Customer360/getNotesUpdate';
      ux.logAction(
        'Communication and Collaboration',
        'Get Data from API: getNotesUpdate',
        url,
      );

      await fetch(helper.getAPIHost() + '/api/Customer360/getNotesUpdate', {
        method: 'POST',
        body: JSON.stringify(payload),
        ...helper.apiHeaders(),
      })
        .then(async (response) => {
          let data = await response.json();
          if (data.status === 'OK') {
            if (data.result.currentNote?.length > 0) {
              //Filter out notes and follow-up
              let newData = [...data.result.currentNote];

              //Collaboration stored as UTC. Revert to user timezone
              newData
                .filter(
                  (rec) =>
                    rec.commType === CommunicationType.Shared ||
                    rec.commType === CommunicationType.Notes ||
                    rec.commType === CommunicationType.FollowUp ||
                    rec.commType === CommunicationType.GX2Complete,
                )
                .forEach((rec) => {
                  rec.lastMessageTimeStamp = rec.lastMessageTimeStamp + 'Z';
                  rec.messageList.forEach((indRec) => {
                    indRec.dateTime = new Date(
                      indRec.dateTime + 'Z',
                    ).toString();
                  });
                });

              //Add manually for displayCreatedTime in Reply/Forward with ParentUID
              newData.forEach((rec) => {
                rec.messageList.forEach((indRec) => {
                  indRec.lastMessageTimeStamp = rec.lastMessageTimeStamp;
                  indRec.parentUID = rec.parentUID;
                });
              });

              setOriginalData([...newData]);
              setData([...newData]);
            }
          }
          localStorage.setItem(
            'LastTrigger',
            data.result.currentNote[0]?.triggerdate === undefined
              ? new Date().toISOString()
              : data.result.currentNote[0].triggerdate,
          );
        })
        .catch((error) => {
          console.log(error);
          ux.logError(
            'Communication and Collaboration',
            'getNotesUpdate',
            error.message,
            '',
          );
        });
    }, timerInMS);
  };

  //Call to start auto refresh
  if (
    parseInt(
      JSON.parse(localStorage.getItem('Parameters'))?.UI_CNC_SCHEDULER,
    ) !== -1
  ) {
    RefreshNotesFollowUp();
  }

  //Landing
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    dynamicFilterData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSalesAssociate]);

  //Effect when filter changed
  useEffect(() => {
    //Handle Acknowledge Filter for All and Note
    if (
      !selectedFilterType.some((x) => x.value === CommunicationType.Notes) &&
      selectedFilterType.length !== 0
    ) {
      //Disable filter acknowledge
      setDisableFilterAcknowledge(true);
      setSelectedFilterAcknowledge(filterAcknowledge[0]);
    } else {
      //Enable
      setDisableFilterAcknowledge(false);
    }

    dynamicFilterData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilterType]);

  //Effect when filter by acknowledge changed
  useEffect(() => {
    //BCP-2678 : Filter By Type multi-select enhancement (commented below logic)
    //If the selection is not "All" then set filter type = Note
    // if (selectedFilterAcknowledge.value > 0) {
    //     setSelectedFilterType(filterType.filter((data) => data.value === 3)[0]);
    //   setSelectedFilterType(currentFilterType);
    // }

    dynamicFilterData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilterAcknowledge]);

  useEffect(() => {
    dynamicFilterData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFloaterRecipient]);

  useEffect(() => {
    dynamicFilterData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUnreadPinned]);

  //To change view type received/sent
  useEffect(() => {
    dynamicFilterData(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewType]);

  useEffect(() => {
    dynamicFilterData(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commCollectionOriginal]);

  useEffect(() => {
    //Unread and Pinned count
    let unreadData = receivedFolderLogic(commCollectionOriginal)?.filter(
      (x) => !x.isRead,
    );
    let pinnedData = receivedFolderLogic(commCollectionOriginal)?.filter(
      (x) => x.isPin,
    );

    setHeaderUnreadCount(unreadData?.length || 0);
    setHeaderPinnedCount(pinnedData?.length || 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commCollection]);

  //To open new creation modal with reply or forward data
  useEffect(() => {
    if (isReplyData || isForwardData) {
      toggleCreateNewModal(true);
    }
  }, [isReplyData, isForwardData]);

  //To reset reply or forward data if creation modal close
  useEffect(() => {
    if (!showCreateNewCollaboration) {
      setIsReply(null);
      setIsForward(null);
    }
  }, [showCreateNewCollaboration]);

  const dynamicFilterData = (landing) => {
    // console.log("dynamicFilterData")
    // console.clear();
    // console.log(selectedSalesAssociate)
    // console.log(selectedFilterType)
    // console.log(selectedFilterAcknowledge)
    let data = [];
    data = JSON.parse(JSON.stringify(commCollectionOriginal)); //Need to do JSON copy as there is issue with data stability
    let currentUserID = localStorage.getItem('EmplId').toString();

    let receivedData = receivedFolderLogic(data);

    if (viewType === Folder.Received) {
      //Received
      data = receivedData;
    } else if (viewType === Folder.Sent) {
      //Sent
      data = data.filter(
        (set) =>
          set.messageList.some(
            (individualData) =>
              individualData.archiveSend?.toUpperCase().trim() !== 'Y' && //BCP-2245 : By folder archive
              individualData.creator === currentUserID, //Created By current user
          ) && !set.isArchive,
      );

      //Filter out not a sender for everyone
      data.forEach((set) => {
        set.messageList = set.messageList.filter(
          (x) => x.creator === currentUserID,
        );
      });

      //Filter for last 14 days only as a backup from API
      let currentDate = new Date();
      let past14days = new Date(
        new Date(currentDate.setDate(currentDate.getDate() - 14)).setHours(
          0,
          0,
          0,
          0,
        ),
      );
      data = data.filter((set) =>
        set.messageList.some(
          (individualData) => new Date(individualData.dateTime) >= past14days,
        ),
      );
    } else if (viewType === Folder.Archive) {
      //Archive
      data = data.filter(
        (set) =>
          set.messageList.some(
            (individualData) =>
              individualData.archiveReceive?.toUpperCase().trim() === 'Y' ||
              individualData.archiveSend?.toUpperCase().trim() === 'Y',
          ) || set.isArchive,
      );
    }

    //console.log(`Data refreshing for view ${viewType === Folder.Received ? 'received' : 'sent'}`, data)

    if (landing) {
      refreshFilterValue(data, true);
    } else {
      refreshFilterValue(data, false);
    }

    //Filters
    data = data.filter(
      (d) =>
        (selectedSalesAssociate
          ? d.messageList?.some(
              (k) =>
                k.empList.some(
                  (x) => x.empId === selectedSalesAssociate.value,
                ) ||
                k.referenceID
                  ?.split(',')
                  .map((x) => parseInt(x))
                  .includes(selectedSalesAssociate.value) ||
                k.salesAssociate === selectedSalesAssociate.value,
            )
          : true) && //For SA filter
        //BCP-2678 : Filter By Type multi-select enhancement
        (selectedFilterType.length > 0
          ? selectedFilterType.some((x) =>
              ![0, 5, 6].includes(x.value)
                ? selectedFilterType.some((x) => x.value === d.commType)
                : true,
            ) && //For type filter other than All, Order Change
            selectedFilterType.some((x) =>
              [5, 6].includes(x.value) ? [5, 6].includes(d.commType) : true,
            )
          : true) && //For type filter on Order Change
        // (![0, 5, 6].includes(selectedFilterType.value)
        //   ? selectedFilterType.value === d.commType
        //   : true) && //For type filter other than All, Order Change
        // ([5, 6].includes(selectedFilterType.value)
        //   ? [5, 6].includes(d.commType)
        //   : true) && //For type filter on Order Change
        (selectedFilterAcknowledge.value === 1 ? d.acknowledgeBy : true) && //For Ack
        (selectedFilterAcknowledge.value === 2
          ? !d.acknowledgeBy &&
            d.messageList.some((x) => !x.salesAssociateNm) &&
            d.commType === CommunicationType.Notes
          : true) && //For not yet Ack
        (selectedUnreadPinned
          ? selectedUnreadPinned === unread
            ? !d.isRead
            : selectedUnreadPinned === read
            ? d.isRead
            : selectedUnreadPinned === unpinned
            ? !d.isPin
            : d.isPin
          : true) && //For Unread and Pinned
        (selectedFloaterRecipient.value === 1
          ? d.messageList.some(
              (x) =>
                x.salesAssociate === currentUserID ||
                x.empList.some((k) => k.empId === currentUserID),
            )
          : true), //For Floater Recipient filter
    );

    //Not acknowledged count
    let ackData = receivedData.filter(
      (x) =>
        x.commType === CommunicationType.Notes &&
        x.messageList.some(
          (rec) => !rec.salesAssociateNm && !rec.acknowledgeBy,
        ),
    );
    setHeaderCount(ackData.length);

    setData([...data]);
  };

  const refreshFilterValue = (data, resetSelected) => {
    // console.clear();
    // console.log('refreshFilterValue');
    // console.log(selectedFilterType);
    // console.log(selectedFilterAcknowledge);
    // console.log(selectedSalesAssociate);

    if (data === null) {
      data = commCollectionOriginal;
    }

    if (data?.length > 0) {
      //Get filter type value dynamic based on data
      let tempFilterArray = data.map((data) => {
        return { label: data.type, value: data.commType };
      });
      tempFilterArray = [
        ...new Map(
          tempFilterArray.map((item) => [item['value'], item]),
        ).values(),
      ].sort((a, b) => a.value - b.value); //To get unique type
      tempFilterArray.splice(0, 0, { label: 'All', value: 0 });

      //Override label
      tempFilterArray.forEach((elem) => {
        if (elem.value === 1) {
          elem.label = 'Store Survey';
        }

        if (elem.value === 2) {
          elem.label = 'Collaboration';
        }

        if (elem.value === 3) {
          elem.label = 'Note';
        }

        if (elem.value === 5 || elem.value === 6) {
          elem.label = 'Order Change';
        }

        if (elem.value === 7) {
          elem.label = 'GXS 2 Completion';
        }

        if (elem.value === 8) {
          elem.label = 'Request to Contact';
        }
      });

      tempFilterArray = [
        ...new Map(
          tempFilterArray.map((item) => [item['label'], item]),
        ).values(),
      ].sort((a, b) => a.value - b.value); //To get unique type

      setFilterType(tempFilterArray);

      //Sales Associate filter
      let tempSAList = [];
      data
        ?.filter((x) => x.empList.length > 0)
        .forEach((x) =>
          x.empList.forEach((k) =>
            tempSAList.push({
              label: k.empPreferredName || k.empName,
              value: k.empId,
            }),
          ),
        );

      tempSAList = [
        ...new Map(tempSAList.map((item) => [item['value'], item])).values(),
      ].sort((a, b) => a.label.localeCompare(b.label)); //To get unique type

      //BCP-2340 - Group in Sales Associate filter
      let groupSet = [];
      let groupData = data?.filter((x) =>
        x.messageList?.some((x) => !x.salesAssociateNm),
      );
      groupData.forEach((x) =>
        x.referenceID
          ?.split(',')
          .filter((x) => x)
          .forEach((x) => {
            if (!isNaN(x)) groupSet.push(x);
          }),
      );

      groupSet = [...new Set(groupSet.filter((x) => x))]; //Unique groups
      groupSet = groupSet.map((x) => {
        if (parseInt(x) === 5) {
          return { label: `Bob's Squad`, value: parseInt(x) };
        }

        if (groupOptions.find((k) => k.value === parseInt(x))) {
          return {
            label: groupOptions.find((k) => k.value === parseInt(x)).label,
            value: parseInt(x),
          };
        }
        return { label: x, value: x };
      });

      let combinedArray = groupSet
        .sort((a, b) => a.value - b.value)
        .concat(tempSAList);

      setSalesAssociateList(combinedArray);

      //Reset the current selected filter
      if (resetSelected && !doNotRefresh) {
        // BCP-2678 : Filter By Type multi-select enhancement
        // Set default of Notes & Request to Contact if exist in current data tempFilterArray
        const filterTypesToDefault = [
          CommunicationType.Notes,
          CommunicationType.RequesToContact,
        ];
        const currentFilterType = tempFilterArray.filter((data) =>
          filterTypesToDefault.includes(data.value),
        );

        if (currentFilterType.length > 0)
          setSelectedFilterType(currentFilterType);
        else setSelectedFilterType(() => [tempFilterArray[0]]);

        setSelectedFilterAcknowledge(filterAcknowledge[0]);
        setSelectedSalesAssociate(null);
        setSelectedUnreadPinned(null);
        setSelectedFloaterRecipient(filterFloaterRecipient[0]);
      }
    } else {
      //If no data
      if (resetSelected) {
        setFilterType([{ label: 'All', value: 0 }]);
        setSelectedFilterType([{ label: 'All', value: 0 }]);
        setSelectedFilterAcknowledge(filterAcknowledge[0]);
        setSalesAssociateList([]);
        setSelectedUnreadPinned(null);
        setSelectedFloaterRecipient(filterFloaterRecipient[0]);
      }
    }
    setDoNotRefresh(false);
  };

  const archiveRecord = async (e) => {
    let dataParentUid = e.currentTarget.getAttribute('data-uid');
    let dataCommType = parseInt(e.currentTarget.getAttribute('data-comm-type'));
    let isArchive = e.currentTarget.getAttribute('isArchive') === 'true'; //Operation archive or unarchive

    let payload = [
      {
        parentUID: dataParentUid,
        commType: dataCommType,
        isArchived: isArchive,
      },
    ];

    //Locally update data
    let commData = [...commCollectionOriginal];

    if (viewType === Folder.Received) {
      //Received folder archive
      //url += `&receive=Y`;
      payload.archiveReceived = 'Y';
    } else if (viewType === Folder.Sent) {
      //Send folder archive
      //url += `&send=Y`;
      payload.archiveSent = 'Y';
    }

    //Set whole conversation as archived
    commData.forEach((set) => {
      if (set.parentUID === parseInt(dataParentUid)) {
        set.messageList.forEach((rec) => {
          if (viewType === Folder.Received && isArchive) {
            rec.archiveReceive = 'Y';
          } else if (viewType === Folder.Sent && isArchive) {
            rec.archiveSend = 'Y';
          } else if (viewType === Folder.Archive && !isArchive) {
            rec.archiveReceive = '';
            rec.archiveSend = '';
          }
        });
        set.isArchive = isArchive;
      }
    });

    await fetch(helper.getAPIHost() + `/api/Customer360/AddUpdateCCAction`, {
      method: 'PUT',
      body: JSON.stringify(payload),
      ...helper.apiHeaders(),
    })
      .then(async (response) => {
        let data = await response.json();
        if (data.status === 'OK') {
          window.message.notification(
            `Record ${isArchive ? 'archived' : 'unarchived'}.`,
            enums.notificationType.S,
          );
          setDoNotRefresh(true);
          setOriginalData(commData);
        }
      })
      .catch((error) => {
        ux.logError(
          'Communication and Collaboration',
          'AddUpdateCCAction-Archive',
          error.message,
          `Parent UID ${dataParentUid}`,
        );
        window.message.notification(
          `Unable to ${
            isArchive ? 'archive' : 'unarchive'
          } record. Please try again later.`,
          enums.notificationType.E,
        );
      });
  };

  const dismissRecord = async (e) => {
    let dataParentUid = e.currentTarget.getAttribute('data-uid');
    let dataCommType = parseInt(e.currentTarget.getAttribute('data-comm-type'));

    let payload = [
      {
        parentUID: dataParentUid,
        commType: dataCommType,
        isDismiss: true,
      },
    ];

    //Locally update data
    let commData = [...commCollectionOriginal];
    let currentDataIndex = commData.findIndex(
      (data) => data.parentUID === parseInt(dataParentUid),
    );

    commData.splice(currentDataIndex, 1); //Delete from current data

    await fetch(helper.getAPIHost() + `/api/Customer360/AddUpdateCCAction`, {
      method: 'PUT',
      body: JSON.stringify(payload),
      ...helper.apiHeaders(),
    })
      .then(async (response) => {
        let data = await response.json();
        if (data.status === 'OK') {
          window.message.notification(
            `Record deleted.`,
            enums.notificationType.S,
          );
          setDoNotRefresh(true);
          setOriginalData(commData);
        }
      })
      .catch((error) => {
        ux.logError(
          'Communication and Collaboration',
          'AddUpdateCCAction-Dismiss',
          error.message,
          `Parent UID ${dataParentUid}`,
        );
        window.message.notification(
          `Unable to delete record. Please try again later.`,
          enums.notificationType.E,
        );
      });
  };

  const handleAcknowledgeCheckBox = async (e) => {
    let currentTarget = e.currentTarget;
    let ackStatus = currentTarget.getAttribute('data-ack');
    let parentUID = currentTarget.getAttribute('data-uid');
    let dataCommType = parseInt(currentTarget.getAttribute('data-comm-type'));

    let payload = [
      {
        parentUID: parentUID,
        commType: dataCommType,
        isAcknowledge: ackStatus === null,
      },
    ];

    let currentParentData = commCollectionOriginal
      .find((x) => x.parentUID === parseInt(parentUID))
      .messageList.find((x) => x.uid === parseInt(parentUID));

    if (ackStatus === null) {
      if (
        (!currentParentData.referenceID.includes(helper.getUserGroup()) ||
          !currentParentData.empList.some(
            (k) => k.empId === helper.getEmplId(),
          ) ||
          !(
            currentParentData.referenceID.includes('3') &&
            (helper.getUserGroup() === 2 || helper.getUserGroup() === 26)
          )) && //BCP-2030
        (currentParentData.targetProfitCenter === 'squad'
          ? localStorage.getItem('isBobSquad') !== 'true'
          : currentParentData.targetProfitCenter !== helper.getStorelId())
      ) {
        window.message.notification(
          `Can only be acknowledged by intended recipients`,
          enums.notificationType.E,
        );
        return;
      }
    } else {
      //For not valid acknowledge owner & user group not manager
      if (
        (currentParentData.acknowledgeBy !== helper.getEmplId() &&
          ![2, 3].includes(parseInt(helper.getUserGroup()))) ||
        (currentParentData.targetProfitCenter === 'squad'
          ? localStorage.getItem('isBobSquad') !== 'true'
          : currentParentData.targetProfitCenter !== helper.getStorelId())
      ) {
        //getData();
        window.message.notification(
          `Can only be unacknowledged by acknowledged recipient`,
          enums.notificationType.E,
        );
        return;
      }
    }

    //To send to API
    await fetch(helper.getAPIHost() + `/api/Customer360/AddUpdateCCAction`, {
      method: 'PUT',
      body: JSON.stringify(payload),
      ...helper.apiHeaders(),
    })
      .then(async (res) => {
        if (res.status === 200) {
          window.message.notification(
            `${ackStatus ? 'Unacknowledged' : 'Acknowledged'} Successfully`,
            enums.notificationType.S,
          );
        } else {
          window.message.notification(
            'Message has already been acknowledged by another user',
            enums.notificationType.E,
          );
          ux.logError(
            'Communication and Collaboration',
            ackStatus ? 'unacknowledgedComment' : 'acknowledgedComment',
            JSON.stringify(res),
            JSON.stringify(parentUID),
          );
        }
      })
      .catch((err) => {
        window.message.notification(
          'Message has already been acknowledged by another user',
          enums.notificationType.E,
        );
        ux.logError(
          'Communication and Collaboration',
          ackStatus ? 'unacknowledgedComment' : 'acknowledgedComment',
          JSON.stringify(err),
          JSON.stringify(parentUID),
        );
      })
      .finally(() => {
        setDoNotRefresh(true);
        getData();
      });
  };

  const toggleRead = async (data) => {
    let payload = [
      {
        parentUID: data.parentUID,
        commType: data.commType,
        isRead: !data.isRead,
      },
    ];

    //To send to API
    await fetch(helper.getAPIHost() + `/api/Customer360/AddUpdateCCAction`, {
      method: 'PUT',
      body: JSON.stringify(payload),
      ...helper.apiHeaders(),
    })
      .then(async (res) => {
        if (res.status === 200) {
          //Update data locally
          let commData = [...commCollectionOriginal];

          commData.forEach((set) => {
            if (set.parentUID === data.parentUID) set.isRead = !data.isRead;
          });
          setDoNotRefresh(true);
          setOriginalData([...commData]);

          ux.logAction(
            'Communication and Collaboration',
            `Mark ${data.parentUID} as ${data.isRead ? 'unread' : 'read'}`,
          );
        } else {
          window.message.notification(
            `Unable to mark communication as ${
              data.isRead ? 'unread' : 'read'
            }`,
            enums.notificationType.E,
          );
          ux.logError(
            'Communication and Collaboration',
            'AddUpdateCCAction-Read',
            JSON.stringify(res),
            JSON.stringify(payload),
          );
        }
      })
      .catch((err) => {
        window.message.notification(
          `Unable to mark communication as ${
            data.isRead ? 'unread' : 'read'
          }, ${err}`,
          enums.notificationType.E,
        );
        ux.logError(
          'Communication and Collaboration',
          'AddUpdateCCAction-Read',
          err,
          JSON.stringify(payload),
        );
      });
  };

  const togglePin = async (data) => {
    let payload = [
      {
        parentUID: data.parentUID,
        commType: data.commType,
        isPin: !data.isPin,
      },
    ];

    //To send to API
    await fetch(helper.getAPIHost() + `/api/Customer360/AddUpdateCCAction`, {
      method: 'PUT',
      body: JSON.stringify(payload),
      ...helper.apiHeaders(),
    })
      .then(async (res) => {
        if (res.status === 200) {
          //Update data locally
          let commData = [...commCollectionOriginal];

          commData.forEach((set) => {
            if (set.parentUID === data.parentUID) set.isPin = !data.isPin;
          });
          setDoNotRefresh(true);
          setOriginalData([...commData]);

          ux.logAction(
            'Communication and Collaboration',
            `Mark ${data.parentUID} as ${data.isPin ? 'unpin' : 'pin'}`,
          );
        } else {
          window.message.notification(
            `Unable to mark communication as ${data.isPin ? 'unpin' : 'pin'}`,
            enums.notificationType.E,
          );
          ux.logError(
            'Communication and Collaboration',
            'AddUpdateCCAction-Pin',
            JSON.stringify(res),
            JSON.stringify(payload),
          );
        }
      })
      .catch((err) => {
        window.message.notification(
          `Unable to mark communication as ${
            data.isPin ? 'unpin' : 'pin'
          }, ${err}`,
          enums.notificationType.E,
        );
        ux.logError(
          'Communication and Collaboration',
          'AddUpdateCCAction-Pin',
          err,
          JSON.stringify(payload),
        );
      });
  };

  const floaterMarkAllRead = async () => {
    let currentUserID = localStorage.getItem('EmplId').toString();

    let receivedData = receivedFolderLogic(
      JSON.parse(JSON.stringify(commCollectionOriginal)),
    ); //Need to do JSON copy as there is issue with data stability

    let filteredData = receivedData.filter(
      (set) =>
        //Not recipient (! infront of set)
        !set.messageList.some(
          (individualData) =>
            individualData.salesAssociate === currentUserID || //For direct notes
            individualData.empList?.some((x) => x.empId === currentUserID) || //For multiple individual selection
            individualData.referenceID === currentUserID, //For GX2 Change & Shared
        ),
    );

    let payload = [];
    filteredData.forEach((data) => {
      let subPayload = {
        parentUID: data.parentUID,
        commType: data.commType,
        isRead: true,
      };

      payload.push(subPayload);
    });

    // let allParentUID = payload.map((x) => x.parentUID).join(';');

    //To send to API
    await fetch(helper.getAPIHost() + `/api/Customer360/AddUpdateCCAction`, {
      method: 'PUT',
      body: JSON.stringify(payload),
      ...helper.apiHeaders(),
    })
      .then(async (res) => {
        if (res.status === 200) {
          //Update data locally
          let commData = [...commCollectionOriginal];

          commData.forEach((set) => {
            if (payload.map((x) => x.parentUID).includes(set.parentUID))
              set.isRead = true;
          });
          setDoNotRefresh(true);
          setOriginalData([...commData]);

          ux.logAction('Communication and Collaboration', `Mark all as read`);
        } else {
          window.message.notification(
            `Unable to mark all communication as read`,
            enums.notificationType.E,
          );
          ux.logError(
            'Communication and Collaboration',
            'AddUpdateCCAction-FloaterReadAll',
            JSON.stringify(res),
            JSON.stringify(payload),
          );
        }
      })
      .catch((err) => {
        window.message.notification(
          `Unable to mark all communication as read`,
          enums.notificationType.E,
        );
        ux.logError(
          'Communication and Collaboration',
          'AddUpdateCCAction-FloaterReadAll',
          err,
          JSON.stringify(payload),
        );
      });
  };

  return (
    <>
      <div className="communication-filters">
        {filterType.length > 0 && (
          <>
            <div style={{ marginRight: '10px' }}>
              <div className="filter-label">Filter By Type</div>

              {/* BCP-2678 : Filter By Type multi-select enhancement */}

              {/* <Dropdown
                id={'communication-filter-type'}
                value={selectedFilterType}
                options={filterType}
                onChange={(obj) => setSelectedFilterType(obj)}
                placeholder="Type"
              /> */}

              <Dropdown
                placeholder={'Type'}
                className="communication-filter-type-multiselect"
                id="communication-filter-type"
                isMulti
                value={selectedFilterType}
                options={filterType}
                isSearchable={false}
                isClearable={false}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                onChange={(obj) => {
                  if (obj && obj.length > 0) {
                    let latestSelection = obj.slice(-1)[0].value;

                    if (
                      !obj.some((x) => x.value === 0) &&
                      selectedFilterType.some((x) => x.value === 0)
                    ) {
                      //If All exist in current selected type but not in newly selected type, remove all
                      obj = [];
                    }

                    if (
                      latestSelection === 0 &&
                      obj.length > selectedFilterType.length
                    ) {
                      //If all is selected, select all
                      obj = filterType;
                    }

                    if (
                      !obj.find((x) => x.value === 0) &&
                      filterType
                        .filter((x) => x.value !== 0)
                        .every((x) => obj.some((k) => k.value === x.value))
                    ) {
                      //If current filter does not have all selected, but all the other options was selected, check all option as well
                      obj = filterType;
                    } else if (
                      obj.find((x) => x.value === 0) &&
                      obj.length !== filterType.length
                    ) {
                      //If current filter have all selected, but some of the other options was not selected, remove all option
                      obj = obj.filter((x) => x.value !== 0);
                    }
                  }

                  setSelectedFilterType(obj);
                }}
                components={{
                  Option: ({ innerProps, label, data, isSelected }) => {
                    return (
                      <div
                        {...innerProps}
                        className={`custom-option ${isSelected && 'selected'}`}
                      >
                        <Checkbox checked={isSelected} disableRipple={true} />

                        <span className="label">{data.label}</span>
                      </div>
                    );
                  },
                  MultiValue: ({ children, ...innerProps }) => {
                    let toDisplayData = innerProps.selectProps.value;

                    const isFirst = (selectedOptions, option) => {
                      return (
                        selectedOptions.length > 0 &&
                        selectedOptions[0].value === option.value
                      );
                    };

                    const { data } = innerProps;

                    //To display only once rather than each selected options
                    return isFirst(toDisplayData, data) ? (
                      <components.MultiValueContainer {...innerProps}>
                        {toDisplayData.some((x) => x.value === 0) ? (
                          <div>All</div> //Display ALL more than All exist
                        ) : toDisplayData.length > 1 ? (
                          <div>{toDisplayData.length} selected</div> //Display if more than one selection
                        ) : (
                          <div>{data.label}</div> //Display if only one selection
                        )}
                      </components.MultiValueContainer>
                    ) : null;
                  },
                }}
              />
            </div>

            {filterType.some((x) => x.value === 3) &&
              viewType === Folder.Received && (
                <div style={{ marginRight: '10px' }}>
                  <div className="filter-label">{'Filter Note'}</div>
                  <Dropdown
                    id={'communication-filter-acknowledge'}
                    value={selectedFilterAcknowledge}
                    options={filterAcknowledge}
                    onChange={(obj) => setSelectedFilterAcknowledge(obj)}
                    isDisabled={disabledFilterAcknowledge}
                  />
                </div>
              )}

            {/* BCP-2470 : Show filter by recipient only for Floater */}
            {localStorage.getItem('isFloater') === 'true' &&
              viewType === Folder.Received &&
              (localStorage.getItem('UserGroupId') === '2' ||
                localStorage.getItem('UserGroupId') === '26') && (
                <div style={{ marginRight: '10px' }}>
                  <div className="filter-label">{'Filter Recipient'}</div>
                  <Dropdown
                    id={'communication-filter-floater-recipient'}
                    value={selectedFloaterRecipient}
                    options={filterFloaterRecipient}
                    onChange={(obj) => setSelectedFloaterRecipient(obj)}
                  />
                </div>
              )}

            {/* Show name filter only for manager and office associate */}
            {currentUserGroup === '2' ||
            currentUserGroup === '26' ||
            currentUserGroup === '3' ? (
              <div>
                <div className="filter-label">{''}</div>

                <Dropdown
                  id={'communication-filter-sales-associate'}
                  value={selectedSalesAssociate}
                  options={salesAssociateList}
                  onChange={(obj) => setSelectedSalesAssociate(obj)}
                  placeholder="Search by name"
                  isClearable={true}
                />
              </div>
            ) : null}
          </>
        )}

        <div className="toggle-data-state">
          <div className="filter-label">{''}</div>
          <ButtonGroup>
            <RedButton
              label={'Received'}
              outline={viewType === Folder.Received ? false : true}
              onClick={() => setViewType(Folder.Received)}
              style={{ marginTop: '0' }}
            />
            <RedButton
              label={'Sent'}
              outline={viewType === Folder.Sent ? false : true}
              onClick={() => setViewType(Folder.Sent)}
              style={{ marginTop: '0' }}
            />
            <RedButton
              label={'Archived'}
              outline={viewType === Folder.Archive ? false : true}
              onClick={() => setViewType(Folder.Archive)}
              style={{ marginTop: '0' }}
            />
          </ButtonGroup>
        </div>

        <div style={{ marginRight: '5px' }}>
          <div className="filter-label">{''}</div>
          <RedButton
            label={'Create New'}
            outline={false}
            onClick={() => toggleCreateNewModal(true)}
            style={{ marginTop: '0', minWidth: '130px' }}
          />
        </div>
      </div>

      <div className="container">
        {isLoading ? (
          <div className="no-data">
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '400px',
                margin: '0',
              }}
            >
              <Spinner color="dark" />
              <div>&nbsp;Loading...</div>
            </div>
          </div>
        ) : (
          <>
            <CollabCommunication
              commData={commCollection}
              archieveFunction={archiveRecord}
              deleteFunction={dismissRecord}
              handleAcknowledgeCheckBox={handleAcknowledgeCheckBox}
              toggleRead={toggleRead}
              togglePin={togglePin}
              floaterMarkAllRead={floaterMarkAllRead}
              viewType={viewType}
              headerNotAck={headerNotAck}
              headerUnread={headerUnread}
              headerPinned={headerPinned}
              setIsReply={setIsReply}
              setIsForward={setIsForward}
              selectedUnreadPinned={selectedUnreadPinned}
              setSelectedUnreadPinned={setSelectedUnreadPinned}
            />

            {showCreateNewCollaboration ? (
              <CreateNewCommunication
                toggleFunction={toggleCreateNewModal}
                toggleState={showCreateNewCollaboration}
                refreshDashboard={getData}
                isReply={isReplyData}
                isForward={isForwardData}
              />
            ) : null}
          </>
        )}
      </div>
    </>
  );
}

const displayTo = (individualData) => {
  switch (individualData.commType) {
    case CommunicationType.MedalliaSurvey:
      // Medallia Survey
      return individualData.salesAssociateNm;

    case CommunicationType.Shared:
      // Shared Order
      return localStorage.getItem('EmplName');

    case CommunicationType.Notes:
      // Order Notes
      return individualData.commType === CommunicationType.Notes &&
        !individualData.salesAssociateNm
        ? //For group note, display as for store / Bob's Squad
          individualData.targetProfitCenter === 'squad'
          ? //Bob Squad display:
            `Bob's Squad (${bobSquadGroupOptions
              .filter((x) =>
                individualData.referenceID
                  .split(',')
                  .map((k) => parseInt(k))
                  .includes(x.value),
              )
              .map((x) => x.label)
              .join(', ')}${
              individualData.empList?.length > 0
                ? `, ${individualData.empList
                    .map((x) => x.empPreferredName || x.empName)
                    .join(', ')}`
                : ''
            })`
          : `${individualData.targetProfitCenterName} (${groupOptions
              .filter((x) =>
                individualData.referenceID
                  .split(',')
                  .map((k) => parseInt(k))
                  .includes(x.value),
              )
              .map((x) => x.label)
              .join(', ')}${
              individualData.empList?.length > 0
                ? `, ${individualData.empList
                    .map((x) => x.empPreferredName || x.empName)
                    .join(', ')}`
                : ''
            })`
        : individualData.salesAssociateNm;
    case CommunicationType.FollowUp:
      // Follow Up
      return individualData.salesAssociateNm;

    case CommunicationType.GX2Complete:
      // GX2 Complete
      return localStorage.getItem('EmplName');

    case CommunicationType.RequesToContact:
      return groupOptions
        .filter((x) =>
          individualData.referenceID
            .split(',')
            .map((k) => parseInt(k))
            .includes(x.value),
        )
        .map((x) => x.label)
        .join(', ');

    default:
      break;
  }
};

const displayFrom = (individualData) => {
  switch (individualData.commType) {
    case CommunicationType.MedalliaSurvey:
      // Medallia Survey
      return `Store Survey ${new Date(
        individualData.dateTime,
      ).toLocaleDateString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      })}`;

    case CommunicationType.Shared:
      // Shared Order
      return `${individualData.salesAssociateNm} ${
        individualData.profitCenterNm
          ? `(${individualData.profitCenterNm})`
          : ''
      } ${new Date(individualData.dateTime).toLocaleDateString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      })}`;

    case CommunicationType.Notes:
      // Order Notes
      return `${
        individualData.creatorPreferredName || individualData.creatorName
      } ${
        individualData.profitCenterNm
          ? `(${individualData.profitCenterNm})`
          : ''
      } ${new Date(individualData.dateTime).toLocaleDateString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      })}`;

    case CommunicationType.FollowUp:
      // Follow Up

      return `${
        individualData.creatorPreferredName || individualData.creatorName
      } ${
        individualData.profitCenterNm
          ? `(${individualData.profitCenterNm})`
          : ''
      } ${new Date(individualData.dateTime).toLocaleDateString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      })}`;

    case CommunicationType.GX2Complete:
      // GX2 Complete
      return `${individualData.salesAssociateNm} ${
        individualData.profitCenterNm
          ? `(${individualData.profitCenterNm})`
          : ''
      } ${new Date(individualData.dateTime).toLocaleDateString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      })}`;

    case CommunicationType.RequesToContact:
      // Request to Contact
      return `Request to Contact ${new Date(
        individualData.dateTime,
      ).toLocaleDateString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      })}`;

    default:
      break;
  }
};

const displayCreatedTime = (individualData) => {
  switch (individualData.commType) {
    case CommunicationType.MedalliaSurvey:
      // Medallia Survey
      return `Created: ${new Date(individualData.dateTime).toLocaleDateString(
        'en-US',
        {
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        },
      )}`;

    case CommunicationType.Shared:
      // Shared Order
      return `Created: ${new Date(individualData.dateTime).toLocaleDateString(
        'en-US',
        {
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        },
      )}`;

    case CommunicationType.Notes:
      // Order Notes
      return `Created: ${new Date(individualData.dateTime).toLocaleDateString(
        'en-US',
        {
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        },
      )}`;

    case CommunicationType.FollowUp:
      // Follow Up
      return `Created: ${new Date(individualData.dateTime).toLocaleDateString(
        'en-US',
        {
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        },
      )}`;

    case CommunicationType.GX2Complete:
      // GX2 Complete
      return `Created: ${new Date(individualData.dateTime).toLocaleDateString(
        'en-US',
        {
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        },
      )}`;

    case CommunicationType.RequesToContact:
      // Request to Contact
      return `Created: ${new Date(individualData.dateTime).toLocaleDateString(
        'en-US',
        {
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        },
      )}`;

    default:
      break;
  }
};

function CollabCommunication({
  commData,
  archieveFunction,
  deleteFunction,
  handleAcknowledgeCheckBox,
  toggleRead,
  togglePin,
  floaterMarkAllRead,
  viewType,
  headerNotAck,
  headerUnread,
  headerPinned,
  setIsReply,
  setIsForward,
  selectedUnreadPinned,
  setSelectedUnreadPinned,
}) {
  const [selectedRecordID, setSelectedRecord] = useState(null);
  const [selectedRecordType, setSelectedRecordType] = useState(null);
  const [selectedData, setSelectedData] = useState({});
  const [displayDataObj, setDisplayDataObj] = useState([]);
  const [toggleCustomerProfileModal, setToggleCustomerProfileModal] =
    useState(false);

  const updateCommDataLocally = (commData) => {
    let localData = [...commData].filter((x) => x.messageList?.length > 0);

    localData.forEach((data) => {
      data.messageList.sort(
        (a, b) => new Date(b.dateTime) - new Date(a.dateTime),
      );
      let latestData = data.messageList[0];
      data.uid = latestData.uid;

      data.toPerson = displayTo(latestData);
      data.fromPerson = displayFrom(latestData);
      data.displayCreatedTime = displayCreatedTime(latestData);
    });

    setDisplayDataObj(localData);
  };

  const sortData = (data) => {
    if (data?.length > 0) {
      return data.sort((a, b) => {
        const pinnedSort = (a, b) =>
          a.isPin === b.isPin ? 0 : a.isPin ? -1 : 1; //By pinned
        const dateSort = (a, b) =>
          new Date(b.lastMessageTimeStamp) - new Date(a.lastMessageTimeStamp); //By datetime

        return pinnedSort(a, b) || dateSort(a, b);
      });
    }
  };

  const getCommTypeListing = (data) => {
    switch (data.commType) {
      case CommunicationType.MedalliaSurvey:
        return (
          <>
            {/* Medallia Survey */}
            {data.commType === CommunicationType.MedalliaSurvey && (
              <>
                <Row className={`to-detail`}>
                  <span className={'bold'}>To: </span>
                  {data.toPerson}
                </Row>
                <Row className={`from-detail`}>
                  <span className={'bold'}>From: </span> {data.fromPerson}
                </Row>
              </>
            )}
          </>
        );
      case CommunicationType.Shared:
        return (
          <>
            {/* Shared Order */}
            {data.commType === CommunicationType.Shared && (
              <>
                <Row className={`to-detail`}>
                  <span className={'bold'}>To: </span> {data.toPerson}
                </Row>
                <Row className={`from-detail`}>
                  <span className={'bold'}>From: </span> {data.fromPerson}
                </Row>
              </>
            )}
          </>
        );
      case CommunicationType.Notes:
        return (
          <>
            {/* Order Notes */}
            {data.commType === CommunicationType.Notes && (
              <>
                <Row className={`to-detail`}>
                  <span className={'bold'}>To: </span>
                  {data.toPerson}
                </Row>

                <Row className={`from-detail`}>
                  <span className={'bold'}>From: </span> {data.fromPerson}
                </Row>

                {data.commType === CommunicationType.Notes &&
                data.acknowledgeBy ? (
                  <Row className={`ack-detail`}>
                    <span className={'bold'}>Acknowledged by: </span>
                    {data.acknowledgeByPreferredName ||
                      data.acknowledgeByName}{' '}
                    on{' '}
                    {new Date(
                      data.acknowledgeDateTime + 'Z',
                    ).toLocaleDateString('en-US', {
                      hour: 'numeric',
                      minute: 'numeric',
                      hour12: true,
                    })}
                  </Row>
                ) : null}
              </>
            )}
          </>
        );
      case CommunicationType.FollowUp:
        return (
          <>
            {/* Follow Up */}
            {data.commType === CommunicationType.FollowUp && (
              <>
                <Row className={`to-detail`}>
                  <span className={'bold'}>To: </span> {data.toPerson}
                </Row>
                <Row className={`from-detail`}>
                  <span className={'bold'}>From: </span>
                  {data.fromPerson}
                </Row>
              </>
            )}
          </>
        );
      case CommunicationType.GX2Complete:
        return (
          <>
            {/* GX2 Complete */}
            {data.commType === CommunicationType.GX2Complete && (
              <>
                <Row className={`to-detail`}>
                  <span className={'bold'}>To: </span> {data.toPerson}
                </Row>
                <Row className={`from-detail`}>
                  <span className={'bold'}>From: </span> {data.fromPerson}
                </Row>
              </>
            )}
          </>
        );

      case CommunicationType.RequesToContact:
        return (
          <>
            {/* Medallia Survey */}
            {data.commType === CommunicationType.RequesToContact && (
              <>
                <Row className={`to-detail`}>
                  <span className={'bold'}>To: </span>
                  {data.toPerson}
                </Row>
                <Row className={`from-detail`}>
                  <span className={'bold'}>From: </span> {data.fromPerson}
                </Row>
              </>
            )}
          </>
        );
      default:
        break;
    }
  };

  const resetSelectedRecord = () => {
    setSelectedData(null);
    setSelectedRecord(null);
    setSelectedRecordType(null);
  };

  useEffect(() => {
    // console.clear();
    console.log(selectedRecordID);
    console.log(selectedRecordType);
    console.log(selectedData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRecordID, selectedRecordType]);

  useEffect(() => {
    updateCommDataLocally(commData);
  }, [commData]);

  useEffect(() => {
    resetSelectedRecord();
  }, [viewType]);

  const handlePodium = (e) => {
    let parameters = JSON.parse(localStorage.getItem('Parameters'));
    let url = parameters.PODIUM_URL || '';

    let pnumber = e.currentTarget.getAttribute('data-phone');
    let pname = e.currentTarget.getAttribute('data-name');
    let puid = e.currentTarget.getAttribute('data-uid');
    let orderNum = e.currentTarget.getAttribute('data-order');

    // Check for last char, make sure is ended by /
    url = url.trim();
    if (url.length > 0) {
      let lastChar = url.slice(-1);
      if (lastChar !== '/' && lastChar !== '=') url += '/';
    }

    if (
      pnumber === null ||
      pnumber === undefined ||
      pnumber?.trim() === '' ||
      pnumber?.trim().toUpperCase() === 'NULL'
    ) {
      window.message.notification(
        'No phone number found!',
        enums.notificationType.W,
      );
      return;
    }

    if (url) {
      url = url + 'phone-number/' + pnumber.replaceAll('-', '');
      url += '?name=' + pname;
      url += '&locationUid=' + puid;
      // console.log('Podium: ' + url);

      if (
        window.podiumWindow === undefined ||
        window.podiumWindow === null ||
        window.podiumWindow.closed
      ) {
        // not open or window is closed
        window.podiumWindow = window.open(url, 'Podium');
        window.podiumOrder = orderNum;
      } else {
        // if window is opened, check is it different order number, if same order, set it focus, otherwise replace it.
        if (window.podiumOrder === orderNum) {
          window.podiumWindow.focus();
        } else {
          window.podiumWindow.location = url;
          window.podiumOrder = orderNum;
          window.podiumWindow.focus();
        }
      }
    }
  };

  return (
    <>
      <Row className="inner-container" key={displayDataObj}>
        {/* Left handside listing */}
        <Col
          className="outer-box-left"
          xs={5}
          sm={5}
          md={5}
          lg={5}
          xl={5}
          xxl={5}
        >
          <Row className="top-row-filter">
            {localStorage.getItem('isFloater') === 'true' &&
              viewType === Folder.Received &&
              (localStorage.getItem('UserGroupId') === '2' ||
                localStorage.getItem('UserGroupId') === '26') && (
                <RedButton
                  id={'mark-all-read'}
                  label={'Mark all as read'}
                  onClick={() => floaterMarkAllRead()}
                />
              )}

            <div>
              <span
                onClick={() =>
                  setSelectedUnreadPinned(
                    selectedUnreadPinned !== read ? read : null,
                  )
                }
                className={`${selectedUnreadPinned === read ? 'active' : ''}`}
              >
                Read
              </span>
              <span
                onClick={() =>
                  setSelectedUnreadPinned(
                    selectedUnreadPinned !== unread ? unread : null,
                  )
                }
                className={`${selectedUnreadPinned === unread ? 'active' : ''}`}
              >
                Unread
              </span>
              <span>|</span>
              <span
                onClick={() =>
                  setSelectedUnreadPinned(
                    selectedUnreadPinned !== pinned ? pinned : null,
                  )
                }
                className={`${selectedUnreadPinned === pinned ? 'active' : ''}`}
              >
                Pinned
              </span>
              <span
                onClick={() =>
                  setSelectedUnreadPinned(
                    selectedUnreadPinned !== unpinned ? unpinned : null,
                  )
                }
                className={`${
                  selectedUnreadPinned === unpinned ? 'active' : ''
                }`}
              >
                Unpinned
              </span>
            </div>
          </Row>

          {sortData(displayDataObj)?.map((data, idx) => {
            return (
              <Row
                key={idx}
                className={`list-rows ${
                  data.uid === selectedRecordID &&
                  data.type === selectedRecordType
                    ? 'selected'
                    : ''
                }`}
              >
                {/* Read/Unread & Icons */}
                <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}>
                  <div className="icons">
                    {viewType === Folder.Received && (
                      <FiCircle
                        size={20}
                        title={data.isRead ? 'Mark as unread' : 'Mark as read'}
                        className={`read-circle ${
                          data.isRead ? 'read' : 'unread'
                        }`}
                        onClick={() => toggleRead(data)}
                      />
                    )}
                  </div>
                </Col>

                {/* Listing Details */}
                <Col
                  xs={9}
                  sm={9}
                  md={9}
                  lg={9}
                  xl={9}
                  xxl={9}
                  className={'listing-detail'}
                  onClick={() => {
                    if (data.uid !== selectedRecordID) {
                      setSelectedRecord(data.uid);
                      setSelectedRecordType(data.type);
                      setSelectedData(data);
                      if (!data.isRead) toggleRead(data); //Only toggle to read
                    } else {
                      //Toggle right hand side
                      resetSelectedRecord();
                    }
                  }}
                >
                  {getCommTypeListing(data)}
                </Col>

                {/* Actionables */}
                <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2}>
                  <div className="icons">
                    {/* Pinned */}
                    {data.isPin && (
                      <AiFillPushpin
                        size={30}
                        title={data.isPin ? 'Unpin' : 'Pin'}
                        className="pinned"
                        onClick={() => togglePin(data)}
                      />
                    )}

                    {/* Acknowledge Checkbox */}
                    {data.commType === CommunicationType.Notes &&
                      !data.messageList.find((x) => x.uid === data.uid)
                        .salesAssociateNm &&
                      viewType === Folder.Received && (
                        //Checkbox for group message notes in received
                        <td
                          className="checkbox-icon"
                          data-uid={data.parentUID}
                          data-ack={data.acknowledgeBy}
                          data-comm-type={data.commType}
                          onClick={handleAcknowledgeCheckBox}
                        >
                          <Checkbox
                            title={
                              data.messageList.find((x) => x.uid === data.uid)
                                .acknowledgeBy
                                ? 'Mark as unacknowledged'
                                : 'Mark as acknowledge'
                            }
                            disableRipple={true}
                            checked={data.acknowledgeBy ? true : false}
                          />
                        </td>
                      )}
                  </div>
                </Col>
                <Row className="create-date">{/*data.displayCreatedTime*/}</Row>
              </Row>
            );
          })}
        </Col>

        {/* Right handside conversation thread */}
        <Col
          className="outer-box-right"
          xs={7}
          sm={7}
          md={7}
          lg={7}
          xl={7}
          xxl={7}
        >
          {/* Landing screen here */}
          {!selectedRecordID && viewType === Folder.Received && (
            <div className="landing-summary" key={viewType}>
              <div>My Unread Notes: {headerUnread}</div>
              <div>My Pinned Notes: {headerPinned}</div>
              <div>
                Unacknowledged Notes:{' '}
                <span style={{ color: 'red' }}>{headerNotAck}</span>
              </div>
            </div>
          )}

          {/* Show message conversation */}
          {displayDataObj
            .filter(
              (x) =>
                x.uid === selectedRecordID && x.type === selectedRecordType,
            )
            ?.map((data) => {
              return data.messageList?.map((individualData) => {
                return (
                  <>
                    <div className="conversation">
                      {/* Header */}
                      <div className="header">
                        <div className="header-detail">
                          <div>
                            <span className="bold">To:</span>{' '}
                            {displayTo(individualData)}
                          </div>
                          <div>
                            <span className="bold">From:</span>{' '}
                            {displayFrom(individualData)}
                          </div>
                          {data.commType === CommunicationType.Notes &&
                          !data.salesAssociateNm &&
                          data.acknowledgeBy ? (
                            <div>
                              <span className="bold">Acknowledged by:</span>{' '}
                              {data.acknowledgeByPreferredName ||
                                data.acknowledgeByName}{' '}
                              on{' '}
                              {new Date(
                                data.acknowledgeDateTime + 'Z',
                              ).toLocaleDateString('en-US', {
                                hour: 'numeric',
                                minute: 'numeric',
                                hour12: true,
                              })}
                            </div>
                          ) : null}
                        </div>
                        <div className="action-icons">
                          {/* Received/Sent folder */}
                          {viewType !== Folder.Archive && (
                            <>
                              {individualData.commType ===
                                CommunicationType.Notes &&
                                (!individualData.salesAssociateNm ? (
                                  ['2', '3', '5'].includes(
                                    localStorage.getItem('UserGroupId'),
                                  ) ? (
                                    <>
                                      {/* Reply Icon */}
                                      <MdReply
                                        size={30}
                                        title="Reply"
                                        onClick={() => {
                                          if (!individualData.acknowledgeBy) {
                                            window.message.notification(
                                              'Group notes need to be acknowledged before replying.',
                                              enums.notificationType.E,
                                            );
                                            return;
                                          }
                                          setIsReply(individualData);
                                        }}
                                      />

                                      {/* Forward Icon */}
                                      <MdForward
                                        size={30}
                                        title="Forward"
                                        onClick={() =>
                                          setIsForward(individualData)
                                        }
                                      />
                                    </>
                                  ) : null //Group notes only for manager to reply or forward
                                ) : (
                                  <>
                                    {/* Reply Icon */}
                                    <MdReply
                                      size={30}
                                      title="Reply"
                                      onClick={() => setIsReply(individualData)}
                                    />

                                    {/* Forward Icon */}
                                    <MdForward
                                      size={30}
                                      title="Forward"
                                      onClick={() =>
                                        setIsForward(individualData)
                                      }
                                    />
                                  </>
                                ))}

                              {/* Forward Icon for Request to Contact */}
                              {individualData.commType ===
                                CommunicationType.RequesToContact && (
                                <MdForward
                                  size={30}
                                  title="Forward"
                                  onClick={() => setIsForward(individualData)}
                                />
                              )}

                              {/* Pin Icon */}
                              <AiFillPushpin
                                size={30}
                                title={data.isPin ? 'Unpin' : 'Pin'}
                                style={{
                                  color: `${data.isPin ? '#d0281b' : ''}`,
                                }}
                                onClick={() => togglePin(data)}
                              />

                              {/* Archive Icon */}
                              <MdArchive
                                size={30}
                                title="Archive"
                                data-uid={data.parentUID}
                                data-comm-type={data.commType}
                                isArchive={'true'}
                                onClick={(e) => {
                                  if (data.isPin) {
                                    window.message.notification(
                                      'Please unpin before archiving',
                                      enums.notificationType.E,
                                    );
                                  } else if (
                                    !individualData.acknowledgeBy &&
                                    !individualData.salesAssociateNm &&
                                    individualData.commType ===
                                      CommunicationType.Notes
                                  ) {
                                    window.message.notification(
                                      'Group notes must be acknowledged before archiving',
                                      enums.notificationType.E,
                                    );
                                  } else {
                                    archieveFunction(e);
                                  }
                                }}
                              />
                            </>
                          )}

                          {/* Archive Folder */}
                          {viewType === Folder.Archive && (
                            <>
                              <MdUnarchive
                                size={30}
                                title="Unarchive"
                                data-uid={data.parentUID}
                                data-comm-type={data.commType}
                                isArchive={'false'}
                                onClick={(e) => {
                                  archieveFunction(e);
                                }}
                              />
                              <FaTrash
                                size={25}
                                title="Delete"
                                data-uid={data.parentUID}
                                data-comm-type={data.commType}
                                onClick={(e) => {
                                  deleteFunction(e);
                                }}
                              />
                            </>
                          )}
                        </div>
                      </div>

                      {/* Customer Information */}
                      <div className="customer-info">
                        <hr />

                        <table>
                          {/* first Row */}
                          <tr>
                            <th>Guest Name</th>
                            <th>Guest Email</th>
                            <th>Guest Phone</th>
                            <th>Customer Profile</th>
                            {[
                              CommunicationType.Notes,
                              CommunicationType.FollowUp,
                              CommunicationType.Shared,
                              CommunicationType.GX2Complete,
                            ].includes(individualData.commType) &&
                            individualData.salesDocNbr ? (
                              <th>Order Number</th>
                            ) : null}
                          </tr>

                          <tr>
                            <td>{individualData.customerNm}</td>
                            <td>{individualData.email}</td>
                            <td>
                              {individualData.phone && (
                                <span
                                  className="hyperlink-highlight"
                                  data-uid={individualData.podiumUID}
                                  data-order={individualData.salesDocNbr}
                                  data-phone={individualData.phone}
                                  data-name={encodeURI(
                                    individualData.customerNm,
                                  )}
                                  data-custid={individualData.customerNbr}
                                  onClick={(e) => handlePodium(e)}
                                >
                                  {helper.phoneNumberFormat2(
                                    individualData.phone,
                                  )}
                                </span>
                              )}
                            </td>
                            <td>
                              {individualData.customerNbr && (
                                <span
                                  className="hyperlink-highlight"
                                  onClick={() =>
                                    setToggleCustomerProfileModal(
                                      !toggleCustomerProfileModal,
                                    )
                                  }
                                >
                                  {individualData.customerNbr}
                                </span>
                              )}
                            </td>
                            {[
                              CommunicationType.Notes,
                              CommunicationType.FollowUp,
                              CommunicationType.Shared,
                              CommunicationType.GX2Complete,
                            ].includes(individualData.commType) &&
                            individualData.salesDocNbr ? (
                              <td>
                                <input
                                  className="order-number-highlight"
                                  type="text"
                                  value={individualData.salesDocNbr}
                                  onClick={orderLib.orderNumberClicked.bind(
                                    this,
                                  )}
                                  readOnly={true}
                                  size={individualData.salesDocNbr.length - 1}
                                />
                              </td>
                            ) : null}
                          </tr>
                          {/* Second Row */}
                          <tr>
                            <th>Customer journey stage</th>
                            <th>Contact method</th>
                            <th
                              colSpan={
                                [
                                  CommunicationType.Notes,
                                  CommunicationType.FollowUp,
                                  CommunicationType.Shared,
                                  CommunicationType.GX2Complete,
                                ].includes(individualData.commType) &&
                                individualData.salesDocNbr
                                  ? 3
                                  : 2
                              }
                            ></th>
                          </tr>
                          <tr>
                            <td>{individualData?.customerJourneyStage}</td>
                            <td>{individualData?.contactMethod}</td>
                            <td
                              colSpan={
                                [
                                  CommunicationType.Notes,
                                  CommunicationType.FollowUp,
                                  CommunicationType.Shared,
                                  CommunicationType.GX2Complete,
                                ].includes(individualData.commType) &&
                                individualData.salesDocNbr
                                  ? 3
                                  : 2
                              }
                            ></td>
                          </tr>
                        </table>

                        <hr />
                      </div>

                      {/* Message Body */}
                      <div className="message-body">
                        {/* Medallia Survey */}
                        {data.commType === CommunicationType.MedalliaSurvey &&
                          individualData.likelihoodComment}

                        {/* Shared Order */}
                        {data.commType === CommunicationType.Shared && (
                          <>
                            <div>
                              {individualData.salesAssociateNm}

                              {' has shared this order '}

                              <input
                                className="order-number-highlight"
                                type="text"
                                value={individualData.salesDocNbr}
                                onClick={orderLib.orderNumberClicked.bind(this)}
                                readOnly={true}
                                size={individualData.salesDocNbr.length - 1}
                              />

                              {'with you and it’s in your '}

                              <span
                                className="hyperlink-highlight"
                                onClick={() => {
                                  navigateToBucket(individualData);
                                }}
                              >
                                {individualData.messageName}
                              </span>

                              <span> bucket.</span>
                            </div>
                            <br />
                            <div>Message: {individualData.details}</div>
                          </>
                        )}

                        {/* Order Notes & Follow Up*/}
                        {(data.commType === CommunicationType.Notes ||
                          data.commType === CommunicationType.FollowUp) &&
                          individualData.details}

                        {/* GX2 Complete */}
                        {data.commType === CommunicationType.GX2Complete && (
                          <>
                            <div>
                              {individualData.salesAssociateNm}

                              {' has completed order '}

                              <input
                                className="order-number-highlight"
                                type="text"
                                value={individualData.salesDocNbr}
                                onClick={orderLib.orderNumberClicked.bind(this)}
                                readOnly={true}
                                size={individualData.salesDocNbr.length - 1}
                              />

                              {'from '}

                              <span
                                className="hyperlink-highlight"
                                onClick={() => {
                                  navigateToBucket(individualData);
                                }}
                              >
                                {individualData.messageName}
                              </span>

                              <span> bucket.</span>
                            </div>
                            <br />
                            <div>Message: {individualData.details}</div>
                          </>
                        )}

                        {/* Request to Contact */}
                        {data.commType ===
                          CommunicationType.RequesToContact && (
                          <>
                            <div>{individualData.subject}</div>
                            <br />
                            <div>Message: {individualData.details}</div>
                          </>
                        )}
                      </div>
                    </div>

                    {toggleCustomerProfileModal && (
                      <ModalCustomerProfile
                        callerToggleToReset={() =>
                          setToggleCustomerProfileModal(
                            !toggleCustomerProfileModal,
                          )
                        }
                        orderNumber={individualData.salesDocNbr}
                        customerID={individualData.customerNbr}
                        customerName={individualData.customerNm}
                        customerEmail={individualData.email}
                        customerPhone={individualData.phone}
                      />
                    )}
                  </>
                );
              });
            })}
        </Col>
      </Row>
    </>
  );
}

function CreateNewCommunication({
  toggleFunction,
  toggleState,
  refreshDashboard,
  isReply,
  isForward,
}) {
  let currentEmpID = localStorage.getItem('EmplId').toString();
  let currentEmpName = localStorage.getItem('EmplName').toString();
  let currentStoreID = localStorage.getItem('StoreId').toString();
  let currentStoreName = localStorage.getItem('StoreName').toString();
  let currentUserGroup = localStorage.getItem('UserGroupId');
  const type = { note: 'Notes', followup: 'Follow Up' };
  const groupInNameList = { label: 'Group', value: 'Group' };
  const enableBobSquad = true; //BCP-2228
  const bobSquad = "Bob's Squad";
  const bobSquadInStoreList = { label: bobSquad, value: bobSquad };

  let defaultData = {
    communicationType: type.note,
    recipientID: currentEmpID,
    recipientName: currentEmpName,
    recipientStoreID: currentStoreID,
    recipientStoreName: currentStoreName,
    guestID: '',
    guestFirstName: '',
    guestLastName: '',
    guestPhoneNumber: '',
    guestEmail: '',
    guestOrderNumber: '',
    followUpDate: new Date(),
    comments: '',
    group: null,
  };

  const [communicationType, setCommunicationType] = useState();
  const [newCommunicationData, setCommunicationData] = useState(defaultData);
  const [storeList, setStoreList] = useState([]);
  const [storeOutletList, setStoreOutletList] = useState([]);
  const [selectedStore, setSelectedStore] = useState(
    currentStoreID !== '1' ? null : { label: 'All Stores', value: null },
  );
  const [originalSalesAssociate, setOriginalSalesAssociate] = useState([]);
  const [salesAssociateList, setSalesAssociateList] = useState([]);
  const [selectedSalesAssociate, setSelectedSalesAssociate] = useState(null);
  const [selectedSalesAssociateFollowUp, setSelectedSalesAssociateFollowUp] =
    useState(currentEmpName);
  const [selectedFollowUpType, setFollowUpType] = useState('t');
  const [selectedFollowUpDate, setFollowUpDate] = useState(new Date());
  const [selectedGroup, setSelectedGroup] = useState([]);
  const [doNotResetOrder, setDoNotResetOrder] = useState(false);
  const [disableLookupButton, setDisableLookupButton] = useState(true);
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  const [journeyStageVal, setJourneyStageVal] = useState(null);

  const [contactMethodVal, setContactMethodVal] = useState(null);

  useEffect(() => {
    //First load
    console.clear();
    console.log('Creating a new communication');

    getStoreList().then(() => {
      if (isReply || isForward) {
        //Do not reset data on first load when default communication type set.
        setDoNotResetOrder(true);

        //Autofill reply or forward data
        let dataToProcess = isReply || isForward;
        let toUseDate = new Date();
        let commentSeparator =
          '\n\n\n____________________________________________________________________________________\n';

        if (dataToProcess.type === type.followup) {
          let today = new Date(new Date().setHours(0, 0, 0, 0));
          let followUpDate = new Date(
            new Date(dataToProcess.scheduledAt).setHours(0, 0, 0, 0),
          );

          //For followup date
          if (followUpDate < today) {
            window.message.notification(
              "Follow up date has been reset to today's since the original date has passed",
              enums.notificationType.W,
            );
            toUseDate = today;
            setFollowUpDate(today);
          } else {
            toUseDate = followUpDate;
            setFollowUpDate(followUpDate);
          }
        }

        defaultData.communicationType =
          dataToProcess.type === type.followup ? type.followup : type.note; //Default as note for non follow up reply or forward
        defaultData.guestID = dataToProcess.customerNbr || '';
        defaultData.guestFirstName = dataToProcess.customerNm || '';
        defaultData.guestPhoneNumber = dataToProcess.phone || '';
        defaultData.guestEmail = dataToProcess.email || '';
        defaultData.guestOrderNumber = dataToProcess.salesDocNbr || '';
        defaultData.guestLastName = '';
        defaultData.followUpDate = toUseDate;
        defaultData.comments =
          commentSeparator +
          'To: ' +
          displayTo(dataToProcess) +
          '\n' +
          'From: ' +
          displayFrom(dataToProcess) +
          (dataToProcess.commType === CommunicationType.Notes &&
          !dataToProcess.salesAssociateNm &&
          dataToProcess.acknowledgeBy
            ? '\nAcknowledged by: ' +
              (dataToProcess.acknowledgeByPreferredName ||
                dataToProcess.acknowledgeByName) +
              ' on ' +
              new Date(dataToProcess.acknowledgeDateTime + 'Z')
                .toLocaleDateString('en-US', {
                  hour: 'numeric',
                  minute: 'numeric',
                  hour12: true,
                })
                .toString()
            : '') +
          '\n\n' +
          //Add subject for Request to Contact
          (dataToProcess.commType === CommunicationType.RequesToContact
            ? dataToProcess.subject + '\n\n'
            : '') +
          (dataToProcess.details || dataToProcess.likelihoodComment);
        defaultData.group =
          dataToProcess.commType === CommunicationType.Notes &&
          !dataToProcess.salesAssociateNm
            ? dataToProcess.referenceID
            : null;

        setCommunicationType(defaultData.communicationType);
        setCommunicationData(defaultData);
      } else {
        //Reset data and type
        setSelectedStore(
          localStorage.getItem('BobSquidManagerView') === 'true' ||
            localStorage.getItem('isBobSquad') === 'true'
            ? bobSquadInStoreList
            : { label: currentStoreName, value: currentStoreID },
        ); //Default
        setCommunicationType(type.note);
        setCommunicationData(defaultData);
      }

      if (isForward) {
        setSelectedStore(
          localStorage.getItem('BobSquidManagerView') === 'true' ||
            localStorage.getItem('isBobSquad') === 'true'
            ? bobSquadInStoreList
            : { label: currentStoreName, value: currentStoreID },
        ); //Default
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (communicationType) {
      if (!doNotResetOrder) {
        //When communication type change, reset follow-up date
        let data = newCommunicationData;
        data.followUpDate = null;
        data.guestOrderNumber = '';

        setCommunicationData(data);
        setFollowUpDate(new Date());
        setSelectedGroup([]);
        setSelectedSalesAssociate([]);
        setSelectedSalesAssociateFollowUp(null);

        //When communication type change, reset associate list
        initializeNameList();
      } else {
        //Allow data to be cleared only for second time change Type.
        setDoNotResetOrder(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communicationType]);

  useEffect(() => {
    if (selectedStore) {
      //Get new associate name list when store changed
      getAssociateNameList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStore]);

  useEffect(() => {
    if (
      !selectedSalesAssociate?.some((x) => x.value === groupInNameList.value)
    ) {
      //Reset selected group when group not choosed as recipient
      setSelectedGroup([]);
    }

    let currentData = newCommunicationData;
    let newRecipientName = [];
    let newRecipientID = [];

    selectedSalesAssociate?.forEach((x) => {
      if (x.value !== groupInNameList.value) {
        //Ignore group selection

        let currentSA = originalSalesAssociate.filter(
          (data) => data.empID === x.value,
        )[0];
        newRecipientName.push(currentSA.preferredName || currentSA.empName);
        newRecipientID.push(currentSA.empID);
      }
    });
    currentData.recipientName =
      newRecipientName.length > 0 ? newRecipientName.join(',') : '';
    currentData.recipientID =
      newRecipientID.length > 0 ? newRecipientID.join(',') : '';

    setCommunicationData({ ...currentData });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSalesAssociate]);

  useEffect(() => {
    if (selectedSalesAssociateFollowUp) {
      let currentSA = originalSalesAssociate.filter(
        (data) => data.preferredName === selectedSalesAssociateFollowUp.label,
      )[0];

      if (currentSA) {
        let currentData = newCommunicationData;

        currentData.recipientName = currentSA.preferredName;
        currentData.recipientID = currentSA.empID;

        setCommunicationData({ ...currentData });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSalesAssociateFollowUp]);

  useEffect(() => {
    initializeNameList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [originalSalesAssociate]);

  const getStoreList = async () => {
    try {
      let url =
        helper.getAPIHost() +
        '/api/Dashboard/getStoreList?DeliverValueLimit=false';

      await fetch(url, { method: 'GET', ...helper.apiHeaders() })
        .then(async (response) => {
          let data = await response.json();
          helper.catchServer(data);
          if (data.status === 'OK') {
            let tempStoreList = [];
            let tempStoreOutletList = [];

            data.result.forEach((item) => {
              tempStoreList.push({
                label: item.store,
                value: item.storeNumber,
              });
              tempStoreOutletList.push({
                storeName: item.store,
                storeNum: item.storeNumber,
                outletNum:
                  item.outletNumber?.toUpperCase().trim() === 'NULL' ||
                  item.outletNumber?.length === 0
                    ? null
                    : item.outletNumber,
                existsGSA: item.existsGSA,
                existsOutlet: item.existsOutlet,
              });
            });

            if (enableBobSquad === true) {
              tempStoreList.splice(0, 0, bobSquadInStoreList);
            }

            //BCP-2340
            if (isReply) {
              let dataToProcess = isReply;

              if (
                dataToProcess.targetProfitCenter === 'squad' &&
                !dataToProcess.profitCenterNbr
              ) {
                setSelectedStore(
                  tempStoreList.find(
                    (x) => x.value === bobSquadInStoreList.value,
                  ),
                );
              } else {
                setSelectedStore(
                  tempStoreList.find(
                    (x) => x.value === dataToProcess.profitCenterNbr,
                  ),
                );
              }
            }

            setStoreList(tempStoreList);
            setStoreOutletList(tempStoreOutletList);
          } else {
            ux.logError(
              'Create New Communication',
              'Get Data from API: getStoreList',
              'Unable to get data from server!',
              url + ' ' + data.errorMsg,
            );
          }
        })
        .catch((error) => {
          ux.logError(
            'Create New Communication',
            'Get Data from API',
            'Init Data API:getStoreList - ' + error,
            url,
          );
        });
    } catch (error) {
      ux.logError(
        'Create New Communication',
        'Get Data from API',
        'Init Data API: getStoreList - ' + error,
      );
    }
  };

  const initializeNameList = () => {
    const setCurrentUser = () => {
      //If null or empty, assign to current user
      let currentUserExist = originalSalesAssociate.filter(
        (item) => item.empID === currentEmpID,
      );

      if (communicationType === type.note) {
        if (currentUserExist.length > 0) {
          setSelectedSalesAssociate([
            {
              label:
                currentUserExist[0].preferredName ||
                currentUserExist[0].empName,
              value: currentUserExist[0].empID,
            },
          ]);
        } else {
          setSelectedSalesAssociate([]);
        }
      } else {
        if (currentUserExist.length > 0) {
          setSelectedSalesAssociateFollowUp({
            label:
              currentUserExist[0].preferredName || currentUserExist[0].empName,
            value: currentUserExist[0].empID,
          });
        } else {
          setSelectedSalesAssociateFollowUp(null);
        }
      }
    };

    let arrayName = [];

    originalSalesAssociate.forEach((item) => {
      arrayName.push({
        label: item.preferredName || item.empName,
        value: item.empID,
      });
    });

    //Sort and set first index
    arrayName = arrayName.sort((a, b) => a.label.localeCompare(b.label));

    if (
      (currentUserGroup === '2' ||
        currentUserGroup === '3' ||
        currentUserGroup === '26' ||
        currentUserGroup === '5') &&
      communicationType === type.note
    ) {
      arrayName.splice(0, 0, groupInNameList);
    }

    setSalesAssociateList(arrayName);

    if (communicationType === type.note) {
      if (!selectedSalesAssociate || selectedSalesAssociate?.length === 0) {
        //setCurrentUser();
      } else {
        //Update all selection when change store and keep only that exist in current arrayName such as "Group"
        let matchingValue = selectedSalesAssociate?.filter((x) =>
          arrayName.some((k) => k.value === x.value),
        );

        if (matchingValue.length >= 0) {
          setSelectedSalesAssociate(matchingValue);
        } else {
          setCurrentUser();
        }
      }
    }

    if (communicationType === type.followup) {
      setCurrentUser();
    }

    //BCP-2340
    if (isReply) {
      let dataToProcess = isReply;

      let selectedUser =
        arrayName.filter((x) => dataToProcess.creator === x.value) || null;

      //BCP-2604
      if (selectedUser && selectedUser.length === 0) {
        // Re-assign to Bob's Squad store with new associate name list from Bob's Squad list if cannot find the user from original profit center.
        // This operation assumes that if user not exist in the current store list, assign the store to Bob's Squad store where the creator could be a Bob Squad associates.
        setSelectedStore(
          storeList.find((x) => x.value === bobSquadInStoreList.value),
        );
      }

      setSelectedSalesAssociate(selectedUser);
    }

    if (isForward) {
      setSelectedSalesAssociate(null);
    }
  };

  const getAssociateNameList = async () => {
    try {
      let storeid =
        selectedStore.value === bobSquad ? 'squad' : selectedStore.value;

      let url =
        helper.getAPIHost() +
        '/api/Employee/SearchAssociateList?key=&storeid=' +
        storeid +
        '&DeliverValueLimit=false&include=true&requireWorkSchedule=true';

      await fetch(url, { method: 'GET', ...helper.apiHeaders() })
        .then(async (response) => {
          let data = await response.json();
          if (data.status === 'OK') {
            helper.catchServer(data);

            if (selectedStore.value !== bobSquad) {
              //BCP-2228 : Remove Bob Squad from normal store selection
              let nonBobSquadSA = data.result.filter((x) => !x.isBobSquad);

              data.result = nonBobSquadSA;
            }

            setOriginalSalesAssociate(data.result);
          } else {
            ux.logError(
              'Create New Communication',
              'Get Data from API: SearchAssociateList',
              'Unable to get data from server!',
              url + ' ' + data.errorMsg,
            );
          }
        })
        .catch((error) => {
          ux.logError(
            'Create New Communication',
            'Get Data from API',
            'Init Data API:SearchAssociateList - ' + error,
            url,
          );
        });
    } catch (error) {
      ux.logError(
        'Create New Communication',
        'Get Data from API',
        'Init Data API: SearchAssociateList - ' + error,
      );
    }
  };

  const handleInputChange = (e) => {
    let currentTarget = e.currentTarget;
    let currentTargetId = currentTarget.getAttribute('id');
    let currentTargetValue = currentTarget.value;
    let currentData = newCommunicationData;

    //console.log(currentTargetId, currentTargetValue, newCommunicationData)

    switch (currentTargetId) {
      case 'customer-id':
        //Only allow number
        if (isNaN(currentTargetValue)) {
          return;
        }
        currentData.guestID = currentTargetValue;
        disableLookupButton && setDisableLookupButton(false);
        break;
      case 'first-name':
        currentData.guestFirstName = currentTargetValue;
        break;
      case 'last-name':
        currentData.guestLastName = currentTargetValue;
        break;
      case 'phone-number':
        currentTargetValue = currentTargetValue
          .replace(/[^0-9]+/g, '')
          .slice(0, 10); //Get first 10 characters of numbers

        //Only allow number
        if (isNaN(currentTargetValue)) {
          return;
        }
        currentData.guestPhoneNumber = currentTargetValue
          .replace('.', '')
          .trim(); //Replace dots (.) with ""
        disableLookupButton && setDisableLookupButton(false);
        break;
      case 'email':
        currentData.guestEmail = currentTargetValue;
        disableLookupButton && setDisableLookupButton(false);
        break;
      case 'order-number':
        currentData.guestOrderNumber = currentTargetValue
          .toString()
          .replace(/\s/g, '');
        break;
      case 'comment':
        currentData.comments = currentTargetValue;
        break;
      default:
        break;
    }

    setCommunicationData({ ...currentData });
  };

  const prospectFollowupDateShortcut = (e) => {
    let type = e.currentTarget.getAttribute('type');
    // console.log(type);

    let date = new Date();
    let days = 0;
    if (type === 't') days += 0;
    if (type === '2d') days += 1;
    if (type === '1w') days += 7;

    date = date.setDate(date.getDate() + days);

    setFollowUpDate(new Date(date));
    setFollowUpType(type);
  };

  const prospectFollowupDate = (date) => {
    // console.log(date);
    setFollowUpDate(new Date(date));
    setFollowUpType('');
  };

  const updateCurrentDataBeforeSubmit = () => {
    let currentData = newCommunicationData;

    //Update store
    if (currentData.recipientStoreID !== selectedStore.value) {
      let storeData = storeList.filter(
        (data) => data.value === selectedStore.value,
      )[0];

      if (storeData) {
        currentData.recipientStoreID = storeData.value;
        currentData.recipientStoreName = storeData.label;
      }
    }

    //Update communication type
    currentData.communicationType = communicationType;

    //Update followup date
    if (communicationType === type.followup) {
      currentData.followUpDate = selectedFollowUpDate;
    }

    //Update Group
    if (selectedGroup?.value === 0 || selectedGroup.length === 0) {
      currentData.group = null;
    } else {
      let tempData = [];
      selectedGroup.forEach((data) => tempData.push(data.value));
      currentData.group = tempData.join(',');
    }

    setCommunicationData({ ...currentData });
  };

  const submitNewCollaboration = async () => {
    setDisableSubmitButton(true);
    updateCurrentDataBeforeSubmit();

    if (!validateInput()) {
      setDisableSubmitButton(false);
      //Return if there is a validation error
      return;
    } else {
      let currentData = newCommunicationData;

      let payload = {
        source: 'Order Notes',
        type: currentData.communicationType,
        salesAssociate:
          currentData.recipientID.length > 0 ? currentData.recipientID : null,
        salesAssociateNm:
          currentData.recipientName.length > 0
            ? currentData.recipientName
            : null,
        callReceiverType:
          currentData.recipientStoreID === bobSquad
            ? 'squad'
            : currentData.recipientStoreID,
        profitCenterNbr: localStorage.getItem('StoreId'),
        profitCenterNm: localStorage.getItem('StoreName'),
        customerNbr: currentData.guestID,
        customerNm: (
          currentData.guestFirstName +
          ' ' +
          currentData.guestLastName
        ).trim(),
        phoneNbr: currentData.guestPhoneNumber,
        email: currentData.guestEmail,
        salesDocNbr:
          currentData.guestOrderNumber.length > 0
            ? currentData.guestOrderNumber
            : null,
        scheduledAt: currentData.followUpDate
          ? new Date(
              currentData.followUpDate.setHours(0, 0, 0, 0),
            ).toLocaleDateString()
          : null,
        details: currentData.comments,
        referenceid:
          currentData.group?.length > 0
            ? currentData.group
            : currentData.recipientID,
        parentUID: isReply ? isReply.parentUID : null,
        customerJourneyStage: journeyStageVal ? journeyStageVal.value : null,
        contactMethod: contactMethodVal ? contactMethodVal.value : null,
      };

      console.log('Payload --> ', payload);

      //To send to API
      await fetch(
        helper.getAPIHost() + '/api/Customer360/postCustomerTimeline',
        {
          method: 'POST',
          body: JSON.stringify(payload),
          ...helper.apiHeaders(),
        },
      )
        .then(async (res) => {
          setDisableSubmitButton(false);
          if (res.status === 200) {
            let messageType = '';
            if (communicationType === type.note) {
              messageType = 'Note';
            } else {
              messageType = 'Follow Up';
            }

            window.message.notification(
              `${messageType} Added Successfully`,
              enums.notificationType.S,
            );

            ux.logAction(
              'Communication and Collaboration',
              `Add ${communicationType}`,
            );
            toggleFunction(!toggleState);
            refreshDashboard();
            setContactMethodVal(null);
            setJourneyStageVal(null);
          } else {
            window.message.notification(
              `Add ${communicationType} Failed`,
              enums.notificationType.E,
            );
            ux.logError(
              'Communication and Collaboration',
              'postCustomerTimeline',
              JSON.stringify(res),
              JSON.stringify(payload),
            );
          }
        })
        .catch((err) => {
          setDisableSubmitButton(false);
          window.message.notification(
            `Add ${communicationType} Failed, ${err}`,
            enums.notificationType.E,
          );
          ux.logError(
            'Communication and Collaboration',
            'postCustomerTimeline',
            err,
            JSON.stringify(payload),
          );
        });
    }
  };

  const validateInput = () => {
    console.log('Validating...');
    let currentData = newCommunicationData;

    let invalidField = [];

    if (
      (!currentData.recipientID && !currentData.group) ||
      (communicationType === type.followup && !selectedSalesAssociateFollowUp)
    ) {
      //If no group and no recipientID or not selected in followup
      invalidField.push('Recipient Name');
    }

    if (
      !currentData.recipientStoreID ||
      currentData.recipientStoreID === '1' ||
      currentData.recipientStoreID?.length === 0
    ) {
      invalidField.push('Recipient Store');
    }

    if (
      currentEmpID !== currentData.recipientID &&
      currentData.communicationType === type.note
    ) {
      //Check guest info if not current user as recipient
      if (currentData.guestFirstName.length === 0) {
        invalidField.push('Guest first name');
      }

      if (currentData.guestPhoneNumber.length === 0) {
        invalidField.push('Guest phone number');
      }
    }

    if (currentData.communicationType === type.followup) {
      //Check guest info if not current user as recipient
      if (currentData.guestFirstName.length === 0) {
        invalidField.push('Guest first name');
      }

      if (currentData.guestPhoneNumber.length === 0) {
        invalidField.push('Guest phone number');
      }

      if (currentData.guestOrderNumber.length === 0) {
        invalidField.push('Order Number');
      }
    }

    if (currentData.comments.length === 0) {
      invalidField.push('Comments');
    }

    if (
      selectedSalesAssociate === groupInNameList &&
      (selectedGroup?.value <= 0 || selectedGroup.length === 0)
    ) {
      invalidField.push(groupInNameList);
    }

    let regEmail =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (
      currentData.guestEmail.length > 0 &&
      !regEmail.test(currentData.guestEmail)
    ) {
      invalidField.push('Email');
    }

    if (
      currentData.guestPhoneNumber.length > 0 &&
      currentData.guestPhoneNumber.length < 10
    ) {
      invalidField.push('Guest phone number requires 10 digits');
    }

    if (invalidField.length > 0 && invalidField.includes('Email')) {
      window.message.notification(
        `These fields are required and email is invalid. ${invalidField.join(
          ', ',
        )}`,
        enums.notificationType.E,
      );
      return false;
    } else if (invalidField.length > 0) {
      window.message.notification(
        `These fields are required. ${invalidField.join(', ')}`,
        enums.notificationType.E,
      );
      return false;
    }

    return true;
  };

  const validateGroupAndStore = (groupObj, newStoreSelected) => {
    //groupObj from handleMultiSelect
    //newStoreSelected from storechange

    if (newStoreSelected === bobSquad) {
      //No need checking group role selection against store/outlet list
      return true;
    }

    //Checking for during group change
    if (groupObj) {
      let checkGroup = {};

      checkGroup.gsa = groupObj.find((x) => x.value === 3); //Guest Support Associates checking
      checkGroup.outletExp = groupObj.find((x) => x.value === 4); //Outlet Experience Specialists checking

      if (
        (checkGroup.gsa &&
          storeOutletList.some(
            (x) => x.storeNum === selectedStore.value && !x.existsGSA,
          )) ||
        (checkGroup.outletExp &&
          storeOutletList.some(
            (x) => x.storeNum === selectedStore.value && !x.existsOutlet,
          ))
      ) {
        window.message.notification(
          `There are no users in the selected group for this store location.`,
          enums.notificationType.E,
        );
        return false;
      } else {
        return true;
      }
    }

    //Checking for during store change
    if (newStoreSelected) {
      let checkNotAllowGroup = [];

      //Guest Support Associates checking
      if (
        selectedGroup.find((x) => x.value === 3) &&
        storeOutletList.some(
          (x) => x.storeNum === newStoreSelected.value && !x.existsGSA,
        )
      ) {
        checkNotAllowGroup.push(selectedGroup.find((x) => x.value === 3));
      }

      //Outlet Experience Specialists checking
      if (
        selectedGroup.find((x) => x.value === 4) &&
        storeOutletList.some(
          (x) => x.storeNum === newStoreSelected.value && !x.existsOutlet,
        )
      ) {
        checkNotAllowGroup.push(selectedGroup.find((x) => x.value === 4));
      }

      if (checkNotAllowGroup.length > 0) {
        checkNotAllowGroup?.forEach((role) => {
          window.message.notification(
            `${newStoreSelected.label} does not support ${role.label} group. Therefore the group selection was removed`,
            enums.notificationType.W,
          );
        });

        setSelectedGroup(
          selectedGroup.filter(
            (x) => !checkNotAllowGroup?.map((x) => x.value).includes(x.value),
          ),
        );
        return true;
      }
    }

    return true;
  };

  const handleMultiSelect = (obj) => {
    if (!validateGroupAndStore(obj)) {
      return;
    }

    let newData = obj.filter((data) => data.value !== 0);

    setSelectedGroup(newData);
  };

  const multiSelectStyle = {
    menu: (provided, state) => ({
      ...provided,
      boxShadow:
        '0 0 0 1px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%)',
      borderRadius: '4px',
    }),
    option: (provided, state) => ({
      ...provided,
      textAlign: 'left',
      background: state.isSelected ? '#FF7285' : '#fff',
      color: state.isSelected ? 'white' : 'black',
      fontWeight: 'inherit',

      '&:hover': {
        background: state.isSelected ? '#FF7285' : '#F9EBE9',
      },
    }),
  };

  return (
    <Modal
      isOpen={toggleState}
      toggle={() => toggleFunction(!toggleState)}
      className="modal-absolute-center modal-communication"
    >
      <ModalHeader>
        {communicationType === type.note ? 'New Message/Note' : 'New follow-up'}
        <ModalBtnClose onClick={() => toggleFunction(!toggleState)} />
      </ModalHeader>
      <ModalBody>
        <div>
          <div key={'recipient'}>
            {communicationType === type.note
              ? 'Recipient'
              : 'Who needs to follow up?'}
            <span style={{ marginLeft: '15px' }}>
              {'['}Note : <FaExclamationCircle color="red" /> Not Working Today
              {'] '}
              {/* {' ['}Note : <FaDotCircle color="red" /> ENGAGE
              {'] '}
              {' ['}Note : <FaDotCircle color="green" /> QUEUE
              {'] '} */}
            </span>
            <div className="info">
              <Dropdown
                id={'store-dropdown'}
                value={selectedStore}
                options={storeList}
                isDisabled={isReply || false}
                onChange={(obj) => {
                  if (
                    obj.value !== selectedStore.value &&
                    validateGroupAndStore(null, obj)
                  )
                    setSelectedStore(obj);

                  if (
                    (selectedStore.value === bobSquad &&
                      obj.value !== bobSquad) ||
                    (selectedStore.value !== bobSquad && obj.value === bobSquad)
                  ) {
                    //Reset when current or previous value is Bob's Squad selection
                    setSelectedGroup([]);

                    if (selectedGroup.length > 0) {
                      window.message.notification(
                        `Group selection removed`,
                        enums.notificationType.W,
                      );
                    }
                  }
                }}
                isClearable={false}
              />
              <span className="red-asterisk">*</span>

              {communicationType === type.note ? (
                <div
                  className={`parent-group-multiselect ${
                    isReply && 'disabled'
                  }`}
                  style={{ marginRight: '10px' }}
                >
                  {isReply ? (
                    <Dropdown
                      value={selectedSalesAssociate}
                      options={salesAssociateList}
                      isDisabled={isReply || false}
                    />
                  ) : (
                    <Dropdown
                      placeholder={'Search by name'}
                      className="recipient-multiselect"
                      id="recipient-multiselect"
                      isMulti
                      value={selectedSalesAssociate}
                      options={salesAssociateList}
                      isSearchable={true}
                      isClearable={false}
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      onChange={(obj) => {
                        if (
                          obj.filter((x) => x.value !== groupInNameList.value)
                            .length > 5
                        ) {
                          window.message.notification(
                            `Only a maximum of five recipients is allowed.`,
                            enums.notificationType.E,
                          );
                          return;
                        }
                        if (
                          obj.some((x) => x.value === groupInNameList.value) &&
                          selectedStore.value === null
                        ) {
                          window.message.notification(
                            `Please select store before selecting group`,
                            enums.notificationType.W,
                          );
                          return;
                        }

                        //Check only during add new associate
                        if (
                          obj?.length > selectedSalesAssociate?.length &&
                          selectedSalesAssociate
                            .map((x) => x.value)
                            .includes(obj.slice(-1)[0].value)
                        ) {
                          //unselect
                          let latestSelection = obj.slice(-1)[0].value;
                          obj = obj.filter((x) => x.value !== latestSelection);
                        }

                        //BCP-2405: Check only during add new associate
                        if (obj?.length > selectedSalesAssociate?.length) {
                          //Get the latest selection of one user
                          let latestSA = obj.slice(-1)[0];

                          if (
                            latestSA &&
                            latestSA.value !== groupInNameList.value
                          ) {
                            //Ignore group selection
                            let currentSA = originalSalesAssociate.find(
                              (data) => data.empID === latestSA.value,
                            );

                            if (currentSA?.filterNotWorkingToday) {
                              //Warn user but still allow for selection
                              window.message.notification(
                                `${latestSA.label} is not working today.`,
                                enums.notificationType.W,
                              );
                            }
                          }
                        }

                        setSelectedSalesAssociate(obj);
                      }}
                      components={{
                        Option: ({ innerProps, label, data, isSelected }) => {
                          let notWorking = originalSalesAssociate.find(
                            (x) => x.empID === data.value,
                          )?.filterNotWorkingToday;

                          // let guestConnectStatus = originalSalesAssociate.find(
                          //   (x) => x.empID === data.value,
                          // )?.guestConnectStatus;

                          return (
                            <div
                              {...innerProps}
                              className={`custom-option ${
                                isSelected && 'selected'
                              }`}
                            >
                              <Checkbox
                                checked={isSelected}
                                disableRipple={true}
                              />

                              <span className="label">{data.label}</span>

                              {notWorking &&
                                data.value !== groupInNameList.value && (
                                  <FaExclamationCircle
                                    className="exclamation-icon"
                                    title={`${label} is not working today`}
                                  />
                                )}

                              {/* {guestConnectStatus &&
                                guestConnectStatus === 'ENGAGE' &&
                                data.value !== groupInNameList.value && (
                                  <FaDotCircle
                                    className="exclamation-icon"
                                    title={`${label} is currently engaging with guest`}
                                  />
                                )}

                              {guestConnectStatus &&
                                guestConnectStatus === 'QUEUE' &&
                                data.value !== groupInNameList.value && (
                                  <FaDotCircle
                                    className="exclamation-icon"
                                    title={`${label} is in queue`}
                                    color="green"
                                  />
                                )} */}
                            </div>
                          );
                        },
                        MultiValue: ({ children, ...innerProps }) => {
                          let toDisplayData = innerProps.selectProps.value;

                          const isFirst = (selectedOptions, option) => {
                            return (
                              selectedOptions.length > 0 &&
                              selectedOptions[0].value === option.value
                            );
                          };

                          const { data } = innerProps;

                          //To display only once rather than each selected options
                          return isFirst(toDisplayData, data) ? (
                            <components.MultiValueContainer {...innerProps}>
                              {toDisplayData.length > 1 ? (
                                <div>{toDisplayData.length} selected</div> //Display if more than one selection
                              ) : (
                                <div>{data.label}</div> //Display if only one selection
                              )}
                            </components.MultiValueContainer>
                          ) : null;
                        },
                      }}
                    />
                  )}
                </div>
              ) : (
                <Dropdown
                  id={'followup-dropdown'}
                  value={selectedSalesAssociateFollowUp}
                  options={salesAssociateList}
                  isClearable={false}
                  onChange={(val) => setSelectedSalesAssociateFollowUp(val)}
                  placeholder={'Search by name'}
                />
              )}
              <span className="red-asterisk">*</span>

              {/* Group selection */}
              {communicationType === type.note &&
              selectedSalesAssociate?.some(
                (x) => x.value === groupInNameList.value,
              ) ? (
                <>
                  <div className="parent-group-multiselect">
                    <ReactMultiSelectCheckboxes
                      options={
                        selectedStore.value === bobSquad
                          ? bobSquadGroupOptions
                          : groupOptions.filter((x) => x.value !== 6) //Remove Guest Experience Specialists
                      }
                      placeholderButtonLabel="Select Group"
                      className="group-multiselect"
                      checked={true}
                      onChange={(obj) => handleMultiSelect(obj)}
                      value={selectedGroup}
                      hideSearch={true}
                      styles={multiSelectStyle}
                    />
                  </div>

                  <span
                    className="red-asterisk"
                    style={{ paddingLeft: '10px' }}
                  >
                    *
                  </span>
                </>
              ) : null}
            </div>
          </div>

          <div key={'guest-info'}>
            Guest Info
            <div className="info">
              <div style={{ display: 'none' }}>
                <Input
                  placeholder="CM #"
                  id="customer-id"
                  onChange={handleInputChange}
                  // onBlur={() => {
                  //   if (!disableLookupButton) {
                  //     setTimeout(() => {
                  //       document.getElementById('potential-cust-button')?.click();
                  //     }, 1000);
                  //   }
                  // }}
                  value={newCommunicationData.guestID}
                  readOnly={true}
                ></Input>
                <Input
                  placeholder="Name"
                  id="first-name"
                  onChange={handleInputChange}
                  value={newCommunicationData.guestFirstName}
                  readOnly={true}
                ></Input>
                {/* {newCommunicationData.recipientID !== currentEmpID ||
              selectedSalesAssociate?.length >= 2 ||
              communicationType === type.followup ? (
                <span className="red-asterisk">*</span>
              ) : null} */}
                <Input
                  placeholder="Order Number"
                  type="text"
                  id="order-number"
                  onChange={handleInputChange}
                  value={newCommunicationData.guestOrderNumber}
                  maxLength={10}
                />
              </div>

              <Input
                placeholder="Email address"
                type="email"
                id="email"
                onChange={handleInputChange}
                // onBlur={() => {
                //   if (!disableLookupButton) {
                //     setTimeout(() => {
                //       document.getElementById('potential-cust-button')?.click();
                //     }, 1000);
                //   }
                // }}
                value={newCommunicationData.guestEmail}
                // maxLength={80}
                // readOnly={true}
              ></Input>

              <Input
                placeholder="Phone Number"
                id="phone-number"
                onChange={handleInputChange}
                // onBlur={() => {
                //   if (!disableLookupButton) {
                //     setTimeout(() => {
                //       document.getElementById('potential-cust-button')?.click();
                //     }, 1000);
                //   }
                // }}
                value={newCommunicationData.guestPhoneNumber}
                // readOnly={true}
              ></Input>
              {/* {newCommunicationData.recipientID !== currentEmpID ||
              selectedSalesAssociate?.length >= 2 ||
              communicationType === type.followup ? (
                <span className="red-asterisk">*</span>
              ) : null} */}

              <PotentialCustomer
                placement={'left-start'}
                customerID={newCommunicationData.guestID}
                customerEmail={newCommunicationData.guestEmail}
                customerPhone={newCommunicationData.guestPhoneNumber}
                origin={communicationType === type.note ? 'note' : 'follow up'}
                updateProfileData={(data) => {
                  let currentData = { ...newCommunicationData };

                  currentData.guestID = data.customerID;
                  currentData.guestEmail = data.customerEmail;
                  currentData.guestPhoneNumber = data.customerPhone;
                  currentData.guestFirstName = data.customerName;

                  setCommunicationData({ ...currentData });
                }}
                disabledProps={disableLookupButton}
                setDisableFunc={setDisableLookupButton}
                isReply={isReply}
                isForward={isForward}
              />
            </div>
          </div>

          <div key={'follow-up'}>
            {communicationType === type.followup ? (
              <>
                <div>
                  When should we follow up?{' '}
                  <span style={{ color: 'red', fontWeight: 'bold' }}>*</span>
                </div>
                <div className="follow-up-date">
                  <div className="shortcut">
                    <span
                      onClick={prospectFollowupDateShortcut}
                      type="t"
                      className={
                        selectedFollowUpType === 't' ? 'dateselected' : ''
                      }
                    >
                      Today
                    </span>
                    <span
                      onClick={prospectFollowupDateShortcut}
                      type="2d"
                      className={
                        selectedFollowUpType === '2d' ? 'dateselected' : ''
                      }
                    >
                      Tomorrow
                    </span>
                    <span
                      onClick={prospectFollowupDateShortcut}
                      type="1w"
                      className={
                        selectedFollowUpType === '1w' ? 'dateselected' : ''
                      }
                    >
                      In one week
                    </span>
                  </div>

                  <DatePickerCalendar
                    locale={enGB}
                    minimumDate={new Date()} // See Calendar props
                    // maximumDate={this.state.snoozeEnd} // See Calendar props
                    date={selectedFollowUpDate}
                    // format='dd/MM/yyyy HH:mm a'
                    value={selectedFollowUpDate}
                    onDateChange={prospectFollowupDate}
                  />
                </div>
              </>
            ) : null}
          </div>

          <div
            key={'customer_journey_stage'}
            className="customer_journey_stage"
          >
            <div>
              <label>Customer journey stage:</label>
            </div>
            <div style={{ paddingBottom: '10px', paddingRight: '10px' }}>
              <Dropdown
                id={'journey-stage-dropdown'}
                value={journeyStageVal}
                options={[
                  { value: 'Pre-Purchase', label: 'Pre-Purchase' },
                  { value: 'Pre-Delivery', label: 'Pre-Delivery' },
                  { value: 'Day of Delivery', label: 'Day of Delivery' },
                  { value: 'Post Delivery', label: 'Post Delivery' },
                ]}
                onChange={(val) => setJourneyStageVal(val)}
                isClearable={true}
              />
            </div>
            <div>
              <label>Contact method:</label>
            </div>
            <div style={{ paddingBottom: '10px', paddingRight: '10px' }}>
              <Dropdown
                id={'contact-method-dropdown'}
                value={contactMethodVal}
                options={[
                  { value: 'Phone Call', label: 'Phone Call' },
                  { value: 'Podium', label: 'Podium' },
                  { value: 'In-Store', label: 'In-Store' },
                  { value: 'Transfer', label: 'Transfer' },
                  { value: 'Online Chat', label: 'Online Chat' },
                ]}
                onChange={(val) => setContactMethodVal(val)}
                isClearable={true}
              />
            </div>
          </div>

          <div key={'comment'} className="view-textarea">
            <div>
              Background Information
              <span style={{ color: 'red', fontWeight: 'bold' }}>*</span>
            </div>
            <textarea
              className="textarea-show"
              name="comment"
              id="comment"
              onChange={handleInputChange}
              value={newCommunicationData.comments}
              rows={10}
            ></textarea>
            <span style={{ color: 'red', fontWeight: 'bold' }}>
              Please enter at least 50 characters
            </span>
          </div>

          <div style={{ textAlign: 'end', marginRight: '8px' }}>
            <RedButton
              label={<>{disableSubmitButton && <Spinner size="sm" />} Submit</>}
              outline={false}
              onClick={submitNewCollaboration}
              disabled={disableSubmitButton}
            />
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}
