import React from 'react';
import PropTypes from 'prop-types';
import jwtDecode from 'jwt-decode';
import * as auth from '../../../application-center/login-center/lib/authentication';

import BDFCustomer360 from '../../customers/customer-timelines';

import { MdInfo } from 'react-icons/md';
import NotificationSystem from 'react-notification-system';
import { NOTIFICATION_SYSTEM_STYLE } from '../../../utils/constants';

const enums = require('../../../enums');
const enumDict = require('../../../enums/enumDict');
const helper = require('../../../utils/helper');
const ux = require('../../../application-center/ux-tracking-center');

class ExternalAccessPage extends React.Component {
  state = {
    apps: {
      // will move to DB config
      bobsuite: {
        appId: 'bbe2e3fb-2dad-4ed9-9c29-c78ba116b81f',
        host: '', // to extend to check referrer
      },
      // reserve for other application
    },
    page: '',
    isLoading: 1,

    orderNum: '',
    custId: '',
    custName: '',
    orderType: '',

    errMsg: '',
  };

  notification(message, type, children, delay) {
    // https://snyk.io/advisor/npm-package/react-notification-system

    if (!delay) delay = 0;

    setTimeout(() => {
      if (!this.notificationSystem) {
        return;
      }

      if (!type) type = 'success';
      if (!children) children = '';

      this.notificationSystem.addNotification({
        title: <MdInfo />,
        message: message,
        level: type,
        children: children,
      });
    }, delay);
  }

  postError(msg, tokenJSON = null, tokenStr = '') {
    ux.logError(
      'Framework',
      'ExternalLogin',
      msg,
      (tokenJSON ? JSON.stringify(tokenJSON) : '') + '; Token: ' + tokenStr,
      true,
    );
  }

  // URL:
  // http://localhost:3000/orderTimeline?orderNum=16382742&custId=8704067&custName=FELIZ%20RODRIGUEZ%20/ZOILA%20MARMOL&token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBJZCI6ImJiZTJlM2ZiLTJkYWQtNGVkOS05YzI5LWM3OGJhMTE2YjgxZiIsInNvdXJjZSI6ImJvYnN1aXRlIiwiZW1wSWQiOiJBODI1NSJ9.F0MJKuK7lBiRGKUuYR1F9RNwuX1VvChIXIXyWLJn9U8
  componentDidMount() {
    const { page } = this.props;

    window.message.notification = this.notification.bind(this);
    window.isExternal = true;

    const orderNum = helper.getQSParam('orderNum');
    const orderType = helper.getQSParam('orderType');
    const custId = helper.getQSParam('custId');
    const custName = helper.getQSParam('custName'); // for future use
    const token = helper.getQSParam('token'); // || "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBJZCI6ImJiZTJlM2ZiLTJkYWQtNGVkOS05YzI5LWM3OGJhMTE2YjgxZiIsInNvdXJjZSI6ImJvYnN1aXRlIiwiZW1wSWQiOiJBODI1NSJ9.F0MJKuK7lBiRGKUuYR1F9RNwuX1VvChIXIXyWLJn9U8"

    // token format:
    /*let tokenJSON = {
        "appId": "bbe2e3fb-2dad-4ed9-9c29-c78ba116b81f",
        "source": "bobsuite",
        "empId": "A8255",
        "loginId": ""
      }*/

    if (!token) {
      this.postError('Error: AppToken is missing!');
      this.setState({
        isLoading: 2,
        errMsg: 'Error: AppToken is missing!',
      });
      return;
    }

    // Extracting the token & verifiying
    const tokenInfo = jwtDecode(token);

    // verify source & appId
    const source = tokenInfo.source;
    const appId = tokenInfo.appId;
    const empId = tokenInfo.empId;
    const loginId = tokenInfo.loginId;

    if (!this.state.apps[source].appId === appId) {
      this.setState({
        isLoading: -1,
      });
    }

    // Validate mandatory keys
    let errorMsg = '';
    if (!empId) {
      errorMsg = `EmpId is missing!`;
      if (!helper.isProd())
        errorMsg += `Token ${token} Content ${JSON.stringify(tokenInfo)}`;
    } else if (!loginId && helper.isProd()) {
      errorMsg = 'LoginId is missing!';
    } else if (!custId) {
      errorMsg = 'Customer # is missing!';
    } else if (!source) {
      errorMsg = 'App Source is missing!';
    } else if (!appId) {
      errorMsg = 'AppId is missing!';
    } else if (
      custId.trim().toLowerCase() === 'undefined' ||
      /^\d*$/.test(custId.trim()) === false
    ) {
      errorMsg = 'Invalid Customer #';
    }

    if (!loginId && !helper.isProd()) {
      alert('QA env: LoginId is missing!');
    }

    if (errorMsg) {
      this.postError(`Error: ${errorMsg}`, tokenInfo, token);
      this.setState({
        isLoading: 2,
        errMsg: `Error: ${errorMsg}`,
      });
      return;
    }

    //console.log(tokenInfo);

    // Check for local login info
    if (
      localStorage.getItem('external_EmpId') === empId &&
      localStorage.getItem('external_LoginId') === loginId &&
      localStorage.getItem('external_token') &&
      localStorage.getItem('external_auth') &&
      new Date(localStorage.getItem('external_auth_exp')).getTime() >
        new Date().getTime() // still valid
    ) {
      console.log('Existing Session');
      //sessionStorage.setItem('isExternal', true);
      // use existing token
      this.setState(
        {
          isLoading: 0,
          page: page,
          orderNum: orderNum,
          custId: custId,
          custName: custName,
          orderType: orderType,
        },
        () => {
          this.initData();
        },
      );
    } else {
      // login to BobBoost
      console.log('New Session');
      var scope = this;
      auth.extSSOAccess(empId, loginId, appId, source, token).then((res) => {
        if (res.status) {
          //sessionStorage.setItem('isExternal', true);
          scope.setState(
            {
              isLoading: 0,
              page: page,
              orderNum: orderNum,
              custId: custId,
              custName: custName,
              orderType: orderType,
            },
            () => {
              this.initData();
            },
          );
        } else {
          if (res.errMsg) {
            this.setState({
              errMsg: 'Error: ' + res.errMsg,
              isLoading: 2,
            });
          }
        }
      });
    }
  }

  initData() {
    enumDict.generateEnums(enums); // update enum from API // for customer timeline
  }

  render() {
    if (this.state.isLoading === 1) {
      return <div>Authenticating...</div>;
    } else if (this.state.errMsg) {
      return <div>{this.state.errMsg}</div>;
    } else if (this.state.isLoading === -1) {
      return <div>Access Denied</div>;
    } else {
      return (
        <>
          {this.state.page === 'order-timeline' && (
            <div className="customer360">
              <BDFCustomer360
                externalAccess={true}
                orderNum={this.state.orderNum}
                custID={this.state.custId}
                custName={this.state.custName}
                enums={{
                  w: enums.writeSalesBucket,
                  d: enums.openOrderBucket,
                  r: enums.repeatSalesBucket,
                }}
                orderType={this.state.orderType}
                toggle={() => {}}
              />
            </div>
          )}

          <NotificationSystem
            dismissible={false}
            ref={(notificationSystem) =>
              (this.notificationSystem = notificationSystem)
            }
            style={NOTIFICATION_SYSTEM_STYLE}
          />
        </>
      );
    }
  }
}

ExternalAccessPage.propTypes = {
  page: PropTypes.string,
};

ExternalAccessPage.defaultProps = {
  page: '',
};

export default ExternalAccessPage;
