import React from 'react';
import {
  DeliverSales,
  CoachingLaunchpad,
  Clock,
  Sofa,
  Table,
  Hand,
  House,
  Money,
  Helpicon,
  bobassist,
} from '../../../assets/img/menus';

const helper = require('../../../utils/helper');
const orderLib = require('../../../bdf-applications/orders-management/lib/orders');
const ux = require('../../ux-tracking-center');

// Common notification module
export const notificationSceduler = async (rand) => {
  // check path
  let path = window.location.pathname.split('/');
  let menu = path[1].toLowerCase();

  let module = null;
  switch (menu) {
    case 'openorders':
      module = window.BDFModules.deliver;
      break;
    case 'writesales':
      module = window.BDFModules.write;
      break;
    default:
  }

  let parameters = JSON.parse(localStorage.getItem('Parameters') || '{}');
  let fq = parseInt(parameters.UI_SCHEDULER || 0);
  let toggle = false;

  if (fq === 0)
    // turned off
    return;

  try {
    await fetch(helper.getAPIHost() + '/api/getNotification', {
      method: 'GET',
      ...helper.apiHeaders(),
    }).then(async (data) => {
      const json = await data.json();
      let currData = json.result;

      helper.catchServer(json);
      console.log('BDF-NOTIFICATION: ' + new Date() + ' In-Menu: ' + menu);

      // process for order scheduling & notification
      toggle = parseInt(parameters.UI_NOTIFICATION || 0);
      if (toggle) {
        if (currData.hasOwnProperty('ordersForScheduling')) {
          orderSchedulingNotification(currData.ordersForScheduling);
        }

        if (currData.hasOwnProperty('generalNotification')) {
          generalNotification(currData.generalNotification);
        }
      }

      // check order update and refresh
      toggle = parseInt(parameters.UI_ORDER_REFRESH || 0);
      if (toggle) checkOrderForUpdate(currData, menu, module, parameters);

      //force logout
      checkAdminForceLogout(currData);

      // call for next
      window.orderSchedule = setTimeout(
        () => notificationSceduler(rand),
        fq * 1000,
      );
    });
  } catch (r) {
    console.log('Error on check notification');
    console.log(r);
  }
};

export const checkAdminForceLogout = (currData) => {
  console.log('force logout');
  let parameters = JSON.parse(localStorage.getItem('Parameters') || '');
  let newSession = parameters.AdminForceLogoutSequences || null;
  let oldSession = currData.adminForceLogout.paramValue || null;

  // localStorage
  console.log(currData);
  console.log(newSession);
  console.log(oldSession);

  if (oldSession !== newSession) {
    window.location.href = '/logout';
  }
};

export const checkOrderForUpdate = (currData, menu, module, parameters) => {
  // only proceed when menu is open order / write sales
  if (menu !== 'openorders' && menu !== 'writesales') return;

  let key = 'deliveredSalesLatestRefresh';
  if (menu === 'writesales') key = 'writeSalesLatestRefresh';

  // previous datetime
  let lastCheckStr = localStorage.getItem('orderLastCheck');
  let lastCheck = {};

  // if first time
  if (lastCheckStr === '')
    lastCheck = {
      deliveredSalesLatestRefresh: '',
      writeSalesLatestRefresh: '',
    };
  else lastCheck = JSON.parse(lastCheckStr || '{}');

  console.log({ last: lastCheck, cur: currData });

  let prevUpdate;
  let currUpdate;

  //console.log(lastCheck[key])
  //console.log(new Date(lastCheck[key].replace(' AM', '').replace(' PM', '')))

  // convert to time
  if (lastCheck[key]) {
    prevUpdate = new Date(
      lastCheck[key].replace(' AM', '').replace(' PM', ''),
    ).getTime();
    currUpdate = new Date(
      currData[key].replace(' AM', '').replace(' PM', ''),
    ).getTime();
  } else {
    prevUpdate = new Date().getTime();
    currUpdate = new Date().getTime();
  }

  if (lastCheck[key] !== '' && currUpdate > prevUpdate) {
    console.log('Order update detected ' + menu);

    let autoRefresh = parseInt(parameters.UI_ORDER_AUTO_REFRESH || 0);

    if (
      menu === 'openorders' &&
      module !== null &&
      parseInt(module.state.caller.state.selectedBucket) < 1000
    ) {
      //CAUTION: MUST CHECK MODULE NOT NULL
      if (autoRefresh) {
        module.state.caller.getBucket(true);
        module.getDetails({ orderRefresh: true });
      }
      module.state.caller.callBack_updateOrderUpdated(true);
    } else if (
      menu === 'writesales' &&
      module !== null &&
      parseInt(module.state.caller.state.selectedBucket) < 1000
    ) {
      //CAUTION: MUST CHECK MODULE NOT null
      if (autoRefresh) {
        module.state.caller.getBucket(true);
        module.getDetails({ orderRefresh: true });
      }
      module.state.caller.callBack_updateOrderUpdated(true);
    }
  }

  // set to local storage
  localStorage.setItem(
    'orderLastCheck',
    JSON.stringify({
      deliveredSalesLatestRefresh: currData.deliveredSalesLatestRefresh,
      writeSalesLatestRefresh: currData.writeSalesLatestRefresh,
    }),
  );
};

export const orderSchedulingNotification = (newOrders) => {
  let headerNoti = window.message.util;
  let local_schedOrders = JSON.parse(
    localStorage.getItem('notifications') || '[]',
  );

  // remove undefined elements
  var schedOrders = local_schedOrders.filter(function (x) {
    return x !== undefined && x !== null;
  });

  // from newOrders check in schedOrders / add orders
  let found = false;
  newOrders.forEach((nOrder) => {
    found = false;
    schedOrders.forEach((oOrder) => {
      if (
        oOrder.type === 'scheduleOrder' &&
        nOrder.salesDocNbr === oOrder.salesDocNbr
      ) {
        // exclude non-scheduleOrders, no salesDocNbr
        found = true;
      }
    });

    if (!found) {
      // new order found
      schedOrders.unshift({
        salesDocNbr: nOrder.salesDocNbr,
        type: 'scheduleOrder',
        dateTime: nOrder.updatedTime + 'Z',
        bucketType: nOrder.bucketType,
        bucketID: nOrder.bucketID,
      });

      // generate notification
      let message = '';
      let orderNum = nOrder.salesDocNbr;
      let children = '';
      children = (
        <div
          className="order-schedule"
          order-num={orderNum}
          onClick={orderLib.handleReactNotiOrderClick}
        >
          <span>
            You reported an issue with the article availability. Please try to
            schedule order <div className="orderNum">#{orderNum}</div> again.
          </span>
        </div>
      );
      window.message.notification(message, 'success', children);
    }
  });

  // from schedOrders check in newOrders / remove orders
  schedOrders.forEach((oOrder, i) => {
    if (oOrder.type === 'scheduleOrder') {
      found = false;
      newOrders.forEach((nOrder) => {
        if (nOrder.salesDocNbr === oOrder.salesDocNbr) {
          found = true;
        }
      });

      if (!found) {
        // remove old order
        delete schedOrders[i];
      }
    }
  });

  // remove undefined elements
  var newSchedOrders = schedOrders.filter(function (x) {
    return x !== undefined && x !== null;
  });

  // push to header
  headerNoti.setState({
    messages: newSchedOrders,
  });

  // update to localstorage
  localStorage.setItem('notifications', JSON.stringify(schedOrders));
};

export const generalNotification = (newMessages) => {
  let headerNoti = window.message.util;
  let local_notifications = JSON.parse(
    localStorage.getItem('notifications') || '[]',
  );

  // remove undefined elements
  var notifications = local_notifications.filter(function (x) {
    return x !== undefined && x !== null;
  });

  // from newMessages check in notifications / add message
  let found = false;
  newMessages.forEach((nMsg) => {
    found = false;
    notifications.forEach((oMsg) => {
      if (oMsg.type !== 'scheduleOrder' && nMsg.uid === oMsg.msgId) {
        // exclude scheduleOrder, no uid/msgId
        found = true;
      }
    });

    if (!found) {
      // new order found
      notifications.unshift({
        msgId: nMsg.uid,
        type: nMsg.type,
        msgTxt: nMsg.message,
        dateTime: nMsg.modifiedDateTime + 'Z',
        refURL: nMsg.refURL,
        customField1: nMsg.customField1,
        customField2: nMsg.customField2,
        customField3: nMsg.customField3,
        customField4: nMsg.customField4,
        customField5: nMsg.customField5,
        customField6: nMsg.customField6,
        customField7: nMsg.customField7,
        customField8: nMsg.customField8,
        customField9: nMsg.customField9,
        customField10: nMsg.customField10,
      });

      // generate notification
      let message = nMsg.message;
      //let orderNum = nOrder.salesDocNbr;
      let children = '';
      /*children = 
            <div className="order-schedule" order-num={orderNum} onClick={orderLib.handleReactNotiOrderClick}>
                <span>
                    You reported an issue with the article availability. Please try to schedule order <a className="orderNum">#{orderNum}</a> again.
                </span>
            </div>;*/
      if (nMsg.type === 'OrderEscalation') {
        // let tittle = nMsg.customField2 + ' Has Escalated Order #' + nMsg.customField3;
        if (
          nMsg.customField5 === 'W' &&
          (nMsg.customField6 === '2' || nMsg.customField6 === '3')
        ) {
          message = 'Has Escalated Approval Number #';
        } else if (nMsg.customField5 === 'W' && nMsg.customField6 === '8') {
          message = 'Has Escalated Prospect ';
        } else {
          message = ' Has Escalated Order #';
        }
        children = (
          <div
            className="order-schedule"
            noti={nMsg.refURL}
            onClick={orderLib.handleReactNotiOrderClickURL.bind(
              this,
              nMsg.refURL,
            )}
          >
            <span style={{ color: 'white' }}>
              {nMsg.customField2} {message}{' '}
              <div className="orderNum">{nMsg.customField3}</div>.
            </span>
          </div>
        );
        window.message.notification('', 'error', children);
      } else if (nMsg.type?.toUpperCase() === 'BobAssist'.toUpperCase()) {
        if (nMsg.customField3 === '3') {
          window.message.notification(message, 'error', children);
        } else {
          window.message.notification(message, 'success', children);
        }
      } else {
        window.message.notification(message, 'success', children);
      }
    }
  });

  // from notifications check in new message / remove message
  notifications.forEach((oMsg, i) => {
    if (oMsg.type !== 'scheduleOrder') {
      found = false;
      newMessages.forEach((nMsg) => {
        if (nMsg.uid === oMsg.msgId) {
          found = true;
        }
      });

      if (!found) {
        // remove old order
        delete notifications[i];
      }
    }
  });

  // remove undefined elements
  var newNotifications = notifications.filter(function (x) {
    return x !== undefined && x !== null;
  });

  // push to header
  headerNoti.setState({
    messages: newNotifications,
  });

  // update to localstorage
  localStorage.setItem('notifications', JSON.stringify(newNotifications));
};

export const notificationArchived = async () => {
  let headerNoti = window.message.util;
  try {
    await fetch(helper.getAPIHost() + '/api/getArchivedNotification', {
      method: 'GET',
      ...helper.apiHeaders(),
    }).then(async (data) => {
      const json = await data.json();
      let result = json.result;
      console.log(result);
      let found = false;

      helper.catchServer(result);
      let oArchieved = headerNoti.state.archived;

      // --- archive will increase but not declease, so add only.
      // for orders
      if (result.hasOwnProperty('archivedOrdersForScheduling')) {
        result.archivedOrdersForScheduling.forEach((nOrder) => {
          found = false;

          oArchieved.forEach((oMsg) => {
            if (
              oMsg.type === 'scheduleOrder' &&
              oMsg.salesDocNbr === nOrder.salesDocNbr
            )
              found = true;
          });

          if (!found)
            oArchieved.push({
              salesDocNbr: nOrder.salesDocNbr,
              type: 'scheduleOrder',
              dateTime: nOrder.updatedTime + 'Z',
              bucketType: nOrder.bucketType,
              bucketID: nOrder.bucketID,
            });
        });
      }

      if (result.hasOwnProperty('archivedNotification')) {
        result.archivedNotification.forEach((nMsg) => {
          found = false;

          oArchieved.forEach((oMsg) => {
            if (oMsg.type !== 'scheduleOrder' && oMsg.msgId === nMsg.uid)
              found = true;
          });

          if (!found)
            oArchieved.push({
              msgId: nMsg.uid,
              type: nMsg.type,
              msgTxt: nMsg.message,
              dateTime: nMsg.modifiedDateTime + 'Z',
              refURL: nMsg.refURL,
              customField1: nMsg.customField1,
              customField2: nMsg.customField2,
              customField3: nMsg.customField3,
              customField4: nMsg.customField4,
              customField5: nMsg.customField5,
              customField6: nMsg.customField6,
              customField7: nMsg.customField7,
              customField8: nMsg.customField8,
              customField9: nMsg.customField9,
              customField10: nMsg.customField10,
            });
        });
      }

      // push to header
      headerNoti.setState({
        archived: oArchieved,
        archiveLoading: false,
      });
    });
  } catch (r) {
    console.log('Error on get archived notification');
    // push to header
    headerNoti.setState({
      archived: [],
      archiveLoading: false,
    });
  }
};

export const groupByDate = (messages) => {
  let newMsg = [[], [], [], []];

  let today = new Date();
  today.setHours(0);
  today.setMinutes(0);
  today.setSeconds(0);

  let yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  yesterday.setHours(0);
  yesterday.setMinutes(0);
  yesterday.setSeconds(0);

  let twoDaysAgo = new Date();
  twoDaysAgo.setDate(twoDaysAgo.getDate() - 2);
  twoDaysAgo.setHours(0);
  twoDaysAgo.setMinutes(0);
  twoDaysAgo.setSeconds(0);

  console.log('Notification date:', [today, yesterday, twoDaysAgo]);
  messages.forEach((msg) => {
    let cDateTime = new Date(msg.dateTime).getTime();
    if (cDateTime >= today.getTime()) newMsg[0].push(msg);
    else if (cDateTime >= yesterday.getTime()) newMsg[1].push(msg);
    else if (cDateTime >= twoDaysAgo.getTime()) newMsg[2].push(msg);
    else newMsg[3].push(msg);
  });

  return newMsg;
};

export const calcHoursText = (nDate) => {
  let dateTimeStr = '';
  let diffToday = (new Date().getTime() - nDate.getTime()) / 1000;
  diffToday /= 60;
  diffToday = Math.abs(Math.round(diffToday));

  if (diffToday < 60) {
    // less than 60 mins
    dateTimeStr =
      diffToday + (diffToday <= 1 ? ' minute' : ' minutes') + ' ago';
  } else if (diffToday / 60 < 24) {
    dateTimeStr =
      Math.round(diffToday / 60) +
      (diffToday / 60 <= 1 ? ' hour' : ' hours') +
      ' ago';
  } else dateTimeStr = nDate.toLocaleString().replace(',', '');

  return dateTimeStr;
};

export const constructMsgTemplate = (msg, isArchived) => {
  let notiMsg = {};
  let message = '';

  switch (msg.type) {
    case 'scheduleOrder':
      notiMsg = {
        msgId: msg.salesDocNbr,
        msgTxt: (
          <>
            Order ready for scheduling <div>#{msg.salesDocNbr}</div>
          </>
        ),
        msgIconSrc: DeliverSales,
        msgIconType: 'img',
      };
      break;
    case 'huddleRevertRequest':
      notiMsg = {
        msgId: msg.msgId,
        msgTxt: (
          <>
            {msg.msgTxt}
            <br />
            {(!isArchived || msg.customField5 === 'NEW') && (
              <>
                <button
                  className="iss-button-small green"
                  action="Approved"
                  msg-id={msg.msgId}
                  onClick={responseHuddle}
                >
                  Approve
                </button>{' '}
                <button
                  className="iss-button-small red"
                  action="Rejected"
                  msg-id={msg.msgId}
                  onClick={responseHuddle}
                >
                  Reject
                </button>
              </>
            )}
            {isArchived && msg.customField5 !== 'NEW' && (
              <span style={{ textTransform: 'capitalize' }}>
                Remarks:{' '}
                {msg.customField5 !== 'NEW' && msg.customField5.toLowerCase()}
              </span>
            )}
          </>
        ),
        msgIconSrc: CoachingLaunchpad,
        msgIconType: 'img',
      };
      break;
    case 'huddleResponse':
      notiMsg = {
        msgId: msg.msgId,
        msgTxt: msg.msgTxt,
        msgIconSrc: CoachingLaunchpad,
        msgIconType: 'img',
      };
      break;
    case 'FollowUp':
    case 'CnCFollowUp':
      if (msg.customField1?.length > 0) {
        message = ' Reminder of Follow-Up: ' + msg.customField1;
      } else {
        message = ' Reminder of Follow-Up';
      }
      notiMsg = {
        msgId: msg.msgId,
        msgTxt: (
          <>
            {message}
            <br />
            {msg.msgTxt}
          </>
        ),
        msgIconSrc: Clock,
        msgIconType: 'img',
      };
      break;
    case 'deliverCommission':
      notiMsg = {
        msgId: msg.msgId,
        msgTxt: <>{msg.msgTxt}</>,
        msgIconSrc: Money,
        msgIconType: 'img',
      };
      break;
    case 'deliverValue':
      let recevedMsg = '';
      let subjectMsg = '';
      let point =
        msg.customField1 > 0 ? '+' + msg.customField1 + ' points' : '';

      message = ' You’ve Received ' + point + ' Recognition';
      recevedMsg = msg.customField5 + ' from ' + msg.customField6;
      subjectMsg = msg.customField10;
      notiMsg = {
        msgId: msg.msgId,
        msgTxt: <>{message}</>,
        msgPerson: <>{recevedMsg}</>,
        msgTittle: <>For</>,
        msgTittleSubject: <>{subjectMsg}</>,
        msgIconSrc:
          msg.customField2 === '1'
            ? Sofa
            : msg.customField2 === '2'
            ? Table
            : msg.customField2 === '3'
            ? Hand
            : msg.customField2 === '4'
            ? House
            : '',
        msgIconType: 'logo',
      };
      break;
    case 'OrderEscalation':
      let orderNumber = msg.customField3;

      if (
        msg.customField5 === 'W' &&
        (msg.customField6 === '2' || msg.customField6 === '3')
      ) {
        message = msg.customField2 + ' Has Escalated Approval Number ';
        orderNumber = '#' + msg.customField3;
      } else if (msg.customField5 === 'W' && msg.customField6 === '8') {
        message = msg.customField2 + ' Has Escalated Prospect ';
        orderNumber = msg.customField3;
      } else {
        message = msg.customField2 + ' Has Escalated Order ';
        orderNumber = '#' + msg.customField3;
      }
      notiMsg = {
        msgId: msg.msgId,
        msgTittleEscalation: <>{message}</>,
        msgTxt: <>{msg.msgTxt}</>,
        msgOrderNo: <>{orderNumber}</>,
        msgIconSrc: Helpicon,
        msgIconType: 'img',
      };
      break;
    case 'FeedbackReply':
      notiMsg = {
        msgIconSrc: Hand,
        msgIconType: 'logo',
        msgId: msg.msgId,
        msgTxt: <>{msg.msgTxt}</>,
        refURL: msg.refURL,
      };
      break;
    case 'BobAssist':
      notiMsg = {
        msgId: msg.msgId,
        msgTxt: msg.msgTxt,
        msgIconSrc: bobassist,
        msgIconType: 'bobimg',
      };
      break;
    case 'GoBackRequestFailed':
      notiMsg = {
        msgId: msg.msgId,
        msgTxt: msg.msgTxt,
        msgIconSrc: '',
        msgIconType: '',
      };
      break;
    case 'deliverCommissionInq':
      notiMsg = {
        msgId: msg.msgId,
        msgTxt: (
          <>
            {msg.msgTxt}
            <br />
            {/* {(!isArchived || msg.customField5 === 'NEW') && (
              <>
                <button
                  className="iss-button-small green"
                  action="Approved"
                  msg-id={msg.msgId}
                  msg-UID={msg.customField6}
                  onClick={responCommissionInq}
                >
                  Approve
                </button>{' '}
                <button
                  className="iss-button-small red"
                  action="Declined"
                  msg-id={msg.msgId}
                  msg-UID={msg.customField6}
                  onClick={responCommissionInq}
                >
                  Decline
                </button>
              </>
            )} */}
            {isArchived && msg.customField5 !== 'NEW' && (
              <span style={{ textTransform: 'capitalize' }}>
                Remarks:{' '}
                {msg.customField5 !== 'NEW' && msg.customField5.toLowerCase()}
              </span>
            )}
          </>
        ),
        msgIconSrc: CoachingLaunchpad,
        msgIconType: 'img',
      };
      break;
    default:
      notiMsg = {
        msgId: msg.msgId,
        msgTxt: msg.msgTxt,
        msgIconSrc: '',
        msgIconType: '',
      };
      break;
  }
  return notiMsg;
};

export const responseHuddle = async (e) => {
  e.stopPropagation();
  console.log(
    e.target.getAttribute('msg-id') + ' ' + e.target.getAttribute('action'),
  );

  // TODO
  // call to /api/Coaching/approveHuddle
  // UID = msg-id
  // status = action
  let UID = e.target.getAttribute('msg-id');
  let status = e.target.getAttribute('action');

  let retData = {
    status: status,
    uid: UID,
  };

  console.log(retData);

  await fetch(helper.getAPIHost() + '/api/Coaching/approveHuddle', {
    method: 'PUT',
    body: JSON.stringify(retData),
    ...helper.apiHeaders(),
  })
    .then(async (response) => {
      let data = await response.json();
      if (data.status === 'OK') {
        console.log('Huddle has been ' + status + '!');
        console.log(data);
        helper.catchServer(data);
        // this.notification("Huddle has been "+status+"!", enums.notificationType.S)
        //notificationSceduler()
        window.message.util.removeMessageByMsgId(UID, false);
      } else {
        // this.notification("Failed to Aprroved huddle. Error message:" + data.errorMsg + " " + (data.result || "") ,enums.notificationType.E);
        ux.logError(
          'Notification',
          'Failed to Aprroved huddle ',
          'Failed to Aprroved huddle.',
          data.errorMsg +
            ' ' +
            (data.result || '') +
            ' Revert:' +
            JSON.stringify(retData),
        );
      }
    })
    .catch((error) => {
      // this.notification("Send Data API: RevertHuddle - "+ error,enums.notificationType.E);
      ux.logError(
        'Notification',
        'Failed to Aprroved huddle ',
        'Send Data API: RevertHuddle',
        error + ' Send: ' + JSON.stringify(retData),
      );
    });
};

export const responCommissionInq = async (e) => {
  e.stopPropagation();
  console.log(
    e.target.getAttribute('msg-id') + ' ' + e.target.getAttribute('action'),
  );

  let id = e.target.getAttribute('msg-id');
  let UID = e.target.getAttribute('msg-UID');
  let status = e.target.getAttribute('action');

  const payload = {
    UID: UID,
    salesAssociateName: localStorage.getItem('EmplName'),
    SalesAssociateId: localStorage.getItem('EmplId'),
    Status: status,
    Comment: '',
    NotifyUID: id,
  };

  console.log(payload);

  await fetch(
    helper.getAPIHost() +
      `/api/infopath/updateCommissionInquiry?UID=${payload.UID}&SalesAssociateName=${payload.salesAssociateName}&SalesAssociateId=${payload.SalesAssociateId}&Status=${payload.Status}&Comment=${payload.Comment}&NotifyUID=${payload.NotifyUID}`,
    {
      method: 'PUT',
      ...helper.apiHeaders(),
    },
  )
    .then(async (response) => {
      let data = await response.json();
      if (data.status === 'OK') {
        console.log(data);
        helper.catchServer(data);
        window.message.util.removeMessageByMsgId(id, false);
      } else {
        ux.logError(
          'Notification',
          'Failed to Aprroved Commission ',
          'Failed to Aprroved Commission.',
          data.errorMsg +
            ' ' +
            (data.result || '') +
            ' updateCommissionInquiry:' +
            JSON.stringify(payload),
        );
      }
    })
    .catch((error) => {
      ux.logError(
        'Notification',
        'Failed to Aprroved Commission ',
        'Send Data API: updateCommissionInquiry',
        error + ' Send: ' + JSON.stringify(payload),
      );
    });
};

export const closeNotification = async (msgId, isBobAssist) => {
  console.log('close notification message ' + msgId, isBobAssist);
  // TODO
  // call to /api/updateNotification
  // UID = msgId
  // status = 1
  let UID = parseInt(msgId);

  // let retData = {
  //     uid : UID
  // };

  console.log(UID);

  await fetch(
    helper.getAPIHost() +
      '/api/updateNotification?uid=' +
      UID +
      '&isBobAssist=' +
      isBobAssist,
    {
      method: 'POST',
      ...helper.apiHeaders(),
    },
  )
    .then(async (response) => {
      let data = await response.json();
      if (data.status === 'OK') {
        console.log('Notification has been send to archived!');
        console.log(data);
        helper.catchServer(data);
        notificationSceduler();
        // this.notification("Huddle has been "+status+"!", enums.notificationType.S)
      } else {
        // this.notification("Failed to Aprroved huddle. Error message:" + data.errorMsg + " " + (data.result || "") ,enums.notificationType.E);
        ux.logError(
          'Notification',
          'Failed to archived',
          'Failed to archived.',
          data.errorMsg + ' ' + (data.result || '') + ' Failed:' + UID,
        );
      }
    })
    .catch((error) => {
      // this.notification("Send Data API: RevertHuddle - "+ error,enums.notificationType.E);
      ux.logError(
        'Notification',
        'Failed to archived ',
        'Send Data API: Archived the notification',
        error + ' Failed: ' + UID,
      );
    });
};
