import React from 'react';
import {
  /*Table,*/ Label,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import PropTypes from 'prop-types';
import enums from '../../../../enums';
import DateTimePicker from 'react-datetime-picker';
import { FcCalendar } from 'react-icons/fc';
// import { MdClose } from 'react-icons/md';
import { enGB } from 'date-fns/locale';
import { DatePickerCalendar } from 'react-nice-dates';
import 'react-nice-dates/build/style.css';
import { ModalBtnClose } from '../../../../components/CommonComponent';

const helper = require('../../../../utils/helper');
const ux = require('../../../../application-center/ux-tracking-center');
const notiLib = require('../../../../application-center/notification-center/lib/notification');

class BDFCust360FollowUp extends React.Component {
  state = {
    orderNumberList: [],
    inputOrderNumber: '',
    inputComment: '',
    extOrder: '',
    isExternal: false,
    custName: '',
    custID: '',
    caller: {},
    //OrderID:"",
    // reminderDate:`${new Date().getFullYear()}-${`${new Date().getMonth() +
    //     1}`.padStart(2, 0)}-${`${new Date().getDate() + 0}`.padStart(
    //     2,
    //     0
    //   )}T${`${new Date().getHours()}`.padStart(
    //     2,
    //     0
    //   )}:${`${new Date().getMinutes()}`.padStart(2, 0)}`,

    //MOdal for Reminder
    reminderModal: false,
    reminderDate: new Date(),
  };

  patchOrderNumber = (OrderID, orderNumberList) => {
    // attach order number into list
    if (OrderID) {
      console.log(JSON.stringify(orderNumberList));
      let orderFound = false;
      orderNumberList.map((o) => {
        if (o.orderNumber === OrderID) orderFound = true;
        return o;
      });
      if (!orderFound) {
        let d = new Date();
        orderNumberList.push({
          orderNumber: OrderID,
          orderTransactionDate:
            d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate(),
        });
      }
      console.log(JSON.stringify(orderNumberList));
    }

    return orderNumberList;
  };

  componentDidMount() {
    const { orderNumberList, custName, custID, caller, OrderID, isExternal } =
      this.props;

    const orderNumberList_v2 = this.patchOrderNumber(OrderID, orderNumberList);
    this.setState({
      orderNumberList: orderNumberList_v2.sort(
        helper.sort('orderTransactionDate', 'desc'),
      ),
      inputOrderNumber: OrderID || '',
      extOrder: OrderID || '',
      isExternal: isExternal,
      custName: custName,
      custID: custID,
      caller: caller,
    });
  }

  componentDidUpdate() {
    const { orderNumberList, OrderID } = this.props;

    const orderNumberList_v2 = this.patchOrderNumber(OrderID, orderNumberList);

    if (orderNumberList_v2.length !== this.state.orderNumberList.length) {
      this.setState({
        orderNumberList: orderNumberList_v2.sort(
          helper.sort('orderTransactionDate', 'desc'),
        ),
      });
    }
  }

  handleInputChanged = (event) => {
    let name = event.target.name;
    let toset = {};
    toset[`input${name}`] = event.target.value;

    this.setState(toset);
  };

  handleAddComment = async (event) => {
    if (!this.state.inputComment) {
      alert('Please dont leave comment blank');
      return;
    }

    if (this.state.reminderDate === '') {
      alert('Please dont leave date blank');
      return;
    }

    let inputOrderNumber = this.state.inputOrderNumber;

    // if(inputOrderNumber === ""){
    //     inputOrderNumber = this.state.OrderID;
    // }

    let payload = {
      customerId: this.state.custID,
      documentId: inputOrderNumber,
      message: this.state.inputComment,
      dateTime: this.changedate(this.state.reminderDate),
    };

    console.log(payload);

    await fetch(helper.getAPIHost() + '/api/Customer360/postFollowUp', {
      method: 'POST',
      body: JSON.stringify(payload),
      ...helper.apiHeaders(),
    })
      .then(async (res) => {
        if (res.status === 200) {
          this.state.caller.notification(
            `Follow-up Added Successfully`,
            enums.notificationType.S,
          );
          this.setState({ inputComment: '' }, () => {
            setTimeout(() => {
              this.state.caller.reloadConatctTimeline();
            }, 1000);
          });
          notiLib.notificationSceduler();
          ux.logAction(
            'Customer Profile',
            'Add Follow Up',
            'CustId: ' +
              this.state.custID +
              '; OrderNum: ' +
              inputOrderNumber +
              '; path: ' +
              ux.getPath(),
          );
        }
      })
      .catch((err) => {
        this.state.caller.notification(
          `Follow-up Failed, ${err}`,
          enums.notificationType.E,
        );
        ux.logError('Follow-up', 'postFollowUp', err, JSON.stringify(payload));
      });
  };

  handleChange = (field, e) => {
    console.log(e.target.value);
    this.setState({ [field]: e.target.value });
  };

  openFollowUpModal = () => {
    let reminderModal = !this.state.reminderModal;

    this.setState({
      reminderModal: reminderModal,
    });
  };

  reminderDateShortcut(e) {
    let type = e.target.getAttribute('type');

    let reminderDate = new Date();
    if (type === '1h') {
      reminderDate = reminderDate.setTime(
        reminderDate.getTime() + 1 * 60 * 60 * 1000,
      );
    }
    if (type === '4h') {
      reminderDate = reminderDate.setTime(
        reminderDate.getTime() + 4 * 60 * 60 * 1000,
      );
    }
    if (type === '1d') {
      reminderDate = reminderDate.setDate(reminderDate.getDate() + 1);
    }

    // console.log(reminderDate)
    this.setState({
      reminderDate: new Date(reminderDate),
      reminderType: type,
      reminderModal: false,
    });
  }

  reminderDateChange(date) {
    console.log(date);
    this.setState({
      reminderDate: date,
      reminderModal: false,
    });
  }

  handleDate = (event) => {
    console.log(event);
    this.setState({ reminderDate: event });
  };

  changedate(indate) {
    let date_ob = new Date(indate);

    // adjust 0 before single digit date
    let date = ('0' + date_ob.getDate()).slice(-2);

    // current month
    let month = ('0' + (date_ob.getMonth() + 1)).slice(-2);

    // current year
    let year = date_ob.getFullYear();

    // current hours
    let hours = date_ob.getHours();

    // current minutes
    let minutes = (date_ob.getMinutes() < 10 ? '0' : '') + date_ob.getMinutes();

    // current seconds
    // let seconds = date_ob.getSeconds();

    // prints date & time in YYYY-MM-DD HH:MM:SS format
    let returnDate =
      year + '-' + month + '-' + date + ' ' + hours + ':' + minutes;
    // console.log(year + "-" + month + "-" + date + " " + hours + ":" + minutes + ":" + seconds);
    return returnDate;
  }

  render() {
    return (
      <div>
        <div className="title"></div>
        <div className="orderSelect">
          <Label>Order # (Order Date)</Label>
          <Input
            type="select"
            name="OrderNumber"
            value={this.state.inputOrderNumber}
            onChange={this.handleInputChanged.bind(this)}
            disabled={this.state.isExternal && this.state.extOrder}
          >
            <option value=""></option>
            {this.state.orderNumberList.map((order, i) => {
              return (
                <option key={i} value={order.orderNumber}>
                  {order.orderNumber} (
                  {helper.formatDate(order.orderTransactionDate)})
                </option>
              );
            })}
          </Input>
          <Label>Reminder Date : </Label>
          {/* <Input  type="datetime-local" className="test" name="reminderDate" value={this.state.reminderDate} onChange={e => this.handleChange('reminderDate', e)}/> */}
          <div className="datepick">
            <DateTimePicker
              onChange={this.handleDate}
              // onClick={this.openFollowUpModal.bind(this)}
              value={this.state.reminderDate}
              minDate={new Date()}
              disableClock={true}
              id={'startDate'}
              className={'startDate'}
              format={'MM-dd-yyyy h a'}
              disableCalendar={true}
              // disabled ={true}
              clearIcon={null}
            />
          </div>
          <div className="test" onClick={this.openFollowUpModal.bind(this)}>
            <FcCalendar size={35} />
          </div>
        </div>
        <div className="form">
          <Input
            type="textarea"
            name="Comment"
            value={this.state.inputComment}
            onChange={this.handleInputChanged.bind(this)}
            placeholder="Comment"
          />
          <button
            className={`saveButton${this.state.isExternal ? 'BobSuite' : ''}`}
            onClick={this.handleAddComment.bind(this)}
          >
            Save
          </button>
        </div>

        {/* Modal for update reminder    */}
        <Modal
          isOpen={this.state.reminderModal}
          toggle={this.openFollowUpModal.bind(this)}
          className="order-reminder-modal"
        >
          <ModalHeader>
            <ModalBtnClose onClick={this.openFollowUpModal.bind(this)} />
            Reminder Date
          </ModalHeader>
          <ModalBody className="reminder-order">
            <div>When would you like to be reminded to follow up?</div>

            <div className="shortchut">
              <span
                onClick={this.reminderDateShortcut.bind(this)}
                type="1h"
                className={
                  this.state.reminderType === '1h' ? 'dateselected' : ''
                }
              >
                1 Hours
              </span>
              <span
                onClick={this.reminderDateShortcut.bind(this)}
                type="4h"
                className={
                  this.state.reminderType === '4h' ? 'dateselected' : ''
                }
              >
                4 Hours
              </span>
              <span
                onClick={this.reminderDateShortcut.bind(this)}
                type="1d"
                className={
                  this.state.reminderType === '1d' ? 'dateselected' : ''
                }
              >
                Tomorrow
              </span>
            </div>

            <DatePickerCalendar
              locale={enGB}
              minimumDate={new Date()} // See Calendar props
              // maximumDate={this.state.snoozeEnd} // See Calendar props
              date={this.state.reminderDate}
              // format='dd/MM/yyyy HH:mm a'
              value={this.state.reminderDate}
              onDateChange={this.reminderDateChange.bind(this)}
            />
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

BDFCust360FollowUp.propTypes = {
  caller: PropTypes.object,
  orderNumberList: PropTypes.array,
  custName: PropTypes.string,
  custID: PropTypes.string,
  OrderID: PropTypes.string,
  isExternal: PropTypes.bool,
};

BDFCust360FollowUp.defaultProps = {
  caller: {},
  orderNumberList: [],
  custName: '',
  custID: '',
  OrderID: '',
  isExternal: false,
};

export default BDFCust360FollowUp;
