module.exports = global.enums = {
  userGroups: {
    SystemAdmin: 1,
    StoreManager: 2,
    SeniorGxs: 26,
    Office: 3,
    Outlet: 4,
    BobsSquad: 5,
    Showroom: 6,
    StoreSupport: 7,
    StoreFloater: 9,
    Special: 12,
  },
  userGroupsStr: [
    /* use now */ 'All',
    'System Admin',
    'Store Manager',
    'SeniorGxs',
    'Office Associate',
    'Outlet Associate',
    'Bobs Squad',
    'Showroom Associate',
    'Store Support',
  ],
  sessionType: {
    huddle: 1,
    '2mindrill': 2,
  },
  feedbackType: {
    Positive: 1,
    Opportunistic: 2,
  },
  feedbackTypeStr: ['', 'Positive', 'Opportunistic'],
  coachingStatus: {
    '': 'N',
    draft: 'F',
    final: 'T',
  },
  coachingStatusStr: {
    N: 'New',
    F: 'Draft',
    T: 'Final',
  },
  notificationType: {
    E: 'error',
    I: 'info',
    W: 'warning',
    S: 'success',
  },
  openOrderBucket: {
    // no using anymore
    // please update in /bucketsetup
    /*
        'AllScheduledOrders': { value: 0, label: "All Scheduled Orders", action: false },
        'AllBlockedOrders': { value: 1, label: "All Blocked Orders", action: false },
        'BlockedNoContact': { value: 2, label: "Blocked - No Contact 7+ Days", action: true },
        'BlockedPushedOut': { value: 3, label: "Blocked - Availability Move Out 7+ Days", action: true },
        'BlockedAvailableSooner': { value: 4, label: "Blocked - Full Delivery Avail Within 2 Weeks", action: true },
        'BlockedInventoryAvailable': { value: 5, label: "Blocked - Inventory Available Immediately", action: true },
        'AllPickUps': { value: 6, label: "All Pick Ups", action: false },
        'AllTakeWiths': { value: 7, label: "All Take Withs", action: false },
        'AllCarrierShipments': { value: 8, label: "All Carrier Shipments", action: false },
        'ATPDelayDayPrior': { value: 9, label: "Scheduled Next Day - Merch Unavailable", action: true },
        'MismatchDaysPrior': { value: 10, label: "Scheduled 2-3 Days Out - Merch Unavailable", action: true },
        'CustomerPickupMerchAvailable' : { value: 11, label: "Customer Pickup - Merch Available", action: true },
        'SKUonOrderNotAvailablefromDC': { value: 12, label: "SKU on Order - Not Available from DC", action: true },
        'BlockedNoMoneyonOrder': { value: 13, label: "Blocked - No Money on Order", action: true },
        'CancelledOrders': { value: 14, label: "Cancelled Orders - Last 30 Days", action: false },
        'ScheduledNoGoof': { value: 15, label: "Scheduled – 5 Days Out – No Goof", action: true },
        'WebSaleAssociateActionNeeded': { value: 16, label: "Web Sale – Associate Action Needed", action: true },
        'WebSaleNew': { value: 17, label: "Web Sale – New and Unassigned", action: true },
        'ScheduledIncompletesectionals': { value: 18, label: "Scheduled - Incomplete Sectionals", action: true }
*/
  },
  openOrderBucketById: [
    /* not using */
    { value: 0, label: 'All Scheduled Orders', code: 'AllScheduledOrders' },
    { value: 1, label: 'All Blocked Orders', code: 'AllBlockedOrders' },
    {
      value: 2,
      label: 'Blocked - No Contact 7+ Days',
      code: 'BlockedNoContact',
    },
    {
      value: 3,
      label: 'Blocked - Availability Move Out 7+ Days',
      code: 'BlockedPushedOut',
    },
    {
      value: 4,
      label: 'Blocked - Available Sooner',
      code: 'BlockedAvailableSooner',
    },
    {
      value: 5,
      label: 'Blocked - Inventory Available Immediately',
      code: 'BlockedInventoryAvailable',
    },
    { value: 6, label: 'All Pick Ups', code: 'AllPickUps' },
    { value: 7, label: 'All Take Withs', code: 'AllTakeWiths' },
    { value: 8, label: 'All Carrier Shipments', code: 'AllCarrierShipments' },
    {
      value: 9,
      label: 'Scheduled Next Day - Merch Unavailable',
      code: 'ATPDelayDayPrior',
    },
    {
      value: 10,
      label: 'Scheduled 2-3 Days Out - Merch Unavailable',
      code: 'MismatchDaysPrior',
    },
    {
      value: 11,
      label: 'Customer Pickup - Merch Available',
      code: 'CustomerPickupMerchAvailable',
    },
  ],

  writeSalesBucket: {
    OpenQuotesAll: { value: 0, label: 'Open Quotes - All', action: false },
    OpenQuotesCustomerActivity: {
      value: 1,
      label: 'Open Quotes - Customer Activity',
      action: true,
    },
    WebFinancingApprovalAssigned: {
      value: 2,
      label: 'Web Financing Approval - Assigned',
      action: true,
    },
    WebFinancingApprovalUnassigned: {
      value: 3,
      label: 'Web Financing Approval - Unassigned',
      action: true,
    },
    MerchWrittenfromWrongDC: {
      value: 4,
      label: 'Merch Written from Wrong DC',
      action: false,
    },
    // 'ConvertedQuotesLast7Days': { value: 5, label: "Converted Quotes - Last 7 Days" },
    OpenQuotes7Days: {
      value: 5,
      label: 'Open Quotes - Time Based Follow Up',
      action: true,
    },
  },

  repeatSalesBucket: {
    BedroomNoMattress: {
      value: 0,
      label: 'Bedroom no mattress 6 months old',
      action: true,
    },
  },

  merchandiseInsightsBucket: {
    ProductSearch: { value: 5, label: 'Product Search' },
    MerchandiseNews: { value: 0, label: 'Merchandise News' },
    WhatsInStock: { value: 1, label: "What's In Stock" },
    WhatsHot: { value: 2, label: "What's Hot" },
    SubstitutionHelp: { value: 3, label: 'Substitution Help' },
    FUTPlaceholder: { value: 4, label: 'FUT Placeholder' },
    BeddingToGo: { value: 6, label: 'Bedding To Go' },
    Lineups: { value: 7, label: 'Lineups' },
  },

  orderBlockedType: {
    Z1: 'Not Fully Tendered',
    Z2: 'Waiting On Customer',
    Z3: 'Special Order',
    Z4: 'Waiting on Merchandise',
    Z5: 'Price Protection',
    Z6: 'Zone Exception',
    Z7: 'Commercial Order',
    Z8: 'Waiting On Part',
    Z9: 'Pending Review',
    ZA: 'Cleanup',
    ZR: 'Alternate Financing',
    ZZ: 'Scheduled (Not Blocked)', // might remove from here
    ZC: 'Delivery Mass Cancel',
    ZD: 'COVID-No Delv Now',
    ZE: 'Reclaimed Inventory',
  },

  orderBlockedTypeArray: [
    // must maintain this as above code & name
    { value: 'Z1', label: 'Not Fully Tendered' },
    { value: 'Z2', label: 'Waiting On Customer' },
    { value: 'Z3', label: 'Special Order' },
    { value: 'Z4', label: 'Waiting on Merchandise' },
    { value: 'Z5', label: 'Price Protection' },
    { value: 'Z6', label: 'Zone Exception' },
    { value: 'Z7', label: 'Commercial Order' },
    { value: 'Z8', label: 'Waiting On Part' },
    { value: 'Z9', label: 'Pending Review' },
    { value: 'ZA', label: 'Cleanup' },
    { value: 'ZR', label: 'Alternate Financing' },
    { value: 'ZZ', label: 'Scheduled (Not Blocked)' },
    { value: 'ZC', label: 'Delivery Mass Cancel' },
    { value: 'ZD', label: 'COVID-No Delv Now' },
    { value: 'ZE', label: 'Reclaimed Inventory' },
  ],

  orderTimeSlot: [
    '',
    'This Week',
    'Next Week',
    'This Month',
    'Next Month',
    'Next 30 Days',
  ],

  orderShipDateSlot: ['', 'Past', 'Today', 'Future'],

  prospectTypeList: ['1', '2'],

  Filterby: [
    { value: 'AllAssociates', label: 'All GX Specialist' },
    { value: 'filterNotWorkingToday', label: 'Not Working Today' },
    { value: 'UnassignedOrders', label: 'Unassigned Orders' },
    { value: 'Associate', label: 'GX Specialist By Name' },
    //Added filter for Cancelled Orders - Last 30 Days by Cxl'd Reason in code
  ],

  dynamicFields: [
    {
      value: 0,
      label: 'Custom',
      mandatory: false,
      code: 'empty',
      location: '',
    },
    {
      value: 1,
      label: 'Store ID',
      mandatory: true,
      code: 'storeId',
      location: 'h',
    },
    {
      value: 2,
      label: 'Customer ID',
      mandatory: true,
      code: 'customerID',
      location: 'h',
    },
    {
      value: 3,
      label: 'Customer Name',
      mandatory: true,
      code: 'customerName',
      location: 'h',
    },
    {
      value: 4,
      label: 'GX Specialist ID',
      mandatory: true,
      code: 'salesAssociateId',
      location: 'h',
    },
    {
      value: 5,
      label: 'Order Number',
      mandatory: true,
      code: 'orderNumber',
      location: 'h',
    },
    {
      value: 6,
      label: 'Contact Method',
      mandatory: false,
      code: 'contactMethod',
      location: 'h',
    },
    {
      value: 7,
      label: 'Article Number',
      mandatory: true,
      code: 'articleNum',
      location: 'd',
    },
    {
      value: 8,
      label: 'Article Description',
      mandatory: true,
      code: 'articleDesc',
      location: 'd',
    },
    {
      value: 9,
      label: 'Order Amount',
      mandatory: false,
      code: 'orderAmount',
      location: 'h',
    },
    {
      value: 10,
      label: 'Line Qty',
      mandatory: false,
      code: 'lineQty',
      location: 'd',
    },
    {
      value: 11,
      label: 'Line Amount',
      mandatory: false,
      code: 'lineAmount',
      location: 'd',
    },
    {
      value: 12,
      label: 'ATP Date',
      mandatory: false,
      code: 'atpDate',
      location: '',
    },
    {
      value: 13,
      label: 'Block Status',
      mandatory: false,
      code: 'blockStatus',
      location: '',
    },
    {
      value: 14,
      label: 'Money Status',
      mandatory: false,
      code: 'moneyStatus',
      location: 'h',
    },
    {
      value: 15,
      label: 'Goof Status',
      mandatory: false,
      code: 'goofStatus',
      location: '',
    },
    {
      value: 16,
      label: 'Drop Indicator',
      mandatory: false,
      code: 'dropIndicator',
      location: '',
    },
    {
      value: 17,
      label: 'Phone',
      mandatory: true,
      code: 'phone',
      location: 'h',
    },
    {
      value: 18,
      label: 'Email',
      mandatory: false,
      code: 'email',
      location: 'h',
    },
  ],

  dynamicsPriority: {
    D: [
      { value: 29, label: 'Critical' },
      { value: 30, label: 'Highly Important' },
      { value: 31, label: 'Important' },
      { value: 32, label: 'FYI' },
    ],
    W: [
      { value: 56, label: 'Critical' },
      { value: 57, label: 'Highly Important' },
      { value: 58, label: 'Important' },
      { value: 59, label: 'FYI' },
    ],
  },
};
